import NewLayout from "../../Components/SidebarLayout";
import React, { useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import ServicesSideBar from "../../Components/ServicesSideBar";
import logo from "../../assets/images/FileNowLogo.svg";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import LegalDocumentCard from "../../Components/LegalDocumentCard";
import Header from "../../Components/Header";
import imgfile from "../../assets/images/Vector.png";
import axiosInstance from "../../Utilities/axiosInstance";
import { Grid, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ImageExpand from "../../Components/ImageExpand";
import CardV2 from "../../Components/CardV2";
import ServicesCard from "../../Components/ServicesCard";
import DocumentCards from "../../Components/DocumentCards";
const filters = ["filter1", "filter2", "filter3", "filter4"];

function LegalDocumentationAdmin() {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const paths = [
    { label: `Legal Documents`, navigation: "legal-documentation" },
  ];

  return (
    <>
      <NewLayout
        paths={paths}
        pageHeading={"Legal Documents"}
      >
        <div className="d-flex">
          <div className="ld-wrap1">
            <div className="d-flex">
            <Typography className="ld-bg ld-border">All</Typography>
            <Typography className="ld-bg">Housing</Typography>
            <Typography className="ld-bg">Business</Typography>
            <Typography className="ld-bg">NDA</Typography>
            </div>

            <Grid conatiner className="grid-end">
              <Grid item>
                <button className="tg-button1">+ Add New Document</button>
                <button className="tg-button2">Filter</button>
                <button className="tg-button2">Search</button>
              </Grid>
            </Grid>

            <Grid container>
              <p className="ms-4 fw-bold"> Rental Document </p>
            </Grid>
            <Grid container>
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
              <DocumentCards desc="Rental Document - Home" />
            </Grid>
            <Grid item display="flex" justifyContent="center" margin={10}>
              <Pagination
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </Grid>
          </div>

          <Grid className="ld-w2">
            <Grid container className="ld-prof">
              profile
            </Grid>
            <Grid container className="ld-w-1">
              <Typography fontWeight="700">Add New Document</Typography>
            </Grid>
            <Grid container>
              <input className="inp-ld" placeholder="Document Name"></input>
            </Grid>
            <Grid container>
              <input className="inp-ld" placeholder="Document Category"></input>
            </Grid>
            <Grid container>
              <label for="fileInput" class="custom-file-upload">
                <Typography>
                  <img src={imgfile} className="img-ld"></img>Drag Here
                </Typography>

                <p className="ld-text-1">Drag and drop or browse your files</p>
              </label>
              <input type="file" id="fileInput" className="ld-inp-1" />
            </Grid>
            <Grid container className="ld-padd">
              <Typography fontWeight="700">Thumbnail</Typography>
            </Grid>
            <Grid container>
              <label for="fileInput" class="custom-file-upload">
                <Typography>
                  <img src={imgfile} className="img-ld"></img>Drag Here
                </Typography>

                <p className="ld-text-1">Drag and drop or browse your files</p>
              </label>
              <input type="file" id="fileInput" className="ld-inp-1" />
            </Grid>

            <Grid container className="ld-padd">
              <button className="tg-button2">Cancel</button>
              <button className="tg-button1">Save Changes</button>
            </Grid>
          </Grid>
        </div>
      </NewLayout>
    </>
  );
}

export default LegalDocumentationAdmin;
