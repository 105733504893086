import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../Components/Layout";
import { Grid, TextField, Typography, Button, IconButton, InputAdornment, AlertTitle, Alert } from "@mui/material";
import Filenowlogo from "../../assets/images/sidebar_filenow_logo.svg";
import { emailRegExp, phoneRegExp } from "../../Utilities/regex";
import CustomizedSnackbar from "../../Utilities/Alert";
import Loader from "../../Components/Loader";
import { useApi, ApiService } from "../../Utilities";
import OTPInput from "otp-input-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { eNums } from "../../constants";

var emailPhone = "";

function ResetPassword() {
  let navigate = useNavigate();
  const { state } = useLocation();

  const [OTP, setOTP] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [alert, setAlert] = useState("");
  const [OTPPage, setOTPPage] = useState(false);
  const [passwordPage, setPasswordPage] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const [isResetSubmitting, setIsResetSubmitting] = useState(false);

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleClickShowPassword = (setter) => {
    setter((show) => !show);
  };

  const {
    action: requestOTP,
    snackbarComponent: validateUserSnackbarComponent,
    loading: validateUserLoading,
    error: validateUserError,
  } = useApi(ApiService.requestOTP, {
    success: true,
    error: true,
    noStore: true,
  });

  const {
    action: validateOTP,
    snackbarComponent: validateOTPSnackbarComponent,
    loading: validateOTPLoading,
    error: validateOTPError,
  } = useApi(ApiService.validateOTP, {
    success: true,
    error: true,
    noStore: true,
  });

  const {
    action: resetPassword,
    snackbarComponent: resetPasswordSnackbarComponent,
    loading: resetPasswordLoading,
    error: resetPasswordError,
  } = useApi(ApiService.resetPassword, {
    success: true,
    error: true,
    noStore: true,
  });

  const setEmailPhone = (val) => {
    emailPhone = val;
  };

  const OnSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!emailPhone) {
      handleSnackbar("Please enter Email / Phone", "error");
      return;
    }
    if (!emailRegExp.test(emailPhone) && !phoneRegExp.test(emailPhone)) {
      handleSnackbar("Invalid Email / Phone", "error");
      return;
    }
    let isEmail = emailRegExp?.test(emailPhone);
    let isMobile = phoneRegExp?.test(emailPhone);
    let obj = {userType: state?.userType || "USER"};
    if (isEmail) {
      obj.email = emailPhone;
    }
    if (isMobile) {
      obj.mobile = emailPhone;
    }
    await requestOTP(obj);
  };

  const OnOTPSubmit = async (event) => {
    event.preventDefault();
    setIsOTPSubmitting(true);

    if (!(OTP?.length === 6)) {
      handleSnackbar("Please enter otp", "error");
      return;
    }

    await validateOTP({ email: emailPhone, otp: OTP, userType: state?.userType || "USER" });
  };

  const OnPasswordSubmit = async (event) => {
    event.preventDefault();

    if (!newPassword) {
      handleSnackbar("Please Enter your new password..!", "error");
      return;
    }

    if (newPassword?.length < 6) {
      handleSnackbar("Password should be atleast 6 characters", "error");
      return;
    }

    if (!confirmPassword) {
      handleSnackbar("Please Confirm your new password..!", "error");
      return;
    }
    if (!confirmPassword) {
      handleSnackbar("Please Confirm your new password..!", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      handleSnackbar("Repeat Password doesn't match", "error");
      return;
    }
    setIsResetSubmitting(true);
    await resetPassword({
      email: emailPhone,
      otp: OTP,
      newPassword: newPassword,
      userType: state?.userType || "USER"
    });
  };

  useEffect(() => {
    if (
      emailPhone &&
      isSubmitting &&
      !validateUserLoading &&
      !validateUserError &&
      !OTPPage
    ) {
      setOTPPage(true);
      setIsSubmitting(false);
    } else if (isSubmitting && !validateUserLoading && validateUserError) {
      setOTPPage(false);
      setIsSubmitting(false);
    }
  }, [isSubmitting, validateUserLoading, validateUserError]);

  useEffect(() => {
    if (
      OTP &&
      isOTPSubmitting &&
      !validateOTPLoading &&
      !validateOTPError &&
      !passwordPage
    ) {
      setPasswordPage(true);
      setOTPPage(false);
      setIsOTPSubmitting(false);
    } else if (isOTPSubmitting && !validateOTPLoading && validateOTPError) {
      setPasswordPage(false);
      setIsOTPSubmitting(false);
    }
  }, [isOTPSubmitting, validateOTPLoading, validateOTPError]);

  useEffect(() => {
    if (
      isResetSubmitting &&
      !resetPasswordLoading &&
      !resetPasswordError &&
      passwordPage
    ) {
      setTimeout(() => {
        navigate(`/${state?.userType === eNums?.AGENT ? 'agent/' : ''}signIn`);
      }, [1500])
    }
  }, [isResetSubmitting, resetPasswordLoading, resetPasswordError]);

  return (
    <>
      <Layout>
        {validateUserSnackbarComponent}
        {validateOTPError ? validateOTPSnackbarComponent : null}
        {resetPasswordSnackbarComponent}
        <Grid container className="wrapC1">
          <div className="wrapC1">
            <div className="d-flex flex-column align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center gap-2">
                <img src={Filenowlogo} alt="" width={30} height={30} />
                <span className="filenow-title-text">FILENOW</span>
              </div>
              <div className="wrapC1">
                <Typography variant="body1" fontWeight="700" fontSize={"16px"}>
                  {passwordPage
                    ? "Enter New Password"
                    : OTPPage
                    ? "Verfity Your Account"
                    : "Reset Password"}
                </Typography>
              </div>
            </div>
            {OTPPage ? (
              <form onSubmit={OnOTPSubmit} className="mt-5">
                <div className="d-flex flex-column gap-1">
                  <span className="otp-verification-text">
                    OTP Verification
                  </span>
                  <div className="d-flex flex-column align-items-center gap-3 justify-content-center mt-3">
                    <OTPInput
                      containerStyle={"otpinputContainer"}
                      inputStyle={"inputstyle_indv"}
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />

                    <Button
                      variant="contained"
                      onClick={OnSubmit}
                      className="resend-otp-btn mt-3"
                    >
                      <span>Resend OTP</span>
                    </Button>
                  </div>
                </div>

                <Grid item container className="wrapC1 mt-3">
                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100 confirm-btn"
                    disabled={validateOTPLoading}
                  >
                    <span className="action-btn-text">
                      {validateOTPLoading ? "Validating..!" : "Validate OTP"}
                    </span>
                  </Button>
                </Grid>

                <Grid item container className="wrapC1 gap-1 mt-2">
                  <Typography
                    variant="body2"
                    fontWeight="700"
                    fontSize={"14px"}
                    color={"#000"}
                  >
                    Change Email / Phone ?
                  </Typography>
                  <Typography
                    onClick={() => setOTPPage(false)}
                    variant="body2"
                    fontWeight="700"
                    fontSize={"16px"}
                    color={"#1E68F9"}
                    className="cursor-pointer"
                  >
                    Click here
                  </Typography>
                </Grid>
              </form>
            ) : passwordPage ? (
              <form onSubmit={OnPasswordSubmit} className="mt-5">
                <div className="wrapC1 gap-4">
                  <TextField
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    className="auth-input"
                    size="small"
                    id="outlined-basic"
                    label="Enter New Password"
                    variant="outlined"
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(setShowNewPassword)}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    className="auth-input"
                    size="small"
                    id="outlined-basic"
                    label="Repeat New Password"
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(setShowConfirmPassword)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100 confirm-btn"
                    disabled={resetPasswordLoading}
                  >
                    <span className="action-btn-text">
                      {resetPasswordLoading ? "Resetting.." : "Confirm"}
                    </span>
                  </Button>
                </div>
              </form>
            ) : (
              <form
                onSubmit={OnSubmit}
                className="mt-5 d-flex flex-column gap-4 "
              >
                <Grid container item className="wrapC1">
                  <TextField
                    onChange={(e) => {
                      setEmailPhone(e.target.value);
                    }}
                    id="outlined-basic"
                    label="Email / Phone"
                    variant="outlined"
                    className="auth-input"
                    size="small"
                  />
                </Grid>
                <Grid item container className="wrapC1">
                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100 confirm-btn"
                    disabled={validateUserLoading}
                  >
                    <span className="action-btn-text">
                      {validateUserLoading
                        ? "Validating..!"
                        : "Validate and send OTP"}
                    </span>
                  </Button>
                </Grid>
              </form>
            )}
          </div>
        </Grid>

        {alert !== "" ? (
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mt: -15, width: "90%", marginLeft: "5%", opacity: 0.7 }}
            severity="error"
          >
            <AlertTitle>{alert}</AlertTitle>
          </Alert>
        ) : null}
      </Layout>

      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
      {validateUserLoading ? <Loader /> : null}
    </>
  );
}

export default ResetPassword;
