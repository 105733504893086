import React from 'react';
import { Grid } from '@mui/material';


function SettingsSidebar({ setSelectedSetting, selectedSetting, services }) {

  const handleItemClick = (settingName) => {
    setSelectedSetting(settingName);
  };

  return (
        <div className='d-flex flex-column sidebarContainer gap-2 px-2 align-items-center cursor-pointer'>
          {services && Array.isArray(services) && services.map((item, index) => (
            <Grid
              item
              container
              className={`d-flex gap-1 align-items-center p-2 flex-wrap settings-sidebar-item ${item?.key === selectedSetting ? 'settingsSideBarNamesActive' : ''}`}
              onClick={() => {
                if(item?.key){
                  handleItemClick(item?.key);
                }
              }}
              key={index}
            >
                {!item?.key ? item?.logo : <img src={item?.logo} alt="" />}
                <span className='settingsSideBarNames'>
                  {item?.name}
                </span>
            </Grid>
          ))}
        </div>
      
  );
}

export default SettingsSidebar;
