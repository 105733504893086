import { Button, Grid, Typography } from "@mui/material";
import React from "react";

function Notify(props) {
  return (
    <>
      <div className="NotifyTopGrid px-3">
        <Grid item className="NotifyImg">
          <div className="NotifyImg1"></div>
        </Grid>
        <div className="NotifyText">
          <Grid item className="NotifyMainText">
            <Typography classNae="d-flex">
              <span className="fw-bold NotifyLine">
                {props.name}
              </span>
              <span>{props.text}</span>
            </Typography>
          </Grid>

          <span className="NotifySubText">
            {/* <Grid item className="NotifySubtext1"> */}
            {props.time} ago
            {/* </Grid> */}
          </span>
          {props.view === true ? (
            <>
              <Grid item container>
                <Button variant="outlined" className="view-decline-btn">
                  View
                </Button>
              </Grid>
            </>
          ) : null}
          {props.ad === true ? (
            <>
              <Grid item container>
                <Button variant="contained" className="accept-btn">
                  Accept
                </Button>

                <Button variant="outlined" className="view-decline-btn">
                  Decline
                </Button>
              </Grid>
            </>
          ) : null}

        </div>
      </div>
      
      <div className="border-b w-100" />
    </>
  );
}

export default Notify;
