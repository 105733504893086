import React, { useEffect } from "react";
import MiniSidebar from "./MiniSidebar";
import { Grid } from "@mui/material";
import PathComponent from "./PathComponent";
import SearchAndIcons from "./SearchandIcons";
import { ApiService } from "../Utilities";
import { usePermissions } from "../PermissionsContext";

function NewLayout({ children, ...props }) {
  const userType = localStorage.getItem("userType");

  const { setRolePermissions } = usePermissions();

  const callPermissions = async () => {
    const permissions = await ApiService.getRolePermissionsById();
    if (permissions?._id) {
      setRolePermissions(permissions);
    }
  };
  useEffect(() => {
    callPermissions()
  },[])

  return (
    <Grid container className="h-100">
      <div item className={`d-flex w-100`}>
        <MiniSidebar />
        <div className="d-flex flex-column main-right-content">
          <SearchAndIcons userType={userType} />

          <div className={`d-flex flex-column h-100vh wrap-settings-page`}>
            {props.noShowHeading ? null : (
              <PathComponent
                paths={props?.paths}
                pageHeading={props.pageHeading}
              />
            )}
            <Grid
              item
              className={`w-100 ${
                !props?.noScroll ? "main-right-content-container" : ""
              }`}
            >
              {children}
            </Grid>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default NewLayout;
