import React from 'react';

const CurrencySymbol = ({ amount }) => {
  return (
    <>
      {/* {'\u20B9'}  */}
      {amount || amount === 0 ? amount + " INR" : ""}
    </>
  );
};

export default CurrencySymbol;
