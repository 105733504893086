import React, { useEffect, useRef, useState } from "react";
import NewLayout from "../../Components/SidebarLayout";
import icon1 from "../../assets/images/icon_client_details_1_outline.svg";
import icon2 from "../../assets/images/icon_client_details_2_outline.svg";
import icon3 from "../../assets/images/icon_client_details_3_outline.svg";
import Document from "../../assets/images/document-text.svg";
import imgfile from "../../assets/images/Vector.png";
import ProfileIcon from "../../assets/images/user_profile.svg";
import MobileIcon from "../../assets/images/mobile_icon.svg";
import EmailIcon from "../../assets/images/email_icon.svg";
import BackIcon from "../../assets/images/back_icon.svg";
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Typography,
    Button,
    Select,
    MenuItem,
    styled,
    Tabs, Tab, Box,
    CircularProgress,
    IconButton,
    Drawer,
    Slide
} from "@mui/material";
import { BorderBottom, Search } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import {
    useApi,
    ApiService,
    dateFormatter,
    ToolTip,
    uploadSingleBlobFile,
    useRolePermissions,
    EmptyState,
    getBlobFile,
    exportBlobFile,
    capitalizeFirstLetter,
    CustomQuill
} from "../../Utilities";
import CustomizedSnackbars from "../../Utilities/Alert";
import edit from "../../assets/images/edit-img.svg";
import deleteImg from "../../assets/images/delete.svg";
import ChatComponent from "../../Components/ChatComponent";
import { eNums } from "../../constants";
import { tableCellClasses } from "@mui/material/TableCell";
import enums from "../../constants/enums";
import CloseIcon from "@mui/icons-material/Close";

function CustomerManagement() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            padding: "6px",
            fontWeight: 600,
            color: "#000",
            letterSpacing: 0,
            borderBottom: 'none'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            padding: "8px",
            fontWeight: 600,
            color: "#000",
            letterSpacing: 0,
            borderBottom: 'none'
        },
    }));
    const Permissions = useRolePermissions();
    const userId = localStorage.getItem("userId");
    const userType = localStorage.getItem("userType");
    const role_level = Number(localStorage.getItem('role_level'))
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceTasks, setSelectedServiceTasks] = useState([]);
    const [previewDeliverableUrl, setPreviewDeliverableUrl] = useState(null);
    const [imageLoading, setImageLoading] = useState("");
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const handleSnackbar = (message, severity = "success") => {
        setSnackbar({ open: true, message, severity });
    };
    const { state } = useLocation();
    const navigate = useNavigate();
    const [isDownloading, setIsDownloading] = useState("");
    const [expandedSubTask, setExpandedSubTask] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [docID, setDocID] = useState('');
    const [docStatus, setDocStatus] = useState('');
    let rejectedReason = '';
    const [openTickets, setOpenTickets] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);

    const [taskItem, setTaskItem] = useState(null);
    const [openTaskInvoice, setOpenTaskInvoice] = useState(false);
    const [taskPricing, setTaskPricicng] = useState(null);

    const {
        action: getMyServices,
        data: services,
        snackbarComponent: getMyServicesSnackbarComponent,
        loading: serviceLoading,
        error: servicesError,
    } = useApi(ApiService.getMyServices, {
        success: true,
        error: true,
        actionName: "Services fetching",
    });

    const { action: getAllEmployees, data: employees } = useApi(
        ApiService.getEmployees
    );

    const { action: updateMyService, loading: updateMyServiceLoading } = useApi(
        ApiService.updateMyService,
        {
            success: true,
            error: true,
            noStore: true,
            actionName: "Service Updating",
        }
    );

    const {
        action: createServiceTicket,
        error: createServiceTicketError,
        loading: createServiceTicketoading,
    } = useApi(ApiService.createServiceTicket, { noStore: true });

    const {
        action: getUserServiceTickets,
        data: serviceTicketsData,
        error: serviceTicketsDataError,
        clearActions,
        loading: createUserServiceChatoading,
    } = useApi(ApiService.getUserServiceTickets);

    const {
        action: updateServiceTicketStatus,
    } = useApi(ApiService.updateServiceTicketStatus, { noStore: true, success: true, error: true });

    const componentRef = useRef(null);

    const resetClickedAndEdit = () => {
        setSelectedServiceTasks(prevTasks =>
            prevTasks.map(task => ({
                ...task,
                clicked: false,
                edit: false,
                subTasks: task.subTasks?.map(subTask => ({
                    ...subTask,
                    clicked: false,
                    edit: false,
                    saved:true
                })) || []
            }))
        );
    };

    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                resetClickedAndEdit(); // Reset all clicked and edit states
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (state?.previous_route_path) {
            localStorage.setItem("previous_route_path", state?.previous_route_path);
        }
        if (state?.user?._id) {
            getMyServices({
                userID: state?.user?._id,
                serviceID: state?.user?.serviceID,
            });
        } else {
            let previous_route_path = localStorage.getItem("previous_route_path");
            if (previous_route_path) {
                navigate(`/${previous_route_path}`);
                localStorage.removeItem("previous_route_path");
            }
        }
        getAllEmployees({ userType: enums?.AGENT })
    }, [state]);

    const handleAddStatus = () => {
        let updatedData = selectedServiceTasks?.map((item) => {
            return { ...item, clicked: false, edit: false };
        });

        // if (
        //   updatedData.length === 0 ||
        //   updatedData[updatedData.length - 1]?.saved
        // ) {
        let obj = {
            title: "",
            clicked: true,
            edit: false,
            saved: false,
            createdOn: new Date(),
            updatedOn: new Date(),
            createdBy: userId,
            updatedBy: userId,
            subTasks: [],
        };
        updatedData.push(obj);
        // } else {
        //   updatedData[updatedData.length - 1] = {
        //     ...updatedData[updatedData.length - 1],
        //     clicked: true,
        //     lastModifiedOn: new Date(),
        //     modifiedBy: userId,
        //   };
        //   handleSnackbar("Please save current task", "error");
        // }

        setSelectedServiceTasks(updatedData);
        selectedService.registeredServices[0].tasks = selectedServiceTasks;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleAddSubtask = (index, item) => {
        setExpandedSubTask(null)
        let obj = {
            name: "",
            priority: "",
            status: "",
            subTaskType: 'input',
            createdOn: new Date(),
            updatedOn: new Date(),
            updatedBy: userId,
            clicked: true,
            edit: false,
            saved: false,
        };
        // let updatedData = [...selectedServiceTasks];
         let updatedData = selectedServiceTasks?.map((task) => ({
            ...task,
            clicked: false,
            edit: false,
            subTasks: task.subTasks?.map((subTask) => ({
                ...subTask,
                clicked: false,
                edit: false,
                saved: true
            })) || [] // Ensure subTasks is always an array
        }));
        
        updatedData[index].subTasks.push(obj);
        setSelectedServiceTasks(updatedData);
        selectedService.registeredServices[0].tasks = selectedServiceTasks;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleTaskNameChange = (value, index) => {
        selectedServiceTasks[index].title = value;
    };

    const handleSubTaskNameChange = (value, taskIndex, subTaskIndex) => {
        selectedServiceTasks[taskIndex].subTasks[subTaskIndex].name = value;
    };

    const handleSubTaskPriorityChange = (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].priority = value;
        setSelectedServiceTasks(updatedData);
        selectedService.registeredServices[0].tasks = updatedData;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleSubTaskStatusChange = (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].status = value;
        setSelectedServiceTasks(updatedData);
        selectedService.registeredServices[0].tasks = updatedData;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleSubTaskTypeChange = (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].subTaskType = value;
        setSelectedServiceTasks(updatedData);
        selectedService.registeredServices[0].tasks = updatedData;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleEditSubTask = (taskIndex, subTaskIndex) => {
        setExpandedSubTask({ taskIndex, subTasksIndex: subTaskIndex });
        setActiveTab(0)
        setPreviewDeliverableUrl(null);
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        edit: false,
                        subTasks: task.subTasks.map((subTask, subTaskInd) => {
                            if (subTaskInd === subTaskIndex) {
                                setUploadedDocs(subTask?.uploadedDocuments)
                                return {
                                    ...subTask,
                                    edit: true,
                                    clicked: true
                                };
                            } else {
                                return {
                                    ...subTask,
                                    edit: false,
                                    clicked: false
                                };
                            }
                        }),
                    };
                } else {
                    return {
                        ...task,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            edit: false,
                            clicked: false
                        })),
                    };
                }
            })
        );
        handleUpdateService()
    };

    const handleUpdateService = (e) => {
        e?.preventDefault();
        selectedService.registeredServices[0].tasks = selectedServiceTasks;
        updateMyService(selectedService, selectedService?._id);
        // setSelectedServiceTasks((prevTasks) =>
        //     prevTasks.map((task, index) => {
        //         return {
        //             ...task,
        //             clicked: false,
        //             edit: false,
        //             saved: true,
        //             subTasks: task.subTasks.map((subTask) => ({
        //                 ...subTask,
        //                 clicked: false,
        //                 edit: false,
        //                 saved: true,
        //             })),
        //         };
        //     })
        // );
    };

    useEffect(() => {
        if (services?.length > 0) {
            handleShowTasks(services?.[0]);
        }
    }, [services]);

    const handleShowTasks = (item) => {
        setExpandedSubTask(null)
        setActiveTab(0)
        setPreviewDeliverableUrl(null);
        setSelectedService(item);
        clearActions.clearError();
        const updatedTasks =
            item?.registeredServices?.[0]?.tasks?.map((task) => {
                const updatedSubTasks = task.subTasks.map((subTask) => ({
                    ...subTask,
                    clicked: false,
                    edit: false,
                    saved: true,
                }));

                return {
                    ...task,
                    clicked: false,
                    edit: false,
                    saved: true,
                    subTasks: updatedSubTasks,
                };
            }) || [];

        setSelectedServiceTasks(updatedTasks);
        getUserServiceTickets({ userServiceID: item?._id });
    };

    const handleEditTask = (taskIndex) => {
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: true,
                        edit: true,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                }
            })
        );
    };

    const handleDeleteTask = (taskIndex) => {
        const updatedTasks = selectedServiceTasks?.filter(
            (_, index) => index !== taskIndex
        );
        setSelectedServiceTasks(updatedTasks);
        selectedService.registeredServices[0].tasks = updatedTasks;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleDeleteSubTask = (taskIndex, subTaskIndex) => {
        const updatedTasks = [...selectedServiceTasks];

        const updatedSubTasks = [...updatedTasks[taskIndex].subTasks];

        updatedSubTasks.splice(subTaskIndex, 1);

        updatedTasks[taskIndex] = {
            ...updatedTasks[taskIndex],
            subTasks: updatedSubTasks,
        };

        setSelectedServiceTasks(updatedTasks);
        selectedService.registeredServices[0].tasks = updatedTasks;
        updateMyService(selectedService, selectedService?._id);
    };

    const handleClickTask = (taskIndex) => {
        setExpandedSubTask(null)
        setActiveTab(0)
        setUploadedDocs([]);
        setPreviewDeliverableUrl(null);
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: true,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                }
            })
        );
    };

    const handleClickSubTask = (taskIndex, subTaskIndex) => {
        setExpandedSubTask({ taskIndex, subTasksIndex: subTaskIndex });
        setActiveTab(0)
        setPreviewDeliverableUrl(null);
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask, subTaskInd) => {
                            if (subTaskInd === subTaskIndex) {
                                setUploadedDocs(subTask?.uploadedDocuments || []);
                                return {
                                    ...subTask,
                                    edit: false,
                                    clicked: true,
                                };
                            } else {
                                return {
                                    ...subTask,
                                    edit: false,
                                    clicked: false,
                                };
                            }
                        }),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            edit: false,
                            clicked: false,
                        })),
                    };
                }
            })
        );

        if (expandedSubTask?.taskIndex === taskIndex && expandedSubTask?.subTasksIndex === subTaskIndex) {
            setExpandedSubTask(null);
        }
    };

    const paths = [
        {
            label: `${state?.previous_route_name || ""}`,
            navigation: `${state?.previous_route_path}`,
        },
    ];

    const handleDeliverableUpload = async (e) => {
        setImageLoading(true);
        const { previewUrl, res } = await uploadSingleBlobFile(e);

        if (res?.fileName) {
            // Remove any existing document uploaded by an "AGENT"
            const filteredDocs = uploadedDocs?.filter(doc => doc?.userType !== enums.AGENT);

            // Add the new document
            filteredDocs.push({
                url: res?.fileName,
                filename: e?.target.files?.[0]?.name,
                uploadedDate: new Date(),
                uploadedBy: userId,
                userType,
            });

            // Update the state
            setUploadedDocs(filteredDocs);

            // Find the subTask where clicked is true and update its uploadedDocs
            let updatedServiceTasks = selectedServiceTasks.map((task) => ({
                ...task,
                subTasks: task.subTasks.map((subTask) =>
                    subTask.clicked ? { ...subTask, uploadedDocuments: filteredDocs } : subTask
                ),
            }));
            setSelectedServiceTasks(updatedServiceTasks);

            selectedService.registeredServices[0].tasks = updatedServiceTasks;

            updateMyService(selectedService, selectedService?._id);
        }

        setPreviewDeliverableUrl(previewUrl);
        setImageLoading(false);
    };

    const handleUpdateUploadedDocStatus = async (docID, status) => {
        setDocStatus(status);
        setDocID(docID)
        if (docID && status !== 'REJECT') {
            let docs = uploadedDocs?.map((doc) =>
                doc._id === docID ? { ...doc, status: status } : doc
            );
            setUploadedDocs(docs)
            let updatedServiceTasks = selectedServiceTasks.map((task) => ({
                ...task,
                subTasks: task.subTasks.map((subTask) => ({
                    ...subTask,
                    uploadedDocuments: subTask.uploadedDocuments.map((doc) =>
                        doc._id === docID ? { ...doc, status: status, rejectedReason: rejectedReason } : doc
                    ),
                })),
            }));

            setSelectedServiceTasks(updatedServiceTasks);

            selectedService.registeredServices[0].tasks = updatedServiceTasks;

            updateMyService(selectedService, selectedService?._id);
            rejectedReason = ''
            setDocID('')
            setDocStatus('')
        }
    };

    const handleChangeSampleFileDescription = (description) => {
        const index = uploadedDocs.findIndex(doc => doc.userType === enums?.AGENT);
        if (index !== -1) {
            uploadedDocs[index] = { ...uploadedDocs[index], fileDescription: description };
        }
        else {
            // Add the new document
            uploadedDocs.push({
                uploadedDate: new Date(),
                uploadedBy: userId,
                userType,
                fileDescription: description
            });
        }
    };

    const clientDetailsData = [
        { label: "Name", value: state?.user?.fullname || "-", icon: ProfileIcon },
        { label: "M No", value: state?.user?.mobile || "-", icon: MobileIcon },
        {
            label: "Mail ID",
            value: state?.user?.email || "-",
            icon: EmailIcon
        },
    ];

    const clientServicesData = [
        {
            label: "Active Services", value: services?.filter(
                (item) =>
                    item?.registeredServices?.[0]?.currentStatus ===
                    "active"
            )?.length || 0, icon: icon1
        },
        {
            label: "Processing Services", value: services?.filter(
                (item) =>
                    item?.registeredServices?.[0]?.currentStatus ===
                    "inprogress"
            )?.length || 0, icon: icon2
        },
        {
            label: "Completed Services",
            value: services?.filter(
                (item) =>
                    item?.registeredServices?.[0]?.currentStatus ===
                    "completed"
            )?.length || 0,
            icon: icon3
        },
    ];

    const handleAddTicket = async () => {
        await createServiceTicket({ userServiceID: selectedService?._id })
        await getUserServiceTickets({ userServiceID: selectedService?._id })
    }

    const handleCloseTaskInvoice = () => {
        setOpenTaskInvoice(false);
        setTaskItem(null);
        setTaskPricicng(null);
    }

    const handleCreateTaskInvoice = async () => {
        if (!Number(taskPricing)) {
            handleSnackbar("Pricing is required..!", "error");
            return;
        }

        let updatedTasks = selectedServiceTasks?.map((task) => {
            if (task?._id === taskItem?._id) {
                return {
                    ...task,
                    typeOfTask: [
                        {
                            name: 'payment',
                            invoiceData: [{
                                invoiceID: '1',
                                orderID: selectedService?.orderID,
                                invoiceDate: new Date(),
                                invoiceAmount: Number(taskPricing)
                            }]
                        }
                    ]
                };
            }
            return task;
        });

        setSelectedServiceTasks(updatedTasks);
        selectedService.registeredServices[0].tasks = updatedTasks;
        await updateMyService(selectedService, selectedService?._id);
        handleCloseTaskInvoice()
    };

    return (
        <NewLayout
            paths={paths}
            pageHeading={
                state?.serviceName || state?.user?.fullname || "Service Details"
            }
        >
            <Grid container className="cd-wrap-1">
                <Grid item className="w-100 d-flex align-items-center justify-content-end mb-2">
                    {/* <Grid className="cd-wrap-2 w-75">
              {userType?.includes(eNums?.CLIENT) ? null : (
                <>
                  <Grid item className="bar-cd blue-cd">
                    <Grid item>
                      <img src={icon1} alt="Loading..." />
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bold" variant="subtitle2">
                        Active Services
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {services?.filter(
                          (item) =>
                            item?.registeredServices?.[0]?.currentStatus ===
                            "active"
                        )?.length || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item className="bar-cd orange-cd">
                    <Grid item>
                      <img src={icon2} alt="Loading..." />
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bold" variant="subtitle2">
                        Processing Services
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {services?.filter(
                          (item) =>
                            item?.registeredServices?.[0]?.currentStatus ===
                            "inprogress"
                        )?.length || 0}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item className="bar-cd green-cd">
                    <Grid item>
                      <img src={icon3} alt="Loading..." />
                    </Grid>
                    <Grid item>
                      <Typography fontWeight="bold" variant="subtitle2">
                        Completed Services
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {services?.filter(
                          (item) =>
                            item?.registeredServices?.[0]?.currentStatus ===
                            "completed"
                        )?.length || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid> */}
                    {/* {userType?.includes(eNums?.CLIENT) ||
            (Permissions?.clientManagement?.submodules?.clientManagement
              ?.enabled &&
              Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                eNums?.EDIT
              ) &&
              services?.length &&
              selectedService?._id) ? (
              <div className="d-flex justify-content-end align-items-center my-auto w-25">
                
                <Button
                  onClick={handleUpdateService}
                  variant="contained"
                  className="cm-btn-11 cursor-pointer"
                >
                  Save changes
                </Button>
              </div>
            ) : null} */}
                    {services?.length ?
                        <div className="d-flex align-items-center my-auto py-2 px-4 gap-2 rounded border-gray cursor-pointer" onClick={() => setOpenTickets(true)}>
                            <Grid item>
                                <img src={icon2} alt="" width={24} height={24} />
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                                    Ticket Management
                                </Typography>
                            </Grid>
                        </div> : null}
                </Grid>

            </Grid>

            <Grid container className="cm-g2">
                {userType === eNums?.CLIENT ? null : (
                    <Grid className="cm-g2-g1">
                        <Grid container className="cm-g1g1 border-b">
                            <Grid className="cm-grid-1">
                                <Typography className="fw-bold">Services</Typography>
                            </Grid>
                        </Grid>

                        {services?.length
                            ? services?.map((item, index) => (
                                <Grid
                                    container
                                    className="cm-service-cont cursor-pointer"
                                    style={
                                        item?._id === selectedService?._id
                                            ? { backgroundColor: "#F6F8FD" }
                                            : {}
                                    }
                                    sx={{ padding: "3px" }}
                                    onClick={() => handleShowTasks(item)}
                                >
                                    <Grid flexDirection="column" className="cm-grid-1 f1-cm">
                                        <Grid item>
                                            <Typography sx={{ fontSize: "12px" }}>
                                                {item?.registeredServices?.[0]?.serviceName || "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ fontSize: "12px" }}>
                                                {item?.createdOn
                                                    ? dateFormatter(item?.createdOn)
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid className="cm-grid-2">
                                        <Typography
                                            sx={{ fontSize: ".6em", fontWeight: "500" }}
                                            className={`d-flex align-items-center justify-content-center px-2 py-1 rounded typo-wrap-${item?.registeredServices?.[0]?.currentStatus || 'active'}`}
                                        >
                                            {item?.registeredServices?.[0]?.currentStatus === "inprogress"
                                                ? "In Progress"
                                                : item?.registeredServices?.[0]?.currentStatus === "completed"
                                                    ? "Completed"
                                                    : item?.registeredServices?.[0]?.currentStatus === "closed"
                                                        ? "Closed"
                                                        : "Active"}

                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                            : <EmptyState />}
                    </Grid>
                )}
                {selectedService ? (
                    <Grid
                        className={`cm-g2-g2 ${userType === eNums?.CLIENT ? "w-100" : "w-75"
                            }`}
                        style={{
                            borderLeft:
                                userType === eNums?.CLIENT ? "1px solid #D9D9D9" : "",
                        }}
                    >

                        <Grid container className="justify-content-between cm-g1g1 border-b">
                            <div>
                                {userType?.includes(enums?.AGENT) ?
                                    <div className="d-flex align-items-center gap-3 flex-wrap">
                                        <div className="d-flex align-items-center gap-2">
                                            <Typography fontSize={14} fontWeight={400} color={'#000'}>
                                                Legal Associate
                                            </Typography>
                                            <Select
                                                // value={role_level === 1 ? (selectedService?.serviceAssingedTo?.admin?.adminID || selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.accountManager?.accountManagerID?._id || selectedService?.serviceAssingedTo?.accountManager?.accountManagerID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 3 ? (selectedService?.serviceAssingedTo?.employee?.employeeID?._id || selectedService?.serviceAssingedTo?.employee?.employeeID?._id) : role_level === 4 ? (selectedService?.serviceAssingedTo?.articleship?.articleshipID || selectedService?.serviceAssingedTo?.articleship?.articleshipID?._id) : ""}
                                                value={selectedService?.serviceAssingedTo?.employee?.employeeID?._id || selectedService?.serviceAssingedTo?.employee?.employeeID || ""}
                                                onChange={(e) => {
                                                    let key = role_level === 1 ? "adminID" : role_level === 2 ? "accountManagerID" : role_level === 3 ? 'employeeID' : role_level === 4 ? 'articleshipID' : "userID";

                                                    let assignTo = {
                                                        employeeID: e.target.value,
                                                        assignedBy: userId,
                                                        assignedOn: new Date()
                                                    };

                                                    // let serviceAssingedTo = { [role_level === 1 ? 'admin' : role_level === 2 ? 'accountManager' : role_level === 3 ? 'employee' : role_level === 4 ? 'articleship' : 'user']: assignTo };
                                                    let serviceAssingedTo = { ['employee']: assignTo };
                                                    selectedService.serviceAssingedTo = serviceAssingedTo;
                                                    updateMyService(selectedService, selectedService?._id);
                                                }}
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (!selected) {
                                                        return <Typography color={"#000"}>Assign To</Typography>;
                                                    }
                                                    return employees?.find((emp) => emp._id === selected)?.fullname || "";
                                                }}
                                                style={{
                                                    height: "2rem",
                                                    fontWeight: 500,
                                                    color: '#000',
                                                }}
                                            >
                                                {employees && employees?.filter((item) => item?.roleID?.level === role_level + 1)?.length ? (
                                                    employees?.filter((item) => item?.roleID?.level === 4)?.map((e, key) => (
                                                        <MenuItem key={key} value={e?._id}>
                                                            {e?.fullname}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    // <MenuItem value={""}>No {role_level === 1 ? 'Admins' : role_level === 2 ? 'Account Managers' : role_level === 3 ? 'Employees' : role_level === 4 ? 'No Article Ships' : 'Users'}</MenuItem>
                                                    <MenuItem value={""}>No Employees</MenuItem>
                                                )}
                                            </Select>
                                        </div>

                                        <div className="d-flex align-items-center gap-2">
                                            <Typography fontSize={14} fontWeight={400} color={'#000'}>
                                                Articleship Associate
                                            </Typography>
                                            <Select
                                                // value={role_level === 1 ? (selectedService?.serviceAssingedTo?.admin?.adminID || selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.accountManager?.accountManagerID?._id || selectedService?.serviceAssingedTo?.accountManager?.accountManagerID?._id) : role_level === 2 ? (selectedService?.serviceAssingedTo?.admin?.adminID?._id) : role_level === 3 ? (selectedService?.serviceAssingedTo?.employee?.employeeID?._id || selectedService?.serviceAssingedTo?.employee?.employeeID?._id) : role_level === 4 ? (selectedService?.serviceAssingedTo?.articleship?.articleshipID || selectedService?.serviceAssingedTo?.articleship?.articleshipID?._id) : ""}
                                                value={selectedService?.serviceAssingedTo?.articleship?.articleshipID?._id || selectedService?.serviceAssingedTo?.articleship?.articleshipID || ""}
                                                onChange={(e) => {
                                                    let key = role_level === 1 ? "adminID" : role_level === 2 ? "accountManagerID" : role_level === 3 ? 'employeeID' : role_level === 4 ? 'articleshipID' : "userID";

                                                    let assignTo = {
                                                        articleshipID: e.target.value,
                                                        assignedBy: userId,
                                                        assignedOn: new Date()
                                                    };

                                                    // let serviceAssingedTo = { [role_level === 1 ? 'admin' : role_level === 2 ? 'accountManager' : role_level === 3 ? 'employee' : role_level === 4 ? 'articleship' : 'user']: assignTo };
                                                    let serviceAssingedTo = { ['articleship']: assignTo };
                                                    // selectedService.serviceAssingedTo = serviceAssingedTo;
                                                    // updateMyService(selectedService, selectedService?._id);
                                                }}
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (!selected) {
                                                        return <Typography color={"#000"}>Assign To</Typography>;
                                                    }
                                                    return employees?.find((emp) => emp._id === selected)?.fullname || "";
                                                }}
                                                style={{
                                                    height: "2rem",
                                                    fontWeight: 500,
                                                    color: '#000',
                                                }}
                                            >
                                                {employees && employees?.filter((item) => item?.roleID?.level === role_level + 1)?.length ? (
                                                    employees?.filter((item) => item?.roleID?.level === 5)?.map((e, key) => (
                                                        <MenuItem key={key} value={e?._id}>
                                                            {e?.fullname}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    // <MenuItem value={""}>No {role_level === 1 ? 'Admins' : role_level === 2 ? 'Account Managers' : role_level === 3 ? 'Employees' : role_level === 4 ? 'No Article Ships' : 'Users'}</MenuItem>
                                                    <MenuItem value={""}>No ArticleShips</MenuItem>
                                                )}
                                            </Select>
                                        </div>
                                    </div> : null}
                            </div>
                            <Grid className="cm-grid-50 d-flex align-items-center justify-content-center cm-g50-v1">
                                {userType?.includes(enums?.AGENT) && Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                    eNums?.EDIT
                                ) ?
                                    <Select
                                        value={
                                            selectedService?.registeredServices?.[0]?.currentStatus || ""
                                        }
                                        onChange={(e) => {
                                            selectedService.registeredServices[0].currentStatus = e.target.value;
                                            updateMyService(selectedService, selectedService?._id);
                                        }}
                                        displayEmpty
                                        fullWidth
                                        style={{
                                            height: "2rem",
                                            fontWeight: 500,
                                            backgroundColor: (() => {
                                                switch (selectedService?.registeredServices?.[0]?.currentStatus) {
                                                    case "active":
                                                        return "#F6F8FD";
                                                    case "inprogress":
                                                        return "#FFF0E4";
                                                    case "completed":
                                                        return "#EEFFEF";
                                                    case "closed":
                                                        return "#FFF0E4";
                                                    default:
                                                        return "#EEFFEF";
                                                }
                                            })(),
                                            color: (() => {
                                                switch (selectedService?.registeredServices?.[0]?.currentStatus) {
                                                    case "active":
                                                        return "#1E68F9";
                                                    case "inprogress":
                                                        return "#FFBA88";
                                                    case "completed":
                                                        return "#0FC716";
                                                    case "closed":
                                                        return "#FF9472";
                                                    default:
                                                        return "#0FC716";
                                                }
                                            })(),
                                        }}
                                    >
                                        <MenuItem value="active" sx={{ color: "#1E68F9" }}>Active</MenuItem>
                                        <MenuItem value="inprogress" sx={{ color: "#FFBA88" }}>In Progress</MenuItem>
                                        <MenuItem value="completed" sx={{ color: "#0FC716" }}>Completed</MenuItem>
                                        <MenuItem value="closed" sx={{ color: "#FF9472" }}>Closed</MenuItem>
                                    </Select> :
                                    <span className={`d-flex align-items-center justify-content-center px-2 py-1 rounded typo-wrap-${selectedService?.registeredServices?.[0]?.currentStatus || 'active'}`}>
                                        {selectedService?.registeredServices?.[0]?.currentStatus === "inprogress"
                                            ? "In Progress"
                                            : selectedService?.registeredServices?.[0]?.currentStatus === "completed"
                                                ? "Completed"
                                                : selectedService?.registeredServices?.[0]?.currentStatus === "closed"
                                                    ? "Closed"
                                                    : "Active"}
                                    </span>}
                            </Grid>
                        </Grid>

                        <Grid container className="cm-w py-3 px-4">
                            <div className="cm-w1 d-flex flex-column gap-2">

                                <Grid className="overflow-auto rounded py-2">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>
                                                    <span className="ms-4">Task</span>
                                                </StyledTableCell>
                                                <StyledTableCell>Task Priority</StyledTableCell>
                                                <StyledTableCell>
                                                    Task Status
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Document
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Payment Status
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Invoice
                                                </StyledTableCell>
                                                {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                    eNums?.EDIT
                                                ) ? (
                                                    <StyledTableCell>Actions</StyledTableCell>
                                                ) : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody ref={componentRef}>
                                            {selectedServiceTasks?.length
                                                ? selectedServiceTasks.map((task, taskIndex) => (
                                                    <React.Fragment key={taskIndex}>
                                                        <TableRow className={`${task?.clicked ? "zoom-in-box" : ""}`}>
                                                            <StyledTableCell className="d-flex gap-1 align-items-center">
                                                                <Typography color={'#000'} fontWeight={600} className="ms-2">{taskIndex + 1}.</Typography>
                                                                {task?.edit || !task?.saved ? (
                                                                    <TextField
                                                                        variant="standard"
                                                                        placeholder="Enter Task"
                                                                        defaultValue={task?.title}
                                                                        fullWidth
                                                                        onChange={(e) =>
                                                                            handleTaskNameChange(
                                                                                e.target.value,
                                                                                taskIndex
                                                                            )
                                                                        }
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                handleUpdateService();
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Typography
                                                                        variant="body2"
                                                                        fontSize={14}
                                                                        onClick={() =>
                                                                            handleClickTask(taskIndex)
                                                                        }
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            cursor: "pointer",
                                                                        }}
                                                                        className="word-break"
                                                                    >
                                                                        {task?.title || "N/A"}
                                                                    </Typography>
                                                                )}
                                                            </StyledTableCell>
                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                eNums?.EDIT
                                                            ) ? (
                                                                <>
                                                                    <StyledTableCell>
                                                                        <Button
                                                                            variant="contained"
                                                                            className="cm-create-status cursor-pointer"
                                                                            onClick={() =>
                                                                                handleAddSubtask(taskIndex)
                                                                            }
                                                                        >
                                                                            + Add Sub Task
                                                                        </Button>
                                                                    </StyledTableCell>

                                                                    <StyledTableCell>
                                                                        {!task?.typeOfTask?.[0]?.invoiceData?.[0]?.paymentStatus ?
                                                                            <Button
                                                                                variant="contained"
                                                                                className="cm-create-status cursor-pointer"
                                                                                onClick={() => {
                                                                                    setTaskItem(task);
                                                                                    setOpenTaskInvoice(true);
                                                                                    if (task?.typeOfTask?.[0]?.invoiceData?.[0]?.invoiceAmount) {
                                                                                        setTaskPricicng(task?.typeOfTask?.[0]?.invoiceData?.[0]?.invoiceAmount)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                + {task?.typeOfTask?.length ? 'Edit' : 'Add'} Invoice
                                                                            </Button> : null}
                                                                    </StyledTableCell>
                                                                </>) : <><StyledTableCell></StyledTableCell><StyledTableCell></StyledTableCell></>}
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell>-</StyledTableCell>
                                                            <StyledTableCell>-</StyledTableCell>
                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                eNums?.EDIT
                                                            ) ? (
                                                                <>
                                                                    <StyledTableCell>
                                                                        {
                                                                            task?.saved &&
                                                                            !task?.edit && (
                                                                                <img
                                                                                    src={edit}
                                                                                    alt="edit"
                                                                                    width={16}
                                                                                    height={16}
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginRight: 8,
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleEditTask(taskIndex)
                                                                                    }
                                                                                />
                                                                            )}

                                                                        <img
                                                                            src={deleteImg}
                                                                            alt="delete"
                                                                            width={16}
                                                                            height={16}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                handleDeleteTask(taskIndex)
                                                                            }
                                                                        />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell></StyledTableCell>
                                                                </>
                                                            ) : null}
                                                        </TableRow>
                                                        {task?.subTasks?.length > 0 &&
                                                            task?.subTasks.map(
                                                                (subTask, subTasksIndex) => (
                                                                    <React.Fragment key={subTasksIndex} >
                                                                        <TableRow className={`${subTask?.clicked ? "zoom-in-box" : ""}`}>
                                                                            <StyledTableCell className="d-flex gap-2 align-items-center">
                                                                                <Typography className="ms-4" color={'#64748B'}>
                                                                                    {subTasksIndex + 1}.
                                                                                </Typography>
                                                                                {subTask?.edit ||
                                                                                    !subTask?.saved ? (
                                                                                    <TextField
                                                                                        variant="standard"
                                                                                        placeholder="Enter Sub Task"
                                                                                        defaultValue={subTask?.name}
                                                                                        fullWidth
                                                                                        onChange={(e) =>
                                                                                            handleSubTaskNameChange(
                                                                                                e.target.value,
                                                                                                taskIndex,
                                                                                                subTasksIndex
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === "Enter") {
                                                                                                handleUpdateService();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        sx={{
                                                                                            cursor: "pointer",
                                                                                            color: "#64748B",
                                                                                            textUnderlineOffset: '4px',
                                                                                            textDecoration: "underline",
                                                                                            "&:hover": {
                                                                                                color: "#aaa7a7",
                                                                                            },
                                                                                        }}
                                                                                        className="word-break"
                                                                                        onClick={() => handleClickSubTask(taskIndex, subTasksIndex)}
                                                                                    >
                                                                                        {subTask?.name || "N/A"}
                                                                                    </Typography>
                                                                                )}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>
                                                                                {subTask?.edit ||
                                                                                    !subTask?.saved ? (
                                                                                    <Select
                                                                                        value={
                                                                                            subTask?.priority || ""
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleSubTaskPriorityChange(
                                                                                                e.target.value,
                                                                                                taskIndex,
                                                                                                subTasksIndex
                                                                                            )
                                                                                        }
                                                                                        displayEmpty
                                                                                        fullWidth
                                                                                        style={{ height: "2rem" }}
                                                                                    >
                                                                                        <MenuItem value="">
                                                                                            Choose Priority
                                                                                        </MenuItem>
                                                                                        <MenuItem value="Low">
                                                                                            Low
                                                                                        </MenuItem>
                                                                                        <MenuItem value="Medium">
                                                                                            Medium
                                                                                        </MenuItem>
                                                                                        <MenuItem value="High">
                                                                                            High
                                                                                        </MenuItem>
                                                                                        <MenuItem value="Very High">
                                                                                            Very High
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        style={{
                                                                                            fontWeight: 400,
                                                                                            color:
                                                                                                subTask?.priority ===
                                                                                                    "Low"
                                                                                                    ? "#36AA00"
                                                                                                    : subTask?.priority ===
                                                                                                        "Medium"
                                                                                                        ? "#36AA00"
                                                                                                        : "#F7425F",
                                                                                        }}
                                                                                    >
                                                                                        {subTask?.priority || 'N/A'}
                                                                                    </Typography>
                                                                                )}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>
                                                                                {subTask?.edit ||
                                                                                    !subTask?.saved ? (
                                                                                    <Select
                                                                                        value={
                                                                                            subTask?.status || ""
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleSubTaskStatusChange(
                                                                                                e.target.value,
                                                                                                taskIndex,
                                                                                                subTasksIndex
                                                                                            )
                                                                                        }
                                                                                        displayEmpty
                                                                                        fullWidth
                                                                                        style={{ height: "2rem" }}
                                                                                    >
                                                                                        <MenuItem value="">
                                                                                            Change Status
                                                                                        </MenuItem>
                                                                                        <MenuItem value="active">
                                                                                            Active
                                                                                        </MenuItem>
                                                                                        <MenuItem value="pending">
                                                                                            Pending
                                                                                        </MenuItem>
                                                                                        <MenuItem value="completed">
                                                                                            Completed
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        style={{
                                                                                            fontWeight: 400,
                                                                                            color: '#000'
                                                                                        }}
                                                                                    >
                                                                                        {subTask?.status ? capitalizeFirstLetter(subTask?.status) : 'N/A'}
                                                                                    </Typography>
                                                                                )}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>
                                                                                {subTask?.edit ||
                                                                                    !subTask?.saved ? (
                                                                                    <Select
                                                                                        value={
                                                                                            subTask?.subTaskType || ""
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            handleSubTaskTypeChange(
                                                                                                e.target.value,
                                                                                                taskIndex,
                                                                                                subTasksIndex
                                                                                            )
                                                                                            setExpandedSubTask({ taskIndex, subTasksIndex });
                                                                                        }}
                                                                                        displayEmpty
                                                                                        fullWidth
                                                                                        style={{ height: "2rem" }}
                                                                                    >
                                                                                        <MenuItem value="input">
                                                                                            Not Required
                                                                                        </MenuItem>
                                                                                        <MenuItem value="upload">
                                                                                            Required
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        style={{
                                                                                            fontWeight: 400,
                                                                                            color: '#000'
                                                                                        }}
                                                                                    >
                                                                                        {subTask?.subTaskType === 'input' ? 'Not' : ''} Required
                                                                                    </Typography>
                                                                                )}
                                                                            </StyledTableCell>

                                                                            <StyledTableCell>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "#000",
                                                                                    }}
                                                                                    className="word-break"
                                                                                >
                                                                                    {subTask?.type || "N/A"}
                                                                                </Typography>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "#000",
                                                                                    }}
                                                                                    className="word-break"
                                                                                >
                                                                                    {subTask?.invoice || "N/A"}
                                                                                </Typography>
                                                                            </StyledTableCell>

                                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                                eNums?.EDIT
                                                                            ) ? (
                                                                                <StyledTableCell>
                                                                                    {
                                                                                        subTask?.saved &&
                                                                                        !subTask?.edit && (
                                                                                            <img
                                                                                                src={edit}
                                                                                                alt="edit"
                                                                                                width={16}
                                                                                                height={16}
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                    marginRight: 8,
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    handleEditSubTask(
                                                                                                        taskIndex,
                                                                                                        subTasksIndex
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        )}

                                                                                    <img
                                                                                        src={deleteImg}
                                                                                        alt="delete"
                                                                                        width={16}
                                                                                        height={16}
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleDeleteSubTask(
                                                                                                taskIndex,
                                                                                                subTasksIndex
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </StyledTableCell>
                                                                            ) : null}
                                                                        </TableRow>


                                                                        {selectedServiceTasks?.some((item) =>
                                                                            item?.subTasks?.some((subTask) => subTask.clicked && subTask?.subTaskType?.includes('upload'))
                                                                        ) && expandedSubTask?.taskIndex === taskIndex &&
                                                                            expandedSubTask?.subTasksIndex === subTasksIndex && (
                                                                                <TableRow className="zoom-in-box">
                                                                                    <StyledTableCell colSpan={7} className="px-4 pt-1 pb-3">
                                                                                        {/* Tabs Component */}
                                                                                        {userType?.includes(enums?.AGENT) ?
                                                                                            <Tabs
                                                                                                value={activeTab}
                                                                                                onChange={(event, newValue) => setActiveTab(newValue)}
                                                                                                indicatorColor="primary"
                                                                                                textColor="#000"
                                                                                            >
                                                                                                <Tab label="Client Document" sx={{ textTransform: "none" }} />
                                                                                                <Tab label="Sample Document" sx={{ textTransform: "none" }} />
                                                                                            </Tabs> : null}

                                                                                        {/* Tab Content */}
                                                                                        <Box mt={2} className='d-flex justify-content-between flex-wrap'>
                                                                                            {userType?.includes(enums?.AGENT) ? <>
                                                                                                {activeTab === 0 && (<>
                                                                                                    <div className="d-flex flex-column gap-2 w-45">
                                                                                                        {selectedServiceTasks?.some((item) =>
                                                                                                            item?.subTasks?.some((subTask) => subTask.clicked)
                                                                                                        ) && uploadedDocs?.filter((item) => item?.userType?.includes(enums?.CLIENT))?.length > 0 ? (
                                                                                                            uploadedDocs?.filter((item) => item?.userType?.includes(enums?.CLIENT))?.map((doc) => (
                                                                                                                <div className="wrap-cm-1 d-flex align-items-center w-100 h-100">
                                                                                                                    <div className="d-flex align-items-center gap-2 me-2">
                                                                                                                        <img src={Document} alt="" />
                                                                                                                        <Typography
                                                                                                                            sx={{
                                                                                                                                fontSize: "14px",
                                                                                                                                wordBreak: "break-word",
                                                                                                                                fontWeight: 600,
                                                                                                                                color: '#666666'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {doc?.filename || "Sample File"}
                                                                                                                        </Typography>
                                                                                                                    </div>
                                                                                                                    {/* <Grid container>
                                                                                                                     <Grid item className="cm-p-w">
                                                                                                                        <Typography sx={{ fontSize: "12px" }}>
                                                                                                                        {" "}
                                                                                                                        2.2 MB
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item className="cm-p-w">
                                                                                                                        <Typography sx={{ fontSize: "12px" }}>
                                                                                                                        2 Mo Ago
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    </Grid> */}
                                                                                                                    {doc?.url ? <>

                                                                                                                        <IconButton
                                                                                                                            id="downloadButton"
                                                                                                                            disabled={isDownloading === doc?.url}
                                                                                                                            onClick={async () => {
                                                                                                                                if (doc?.url) {
                                                                                                                                    setIsDownloading(doc?.url);

                                                                                                                                    const imgUrl = await getBlobFile(doc?.url);

                                                                                                                                    if (imgUrl) {
                                                                                                                                        const fileName = doc?.filename || "image.png";
                                                                                                                                        exportBlobFile(imgUrl, fileName);
                                                                                                                                    }

                                                                                                                                    setIsDownloading("");
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <DownloadIcon />
                                                                                                                        </IconButton>
                                                                                                                        {doc?.status ? <span className={`cursor-pointer ${doc?.status?.includes(enums?.REJECTED) ? 'cm-reject-btn' : 'cm-approve-btn'}`}>
                                                                                                                            {doc?.status?.includes(enums?.APPROVED)
                                                                                                                                ? "Approved"
                                                                                                                                : doc?.status?.includes(enums?.REJECTED)
                                                                                                                                    ? "Rejected"
                                                                                                                                    : "N/A"}
                                                                                                                        </span> : <>
                                                                                                                            <span className={`cursor-pointer cm-approve-btn`} onClick={() => handleUpdateUploadedDocStatus(doc?._id, 'APPROVED')}>
                                                                                                                                {"Approve"}
                                                                                                                            </span>
                                                                                                                            <span className={`cursor-pointer cm-reject-btn`} onClick={() => handleUpdateUploadedDocStatus(doc?._id, 'REJECT')}>
                                                                                                                                {"Reject"}
                                                                                                                            </span>
                                                                                                                        </>}
                                                                                                                    </> : null}
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            <div className="p-4">No Documents yet..!</div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {docStatus?.includes('REJECT') ? <>
                                                                                                        <div className="d-flex flex-column gap-2 w-30">
                                                                                                            <Typography color={'#000'} fontSize={12} fontWeight={600}>Rejection Reason</Typography>
                                                                                                            <textarea
                                                                                                                rows="4"
                                                                                                                cols="25"
                                                                                                                wrap="hard"
                                                                                                                placeholder="Type Reason here..!"
                                                                                                                sx={{ borderColor: 'red' }}
                                                                                                                onChange={(e) => rejectedReason = e.target.value}
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div className="d-flex flex-column gap-2">
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                className="alert-cancel-btn cursor-pointer"
                                                                                                                onClick={() => { setDocStatus(''); rejectedReason = '' }}
                                                                                                            >
                                                                                                                Cancel
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                className="save-btn cursor-pointer"
                                                                                                                type="submit"
                                                                                                                onClick={() => handleUpdateUploadedDocStatus(docID, 'REJECTED')}
                                                                                                            >
                                                                                                                {!updateMyServiceLoading ? "Save Changes" : "Saving..."}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </> : null}
                                                                                                </>)}

                                                                                                {activeTab === 1 && (
                                                                                                    <div className="d-flex justify-content-between flex-wrap w-100 mx-2">
                                                                                                        <div className="w-30 d-flex flex-column">
                                                                                                            <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">{uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url ? 'Change' : 'Upload'} File</Typography>
                                                                                                            {imageLoading ? (
                                                                                                                <div className="d-flex justify-content-center align-items-center h-100">
                                                                                                                    <CircularProgress />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <label
                                                                                                                    for="fileInput"
                                                                                                                    class="cm-sample-file-upload cursor-pointer"
                                                                                                                >
                                                                                                                    {previewDeliverableUrl ? (
                                                                                                                        <>
                                                                                                                            <div className="image-preview">
                                                                                                                                <img
                                                                                                                                    src={previewDeliverableUrl}
                                                                                                                                    alt=""
                                                                                                                                    className="w-100 h-100 rounded"
                                                                                                                                    loading="lazy"
                                                                                                                                    height={'150px'}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <Typography color={'#312D2D'} fontSize={16} fontWeight={400}>
                                                                                                                                <img
                                                                                                                                    src={imgfile}
                                                                                                                                    className="img-ld"
                                                                                                                                    alt=""
                                                                                                                                ></img>
                                                                                                                                Drag Here
                                                                                                                            </Typography>

                                                                                                                            <p className="ld-text-1 mt-2 d-flex">
                                                                                                                                Drag and drop or <span>&nbsp;</span>
                                                                                                                                <Typography color={'#1E68F9'} fontSize={12} fontWeight={400}>
                                                                                                                                    browse
                                                                                                                                </Typography> <span>&nbsp;</span>your files
                                                                                                                            </p>

                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </label>
                                                                                                            )}
                                                                                                            <input
                                                                                                                type="file"
                                                                                                                id="fileInput"
                                                                                                                className="ld-inp-1"
                                                                                                                accept="image/png, image/jpeg, image/jpg"
                                                                                                                onChange={(e) => handleDeliverableUpload(e)}
                                                                                                            />
                                                                                                            {uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url ?
                                                                                                                <Typography color={'#1E68F9'} fontSize={12} fontWeight={500} className="my-2 cursor-pointer justify-content-end d-flex w-100" onClick={async () => {
                                                                                                                    if (uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url) {
                                                                                                                        setIsDownloading(uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url);

                                                                                                                        const imgUrl = await getBlobFile(uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url);

                                                                                                                        if (imgUrl) {
                                                                                                                            const fileName = uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.filename || "image.png";
                                                                                                                            exportBlobFile(imgUrl, fileName);
                                                                                                                        }

                                                                                                                        setIsDownloading("");
                                                                                                                    }
                                                                                                                }}>
                                                                                                                    {isDownloading ? '...' : 'View Sample'}
                                                                                                                </Typography> : null}
                                                                                                        </div>
                                                                                                        <div className="w-45">

                                                                                                            <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">File Description</Typography>
                                                                                                            <CustomQuill
                                                                                                                value={
                                                                                                                    uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.fileDescription ||
                                                                                                                    ""
                                                                                                                }
                                                                                                                readOnly={false}
                                                                                                                onChange={(value) =>
                                                                                                                    handleChangeSampleFileDescription(value)
                                                                                                                }
                                                                                                            />
                                                                                                        </div>

                                                                                                        <div className="d-flex flex-column gap-2 justify-content-end">
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                className="alert-cancel-btn cursor-pointer"
                                                                                                                onClick={() => handleClickSubTask(expandedSubTask?.taskIndex, expandedSubTask?.subTasksIndex)}
                                                                                                            >
                                                                                                                Cancel
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                variant="contained"
                                                                                                                className="save-btn cursor-pointer"
                                                                                                                type="submit"
                                                                                                                onClick={() => {
                                                                                                                    let updatedServiceTasks = selectedServiceTasks.map((task) => ({
                                                                                                                        ...task,
                                                                                                                        subTasks: task.subTasks.map((subTask) => ({
                                                                                                                            ...subTask,
                                                                                                                            uploadedDocuments: subTask?.clicked ? uploadedDocs : subTask?.uploadedDocuments,
                                                                                                                        })),
                                                                                                                    }));

                                                                                                                    setSelectedServiceTasks(updatedServiceTasks);
                                                                                                                    selectedService.registeredServices[0].tasks = updatedServiceTasks;
                                                                                                                    updateMyService(selectedService, selectedService?._id);
                                                                                                                }}
                                                                                                            >
                                                                                                                {!updateMyServiceLoading ? "Save Changes" : "Saving..."}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </> :
                                                                                                <div className="d-flex justify-content-between flex-wrap w-100 mx-2">
                                                                                                    <div className="w-30 d-flex flex-column">
                                                                                                        <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">Upload File</Typography>
                                                                                                        {imageLoading ? (
                                                                                                            <div className="d-flex justify-content-center align-items-center h-100">
                                                                                                                <CircularProgress />
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <label
                                                                                                                for="fileInput"
                                                                                                                class="cm-sample-file-upload cursor-pointer"
                                                                                                            >
                                                                                                                {previewDeliverableUrl ? (
                                                                                                                    <>
                                                                                                                        <div className="image-preview">
                                                                                                                            <img
                                                                                                                                src={previewDeliverableUrl}
                                                                                                                                alt=""
                                                                                                                                className="w-100 h-100 rounded"
                                                                                                                                loading="lazy"
                                                                                                                                height={'150px'}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <Typography color={'#312D2D'} fontSize={16} fontWeight={400}>
                                                                                                                            <img
                                                                                                                                src={imgfile}
                                                                                                                                className="img-ld"
                                                                                                                                alt=""
                                                                                                                            ></img>
                                                                                                                            Drag Here
                                                                                                                        </Typography>

                                                                                                                        <p className="ld-text-1 mt-2 d-flex">
                                                                                                                            Drag and drop or <span>&nbsp;</span>
                                                                                                                            <Typography color={'#1E68F9'} fontSize={12} fontWeight={400}>
                                                                                                                                browse
                                                                                                                            </Typography> <span>&nbsp;</span>your files
                                                                                                                        </p>

                                                                                                                    </>
                                                                                                                )}
                                                                                                            </label>
                                                                                                        )}
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            id="fileInput"
                                                                                                            className="ld-inp-1"
                                                                                                            accept="image/png, image/jpeg, image/jpg"
                                                                                                            onChange={(e) => handleDeliverableUpload(e)}
                                                                                                        />
                                                                                                        {uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url ?
                                                                                                            <Typography color={'#1E68F9'} fontSize={12} fontWeight={500} className="my-2 cursor-pointer justify-content-end d-flex w-100" onClick={async () => {
                                                                                                                if (uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url) {
                                                                                                                    setIsDownloading(uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url);

                                                                                                                    const imgUrl = await getBlobFile(uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.url);

                                                                                                                    if (imgUrl) {
                                                                                                                        const fileName = uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.filename || "image.png";
                                                                                                                        exportBlobFile(imgUrl, fileName);
                                                                                                                    }

                                                                                                                    setIsDownloading("");
                                                                                                                }
                                                                                                            }}>
                                                                                                                {isDownloading ? '...' : 'View Sample'}
                                                                                                            </Typography> : null}
                                                                                                    </div>
                                                                                                    <div className="w-30">

                                                                                                        <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">File Description</Typography>
                                                                                                        <CustomQuill
                                                                                                            value={
                                                                                                                uploadedDocs?.find((item) => item?.userType === enums?.AGENT)?.fileDescription ||
                                                                                                                ""
                                                                                                            }
                                                                                                            readOnly={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="w-30 overflow-auto d-flex flex-column gap-2 mt-2">
                                                                                                        {selectedServiceTasks?.some((item) =>
                                                                                                            item?.subTasks?.some((subTask) => subTask.clicked)
                                                                                                        ) && uploadedDocs?.filter((item) => item?.userType?.includes(enums?.CLIENT))?.length > 0 ? (
                                                                                                            uploadedDocs?.filter((item) => item?.userType?.includes(enums?.CLIENT))?.map((doc) => (
                                                                                                                <div className="wrap-cm-1 d-flex align-items-center w-100">
                                                                                                                    <div className="d-flex align-items-center gap-2 me-2">
                                                                                                                        <img src={Document} alt="" />
                                                                                                                        <Typography
                                                                                                                            sx={{
                                                                                                                                fontSize: "14px",
                                                                                                                                wordBreak: "break-word",
                                                                                                                                fontWeight: 600,
                                                                                                                                color: '#666666'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {doc?.filename || "Sample File"}
                                                                                                                        </Typography>
                                                                                                                    </div>
                                                                                                                    {/* <Grid container>
                                                                                                                     <Grid item className="cm-p-w">
                                                                                                                        <Typography sx={{ fontSize: "12px" }}>
                                                                                                                        {" "}
                                                                                                                        2.2 MB
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item className="cm-p-w">
                                                                                                                        <Typography sx={{ fontSize: "12px" }}>
                                                                                                                        2 Mo Ago
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    </Grid> */}
                                                                                                                    {doc?.url ? <>

                                                                                                                        <IconButton
                                                                                                                            id="downloadButton"
                                                                                                                            disabled={isDownloading === doc?.url}
                                                                                                                            onClick={async () => {
                                                                                                                                if (doc?.url) {
                                                                                                                                    setIsDownloading(doc?.url);

                                                                                                                                    const imgUrl = await getBlobFile(doc?.url);

                                                                                                                                    if (imgUrl) {
                                                                                                                                        const fileName = doc?.filename || "image.png";
                                                                                                                                        exportBlobFile(imgUrl, fileName);
                                                                                                                                    }

                                                                                                                                    setIsDownloading("");
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <DownloadIcon />
                                                                                                                        </IconButton>
                                                                                                                        {doc?.status ? <span className={`cursor-pointer ${doc?.status?.includes(enums?.REJECTED) ? 'cm-reject-btn' : 'cm-approve-btn'}`}>
                                                                                                                            {doc?.status?.includes(enums?.APPROVED)
                                                                                                                                ? "Approved"
                                                                                                                                : doc?.status?.includes(enums?.REJECTED)
                                                                                                                                    ? "Rejected"
                                                                                                                                    : "N/A"}
                                                                                                                        </span> : <>N/A</>}
                                                                                                                    </> : null}
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            <div className="p-4">No Documents yet..!</div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </Box>

                                                                                        {/* Close Button */}
                                                                                        {/* <Box mt={2}>
                                                                                            <Button onClick={() => setExpandedSubTask(null)} variant="contained" color="secondary">
                                                                                                Close
                                                                                            </Button>
                                                                                        </Box> */}
                                                                                    </StyledTableCell>
                                                                                </TableRow>
                                                                            )}

                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </React.Fragment>
                                                ))
                                                : null}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                {userType?.includes(eNums?.AGENT) &&
                                    Permissions?.clientManagement?.submodules?.clientManagement
                                        ?.enabled &&
                                    Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                        eNums?.EDIT
                                    ) ? (
                                    <Grid
                                        item
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "3rem",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            className="cm-create-status cursor-pointer"
                                            onClick={handleAddStatus}
                                        >
                                            + Add Task
                                        </Button>
                                    </Grid>
                                ) : null}
                            </div>

                        </Grid>
                    </Grid>
                ) : null
                }
            </Grid >

            <Drawer
                anchor={"right"}
                open={openTickets}
                onClose={() => setOpenTickets(false)}
                TransitionComponent={Slide}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "400px",
                    },
                }}
            >
                <div>
                    <CloseIcon
                        fontSize="inherit"
                        sx={{ color: "#2A2A2A", right: 0, position: "absolute" }}
                        className="cursor-pointer my-2 me-2"
                        onClick={() => setOpenTickets(false)}
                    />
                    {userType?.includes(enums?.CLIENT) ? null : <>
                        <div className="mt-5 mb-3 mx-5">
                            <Typography fontWeight={700} fontSize={18} color={'#333'}>
                                Client Management
                            </Typography>
                            {clientDetailsData?.map((item, index) => (
                                <div className="d-flex flex-wrap gap-1 my-3" key={index}>
                                    <img src={item?.icon} alt="" width={20} height={20} />
                                    <Typography
                                        fontWeight={600}
                                        fontSize={14}
                                        color={"#303030"}
                                        className="ms-1"
                                        letterSpacing={0}
                                    >
                                        {item?.label} :
                                    </Typography>
                                    <Typography
                                        fontWeight={600}
                                        fontSize={14}
                                        color={"#303030"}
                                        letterSpacing={0}
                                    >
                                        {item?.value}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <div className="border-bottom-gray" />

                        <div className="my-2 mx-5">

                            {clientServicesData?.map((item, index) => (
                                <div className="d-flex flex-wrap gap-1 my-3 align-items-center" key={index}>
                                    <img src={item?.icon} alt="" width={20} height={20} />
                                    <Typography
                                        fontWeight={600}
                                        fontSize={14}
                                        color={"#303030"}
                                        className="ms-1"
                                        letterSpacing={0}
                                    >
                                        {item?.label}
                                    </Typography>
                                    <Typography
                                        fontWeight={600}
                                        fontSize={20}
                                        color={"#303030"}
                                        className="ms-1"
                                        letterSpacing={0}
                                    >
                                        {item?.value}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <div className="border-bottom-gray" />
                    </>}

                    <div className="my-4 mx-5">
                        <Typography fontWeight={700} fontSize={18} color={'#333'} className={`mb-2 ${userType?.includes(enums?.CLIENT) ? 'mt-5' : ''}`}>
                            Tickets
                        </Typography>

                        <div className="d-flex flex-column gap-4 overflow-auto">
                            {serviceTicketsData?.length ? serviceTicketsData?.map((ticket, index) => (
                                <Grid item className="wrap-em" onClick={() => setSelectedTicket(ticket)}>
                                    <Grid container className="ticket-card d-flex flex-column px-4">
                                        <Typography fontWeight={600} fontSize={14} color={"#333"}>
                                            Ticket ID - {ticket?.ticketID || '-'}
                                        </Typography>
                                        {/* <Typography fontWeight={500} fontSize={12} color={"##3E3E3E"}>
                                            Created a request
                                        </Typography> */}
                                        <div className="d-flex justify-content-between flex-wrap w-100">
                                            <Typography fontWeight={600} fontSize={12} color={"##3E3E3E"} className="d-flex flex-wrap align-items-center">
                                                Generated on -&gt; <Typography fontSize={10} fontWeight={400} className="ms-1">{dateFormatter(ticket?.generatedOn)}</Typography>
                                            </Typography>
                                            {ticket?.status ?
                                                <span className={`${ticket?.status}-ticket-status-btn`}>{ticket?.status}</span> : null}
                                        </div>
                                    </Grid>
                                </Grid>
                            )) : <>No Tickets yet..!</>}
                        </div>

                        {userType?.includes(enums?.CLIENT) ?
                            <div className="d-flex justify-content-center my-3">
                                <Button
                                    variant="contained"
                                    className="cc-btn1 cursor-pointer py-2"
                                    onClick={handleAddTicket}
                                    disabled={createServiceTicketoading}
                                >
                                    {createServiceTicketoading ? 'Creating...' : 'Create New Ticket'}
                                </Button></div> : null}
                    </div>
                </div>
            </Drawer>

            <Drawer
                anchor={"right"}
                open={selectedTicket?._id}
                onClose={() => {setSelectedTicket(null);  getUserServiceTickets({ userServiceID: selectedService?._id });}}
                TransitionComponent={Slide}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "400px",
                    },
                }}
            >
                <div>
                    <div className="d-flex align-items-center flex-wrap mt-4 mx-2">
                        <IconButton onClick={() => {setSelectedTicket(null);  getUserServiceTickets({ userServiceID: selectedService?._id });}}><img src={BackIcon} width={24} height={24} alt='' /></IconButton>
                        <Typography fontWeight={600} fontSize={18} color={'#000'}>
                            Ticket Details
                        </Typography>
                    </div>

                    <div className=" d-flex flex-column gap-1 mt-3 mx-5">
                        <div className="d-flex align-items-center flex-wrap gap-1">
                            <Typography
                                fontWeight={600}
                                fontSize={16}
                                color={"#495056"}
                                className="ms-1"
                                letterSpacing={0}
                            >
                                Ticket ID :
                            </Typography>
                            <Typography
                                fontWeight={400}
                                fontSize={16}
                                color={"#495056"}
                                letterSpacing={0}
                            >
                                {selectedTicket?.ticketID}
                            </Typography>
                        </div>

                        <div className="d-flex align-items-center flex-wrap gap-1">
                            <Typography
                                fontWeight={600}
                                fontSize={12}
                                color={"#495056"}
                                className="ms-1"
                                letterSpacing={0}
                            >
                                Generated On:
                            </Typography>
                            <Typography
                                fontWeight={400}
                                fontSize={12}
                                color={"#495056"}
                                letterSpacing={0}
                            >
                                {dateFormatter(selectedTicket?.generatedOn)}
                            </Typography>
                        </div>

                        {userType?.includes(enums?.AGENT) && Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                            eNums?.EDIT
                        ) ?
                            <Select
                                value={
                                    selectedTicket?.status || ""
                                }
                                onChange={async (e) => {
                                    await updateServiceTicketStatus({ ticketId: selectedTicket?._id, status: e.target.value });
                                    await getUserServiceTickets({ userServiceID: selectedService?._id });
                                    setSelectedTicket(null);
                                }}
                                displayEmpty
                                fullWidth
                                style={{
                                    height: "2rem",
                                    fontWeight: 500,
                                    backgroundColor: (() => {
                                        switch (selectedTicket?.status) {
                                            case "Open":
                                                return "#E6F3FF";
                                            case "Closed":
                                                return "#FF5C5C";
                                            default:
                                                return "#EEFFEF";
                                        }
                                    })(),
                                    color: (() => {
                                        switch (selectedTicket?.status) {
                                            case "Open":
                                                return "#1E68F9";
                                            case "Closed":
                                                return "#fff";
                                            default:
                                                return "#1E68F9";
                                        }
                                    })(),
                                }}
                            >
                                <MenuItem value="Open" sx={{ color: "#1E68F9" }}>Open Ticket</MenuItem>
                                <MenuItem value="Closed" sx={{ color: "#FF5C5C" }}>Close Ticket</MenuItem>
                            </Select> :
                            <Typography className={`d-flex align-items-center justify-content-center px-2 py-1 rounded`} backgroundColor={selectedTicket?.status === 'Open' ? '#1E68F9' : '#FF5C5C'} color={'#fff'}>
                                {selectedTicket?.status}
                            </Typography>}
                    </div>
                    <div className="border-bottom-gray my-3" />

                    {
                        selectedTicket?._id ? (
                            <ChatComponent
                                serviceDetails={selectedService}
                                ticketDetails={selectedTicket}
                                messages={selectedTicket?.messages}
                                Permission={(userType?.includes(enums?.CLIENT) || Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                    eNums?.EDIT)) && selectedTicket?.status === 'Open'
                                    ? true : false}
                                error={serviceTicketsDataError}
                            />
                        ) : null
                    }
                </div>
            </Drawer>

            <Drawer
                anchor={"right"}
                open={openTaskInvoice}
                onClose={handleCloseTaskInvoice}
                TransitionComponent={Slide}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "400px",
                    },
                }}
            >
                <div>
                    <div className="d-flex align-items-center flex-wrap mt-4 mx-2">
                        <IconButton onClick={handleCloseTaskInvoice}><img src={BackIcon} width={24} height={24} alt='' /></IconButton>
                        <Typography fontWeight={600} fontSize={18} color={'#000'}>
                            Create Invoice
                        </Typography>
                    </div>

                    <div className="my-4 mx-5">

                        <div className="d-flex align-items-center my-auto py-3 px-4 gap-2 rounded border-gray">
                            <Typography fontWeight={700} fontSize={14} color={"#404040"}>
                                Task Status:
                            </Typography>
                            <Typography fontWeight={400} fontSize={14} color={"#404040"}>
                                {taskItem?.title || 'N/A'}
                            </Typography>
                        </div>

                        <Grid item className="d-flex mt-4 flex-column">
                            <Typography fontWeight={500} fontSize={14} color={"#5c5c5c"}>
                                Pricing
                            </Typography>
                            <input
                                className="sm-grid-1"
                                type="number"
                                min={1}
                                placeholder="Enter Amount"
                                value={taskPricing}
                                onChange={(e) => setTaskPricicng(e.target.value)}
                            />
                        </Grid>

                        {userType?.includes(enums?.AGENT) ?
                            <div className="d-flex my-3">
                                <Button
                                    variant="contained"
                                    className="cc-btn1 cursor-pointer py-3 px-4"
                                    onClick={handleCreateTaskInvoice}
                                    disabled={updateMyServiceLoading}
                                >
                                    {updateMyServiceLoading ? 'Saving...' : `${taskItem?.typeOfTask?.[0]?.invoiceData?.[0]?.invoiceAmount ? 'Edit' : 'Create'} Invoice`}
                                </Button></div> : null}
                        {taskItem?.typeOfTask?.[0]?.invoiceData?.[0]?.invoiceAmount ?
                            <div className="d-flex flex-column my-auto py-3 px-4 gap-2 rounded border-gray">
                                <div className="d-flex align-items-center gap-1 flex-wrap w-100">
                                    <Typography fontWeight={700} fontSize={14} color={"#404040"}>
                                        Order ID:
                                    </Typography>
                                    <Typography fontWeight={400} fontSize={14} color={"#404040"}>
                                        {taskItem?.typeOfTask?.[0]?.invoiceData?.[0]?.orderID || 'N/A'}
                                    </Typography>
                                </div>
                                <div className="d-flex align-items-center gap-1 flex-wrap w-100">
                                    <Typography fontWeight={700} fontSize={14} color={"#404040"}>
                                        Invoice ID:
                                    </Typography>
                                    <Typography fontWeight={400} fontSize={14} color={"#404040"}>
                                        {taskItem?.typeOfTask?.[0]?.invoiceData?.[0]?.invoiceID || 'N/A'}
                                    </Typography>
                                </div>
                            </div> : null}
                    </div>
                </div>
            </Drawer>

            {
                snackbar?.open ? (
                    <CustomizedSnackbars
                        open={snackbar?.open}
                        message={snackbar?.message}
                        severity={snackbar?.severity}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                    />
                ) : null
            }

        </NewLayout >
    );
}

export default CustomerManagement;
