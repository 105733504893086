export const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return {
        apiURL: process.env.REACT_APP_API_BASE_DEV_URL,
        scriptURL: process.env.REACT_APP_SCRIPT_DEV_URL,
        rezorpayKeyID: process.env.RAZORPAY_KEY_ID_DEV,
      };
    case "production":
      return {
        apiURL: process.env.REACT_APP_API_BASE_PROD_URL,
        scriptURL: process.env.REACT_APP_SCRIPT_PROD_URL,
        rezorpayKeyID: process.env.RAZORPAY_KEY_ID_PROD,
      };
    default:
      return {
        apiURL: process.env.REACT_APP_API_BASE_DEV_URL,
        scriptURL: process.env.REACT_APP_SCRIPT_DEV_URL,
        rezorpayKeyID: process.env.RAZORPAY_KEY_ID_DEV,
      };
  }
};
