import FileNowLogo from "../../assets/images/File_Now_Logo.svg";

function PrivacyPolicy() {
  return (
    <div className="policies-container">
        <div className="logo-container">
          <img alt="content" src={FileNowLogo} width="160" height="100" />
        </div>
      <h3><strong>Privacy Policy</strong></h3>
      <ol className="privacy-list-style">
        <strong>1. General</strong>
        <li>&nbsp;</li>
        <li>
          a) This document is an electronic record under the Information
          Technology Act, 2000, and applicable rules and amendments pertaining
          to electronic records. It is generated by a computer system and does
          not require physical or digital signatures.
        </li>
        <li>&nbsp;</li>
        <li>
          b) This document is published in accordance with Rule 3(1) of the
          Information Technology (Intermediaries Guidelines) Rules, 2011,
          which requires the publication of rules, regulations, privacy
          policies, and Terms of Use for accessing or using filenow.in.
        </li>
        <li>&nbsp;</li>
        <li>
          c) The domain name{" "}
          <a href="http://www.vakilsearch.com">filenow.in</a> ("Website") is
          owned and operated by Edfox Technologies Pvt. Ltd. ("Company"), a
          private company limited by shares, incorporated under the Companies
          Act, 2013. The registered office is at G1,Sri Vaikunth Flats, No 1,
          Vivekanandha Street,Gandhi Nagar, Avadi, , CHENNAI, Tamil Nadu,
          India - 600054. References to the Company include its
          representatives, administrators, employees, directors, officers,
          agents, and their successors and assigns.
        </li>
        <li>&nbsp;</li>
        <li>
          d) For the purposes of this Privacy Policy ("Policy"): i) "You" and
          "User" refer to any legal person or entity accessing or using the
          services on this Website and who is competent to enter into binding
          contracts under the Indian Contract Act, 1872. ii) "We," "Us," and
          "Our" refer to the Website and/or the Company, as the context
          requires. iii) "Party" and "Parties" refer to the User and the
          Company individually and collectively, as the context requires.
        </li>
        <li>&nbsp;</li>
        <li>
          e) Headings in this Policy are for organizational purposes only and
          do not affect the interpretation of the provisions. The headings
          have no legal or contractual value.
        </li>
        <li>&nbsp;</li>
        <li>
          f) Use of the Website is governed by this Policy and the Terms of
          Use ("Terms," available at{" "}
          <a href="http://www.vakilsearch.com">filenow.in</a>), including any
          amendments made by the Company. By visiting the Website or using its
          services, the User accepts this Policy and the Terms. The User
          agrees that the Terms and Policy are linked; the expiration or
          termination of one will result in the termination of the other.
        </li>
        <li>&nbsp;</li>
        <li>
          g) This Policy and the Terms constitute a legally binding agreement
          between the User and the Company. The User agrees to abide by the
          rules, guidelines, policies, and conditions applicable to any
          service provided by the Website, which are incorporated into the
          Terms. The User's use of the Website constitutes full and final
          acceptance of the Policy and Terms without the need for a signature.
        </li>
        <li>&nbsp;</li>
        <li>
          h) The Company retains the right to amend or modify the Policy and
          Terms without prior notice to the User. Any changes take effect
          immediately. The User is responsible for reviewing the Policy and
          Terms periodically. Continued use of the Website after changes
          indicates acceptance of the amended Policy and Terms. Compliance
          with the Policy and Terms grants the User a personal, non-exclusive,
          non-transferable, revocable privilege to access and use the Website.
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>2. Collection of Personal and Other Information</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          a) The User acknowledges and agrees that the Company collects and
          stores personal information provided by the User through the
          Website. This includes, but is not limited to, usernames, passwords,
          email addresses, names, addresses, age, date of birth, gender,
          nationality, shopping preferences, browsing history, and any images
          or other content uploaded by the User. This information is used to
          provide and improve services, customize the Website to better meet
          User needs, and enhance user experience.
        </li>
        <li>&nbsp;</li>
        <li>
          b) The User understands that the Company/Website may automatically
          collect information based on the User&rsquo;s IP address and
          behavior on the Website. This includes data such as the URLs visited
          before and after accessing the Website, browser and computer
          information, and IP address. This data is used for internal research
          on demographics, interests, and behavior to improve services. The
          User consents to the collection and use of this information.
        </li>
        <li>&nbsp;</li>
        <li>
          c) If the User makes a purchase on the Website, the Company/Website
          may collect information about the User&rsquo;s buying behavior and
          trends.
        </li>
        <li>&nbsp;</li>
        <li>
          d) The User agrees to receive communications at the provided mobile
          number, even if it is listed on the DND/NCPR list under TRAI
          regulations. The User authorizes the Company to share this
          information with third-party service providers, affiliates, or group
          companies for the purpose of communication.
        </li>
        <li>&nbsp;</li>
        <li>
          e) The User understands that any messages, reviews, or feedback
          posted on the Website will be collected and stored indefinitely.
          This information may be used for resolving disputes, customer
          support, troubleshooting, and may be disclosed to judicial or
          governmental authorities if required by law.
        </li>
        <li>&nbsp;</li>
        <li>
          f) The User acknowledges that the Company may collect and compile
          all information related to the User, including personal
          correspondence, feedback from other users, and activities on the
          Website, into a user-specific file/folder. The User consents to this
          collection and compilation.
        </li>
        <li>&nbsp;</li>
        <li>
          g) While some sections of the Website can be accessed without
          registration, certain activities, such as placing an order, require
          valid personal information for registration. The contact information
          provided may be used to send offers and promotions, and the User
          consents to receiving such communications.
        </li>
        <li>&nbsp;</li>
        <li>
          h) The User may occasionally be asked to complete optional online
          surveys, which may require contact and demographic information. This
          data helps customize the Website and provide relevant products,
          services, and content based on User preferences.
        </li>
        <li>&nbsp;</li>
        <li>
          i) The User may be requested to write reviews for products or
          services purchased from the Website or for sellers listing on the
          Website. Reviews help other users make informed decisions and assist
          the Company in maintaining quality. The User authorizes the Company
          to publish these reviews, including the User&rsquo;s name and
          contact details, on the Website.
        </li>
        <li>&nbsp;</li>
        <li>
          j) The Company/Website is not obligated to store, upload, publish,
          or display content, reviews, surveys, or feedback submitted by the
          User. The Company reserves the right to remove such content without
          notifying the User.
        </li>
        <li>&nbsp;</li>
        <li>
          k) As part of using our services, we collect information such as
          phone number, email address, device details, and IP address. By
          using our services, you consent to the sharing and disclosure of
          this information with third-party service providers, business
          partners, and agents.
        </li>
        <li>&nbsp;</li>
        <li>
          This may include sharing information with services like GetSimpl for
          fraud prevention and PayLater checkout experiences. Please refer to
          the respective third-party privacy policies for further details.
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>3. Cookies</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          a) The User acknowledges that a "Cookie" is a small piece of data
          stored by a web server on a web browser, which can be later
          retrieved from that browser. Cookies help the browser remember
          user-specific information, such as login credentials and
          preferences. The User consents to the Website placing both permanent
          and temporary cookies on their computer's hard drive and web
          browser.
        </li>
        <li>&nbsp;</li>
        <li>
          b) The User understands that the Website uses cookies on certain
          pages to analyze web page flow, measure promotional effectiveness,
          and ensure trust and safety. Some features of the Website are
          accessible only through the use of cookies. While the User can
          choose to decline cookies if their browser allows, this may result
          in limited access to certain features on the Website.
        </li>
        <li>&nbsp;</li>
        <li>
          c) The User is aware that they may encounter cookies or similar
          tracking technologies on certain pages of the Website that are
          placed by third parties or affiliates. The User acknowledges that
          the Company/Website does not control these third-party cookies or
          tracking technologies and is not responsible for them. The User
          assumes all risks associated with their use.
        </li>
        <li>&nbsp;</li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>4. Divulging/Sharing of Personal Information</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          a) The User understands that the Website/Company may share personal
          information with affiliated entities and partners to help detect and
          prevent identity theft, fraud, and other illegal activities. This
          may include correlating accounts to prevent misuse and facilitating
          joint services provided by multiple entities.
        </li>
        <li>&nbsp;</li>
        <li>
          b) The User acknowledges that the Website/Company may disclose
          personal information if required by law or if the Website/Company
          believes, in good faith, that such disclosure is necessary to
          respond to legal processes such as subpoenas or court orders. The
          Website/Company may also disclose information to law enforcement,
          rights holders, or other parties if it believes such action is
          necessary to enforce the Terms, respond to claims of content
          violations, or protect the rights and safety of users and the
          public.
        </li>
        <li>&nbsp;</li>
        <li>
          c) The User is aware that the Website/Company and its affiliates may
          share or sell personal information if the Company/Website plans to
          merge with or be acquired by another entity, or in the event of a
          reorganization or restructuring. The new entity will be bound by the
          Terms and Policy, which may be updated periodically.
        </li>
        <li>&nbsp;</li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>5. Security</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          Transactions on the Website are secure. Any information you enter
          while transacting is encrypted to protect it from unauthorized
          access. The Company/Website does not receive, store, or retain your
          credit or debit card information. This information is directly
          provided to the payment gateway, which is authorized and compliant
          with the regulations of banks, institutions, and payment providers.
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>6. Third Party Advertisements / Promotions</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          The Company/Website uses third-party advertising companies to serve
          ads on the Website. These companies may use information about your
          visits to the Website and other sites to provide customized
          advertisements. Additionally, the Website may include links to
          external websites that may collect personally identifiable
          information. The Company/Website is not responsible for the privacy
          practices or content of these external sites. You acknowledge and
          agree that any risks associated with such external sites are solely
          your responsibility.
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>7. User&rsquo;s Consent</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          By using the Website and providing information to the Company, you
          consent to the collection, use, and sharing of your information as
          described in this Policy. This includes, but is not limited to, the
          disclosure of your information as outlined in Section 4 of this
          Policy.
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>8. Grievance Officer</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          In compliance with the Information Technology Act 2000 and its
          associated rules, the contact details for the Grievance Officer are
          as follows:
        </li>
        <li>&nbsp;</li>
        <li>
          <strong>Name:</strong> Karthick Sreenivasan
          <br /> <strong>Email:</strong>{" "}
          <a href="mailto:karthick@filenow.in">karthick@filenow.in</a>
        </li>
      </ol>
      <ol className="privacy-list-style">
        <li>
          <strong>9. Dispute Resolution and Jurisdiction</strong>
        </li>
        <li>&nbsp;</li>
        <li>
          <strong>a) Dispute Resolution Process:</strong> Any disputes arising
          from or related to this Policy will be resolved through a two-step
          Alternative Dispute Resolution (ADR) process. This process includes:
        </li>
        <li>&nbsp;</li>
        <li>
          <strong>1. Mediation:</strong> The parties involved will first
          attempt to resolve the dispute amicably through mediation. If they
          cannot reach a resolution within 30 days of a dispute being
          communicated, the matter will move to arbitration as outlined below.
        </li>
        <li>&nbsp;</li>
        <li>
          <strong>2. Arbitration:</strong> If mediation fails, the dispute
          will be resolved through arbitration conducted by a sole arbitrator
          appointed by Edfox Technologies Private Limited. The arbitration
          will be held in English, with Mumbai, Maharashtra, India as the seat
          of arbitration. Each party will cover its own costs, although the
          arbitrator may, at their discretion, allocate costs between the
          parties. The arbitrator's decision will be final and binding.
        </li>
        <li>&nbsp;</li>
        <li>
          <strong>b) Governing Law and Jurisdiction:</strong> This Policy and
          any related agreements are governed by the laws of India. Any
          disputes will be subject to the exclusive jurisdiction of the courts
          located in Chennai, Tamil Nadu.
        </li>
      </ol>
      <p>&nbsp;</p>
    </div>
  );
}

export default PrivacyPolicy;
