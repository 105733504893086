import { Button, Grid, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import UserNamePlaceHolderIcon from "../../assets/images/username-placeholder-icon.svg";
import PasswordPlaceHolderIcon from "../../assets/images/password-placeholder-icon.svg";
import GoogleIcon from "../../assets/images/google.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Utilities/axiosInstance";
import Loader from "../../Components/Loader";
import { emailRegExp, phoneRegExp } from "../../Utilities/regex";
import CustomizedSnackbar from "../../Utilities/Alert";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Filenowlogo from "../../assets/images/sidebar_filenow_logo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { eNums } from "../../constants";

const Register = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const [data, setData] = useState({
    fullName: "",
    password: "",
    conformPassword: "",
    termAndCondition: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const GetOTP = async (body) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`auth/otp`, {
        headers: {
          audience: "FILENOWCLIENTID",
        },
      });

      if (response?.otp) {
        localStorage.setItem("otp", JSON.stringify(response.otp));
        navigate("/otp", { state: { createUserPayload: body } });
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const OnSubmit = async (e) => {
    e.preventDefault();
    if (!data?.emailorphone && !data?.password) {
      handleSnackbar("Please enter Email / Phone no & Password", "error");
      return;
    }
    if (!data?.emailorphone) {
      handleSnackbar("Please enter Email / Phone no", "error");
      return;
    }
    if (
      !emailRegExp.test(data?.emailorphone) &&
      !phoneRegExp.test(data?.emailorphone)
    ) {
      handleSnackbar("Invalid Email / Phone no", "error");
      return;
    }

    if (!data?.password) {
      handleSnackbar("Please enter Password", "error");
      return;
    }

    if (data?.password?.length < 6) {
      handleSnackbar("Password should be at least 6 characters", "error");
      return;
    }

    var currentDate = new Date();
    let isEmail = emailRegExp?.test(data?.emailorphone);
    let isMobile = phoneRegExp?.test(data?.emailorphone);
    var body = {
      fullname: "",
      userType: eNums?.AGENT,
      password: data?.password,
      isNewUser: true,
      createdOn: currentDate,
      updatedOn: currentDate,
      signUpType: isEmail ? "EMAIL" : "MOBILE",
      emailVerified: isEmail ? true : false,
      mobileVerified: isMobile ? true : false,
    };
    if (isEmail) {
      body.email = data?.emailorphone;
    }
    if (isMobile) {
      body.mobile = data?.emailorphone;
    }
    setLoading(true);
    try {
      let obj = { isNewUser: true };
      if (isEmail) {
        obj.email = data?.emailorphone;
      }
      if (isMobile) {
        obj.mobile = data?.emailorphone;
      }
      await axiosInstance.post(`user/request_otp`, obj);
      await GetOTP(body);
    } catch (error) {
      let msg = error?.response?.data?.error || "Something went wrong!";
      handleSnackbar(msg, "error");
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => {},
  });

  useEffect(() => {
    if (user && user?.access_token) {
      (async () => {
        try {
          const res = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: "application/json",
              },
            }
          );

          setLoading(true);
          try {
            let obj = { isNewUser: true };
            if (emailRegExp?.test(data?.emailorphone)) {
              obj.email = data?.emailorphone;
            }
            if (phoneRegExp?.test(data?.emailorphone)) {
              obj.mobile = data?.emailorphone;
            }
            await axiosInstance.post("user/request_otp", obj);
            let body = {
              fullname: res?.data?.name,
              userType: eNums?.AGENT,
              email: res?.data?.email,
              isNewUser: true,
              signUpType: "GOOGLE",
              createdOn: new Date(),
              updatedOn: new Date(),
              emailVerified: true,
              mobileVerified: false,
            };
            await GetOTP(body);
          } catch (error) {
            let msg = error?.response?.data?.error || "Something went wrong!";
            handleSnackbar(msg, "error");
          } finally {
            setLoading(false);
          }
        } catch (err) {
          //
        }
      })();
    }
  }, [user]);

  return (
    <>
      <Layout>

        <div className="d-flex flex-column align-items-center justify-content-center mb-5">
          <Grid container item className="wrapC1">
            <div className="d-flex flex-column align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center gap-2">
                <img src={Filenowlogo} alt="" width={30} height={30} />
                <span className="filenow-title-text">FILENOW</span>
              </div>
              <div className="wrapC1">
                <Typography variant="body1" fontWeight="700">
                  Create Account
                </Typography>
              </div>
              <Grid item container className="wrapC1">
                <Button
                  variant="contained"
                  className="d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: "#1E68F9", minWidth: "250px" }}
                  onClick={login}
                >
                  <div className="google-img d-flex align-items-center justify-content-center">
                    <img src={GoogleIcon} alt="" />
                  </div>
                  <span className="buttonText">Sign up with Google</span>
                </Button>
              </Grid>
            </div>

            <span className="auth-or-text mt-2 d-flex justify-content-center w-100">
              or
            </span>
            <Grid item container className="wrapC1 pt-0">
              <form onSubmit={OnSubmit} className="d-flex flex-column gap-2">
                <Grid item container className="wrapC1">
                  <TextField
                    id="outlined-start-adornment"
                    label="Email / Phone no"
                    variant="outlined"
                    className="auth-input"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={UserNamePlaceHolderIcon} alt="" width={18} height={18} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, emailorphone: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item container className="wrapC1">
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    className="auth-input"
                    size="small"
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, password: e.target.value };
                      });
                    }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={PasswordPlaceHolderIcon} alt="" width={18} height={18} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container className="wrapC1">
                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100 action-btn"
                  >
                    <span className="action-btn-text">Create Account</span>
                  </Button>
                </Grid>
              </form>
            </Grid>

            <Grid item container className="wrapC1 gap-1">
              <Typography
                variant="body2"
                fontWeight="700"
                fontSize={"16px"}
                color={"#000"}
              >
                Already have an FileNow Account ?
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/signIn");
                }}
                variant="body2"
                fontWeight="700"
                fontSize={"16px"}
                color={"#1E68F9"}
                className="cursor-pointer"
              >
                Sign In
              </Typography>
            </Grid>

            <Grid item container className="wrapC1 mt-3 gap-1">
              <Typography
                variant="body2"
                fontWeight="700"
                fontSize={"14px"}
                color={"#000"}
              >
                By using FileNow you agree to the
              </Typography>
              <Typography
                variant="body2"
                fontWeight="700"
                fontSize={"14px"}
                color={"#1E68F9"}
                className="cursor-pointer"
                onClick={() => navigate("/terms_and_conditions")}
              >
                Terms of Service
              </Typography>
              <Typography
                variant="body2"
                style={{ margin: "0 2px" }}
                fontWeight="700"
                fontSize={"14px"}
                color={"#000"}
              >
                and
              </Typography>
              <Typography
                variant="body2"
                fontWeight="700"
                color={"#1E68F9"}
                fontSize={"14px"}
                className="cursor-pointer"
                onClick={() => navigate("/privacy_policy")}
              >
                Privacy Policy
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Layout>

      {loading === true ? <Loader /> : null}

      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
    </>
  );
};

export default Register;
