import FileNowLogo from "../../assets/images/File_Now_Logo.svg";

function TermsAndConditions() {

  return (
      <div className="policies-container">
          <div className="logo-container">
            <img alt="content" src={FileNowLogo} width="160" height="100" />
          </div>
        <h3><strong>Terms and Conditions</strong></h3>
        <h1>Please read these Terms and Conditions carefully before using this site</h1>

<strong>I. WELCOME TO FileNow !</strong>
<p>Welcome to Filenow.in , A Product of Edfox Technologies Private Limited. We are delighted to have you explore our services. Since our interactions will be conducted online rather than in person, it is essential to clearly outline the terms of our agreement in advance.</p>
<strong>1. Agreement Overview</strong>
<p>In this agreement:</p>
<ul>
<li>The term &ldquo;Service&rdquo; refers to the various services offered by Filenow.</li>
<li>You, the individual accessing or using our services, are referred to as the &ldquo;User.&rdquo;</li>
<li>Our collective understanding and the terms governing our relationship are referred to as the &ldquo;Agreement.&rdquo;</li>
<li>The term &ldquo;Advisor&rdquo; encompasses advocates, advisors, Chartered Accountants, and Company Secretaries.</li>
</ul>
<strong>2. Age Requirement</strong>
<p>By using our &ldquo;Common Needs&rdquo; feature, you confirm that you are at least 18 years of age and are fully capable of entering into and complying with the terms, conditions, obligations, affirmations, representations, and warranties related to the creation of legal documents. If you have any questions or require assistance, please do not hesitate to contact us.</p>
<p>Thank you for choosing Filenow. We look forward to assisting you with our comprehensive services.Certainly! Here&rsquo;s the revised content:</p>
<strong>II. FILENOW ONLY PROVIDES A MEDIUM FOR INTERACTION</strong>
<p>Filenow.in is an internet portal that facilitates communication between professionals and potential users of professional services. Filenow acts as a venue for providers and consumers of professional services to exchange information with the goal of eventually forming a professional relationship. Filenow does not guarantee that users will successfully find an Advisor through this system. Filenow takes no position and offers no opinion on when or if an Advisor-client relationship has been formed.</p>
<p>In order to provide an optimal forum for advisors and clients, Filenow does not involve itself in the agreements between Advisors and clients or the actual representation of clients. Therefore, we cannot ensure the completion of the agreement or the integrity of either party. The user, and not Filenow, is solely responsible for assessing the integrity, honesty, and trustworthiness of all persons with whom the user communicates on this service. Each advisor has their own terms and conditions, and users are advised to go through the same before engaging with such advisors.</p>
<p>(a) <strong>Disclaimer of Advisor-Client Relationship</strong></p>
<p>Filenow is not an agent of the advisors. It only facilitates the communication of advisors and potential clients. Any electronic communication sent to Filenow alone will not create an advisor-client relationship between the user and Filenow, such being expressly denied.</p>
<p>(b) <strong>Filenow Does Not Promote Any User</strong></p>
<p>Filenow seeks to help every needy litigant find advisors best suited to his/her needs. Filenow is not intended to be a source of advertising or solicitation, and the contents of the website should not be construed as professional advice. Filenow may recommend subscribing advisors if they match a user&rsquo;s requirements, but not otherwise. Transmission, receipt, or use of Filenow does not constitute or create an advisor-client relationship. No recipients of content from this website should act, or refrain from acting, based upon any or all of the contents of this site. We welcome the user to study the profiles of advisors independently and make an informed choice.</p>
<p>(c) <strong>Specifically, Filenow Does Not Provide Any Avenue for Solicitation</strong></p>
<p>Filenow hides information about clients from advisors until the client communicates with the advisors directly or online. Therefore, the advisors are not allowed to view private information about potential clients.</p>
<p>(d) <strong>Filenow Does Not Provide Professional Advice</strong></p>
<p>Filenow&rsquo;s &lsquo;Common Needs&rsquo; feature uses only user-supplied content to produce basic documents. The information provided in the &lsquo;FAQs&rsquo; section also does not amount to professional advice, such merely being commonly asked queries about Will making, Lease Agreement drafting, Cheque Dishonour notices, Money recovery notices, Power of Attorney to collect rent, and other documents which may be added from time to time. Users are advised to consult advisors if they need specialized guidance on any of these documents.</p>
<p>(e) <strong>'Common Needs' - Resale of Forms Prohibited</strong></p>
<p>Filenow grants you a limited, personal, non-exclusive, non-transferable license to use our &ldquo;Common Needs&rdquo; feature for your own personal use, or if you are an attorney or professional, for your client. Except as otherwise provided, you acknowledge and agree that you have no right to modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance, or in any way exploit any of the Forms in any manner, except for modifications in filling out the Forms for your authorized use.</p>
<p>By ordering a document from Filenow, you agree that the document you purchase may only be used by you for your personal or business use or used by you in connection with your client and may not be sold or redistributed without the express written consent of Filenow. Reselling or distributing without permission amounts to a violation of Filenow&rsquo;s exclusive copyright and is liable to prosecution.</p>
<p>(f) <strong>Disclaimer of Representations by Users</strong></p>
<p>Filenow makes no representation, guarantee, or warranty (express or implied) as to their ability, competence, or quality of representation which may be provided by any of the advisors listed through this site or any affiliate thereof.</p>
<p>Please note that neither Filenow, nor any of its subsidiaries or employees are advocates, chartered accountants, or company secretaries. We are not a professional firm and we do not provide professional advice. Nothing on our website or material sent to you in our communication is to be construed as professional advice.</p>
<p>Our website, blog, and other material are only for the purpose of spreading information and awareness and are not substitutes for the advice and services of an advocate/advisor/legal professional/chartered accountant/company secretary. Wherever required to fulfill your needs, we will facilitate a connection with a suitable professional such as advisors, chartered accountants, or company secretaries. Please bear in mind that such professionals are not our representatives, agents, or employees. Our site and services are only one source of information among the many sources available to you. You may wish to consider multiple sources in order to make an informed decision.</p>
<p>The decision to engage any of these professionals is an important one, and one that you must make carefully based solely on your own judgment. If you agree to avail of the services on our website, you are giving us permission to make this selection on your behalf. If you disagree with these terms and conditions or do not want us to choose a suitable professional to fulfill your request, please do not use our services.</p>
<p>We constantly strive to keep our content and documents accurate, current, and up-to-date. However, because of changes in the law and regulations, we cannot and do not guarantee that any or all of the information on the site and other communication is completely current.</p>
<p>Please be advised that sometimes, the law, legal requirements, rules, and regulations are location-specific and may differ from location to location. The general information or other material we provide cannot fit every situation or circumstance.</p>
<p>Our sites and services are not intended to create any advocate-client relationship, and your use of our sites and services does not and will not in any circumstance create any such relationship between you and us.</p>
<h5>**III. User Guidelines**</h5>
<p>The users of Filenow are granted a non-exclusive, limited right to access and use the service in accordance with the rules outlined in this agreement. To maintain the attractiveness and usefulness of our system for all users, it is essential that users adhere to these guidelines. Filenow reserves the right to deny further access to its service to any user who violates these rules, is the subject of complaints by other Filenow users, or for any other reason deemed appropriate by Filenow.</p>
<p>Users engaged in any of the following activities on our system will not be tolerated:</p>
<p><strong>Foul or Inappropriate Language:</strong> Use of any foul, abusive, or otherwise inappropriate language.</p>
<p><strong>Racist or Offensive Comments:</strong> Making racist, hateful, or otherwise offensive comments.</p>
<p><strong>Promotion of Illegal Activities:</strong> Providing instructional information about illegal activities or promoting physical harm or injury against any group or individual.</p>
<p><strong>Defamation:</strong> Defaming any person or group, including individuals of all ages, races, religions, and nationalities.</p>
<p><strong>Intellectual Property Violations:</strong> Violating the rights of others, including intellectual property rights such as copyright, trademark, patent, and trade secrets. This includes using the service for acts of intellectual property infringement, offering pirated computer programs or links to such programs, information to circumvent copy-protect devices, or any type of cracker utilities (including files intended for game emulation).</p>
<p><strong>Internet Standards Violations: </strong>Violating Internet standards.</p>
<p><strong>Harassment and Privacy Invasion:</strong> Displaying harassing, abusive, threatening, harmful, vulgar, obscene, or tortuous material, or invading others' privacy.</p>
<p><strong>Disruption of Service:</strong> Interfering with or disrupting the service or servers or networks connected to the service by posting advertisements or links to competing services, transmitting "junk mail," "spam," "chain letters," or unsolicited mass distribution of e-mail.</p>
<p><strong>Security Compromise:**</strong> Compromising the security of the service provided by Filenow. This includes attempting to gain access to system areas private to Filenow or other users.</p>
<strong>IV. Disclaimer of Information Obtained on the Service and Some User-Supplied Content</strong>
<p><strong>(a) Disclaimer of Information Obtained on the Service</strong></p>
<p>Filenow provides a platform for connecting individuals who require professional representation or assistance with providers of such services. Filenow is intended as a resource for informational purposes and strives to be accurate, complete, and up-to-date. However, Filenow does not warrant or guarantee the accuracy, completeness, or adequacy of the information available on this website. Filenow assumes no liability for the interpretation or use of the information provided.</p>
<p>Links from Filenow to other websites are not endorsements, referrals, or affiliations with the linked entities. Filenow is not responsible for the content of websites to which links may be provided and makes no representations or warranties about these external sites.</p>
<p>Filenow will make efforts to remove promotional material of users attempting to self-promote on the site. Opinions and views expressed by users are their own and do not reflect those of Filenow. Data submitted by users is not verified or reviewed before appearing on Filenow. Users are advised to exercise caution when using the site.</p>
<p>Filenow makes efforts to verify that advisors subscribing to the service are licensed and in good standing with their local bar at the time of registration. However, Filenow cannot continuously track or monitor the standing of advisors. Thus, Filenow makes no representation regarding the status, standing, or ability of advisors listed on the site.</p>
<p>Users are encouraged to independently investigate and evaluate advisors. The decision to engage professional services should not be based solely on claims of expertise or cost.</p>
<p>Filenow does not endorse any descriptions, specializations, or practice limitations claimed by advisors. Efforts will be made to avoid false information, but no agency or board certifies advisors as specialists or experts. Users should question advisors about their claims, ask for the names of certifying agencies, and verify all information.</p>
<p>Users are advised to exercise caution when reviewing any information provided by advisors and other parties. While Filenow requires compliance with regulations governing advisors' conduct, it is impossible for Filenow to monitor advisors' integrity.</p>
<p>Filenow does not endorse or guarantee the content or legality of any offers, statements, or promises made by advisors or other parties, on or off the site.</p>
<p><strong>(b) Disclaimer of Content Supplied by Users</strong></p>
<p>Filenow may permit visitors to post reviews, comments, and other content ("user content") at various locations on the site. Filenow is not the publisher or author of such user content but merely stores and disseminates the ideas and opinions posted by Filenow members. Filenow disclaims all responsibility for this content. If offensive material is brought to Filenow's attention, it will be removed as soon as possible, with the determination of what constitutes offending material left to Filenow&rsquo;s discretion.</p>
<strong>V. Limitations on Use</strong>
<p>The contents of Filenow are intended for personal use only and not for commercial exploitation. You may not decompile, reverse engineer, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from Filenow. Additionally, you may not use any network monitoring or discovery software to determine the site architecture or extract information about usage or users.</p>
<p>You are prohibited from using any automated device, such as a robot or spider, or any manual process to monitor or copy the contents of Filenow without prior written permission. Copying, modifying, reproducing, republishing, distributing, displaying, or transmitting any portion of Filenow for commercial, non-profit, or public purposes is also not permitted, except as expressly allowed.</p>
<p>You may not export or re-export Filenow or any content available on or through Filenow in violation of applicable export control laws and regulations of India. Any unauthorized use of Filenow or its content is strictly prohibited.</p>
<strong>VI. Confidentiality</strong>
<p>Filenow makes every effort to maintain the confidentiality of any information submitted by users to our system and our database of advisors. However, users should be aware that the use of the internet or email for confidential or sensitive information carries inherent risks. Because Filenow cannot control the conduct of others, we cannot guarantee the confidentiality of this information. Users should exercise caution when deciding what information to input into the system and should avoid making confessions or admissions.</p>
<p>Users are advised to describe their issues or disputes in general terms initially. Specific information should only be disclosed after selecting an advisor and making contact outside the service, such as via telephone or in-person appointment. Advisors subscribing to this service should refrain from requesting any specific or confidential information through the service. Filenow is not responsible for the release or improper use of such information by users or any release resulting from errors or failures in the system.</p>
<p><strong>VII. Indemnification</strong></p>
<p>Users agree that Filenow is not responsible for any harm caused by their use of the service. Users agree to indemnify, defend, and hold Filenow harmless from and against any and all liabilities, costs, claims, demands, damages, and expenses arising from or related to their use of the service or the contents therein. This indemnification includes, but is not limited to, advisors' fees and costs. This indemnification obligation also extends to any breaches of the agreement by the user. Filenow reserves the right to assume the exclusive defense and control of any matter subject to indemnification by the user and the user shall not settle any matter without Filenow&rsquo;s written consent.</p>
<p><strong>VIII. Communications and Other Data</strong></p>
<p>Filenow is not responsible for any loss of data resulting from accidental or deliberate deletion, network or system outages, backup failures, file corruption, or any other reasons.</p>
<strong>IX. License of Your Contents to Filenow</strong>
<p>By uploading content to or submitting any materials for use on Filenow, you grant (or warrant that the owner of such rights has expressly granted) Filenow a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, and distribute such materials or incorporate them into any form, medium, or technology now known or later developed. Filenow assures that any sensitive information will not be intentionally disclosed to third parties.</p>
<strong>X. Filenow Proprietary Rights</strong>
<p>Except as expressly provided in these terms and conditions, nothing contained herein shall be construed as conferring any license or right, by implication, estoppel, or otherwise, under copyright or other intellectual property rights. The user agrees that the content and website are protected by copyright, trademark, service marks, patents, or other proprietary rights and laws. The user acknowledges and agrees that the use of this material and information is permitted only as expressly authorized by Filenow and may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express authorization. The user acknowledges and agrees that Filenow can display images and text throughout the Service. Users cannot extract and publish any information from the system, either electronically or in print, without the permission of Filenow and all other concerned parties. This is not a complete list&mdash;other elements on the system are also Filenow&rsquo;s property. Contact Filenow before copying any material from the system with plans of reproducing or distributing it.</p>
<strong>XI. Linking to Filenow.in</strong>
<p>Users are welcome to provide links to the homepage of Filenow, provided they do not remove or obscure, by framing or otherwise, any portion of the homepage, and that they discontinue providing links to the site if requested by Filenow.</p>
<p><strong>XII. Advertisers</strong></p>
<p>Filenow may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on Filenow is accurate and complies with applicable laws. Filenow will not be responsible for the legality of or any error or inaccuracy in advertisers' or sponsors' materials.</p>
<p><strong>XIII. Registration</strong></p>
<p>Certain sections of Filenow may require you to register. If registration is requested, you agree to provide Filenow with accurate and complete registration information. It is your responsibility to inform Filenow of any changes to that information. Each registration is for a single person only, unless specifically designated otherwise on the registration page. Filenow does not permit a) any other person using the registered sections under your name; or b) access through a single name being made available to multiple users on a network. You are responsible for preventing such unauthorized use. If you believe there has been unauthorized use, please notify Filenow immediately by contacting us. If we find that unauthorized use is being made of Filenow and the services we provide, the rights of any or many users may be terminated.</p>
<p><strong>XIV. Errors and Corrections</strong></p>
<p>Filenow does not represent or warrant that the service will be error-free, free of viruses or other harmful components, or that defects will be corrected. Filenow may make improvements and/or changes to its features, functionality, or service at any time.</p>
<strong>XV. Third Party Content</strong>
<p>Third-party content may appear on Filenow or may be accessible via links from Filenow. Filenow is not responsible for and assumes no liability for any mistakes, misstatements of law, defamation, slander, libel, omissions, falsehood, obscenity, or profanity in the statements, opinions, representations, or any other form of information contained in any third-party content appearing on Filenow. You understand that the information and opinions in the third-party content are neither endorsed by nor do they reflect the beliefs of Filenow.</p>
<p><strong>XVI. Unlawful Activity</strong></p>
<p>Filenow reserves the right to investigate complaints or reported violations of the Agreement and to take any action Filenow deems appropriate, including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, email addresses, usage history, posted materials, IP addresses, and traffic information.</p>
<p><strong>XVII. Remedies for Violations</strong></p>
<p>Filenow reserves the right to seek all remedies available at law and in equity for violations of the Agreement, including but not limited to the right to block access from a particular Internet address to Filenow and its features.</p>
<p><strong>XVIII. Conflicts Checks</strong></p>
<p>The user understands that registered advisors will not be able to and will not perform a check for conflicts of interest between the user and other clients of the registered advisors prior to responding to a request. Conflict checks require the user to provide their name and contact information and the identity of any affiliated entities, opposing individuals and entities, and such other information as advisors may require. Conflict checks by registered advisors who obtain information from the user through this service are not possible since submissions by the user to subscribing advisors are not sufficient to conduct such a check.</p>
<p><strong>XIX. Severability of Provisions</strong></p>
<p>The Agreement and the Privacy Policy constitute the entire agreement with respect to the use of the service provided by Filenow. If any provision of these terms and conditions is unlawful, void, or unenforceable, then that provision shall be deemed severable from the remaining provisions and shall not affect their validity and enforceability.</p>
<strong>XX. Modifications to Terms of Use</strong>
<p>Filenow may change the Agreement at any time. The user will be responsible for checking the Terms and Conditions before use. Use of the service after the change will indicate acceptance of the new terms and conditions.</p>
<p><strong>XXI. Modifications to Service</strong></p>
<p>Filenow reserves the right to modify or discontinue, temporarily or permanently, the service with or without notice to the user. The user agrees that Filenow shall not be liable to the user or any third party for any modification or discontinuance of the service. The user acknowledges and agrees that any termination of service under any provision of this agreement may be effected without prior notice, and acknowledges and agrees that Filenow may immediately delete data and files in the user's account and bar any further access to such files or the Service.</p>
<p><strong>XXII. Disclaimer of Warranties and Limitation of Liability</strong></p>
<p>Filenow, like all operators of online systems, faces the risk of being held accountable for the wrongful actions of users. This includes potential liabilities for issues such as user-generated libel, viruses, illegal or improper transmissions, or other damages caused by users. While Filenow strives to provide a reliable and quality service, occasional mistakes, system failures, or errors are inevitable. Therefore, Filenow must limit its liability to ensure the continued operation and improvement of the service.</p>
<p>These needs are addressed by the following disclaimers:</p>
<p>(a) <strong>Disclaimer of Warranties</strong></p>
<p>The user expressly agrees that use of the service is at the user's sole risk. The service is provided on an "as is" and "as available" basis. Filenow expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Filenow makes no warranty that the service will meet a user's requirements, that the service will be uninterrupted, timely, secure, or error-free; nor does Filenow make any warranty as to the results that may be obtained from the use of the service or the accuracy or reliability of any information obtained through the service, or that defects in the software will be corrected. Filenow makes no warranty regarding any goods or services purchased or information obtained through the service or any transactions entered into through the service.</p>
<p>No advice or information, whether oral or written, obtained by the user from Filenow shall create any warranty not expressly stated herein.</p>
<p>(b) <strong>Limitation of Liability</strong></p>
<p>The user agrees that Filenow shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use the service, or for the cost of procurement of substitute goods and services, or resulting from any goods or services purchased or obtained, or messages received or transactions entered into through or from the service, or resulting from unauthorized access to or alteration of user&rsquo;s transmissions or data, including, but not limited to, damages for loss of profits, use, data, or other intangibles, even if Filenow has been advised of the possibility of such damages. The user further agrees that Filenow shall not be liable for any damages arising from interruption, suspension, or termination of service, including, but not limited to, direct, indirect, incidental, special, consequential, or exemplary damages, whether or not such interruption, suspension, or termination was justified, negligent, intentional, or inadvertent.</p>
<p><strong>XXIII. Arbitration</strong></p>
<p>Any controversy or claim arising out of or relating to this Agreement or Filenow services shall be settled by binding arbitration in accordance with the laws of India. Any such controversy or claim shall be arbitrated on an individual basis and shall not be consolidated with any claim or controversy of any other party. Any other dispute or disagreement of a legal nature will also be decided in accordance with the laws of India, and the Courts of Chennai shall have jurisdiction in all such cases.</p>
<p><strong>XXIV. Ownership</strong></p>
<p>This Site is owned and operated by Filenow. All rights, title, and interest in and to the materials provided on this Site, including but not limited to information, documents, logos, graphics, sounds, and images (the "Materials"), are owned by Filenow. Except as otherwise expressly provided by Filenow, none of the materials may be copied, reproduced, republished, downloaded, uploaded, posted, displayed, transmitted, or distributed in any way. Nothing on this Site shall be construed to confer any license under any of Filenow's intellectual property rights, whether by estoppel, implication, or otherwise. Contact us if you have any questions about obtaining such licenses. Filenow does not sell, license, lease, or otherwise provide any of the materials other than those specifically identified as being provided by Filenow. Any rights not expressly granted herein are reserved by Filenow.</p>
<p><strong>XXV. Entire Agreement</strong></p>
<p>This agreement constitutes the entire and complete agreement between the user and Filenow and is intended as a complete and exclusive statement of the terms of the agreement. This agreement supersedes all other communications between Filenow and its users with respect to the subject matter hereof and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. If at any time you find these Terms and Conditions unacceptable or if you do not agree to these Terms and Conditions, please do not use this Site. We may revise these Terms and Conditions at any time without notice to you. It is your responsibility to review these Terms and Conditions periodically. By using Filenow services or accessing the Filenow site, you acknowledge that you have read these terms and conditions and agree to be bound by them.</p>
<p><strong>XXVI. Indemnification</strong></p>
<p>You agree to defend, indemnify, and hold harmless Filenow, our officers, directors, shareholders, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the site and the materials.</p>
<p><strong>XXVII. Cancellation and Refund Policy</strong></p>
<p>We strive to ensure that the services you avail through our website are to your full satisfaction and are the best in the industry at extremely reasonable and affordable rates. However, there may be situations when you desire a refund. These include:</p>
<ol>
<li>when you pay for services but later decide not to avail them;</li>
<li>when there is a delay in services from our side beyond the time frame we have communicated due to human error;</li>
<li>if you find our services unsatisfactory. In such cases, please send an email to the Ticket created in your name, with a copy to attention@filenow.in. Refunds will only apply to the professional fees component of the charges paid. Upon receiving your request, the Senior Management at Filenow will decide whether to process the refund based on the reasons provided. Please note that we reserve the right to make the final and binding decision on refund requests. We are not liable for delays caused by factors outside our control, such as national or department holidays, government delays, acts of war, natural disasters, internet or power disruptions, technical failures, hacking, piracy, etc. If a refund is confirmed, we will request your Bank Account details for the transfer, which will take 48-72 working hours from receipt of the information to process. Only the professional fees paid will be refunded, subject to Senior Management discretion.</li>
</ol>
<p><strong>XXVIII. Grievance Officer</strong></p>
<p>In accordance with the Consumer Protection (E-Commerce) Rules, 2020, and Companies (Incorporation) Rules, 2014, and rules made thereunder, the name and contact details of the Grievance Officer are:</p>
<p>Name : Karthick Sreenivas</p>
<p>Email: &nbsp;nodalofficer@filenow.in</p>
<p>&nbsp;</p>
      </div>
  );
}

export default TermsAndConditions;
