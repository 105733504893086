import React from 'react'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactPageSharpIcon from '@mui/icons-material/ContactPageSharp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from '@mui/material';

const services = [
  {
    name: "Dashboard",
    logo: <SpaceDashboardIcon />,
    link: "/Dashboard",
  },
  {
    name: "New Services",
    logo: <AddBoxIcon />,
    link: "/NewService",
  },
  {
    name: "My Services",
    logo: <ContactPageSharpIcon />,
    link: "/my-services",
  },
  {
    name: "Legal Documentations",
    logo: <SummarizeIcon />,
    link: "/legal-documentation",
  },
  {
    name: "Orders & Payments",
    logo: <LocalMallIcon />,
    link: "/orders-and-payments",
  },
];

function ServicesSideBar() {
  let navigate = useNavigate();
  return (

    <>
      <Grid container display="flex" className="servicesContainer">
      <Grid item container display="flex" flexDirection="column" className="sidebarContainer">
        {services.map((item, index) => (
          <Grid
            item
            container
            display="flex"
            className="serviceItem"
            onClick={() => {
              navigate(item.link);
            }}
            key={index}
          >
             <Grid item style={{width:"20%"}}>{item.logo}</Grid>
            
            <Grid item style={{width:"80%"}}>
            <Typography variant='body1'  fontWeight="bold" className="servicesName">
              {item.name}
            </Typography>
            </Grid>

          </Grid>
        ))}
      </Grid>
    </Grid>
    </>
  )
}

export default ServicesSideBar;
