import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../Components/Loader";
import NewLayout from "../../Components/SidebarLayout";
import icon1 from "../../assets/images/icon_client_details_1.svg";
import icon2 from "../../assets/images/icon_client_details_2.svg";
import icon3 from "../../assets/images/icon_client_details_3.svg";
import * as ApiServices from "../../Utilities/apis";
import { useApi, EmptyState, PlaceholderTable, useRolePermissions } from "../../Utilities";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { eNums } from "../../constants";
import enums from "../../constants/enums";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const itemsPerPage = 8;

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#22B378",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
}));

function ClientOrderManagement() {
  const Permissions = useRolePermissions();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const userId = localStorage.getItem('userId')
  const role_level = Number(localStorage.getItem('role_level'))
  const userType = localStorage.getItem('userType')

  const {
    action: getAllOrders,
    data: orders,
    snackbarComponent,
    loading: ordersLoading,
  } = useApi(ApiServices.getUsersorOrders, {
    error: true,
    actionName: "Orders fetching",
  });
  const { action: getAllEmployees, data: employees } = useApi(
    ApiServices.getEmployees
  );
  const { action: updateUser, snackbarComponent: updateSnackbarComponent } = useApi(
    ApiServices.updateUser,
    { initialLoad: true, success: true, error: true, actionName: "Assigned" }
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      await getAllOrders();
      await getAllEmployees({ userType: eNums?.AGENT });
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (orders?.length) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const slicedData = orders.slice(indexOfFirstItem, indexOfLastItem);
      setDisplayData(slicedData);
    } else {
      setDisplayData([]);
    }
  }, [orders, currentPage]);

  const handleUpdateAssignee = async (value, id) => {
    let assignTo = value;
    let data = orders?.find((item) => item?._id === id);

    if (assignTo === "null") {
      assignTo = null;
    } else {
      let key = role_level === 1 ? "adminID" : role_level === 2 ? "accountManagerID" : "userID";

      assignTo = {
        [key]: assignTo,
        assignedBy: userId,
        assignedOn: new Date()
      };
    }

    let obj = { ...data, assignTo: { [role_level === 1 ? 'admin' : role_level === 2 ? 'accountManager' : 'user']: assignTo } };

    await updateUser(obj, id);
    handleSearch(searchString);
  };


  const handleSearch = (value) => {
    setSearchString(value)
    let obj = {}
    if (value?.length > 2) {
      obj.fullname = value
      getAllOrders(obj);
    }
    else if (!value) {

      getAllOrders(obj)
    }
  }

  const paths = [
    { label: `Order Management`, navigation: "client-details" },
  ];

  return (
    <>
      <NewLayout
        paths={paths}
        pageHeading={"Order Management"}
      >
        {snackbarComponent}
        {updateSnackbarComponent}
        <Grid container className="cd-wrap-1">
          <Grid item className="cd-wrap-2">
            <Grid item className="wrap-em">
              <Grid container className="bar-cd">
                <Grid item>
                  <img src={icon1} alt="" width={24} height={24} />
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                    Total Orders
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={26} color={"#303030"}>
                    {orders?.length || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="wrap-em">
              <Grid container className="bar-cd">
                <Grid item>
                  <img src={icon2} alt="" width={24} height={24} />
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                    Business Orders
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={26} color={"#303030"}>
                    {/* {orders?.find((item) => item?.clientType === "Business")
                      ?.length || 0} */} 0
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="wrap-em">
              <Grid container className="bar-cd">
                <Grid item>
                  <img src={icon3} alt="" width={24} height={24} />
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                    Individual Orders
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={600} fontSize={26} color={"#303030"}>
                    {/* {orders?.find((item) => item?.clientType === "Individual")
                      ?.length || 0} */} 0
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <div className="d-flex justify-content-end w-100">
            <FormControl variant="outlined">
              <OutlinedInput
                id="search"
                type="text"
                placeholder="search"
                className="cd-search"
                onChange={(e) => handleSearch(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          {ordersLoading ? <PlaceholderTable columns={6} /> : orders && orders?.length ? (
            <div className="mt-4 w-100">
              <TableContainer component={Paper} sx={{ minHeight: '30rem' }}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                      <StyledTableCell>Order</StyledTableCell>
                      <StyledTableCell>Client Name</StyledTableCell>
                      <StyledTableCell>Client Type</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Mobile</StyledTableCell>
                      {userType?.includes(eNums?.AGENT) && role_level < 5 && Permissions?.clientManagement?.submodules?.clientDashboard?.enabled && Permissions?.clientManagement?.submodules?.clientDashboard?.permission?.includes(eNums?.EDIT) ?
                        <StyledTableCell>Assign To</StyledTableCell> : null}
                      <StyledTableCell>View Details</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayData?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell onClick={() => navigate(`/customer-management`, {
                          state: {
                            user: {
                              _id: row?.userID?._id,
                              fullname: row?.userID?.fullname,
                              serviceID: row?._id,
                            },
                            previous_route_name: 'Client Management', previous_route_path: 'client-details',
                            serviceName: row?.registeredServices?.[0]?.serviceName,
                          },
                        })}>
                          <span className="cursor-pointer client-name">{row?.registeredServices?.[0]?.serviceName || "N/A"}</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.userID?.fullname || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.clientType || "Individual"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.userID?.email || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.userID?.mobile || "-"}
                        </StyledTableCell>
                        {userType?.includes(eNums?.AGENT) && role_level < 5 && Permissions?.clientManagement?.submodules?.clientDashboard?.enabled && Permissions?.clientManagement?.submodules?.clientDashboard?.permission?.includes(eNums?.EDIT) ?
                          <StyledTableCell>
                            <CustomSelect
                              sx={{ height: "30px" }}
                              className="span-cd"
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              defaultValue={""}
                              value={role_level === 1 ? row?.assignTo?.admin?.adminID : role_level === 2 ? row?.assignTo?.accountManager?.accountManagerID : ""}
                              onChange={(e) => handleUpdateAssignee(e.target.value, row?._id)}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return <Typography color={"#fff"}>Assign To</Typography>;
                                }
                                return employees?.find((emp) => emp._id === selected)?.fullname || "";
                              }}>

                              {employees && employees?.filter((item) => item?.roleID?.level === role_level + 1)?.length ? (
                                employees?.filter((item) => item?.roleID?.level === role_level + 1)?.map((e, key) => (
                                  <MenuItem key={key} value={e?._id}>
                                    {e?.fullname}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value={""}>No {role_level === 1 ? 'Admins' : 'Account Managers'}</MenuItem>
                              )}
                            </CustomSelect>
                          </StyledTableCell>
                          : null}
                        <StyledTableCell onClick={() => navigate(`/customer-management`, {
                          state: {
                            user: {
                              _id: row?.userID?._id,
                              fullname: row?.userID?.fullname,
                              serviceID: row?._id,
                            },
                            previous_route_name: 'Client Management', previous_route_path: 'client-details',
                            serviceName: row?.registeredServices?.[0]?.serviceName,
                          },
                        })}>
                          <button className="view-details-btn">View</button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {orders?.length > 8 ?
                <Grid item display="flex" justifyContent="center" margin={10}>
                  <Pagination
                    count={Math.ceil(orders.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                </Grid>
                : <></>}

            </div>
          ) : <EmptyState />}
        </Grid>
      </NewLayout>
    </>
  );
}

export default ClientOrderManagement;
