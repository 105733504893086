import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ImageExpand = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Card>
        <CardContent onClick={handleOpen} style={{ cursor: 'pointer' }}>
          <CardMedia
            component="img"
            height="200"
            image={imageUrl}
            alt="Image"
          />
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Expanded Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={imageUrl} alt="Expanded" style={{ maxWidth: '100%' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownload} color="primary">
            Download
          </Button>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageExpand;
