import { Grid, Typography } from "@mui/material";
import React from "react";
import img1 from "../assets/images/fillerimg.png";
import CurrencySymbol from '../Utilities/CurrencySymbols'
import { useNavigate } from "react-router-dom";

function CardV2({ data }) {
  const navigate = useNavigate();

  return (
    <>
      <Grid className="card-w-1 cursor-pointer" onClick={() => navigate(`/service-details`, {state: {serviceDetails: data}})}>
        <Grid container>
          <Grid item>
            <img src={img1} alt=""></img>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography>{data?.serviceName || "Loading..."}</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Typography variant="body2">
              Starts @ <CurrencySymbol amount={data?.priceTag || "..."}/>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CardV2;
