import FileNowLogo from "../../assets/images/File_Now_Logo.svg";

function RefundPolicy() {
  return (
    <div className="policies-container">
        <div className="logo-container">
          <img alt="content" src={FileNowLogo} width="160" height="100" />
        </div>
      <h3><strong>Refund Policy</strong></h3>
<p><strong>Information to Customers</strong></p>
<p>Thank you for choosing Filenow. We appreciate your purchase or service request through our website <a href="http://www.filenow.com">www.filenow.in</a> (hereinafter referred to as &ldquo;Filenow&rdquo;). Please review this policy along with the Filenow Terms and Conditions, as they provide important information regarding your rights and obligations related to your purchase or service.</p>
<p><strong>1. Eligibility for Refund</strong></p>
<p>We strive to deliver our services or products as per the specifications and timelines outlined at the time of purchase. However, in cases where we are unable to meet these specifications or timelines, you may be eligible for a refund under the following conditions:</p>
<p><strong>Deficiency in Service/Product</strong>: Refunds will be considered only if there is a clear and visible deficiency in the service or product provided by Filenow.</p>
<p><strong>Change of Mind</strong>: Refund requests based solely on a change of mind, where no fault or defect is present, will not be entertained.</p>
<p><strong>Post-Delivery Requests</strong>: Refund requests will not be considered once the work has been delivered. In such cases, we offer the option to use the amount paid towards an alternative service of equal value within Filenow. This amount can be applied in part or in full towards the new service.</p>
<p><strong>Timeframe for Refund Requests</strong>: Requests made more than 30 (thirty) days after the completion of the service or product will not be considered. The completion of the service or product will be communicated to you via email or other forms of communication.</p>
<p><strong>2. Refund Process</strong></p>
<p>If your refund request is approved, it will be processed and communicated to you via email. The refund process may take up to 15 (fifteen) business days to complete, and the amount will be credited to your bank account accordingly. We will handle your refund request with care and ensure a prompt return of your funds.</p>
<p><strong>3. Grievance Officer</strong></p>
<p>In accordance with the Consumer Protection (E-Commerce) Rules, 2020, the contact details for our Grievance Officer are as follows:</p>
<ul>
<li><strong>Email</strong>: nodalofficer@filenow.com</li>
<li><strong>Attention</strong>: attention@filenow.com</li>
</ul>
<p>For any issues or complaints, please contact our Grievance Officer, and we will address your concerns promptly.</p>
<p>&nbsp;</p>
    </div>
  );
}

export default RefundPolicy;
