import React from 'react'
import img from '../assets/images/servicesimg.png'
import { Grid, Typography } from '@mui/material'
function DocumentCards(props) {
  return (
<>
<Grid className='servicesWrap'>

<Grid className='serviceCard'>
    <Grid container>
        <img src={img} style={{width:"100%"}}/>
    </Grid>
    <Grid container>
        <Typography style={{fontWeight:"700"}}>
       {props.desc}
        </Typography>
    </Grid>
 
    <Grid container className=" grid-end"> 
        <button className='tg-button1'>
        Buy Now
        </button>
    </Grid>
</Grid>
</Grid>
</>
  )
}

export default DocumentCards