import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Typography,
  Button,
  Drawer,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import People from "../../assets/images/people.svg";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import {
  CustomizedSnackbar,
  EmptyState,
  PlaceholderTable,
  useApi,
  useRolePermissions,
} from "../../Utilities";
import * as ApiServices from "../../Utilities/apis";
import Loader from "../../Components/Loader";
import RoleManagement from "../../assets/images/role_management.svg";
import ArticleShips from "../../assets/images/profile-2user.svg";
import Edit from "../../assets/images/edit-blue.svg";
import Delete from "../../assets/images/delete-red.svg";
import UpArrowWhite from "../../assets/images/up_arrow_white.svg";
import { useNavigate } from "react-router-dom";
import NewLayout from "../../Components/SidebarLayout";
import CloseIcon from "@mui/icons-material/Close";
import { eNums } from "../../constants";
import enums from "../../constants/enums";
import { emailRegExp, phoneRegExp } from "../../Utilities/regex";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    padding: "10px",
    fontWeight: 400,
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    fontWeight: 400,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const itemsPerPage = 8; // Change itemsPerPage to 7

function EmployeeManagement() {
  const Permissions = useRolePermissions();
  const {
    action: getAllRoles,
    data: roles,
    loading: roleLoading,
  } = useApi(ApiServices.getAllRoles, {
    success: true,
    error: true,
    actionName: "Roles fetching",
  });

  const {
    action: createEmployee,
    snackbarComponent: createSnackbarComponent,
    error: createEmployeeError,
    loading: createEmployeeLoading,
  } = useApi(ApiServices.createUser, {
    success: true,
    error: true,
    actionName: "Employee creating",
  });
  const {
    action: updateEmployee,
    snackbarComponent: updateSnackbarComponent,
    error: updateEmployeeError,
    loading: updateEmployeeLoading,
  } = useApi(ApiServices.updateUser, {
    success: true,
    error: true,
    actionName: "Employee updating",
  });
  const {
    action: deleteEmployee,
    snackbarComponent: deleteSnackbarComponent,
    loading: deleteEmployeeLoading,
  } = useApi(ApiServices.deleteUser, {
    success: true,
    error: true,
    actionName: "Employee deleting",
  });
  const {
    action: getAllEmployees,
    data: employees,
    loading: employeeLoading,
    error: employeeError,
    snackbarComponent: getAllEmployeesSnackbarComponent,
  } = useApi(ApiServices.getEmployees, {
    success: true,
    error: true,
    actionName: "Employees fetching",
  });

  const userID = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const roleLevel = Number(localStorage.getItem("role_level"));
  const navigate = useNavigate();
  const [employeeEditId, setEmployeeEditId] = useState("");
  const [action, setAction] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const [employee, setEmployee] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [employeesData, setEmployeesData] = useState([]);
  const [isOpen, setIsOpen] = useState("");

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const slicedData = employees?.slice(indexOfFirstItem, indexOfLastItem);
    setEmployeesData(slicedData);
  }, [currentPage]);

  useEffect(() => {
    if (!employeeLoading || !employeeError) setEmployeesData(employees);
    else {
      setEmployeesData([]);
    }
  }, [employees]);

  useEffect(() => {
    getAllRoles();
    getAllEmployees({ userType: eNums?.AGENT, roleLevel, userID });
  }, []);

  const handleAddNew = () => {
    setEmployeeEditId("");
    setIsOpen("add");
    let obj = {
      ...employee,
      fullname: "",
      email: "",
      mobile: "",
      roleID: "",
      jobType: "",
      salaryType: "Monthly",
      userType: eNums?.AGENT,
      status: "ACTIVE",
      promotedFrom: "",
      promotedTo: "",
      createdOn: new Date(),
      updatedOn: new Date(),
    };
    setEmployee(obj);
  };

  const handleClose = () => {
    setIsOpen("");
    setEmployee(null);
  };

  const handleEmployeeDetail = (type, value) => {
    let obj = { ...employee, [type]: value };
    setEmployee(obj);
  };

  const handleEmployeeStatus = (checked) => {
    let obj = {
      ...employee,
      status: checked ? eNums?.PROMOTED : eNums?.ACTIVE,
    };
    setEmployee(obj);
  };

  const handleEdit = (item, open) => {
    setEmployeeEditId(item?._id);
    let obj = {
      ...item,
      status: item?.status || eNums?.ACTIVE,
      roleID: item?.roleID?._id,
      roleName: item?.roleID?.name,
      salaryType: item?.salaryType || 'Monthly',
      promotedFrom: item?.promotedFrom?._id || "",
      promotedTo: item?.promotedTo?._id || "",
      promotedFromName: item?.promotedFrom?.name,
      promotedToName: item?.promotedTo?.name,
    };
    setEmployee(obj);
    setIsOpen(open);
  };

  useEffect(() => {
    if (
      loading &&
      !createEmployeeLoading &&
      !createEmployeeError &&
      !updateEmployeeLoading &&
      !updateEmployeeError
    ) {
      getAllEmployees({ userType: eNums?.AGENT, roleLevel, userID });
      setEmployeeEditId("");
      setIsLoading(false);
      setAction(false);
      handleClose();
    }
  }, [loading, employeeEditId, createEmployeeError, updateEmployeeError]);
  const handleSaveEmployee = async (e) => {
    e.preventDefault();
    if (
      !employee?.fullname 
    ) {
      handleSnackbar("Name is Required..!", "error");
      return;
    }
    if (
      !employee?.email 
    ) {
      handleSnackbar("Email is Required..!", "error");
      return;
    }
    if (
      !employee?.mobile 
    ) {
      handleSnackbar("Mobile No is Required..!", "error");
      return;
    }
    if (
      !employee?.roleID 
    ) {
      handleSnackbar("Role is Required..!", "error");
      return;
    }
    if (
      !employee?.jobType 
    ) {
      handleSnackbar("Job Type is Required..!", "error");
      return;
    }
    if (
      !emailRegExp.test(employee?.email) 
    ) {
      handleSnackbar("Invalid Email..!", "error");
      return;
    }
    if (
      !phoneRegExp.test(employee?.mobile)
    ) {
      handleSnackbar("Invalid Mobile No..!", "error");
      return;
    }

      if (employeeEditId) {
        setAction(true);
        let obj = {
          ...employee,
          updatedOn: new Date(),
        };
        if (obj?.promotedFromName || obj?.promotedToName) {
          delete obj?.promotedFromName;
          delete obj?.promotedToName;
        }
        obj.roleID = employee?.roleID || null;
        obj.promotedFrom = employee?.promotedFrom || null;
        obj.promotedTo = employee?.promotedTo || null;
        await updateEmployee(obj, employeeEditId);
        setIsLoading(true);
      } else {
        let obj = {
          ...employee,
          createdOn: new Date(),
          updatedOn: new Date(),
        };
        obj.roleID = employee?.roleID || null;
        obj.promotedFrom = employee?.promotedFrom || null;
        obj.promotedTo = employee?.promotedTo || null;
        setAction(true);
        await createEmployee(obj);
        setIsLoading(true);
      }
  };

  const handleDeleteEmployee = async (id) => {
    if (id) {
      setIsLoading(true);
      await deleteEmployee(id);
      await getAllEmployees({ userType: eNums?.AGENT, roleLevel, userID });
      setIsLoading(false);
    }
  };

  const paths = [
    { label: `Employee Management`, navigation: "employee-management" },
  ];

  const employeeDetailsData = [
    { label: "Employee Name", value: employee?.fullname || "N/A" },
    { label: "Employee ID", value: employee?.employeeID || "N/A" },
    {
      label: "Mail ID",
      value: employee?.email || "N/A",
    },
    { label: "Mobile No", value: employee?.mobile || "N/A" },
  ];

  return userType === eNums?.AGENT ? (
    <NewLayout paths={paths} pageHeading={"Employee Management"}>
      {createSnackbarComponent}
      {deleteSnackbarComponent}
      {updateSnackbarComponent}
      <Grid item className={`w-100 ${isOpen ? "border-right-gray" : ""}`}>
        <div className="d-flex gap-3">
          <Grid item className="wrap-em">
            <Grid container className="bar-cd">
              <Grid item>
                <img src={People} alt="" width={24} height={24} />
              </Grid>
              <Grid item>
                <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                  Total Employees
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600} fontSize={26} color={"#303030"}>
                  {employees?.length || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item className="wrap-em">
            <Grid container className="bar-cd">
              <Grid item>
                <img src={ArticleShips} alt="" width={24} height={24} />
              </Grid>
              <Grid item>
                <Typography fontWeight={600} fontSize={16} color={"#303030"}>
                  Acticle Ships
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600} fontSize={26} color={"#303030"}>
                  {0}
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </div>
        <Grid container display="flex" flexDirection="row-reverse">
          {/* <Grid item className="wrap-sd1">
                  <button className="em-btn-1">Invite an Employee</button>
                </Grid> */}
          <div className="d-flex align-items-center">
            {roleLevel === 1 ? (
              <button
                className="role-manag-btn cursor-pointer me-2 my-3"
                onClick={() =>
                  navigate(`/settings`, {
                    state: { type: 4 },
                  })
                }
              >
                <img src={RoleManagement} alt="" />
                <span>Role Management</span>
              </button>
            ) : null}
            {userType?.includes(eNums?.AGENT) &&
            Permissions?.employeeManagement?.submodules?.employeeManagement?.enabled && Permissions?.employeeManagement?.submodules?.employeeManagement?.permission?.includes(
              eNums?.EDIT
            ) ? (
              <button
                className="em-btn-1 cursor-pointer me-2"
                onClick={handleAddNew}
              >
                <span className="em-btn-1-text">+ Add New Employee</span>
              </button>
            ) : null}
          </div>
        </Grid>

        {employeeLoading ? (
          <PlaceholderTable columns={10} />
        ) : employeesData && employeesData?.length ? (
          <>
            {" "}
            <p className="employee-list-title">Employee List</p>
            <Grid container item className="bg-cd-table">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                      <StyledTableCell>S No</StyledTableCell>
                      <StyledTableCell>Employee Name</StyledTableCell>
                      <StyledTableCell>Employee ID</StyledTableCell>
                      <StyledTableCell>Mobile No</StyledTableCell>
                      <StyledTableCell>Mail ID</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Job Type</StyledTableCell>
                      <StyledTableCell>Salary Type</StyledTableCell>
                      <StyledTableCell>Assigned Services</StyledTableCell>
                      {userType?.includes(eNums?.AGENT) &&
                      Permissions?.employeeManagement?.submodules?.employeeManagement?.enabled && Permissions?.employeeManagement?.submodules?.employeeManagement?.permission?.includes(
                        eNums?.EDIT
                      ) ? (
                        <StyledTableCell className="cursor-pointer">
                          Actions
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell className="cursor-pointer"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeesData?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell
                          className="text-highlight-hover cursor-pointer"
                          onClick={() => handleEdit(row, "view")}
                        >
                          {row?.fullname || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.employeeID || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.mobile || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>{row?.email || "N/A"}</StyledTableCell>

                        <StyledTableCell>
                          {row?.roleID?.name || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.jobType || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.salaryType || "N/A"}
                        </StyledTableCell>

                        <StyledTableCell>
                          <span className="em-blue fw-bold">
                            {row?.assignedServices || 0}
                          </span>
                        </StyledTableCell>
                        {userType?.includes(eNums?.AGENT) &&
                        Permissions?.employeeManagement?.submodules?.employeeManagement?.enabled && Permissions?.employeeManagement?.submodules?.employeeManagement?.permission?.includes(
                          eNums?.EDIT
                        ) ? (
                          <StyledTableCell className="d-flex gap-3 flex-wrap">
                            <img
                              className="cursor-pointer"
                              src={Edit}
                              size={20}
                              alt=""
                              onClick={() => handleEdit(row, "edit")}
                            />
                            <img
                              className="cursor-pointer"
                              src={Delete}
                              size={20}
                              alt=""
                              onClick={() => handleDeleteEmployee(row?._id)}
                            />
                          </StyledTableCell>
                        ) : null}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {employees?.length > 8 ? (
                <Grid>
                  <Grid item display="flex" justifyContent="center" margin={10}>
                    <Pagination
                      count={Math.ceil(employees?.length / itemsPerPage)}
                      page={currentPage}
                      onChange={(event, page) => setCurrentPage(page)}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid></Grid>
            </Grid>
          </>
        ) : (
          <EmptyState />
        )}
      </Grid>

      <Drawer
        anchor={"right"}
        open={isOpen ? true : false}
        onClose={handleClose}
        TransitionComponent={Slide}
        sx={{
          "& .MuiDrawer-paper": {
            width: "360px",
          },
        }}
      >
        <div>
          <CloseIcon
            fontSize="inherit"
            sx={{ color: "#2A2A2A", right: 0, position: "absolute" }}
            className="cursor-pointer my-2 me-2"
            onClick={handleClose}
          />
          <div item className="em-g-1-2 my-4 mx-3 p-3">
            {isOpen === "view" ? (
              <div className="ms-2">
                <Typography fontWeight={600} fontSize={16}>
                  Personal Details
                </Typography>
                {employeeDetailsData?.map((item, index) => (
                  <div className="d-flex flex-wrap my-3" key={index}>
                    <Typography
                      fontWeight={700}
                      fontSize={12}
                      color={"#404040"}
                      letterSpacing={0}
                    >
                      {item?.label} :
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={"#404040"}
                      className="ms-1"
                      letterSpacing={0}
                    >
                      {item?.value}
                    </Typography>
                  </div>
                ))}

                <Typography fontWeight={600} fontSize={16}>
                  Job Details
                </Typography>

                <div className="d-flex flex-wrap my-3">
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    color={"#404040"}
                    letterSpacing={0}
                  >
                    Role :
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={"#404040"}
                    className="ms-1"
                    letterSpacing={0}
                  >
                    {employee?.roleName || "N/A"}
                  </Typography>
                </div>

                <div className="d-flex flex-wrap my-3">
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    color={"#404040"}
                    letterSpacing={0}
                  >
                    Employment Type :
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={"#404040"}
                    className="ms-1"
                    letterSpacing={0}
                  >
                    {employee?.jobType || "N/A"}
                  </Typography>
                </div>

                <Typography fontWeight={600} fontSize={16}>
                  Compensation Details
                </Typography>

                <div className="d-flex flex-wrap my-3">
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    color={"#404040"}
                    letterSpacing={0}
                  >
                    Salary Type :
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={"#404040"}
                    className="ms-1"
                    letterSpacing={0}
                  >
                    {employee?.salaryType || "N/A"}
                  </Typography>
                </div>

                <div className="border-bottom-dashed my-4" />
                {userType?.includes(eNums?.AGENT) &&
                Permissions?.employeeManagement?.submodules?.employeeManagement?.enabled && Permissions?.employeeManagement?.submodules?.employeeManagement?.permission?.includes(
                  eNums?.EDIT
                ) ? (
                  <>
                    <Typography fontWeight={600} fontSize={16}>
                      Role Management
                    </Typography>

                    <div className="d-flex flex-wrap my-3">
                      <Typography
                        fontWeight={700}
                        fontSize={12}
                        color={"#404040"}
                        letterSpacing={0}
                        className="w-25"
                      >
                        From :
                      </Typography>
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={"#404040"}
                        className="ms-1"
                        letterSpacing={0}
                      >
                        {employee?.roleName || "N/A"}
                      </Typography>
                    </div>

                    <div className="d-flex align-items-center flex-wrap my-3">
                      <Typography
                        fontWeight={700}
                        fontSize={12}
                        color={"#404040"}
                        letterSpacing={0}
                        className="w-25"
                      >
                        To :
                      </Typography>
                      <Select
                        sx={{ height: "30px", border: "transparent" }}
                        className="inp-feild-em2"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={employee?.promotedTo || "null"}
                        placeholder="Select Role"
                        onChange={(e) =>
                          handleEmployeeDetail("promotedTo", e.target.value)
                        }
                      >
                        <MenuItem value={"null"}>Select Role</MenuItem>
                        {roles &&
                        roles?.filter((item) => item?.level > roleLevel)
                          ?.length ? (
                          roles
                            ?.filter(
                              (item) =>
                                item?.level > roleLevel &&
                                !item?._id?.includes(employee?.roleID)
                            )
                            ?.map((e, key) => {
                              return (
                                <MenuItem key={key} value={e?._id}>
                                  {e?.name}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem value={""}>No Roles</MenuItem>
                        )}
                      </Select>

                      <button
                        className="em-btn-1 cursor-pointer d-flex justify-content-center gap-2 my-3"
                        onClick={handleSaveEmployee}
                      >
                        <span className="em-btn-1-text">Update Role</span>
                        <img src={UpArrowWhite} alt="" />
                      </button>

                      <Button
                        variant="contained"
                        className="emp-cancel-btn w-100 cursor-pointer my-2"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              <form onSubmit={handleSaveEmployee}>
                <Grid item>
                  <Grid container className="ms-half">
                    <Typography fontWeight={600} fontSize={16}>
                      Personal Details
                    </Typography>
                  </Grid>

                  <Grid container className="inp-em">
                    <input
                      placeholder="Employee Name"
                      className="inp-feild-em"
                      value={employee?.fullname}
                      onChange={(e) =>
                        handleEmployeeDetail("fullname", e.target.value)
                      }
                    ></input>
                  </Grid>
                  <Grid container className="inp-em">
                    <input
                      placeholder="Employee Mail"
                      className="inp-feild-em"
                      value={employee?.email}
                      onChange={(e) =>
                        handleEmployeeDetail("email", e.target.value)
                      }
                    ></input>
                  </Grid>
                  <Grid container className="inp-em">
                    <input
                      placeholder="Employee Mobile"
                      className="inp-feild-em"
                      value={employee?.mobile}
                      onChange={(e) =>
                        handleEmployeeDetail("mobile", e.target.value)
                      }
                    ></input>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container className="ms-half">
                    <Typography fontWeight={600} fontSize={16}>
                      Job Details
                    </Typography>
                  </Grid>

                  <Grid container className="inp-em">
                    <Select
                      sx={{ height: "30px", border: "transparent" }}
                      className="inp-feild-em"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={employee?.roleID || "null"}
                      placeholder="Select State"
                      onChange={(e) =>
                        handleEmployeeDetail("roleID", e.target.value)
                      }
                    >
                      <MenuItem value={"null"}>Designation</MenuItem>
                      {roles &&
                      roles?.filter((item) => item?.level > roleLevel)
                        ?.length ? (
                        roles
                          ?.filter(
                            (item) =>
                              item?.level > roleLevel &&
                              !item?.name
                                ?.toLowerCase()
                                ?.includes(enums?.CLIENT?.toLowerCase())
                          )
                          ?.map((e, key) => {
                            return (
                              <MenuItem key={key} value={e?._id}>
                                {e?.name}
                              </MenuItem>
                            );
                          })
                      ) : (
                        <MenuItem value={""}>No Roles</MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <FormControl className="ms-2 my-1">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <Typography
                        fontWeight={700}
                        fontSize={12}
                        color={"#404040"}
                        letterSpacing={0}
                      >
                        Employment Type
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={employee?.jobType || "null"}
                      onChange={(e) =>
                        handleEmployeeDetail("jobType", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="Regular"
                        control={<Radio />}
                        label="Full-Time"
                      />
                      <FormControlLabel
                        value="Contract"
                        control={<Radio />}
                        label="Contract"
                      />
                      <FormControlLabel
                        value="Part-Time"
                        control={<Radio />}
                        label="Part-Time"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Grid container className="ms-half">
                    <Typography fontWeight={600} fontSize={16}>
                      Compensation Details
                    </Typography>
                  </Grid>

                  <div className="my-3 ms-2">
                    <Typography
                      fontWeight={700}
                      fontSize={12}
                      color={"#404040"}
                      letterSpacing={0}
                    >
                      Salary Type
                    </Typography>
                    <Select
                      sx={{ height: "30px", border: "transparent" }}
                      className="inp-feild-em"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={employee?.salaryType || "Monthly"}
                      placeholder="Select State"
                      onChange={(e) =>
                        handleEmployeeDetail("salaryType", e.target.value)
                      }
                    >
                      <MenuItem value={"Hourly"}>Hourly</MenuItem>
                      <MenuItem value={"Monthly"}>Monthly</MenuItem>
                      <MenuItem value={"Yearly"}>Yearly</MenuItem>
                    </Select>
                  </div>
                </Grid>
                <Grid
                  container
                  className="align-items-center gap-3 justify-content-between mt-3 px-2 flex-wrap"
                >
                  <Button
                    variant="contained"
                    className="emp-cancel-btn cursor-pointer ms-2"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="emp-save-btn cursor-pointer"
                    type="submit"
                  >
                    {!createEmployeeLoading || !updateEmployeeLoading
                      ? "Save Changes"
                      : "Saving..."}
                  </Button>
                </Grid>
              </form>
            )}
          </div>
        </div>
      </Drawer>

      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
    </NewLayout>
  ) : null;
}

export default EmployeeManagement;
