import React, {useState} from 'react';
import CustomizedSnackbar from "./Alert"
import axios from 'axios';

const useApi = (action, options) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(!!options?.initialLoad);
  const [error, setError] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });

    setTimeout(() => {
      setSnackbar({ open: false, message: '', severity: 'success' });
    }, 3000);
  };

  const fetchData = async (body = null, id) => {
    try {
      setLoading(true);
      if (!body) {
        body = {};
      }
      const res = await action(body, id);

      const isAxiosError = axios.isAxiosError(res);
      const isStockError = res instanceof Error;

      if (!isStockError && !isAxiosError) {
        if (!options?.noStore) {
          setData(res);
        }
        if (options?.success) {
          let message = typeof res?.data === "string" ? res?.data : res?.message ?? options?.successMessage ?? `${options?.actionName ? options?.actionName : "Action"} Successful! ${options?.additionalActionName ? options?.additionalActionName : ""}`;
          
          handleSnackbar(message, "success");
        }
        setError(false);
      } else if (isAxiosError && res?.response?.status && res?.response?.status >= 400) {
        if (res.response?.status === 404) {
          let message = typeof res.response?.data === "string" ? res.response?.data : (res.response?.data?.error || res?.response?.data?.msg || res?.response?.data?.message) ??  options?.errorMessage ?? `${options?.actionName ? options?.actionName : ""}!`;
          handleSnackbar(message || '404 Not Found', "error");
          throw new Error("404 Not Found!");
        } else {
          let message = typeof res.response?.data === "string" ? res.response?.data : (typeof res.response?.data?.error === "string" ? res?.response?.data?.error : res?.response?.data?.error?.message || res?.response?.data?.msg || res?.response?.data?.message) ??  options?.errorMessage ?? `${options?.actionName ? options?.actionName : "Action"} Failed!`;
          if (options?.error) {
            handleSnackbar(message, "error");
          }
          throw new Error(message);
        }
      } else {
        if (options?.error) {
          handleSnackbar(res?.message || "Action Failed", "error");
        }
        throw new Error(res.message);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const clearError = () => {
    setError(false);
  };

  const clearLoading = () => {
    setLoading(false);
  };

  const clearData = () => {
    setData(null);
  };

  const clearAll = () => {
    setError(false);
    setLoading(false);
    setData(null);
  };

  // JSX for snackbar component
  const snackbarComponent = snackbar?.open ? (
    <CustomizedSnackbar
      open={snackbar.open}
      message={snackbar.message}
      severity={snackbar.severity}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
    />
  ) : null;

  // Return all the necessary values and functions
  return {
    data,
    loading,
    error,
    action: fetchData,
    clearActions: { clearError, clearLoading, clearData, clearAll },
    snackbar,
    snackbarComponent, // Return snackbar component
  };
};

export default useApi;
