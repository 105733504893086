import FileNowLogo from "../../assets/images/File_Now_Logo.svg";

function CookiesPolicy() {
  return (
      <div className="policies-container">
          <div className="logo-container">
            <img alt="content" src={FileNowLogo} width="160" height="100" />
          </div>
        <p><strong>Cookie Policy</strong></p>
<strong>1. Introduction</strong>
<p>This Cookie Policy explains how Edfox Technologies Pvt. Ltd. ("Company," "We," "Our," or "Us") uses cookies and similar technologies on our website, <a href="http://www.vakilsearch.com">www.filenow.in</a> ("Website"). By using our Website, you consent to the use of cookies in accordance with this policy.</p>
<strong>2. What Are Cookies?</strong>
<p>Cookies are small text files placed on your device by websites you visit. They are used to store information that helps enhance your browsing experience and provide tailored services. Cookies can be classified into several types:</p>
<ul>
<li><strong>Session Cookies:</strong> Temporary cookies that expire when you close your browser.</li>
<li><strong>Persistent Cookies:</strong> Cookies that remain on your device for a set period or until deleted by you.</li>
<li><strong>First-Party Cookies:</strong> Cookies set by the website you are visiting.</li>
<li><strong>Third-Party Cookies:</strong> Cookies set by domains other than the one you are visiting.</li>
</ul>
<strong>3. How We Use Cookies</strong>
<p>We use cookies for the following purposes:</p>
<ul>
<li><strong>Essential Cookies:</strong> To ensure the functionality and performance of the Website, such as enabling you to log in and navigate securely.</li>
<li><strong>Performance Cookies:</strong> To analyze how users interact with our Website, which helps us improve its functionality and user experience. These cookies collect anonymous information about your visit.</li>
<li><strong>Functionality Cookies:</strong> To remember your preferences and settings, such as your language choice or login details, to provide a more personalized experience.</li>
<li><strong>Advertising Cookies:</strong> To deliver relevant advertisements to you and track the effectiveness of our marketing campaigns. These cookies may also be used by third-party advertisers.</li>
</ul>
<strong>4. Third-Party Cookies</strong>
<p>Our Website may contain cookies from third-party services that help us provide additional functionalities, such as social media integration and targeted advertising. We do not control these cookies and encourage you to review the privacy policies of these third parties to understand how they use cookies.</p>
<strong>5. Managing Cookies</strong>
<p>You can control and manage cookies through your browser settings. Most browsers allow you to refuse cookies or delete them from your device. Please note that disabling cookies may affect the functionality and performance of our Website.</p>
<ul>
<li><strong>For Chrome:</strong> Instructions</li>
<li><strong>For Firefox:</strong> <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Instructions</a></li>
<li><strong>For Safari:</strong> <a href="https://support.apple.com/en-us/HT201265">Instructions</a></li>
<li><strong>For Edge:</strong> <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy">Instructions</a></li>
</ul>
<strong>6. Changes to This Cookie Policy</strong>
<p>We may update this Cookie Policy from time to time to reflect changes in technology or legal requirements. Any updates will be posted on this page, and your continued use of the Website constitutes acceptance of any changes.</p>
<strong>7. Contact Us</strong>
<p>If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at:</p>
<p>Edfox Technologies Pvt. Ltd.<br /> G1,Sri Vaikunth Flats,</p>
<p>No 1, Vivekanandha Street,</p>
<p>Gandhi Nagar, Avadi, ,</p>
<p>&nbsp;CHENNAI, Tamil Nadu,</p>
<p>India - 600054.</p>
      </div>
  );
}

export default CookiesPolicy;
