import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabsComponent({
  tabTitles,
  tabContents,
  onTabChange,
  contentBoxStyles,
  tabsBoxStyles
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", ...tabsBoxStyles}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="custom tabs"
        >
          {tabTitles.map((title, index) => (
            <Tab
              key={index}
              label={title}
              {...a11yProps(index)}
              sx={{ textTransform: "none" }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ minHeight: "300px", ...contentBoxStyles }}>
        {" "}
        {/* Consistent height for all tab contents */}
        {tabContents.map((content, index) => (
          <TabPanel key={index} value={value} index={index}>
            {content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
