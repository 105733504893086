import React from "react";
import { Popover, Typography } from "@mui/material";
import Notify from "./Notify";
import TabsComponent from "./TabsComponent";
import { useNavigate } from "react-router-dom";

const data = [
  {
    name: "vish",
    text: "Anna Srzand has accepted the Aadhar file and left a comment in the Ticket",
    time: "2h",
    view: false,
    ad: false,
  },
  {
    name: "vish",
    text: "Aadhar uploaded recently",
    time: "2h",
    view: false,
    ad: false,
  },
  {
    name: "vish",
    text: "Aadhar uploaded recently",
    time: "2h",
    view: false,
    ad: false,
  },
  {
    name: "vish",
    text: "Aadhar uploaded recently",
    time: "2h",
    view: false,
    ad: false,
  },
  {
    name: "vish",
    text: "Aadhar uploaded recently",
    time: "2h",
    view: false,
    ad: false,
  },
];

const NotificationPopover = ({ anchorEl, open, onClose }) => {
  const navigate = useNavigate();
  const tabTitles = ["All", "Service", "General"];

  // Separate the notifications based on your logic for tabs
  const tabContents = [
    data.map((item, index) => (
      <Notify
        key={index}
        name={item.name}
        text={item.text}
        time={item.time}
        view={item.view}
        ad={item.ad}
      />
    )),
    data
      .map(
        (
          item,
          index
        ) => (
          <Notify
            key={index}
            name={item.name}
            text={item.text}
            time={item.time}
            view={item.view}
            ad={item.ad}
          />
        )
      ),
    data
      .map(
        (
          item,
          index
        ) => (
          <Notify
            key={index}
            name={item.name}
            text={item.text}
            time={item.time}
            view={item.view}
            ad={item.ad}
          />
        )
      ),
  ];

  const handleNotification = () => {
    navigate(`/notifications`);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "360px",
          height: "450px"
        },
      }}
    >
      <div className="px-4 mt-4 d-flex justify-content-between align-items-center w-100">
        <Typography fontSize={18} fontWeight={600} color={"#141B23"}>
          Notifications
        </Typography>
        <Typography fontSize={12} fontWeight={700} color={"#1E68F9"} onClick={handleNotification} className="cursor-pointer">
          View All
        </Typography>
      </div>
      <TabsComponent
        tabTitles={tabTitles}
        tabContents={tabContents}
        tabsBoxStyles={{position: "sticky", top: 0, background: '#fff'}}
      />
    </Popover>
  );
};

export default NotificationPopover;
