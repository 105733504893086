// SearchAndIcons.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "../assets/images/search-icon.svg";
import Help from "../assets/images/help-desk.svg";
import Notifications from "../assets/images/notifications-icon.svg";
import AppServices from "../assets/images/app-services.svg";
import NotificationPopover from "./NotificationPopover";
import { eNums } from "../constants";

const SearchAndIcons = ({ userType }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [icon, setIcon] = useState(SearchIcon);
  const userName = localStorage.getItem("user_name");

  const [anchorProfile, setAnchorProfile] = useState(null);
  const openProfile = Boolean(anchorProfile);

  const [anchorNoti, setAnchorNoti] = useState(null);
  const openNoti = Boolean(anchorNoti);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setIcon(e.target.value.length > 0 ? ClearIcon : SearchIcon);
  };

  const handleClearSearch = () => {
    if (icon === ClearIcon) {
      setSearchText("");
      setIcon(SearchIcon);
    }
  };

  const handleClose = () => {
    setAnchorProfile(null);
    setAnchorNoti(null);
  };

  const handleClickProfile = (e) => {
    setAnchorProfile(e?.currentTarget);
  };

  const handleClickNotification = (e) => {
    setAnchorNoti(e?.currentTarget);
  };

  const handleProfile = () => {
    navigate(`/settings`);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(
    `/${
          userType !== eNums?.CLIENT ? 'agent/' : ""
        }signIn`);
  };

  return (
    <div className="Settings-search">
      <div></div>
      {/* {userType?.includes(eNums?.CLIENT) ? null :
        <div className="search-center d-flex align-items-center">
          <input
            type="text"
            placeholder="Search here"
            className="search-input ms-3"
            value={searchText}
            onChange={handleInputChange}
          />
          {searchText ? (
            <IconButton onClick={handleClearSearch}>
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton className="me-1">
              <img src={SearchIcon} alt="" width={16} height={16} />
            </IconButton>
          )}
        </div>
      } */}
      <div className="d-flex">
        <div className="search-bar-right-vertical-line"></div>
        <IconButton className="h-100 my-auto">
          <img src={Help} alt="" width={20} height={20} />
        </IconButton>
        <IconButton onClick={handleClickNotification} className="h-100 my-auto">
          <img src={Notifications} alt="" width={16} height={16} />
        </IconButton>

        <NotificationPopover
          anchorEl={anchorNoti}
          open={openNoti}
          onClose={handleClose}
        />

        <IconButton className="h-100 my-auto">
          <img src={AppServices} alt="" width={16} height={16} />
        </IconButton>
        <IconButton
          id="profile-button"
          aria-controls={openProfile ? "profile-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openProfile ? "true" : undefined}
          onClick={handleClickProfile}
          className="h-100 my-auto"
        >
          {userName ? (
            <div className="user-profile-icon d-flex align-items-center justify-content-center cursor-pointer">
              {userName[0]}
            </div>
          ) : (
            <AccountCircleIcon />
          )}
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorProfile}
          open={openProfile}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "profile-button",
          }}
        >
          <MenuItem onClick={handleProfile}>My Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default SearchAndIcons;
