import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

function Header(props) {
  return (
    <>
      <Grid item container direction="column">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Skeleton variant="rectangular" width={40} height={40} />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ marginBottom: "-1vh" }}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>

        <p style={{ color: "#7790e9" }}>{props.desc}</p>
      </Grid>
    </>
  );
}

export default Header;
