import React from "react";
import { Grid } from "@mui/material";
import DummyProfile from "../assets/images/dummy_profile_placeholder.svg";
import ReviewsFeaturedLogo1 from "../assets/images/reviews-featured-logo-1.svg";
import ReviewsFeaturedLogo2 from "../assets/images/reviews-featured-logo-2.svg";
import ReviewsFeaturedLogo3 from "../assets/images/reviews-featured-logo-3.svg";
import ReviewsFeaturedLogo4 from "../assets/images/reviews-featured-logo-4.svg";
import ReviewsFeaturedLogo5 from "../assets/images/reviews-featured-logo-5.svg";

export default function Layout({ children }) {
  return (
    <Grid className="outter-c1">
      <Grid className="sidebar-c1">
        <Grid container className="wrap-c1">
          <div className="d-flex align-items-center flex-wrap gap-2">
            <img
              src={DummyProfile}
              alt=""
              width={46}
              height={46}
              className="mt-2"
            />
            <div className="d-flex flex-column">
              <span className="reviewer-name">Mahesh Rao</span>
              <span className="reviewer-designation">Product Manager</span>
            </div>
          </div>
          <Grid item container className="cen cen-c1">
            <span className="reviewer-content">
              File now has really boosted my confidence in my work because now I
              know that every time i do a legal filing is hassle free and easy.
            </span>
          </Grid>
          <Grid item container className="Lay-b1">
            <span className="featured-bound-text">Featured on:</span>
            <div className="d-flex flex-wrap align-items w-100 justify-content-evenly gap-2 mt-3">
              <div className="card-logo">
                <img src={ReviewsFeaturedLogo1} alt="" />
              </div>
              <div className="card-logo">
                <img src={ReviewsFeaturedLogo2} alt="" />
              </div>
            </div>
            <div className="d-flex flex-wrap w-100 align-items justify-content-evenly mt-3 gap-2">
              <div className="card-logo">
                <img src={ReviewsFeaturedLogo3} alt="" />
              </div>
              <div className="card-logo">
                <img src={ReviewsFeaturedLogo4} alt="" />
              </div>
              <div className="card-logo">
                <img src={ReviewsFeaturedLogo5} alt="" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div className="d-flex flex-column w-100 overflow-auto">
        <div className="waterMarktext">@ Atlas . Privacy & terms</div>
        <Grid container className="content-c1 my-auto">
          {children}
        </Grid>
      </div>
    </Grid>
  );
}
