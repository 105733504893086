import React, { useState, useEffect } from "react";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  toggleWidget,
  isWidgetOpened,
  deleteMessages,
  renderCustomComponent,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import { useApi, ApiService } from "../Utilities";
import moment from "moment";

function ChatComponent({ serviceDetails, ticketDetails, messages, error, Permission }) {
  const [chatWindowOpen, setChatWindowOpen] = useState(true);
  const [inputText, setInputText] = useState("");
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const user_name = localStorage.getItem("user_name");

  const {
    action: createUserServiceChat,
    snackbarComponent: createUserServiceChatComponent,
  } = useApi(ApiService.createUserServiceChat, {
    error: true,
    noStore: true,
  });

  const handleToggle = () => {
    setChatWindowOpen((prev) => !prev);
  };

  useEffect(() => {
    // Automatically open the widget when component mounts
    if (!isWidgetOpened()) {
      toggleWidget();
    }
    setInputText("");
  }, []);

  useEffect(() => {
    const inputElement = document.querySelector(".rcw-new-message");
    if (inputElement) {
      const observer = new MutationObserver(() => {
        setInputText(inputElement.textContent || "");
      });
      observer.observe(inputElement, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, [chatWindowOpen]);

  const formatTimestamp = (timestamp) => {
    const date = moment(timestamp);
    const now = moment();
    const isSameDay = date.isSame(now, "day");
    const isSameWeek = date.isSame(now, "week");

    if (isSameDay) {
      return date.format("h:mm a");
    } else if (isSameWeek) {
      return `${date.format("ddd")}, ${date.format("h:mm a")}`;
    } else {
      return `${date.format("DD/MM/YY")}, ${date.format("h:mm a")}`;
    }
  };

  const CustomTimeStampFragment = ({ timestamp, createdBy }) => (
    <div className={createdBy === userId ? "rcw-client" : ""}>
      <span className="user-timestamp">{formatTimestamp(timestamp)}</span>
    </div>
  );

  const CustomUserStampFragment = ({ createdByUserName, createdBy }) => (
    <div className={createdBy === userId ? "rcw-client" : ""}>
      <span className="user-name">{createdByUserName}</span>
    </div>
  );

  useEffect(() => {
    deleteMessages();
    if (messages?.length) {
      messages.forEach((message) => {
        if (message.createdBy === userId) {
          addUserMessage(message.message);
          renderCustomComponent(CustomTimeStampFragment, {
            timestamp: message?.timestamp,
            createdBy: message?.createdBy,
          });
        } else {
          if (message?.createdByUserName) {
            renderCustomComponent(CustomUserStampFragment, {
              createdBy: message?.createdBy,
              createdByUserName: message?.createdByUserName,
            });
          }
          addResponseMessage(message.message || "");
          renderCustomComponent(CustomTimeStampFragment, {
            timestamp: message?.timestamp,
            createdBy: message?.createdBy,
          });
        }
      });
    }
  }, [messages]);

  useEffect(() => {
    if (error) {
      deleteMessages();
    }
  }, [error]);

  const handleNewUserMessage = async (newMessage) => {
    try {
      const obj = {
        userServiceID: serviceDetails?._id,
        ticketID: ticketDetails?.ticketID,
        message: newMessage,
        timestamp: new Date().toISOString(),
        createdBy: userId,
        createdByUserName: user_name || "",
        createdByType: "users",
      };
      await createUserServiceChat(obj);
    } catch (error) {
      // Handle error silently or add logging if needed
    }
  };

  return (
    <div
      className={`chat-container ${inputText ? "highlight-send-button" : ""} ${Permission ? '' : 'hide-input'}`}
    >
      {createUserServiceChatComponent}
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="Chat with Us"
        subtitle="We are here to help"
        emojis={Permission ? true : false}
        handleToggle={handleToggle}
        showTimeStamp={false}
        senderPlaceHolder="Start Typing..."
      />
    </div>
  );
}

export default ChatComponent;
