import axiosInstance from "./axiosInstance";

export const OnMyServiceLoad = async () => {
  axiosInstance.get("/myservices/647926a96d35fa252c7de13b");
  try {
    const response = await axiosInstance.get(
      "/myservices/647926a96d35fa252c7de13b"
    );
    const res = response.map((item) => item);
    return res;
  } catch (error) {}
};

// module.export.OnMyServiceLoad = OnMyServiceLoad()

// const OnLegalDocLoad = async() => {
//     axiosInstance.get('/myservices/647926a96d35fa252c7de13b')
//     try {
//         const response = await axiosInstance.get('/myservices/647926a96d35fa252c7de13b');
//         const res = response.map((item) => item);
//         return(res)
//     } catch (error) {
//       console.log(error);
//     }
// }

// module.export.OnLegalDocLoad = OnLegalDocLoad()
// API : user/getAllUsers

export async function createUser(body) {
  try {
    return await axiosInstance.post(`auth/register`, body);
  } catch (error) {
    return error;
  }
}

export async function updateUser(body, id) {
  try {
    return await axiosInstance.put(`user/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function deleteUser(id) {
  try {
    return await axiosInstance.delete(`user/${id}`);
  } catch (error) {
    return error;
  }
}

export async function getUsersorOrders(body) {
  try {
    return await axiosInstance.post(`user/get`, body);
  } catch (error) {
    return error;
  }
}

export async function getEmployees(body) {
  try {
    return await axiosInstance.post(`user/get_employees`, body);
  } catch (error) {
    return error;
  }
}

export async function getUserById(id, body) {
  try {
    return await axiosInstance.get(`user/${id}`, { params: body });
  } catch (error) {
    return error;
  }
}

export async function changePassword(body) {
  try {
    return await axiosInstance.post(`user/reset_password`, body);
  } catch (error) {
    return error;
  }
}

export async function requestOTP(body) {
  try {
    const obj= {...body, requestedAccess: 'Edit'};
    const res = await axiosInstance.post(`user/request_otp`, obj);
    return res;
  } catch (error) {
    return error;
  }
}

export async function verifyUserMobileOrEmail(body) {
  try {
    const obj= {...body, requestedAccess: 'Edit'};
    const res = await axiosInstance.post(`user/verify_email_mobile`, obj);
    if (body?.isMobile) {
      localStorage.setItem("mobileVerified", true);
    }
    return res;
  } catch (error) {
    return error;
  }
}

export async function validateOTP(body) {
  try {
    const obj= {...body, requestedAccess: 'Edit'};
    return await axiosInstance.post(`user/validate_otp`, obj);
  } catch (error) {
    return error;
  }
}

export async function resetPassword(body) {
  try {
    const obj= {...body, requestedAccess: 'Edit'};
    return await axiosInstance.post(`user/update_new_password`, obj);
  } catch (error) {
    return error;
  }
}

export async function createCategory(body) {
  try {
    return await axiosInstance.post(`categories/`, body);
  } catch (error) {
    return error;
  }
}

export async function updateCategory(body, id) {
  try {
    return await axiosInstance.put(`categories/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function getAllCategories(body) {
  try {
    return await axiosInstance.get(`categories/`, body);
  } catch (error) {
    return error;
  }
}

export async function deleteCategory(id) {
  try {
    const obj = {requestedAccess: 'Edit'}
    return await axiosInstance.delete(`categories/${id}`, obj);
  } catch (error) {
    return error;
  }
}

export async function createService(body) {
  try {
    return await axiosInstance.post(`services/`, body);
  } catch (error) {
    return error;
  }
}

export async function updateService(body, id) {
  try {
    return await axiosInstance.put(`services/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function updateMyService(body, id) {
  try {
    return await axiosInstance.put(`myservices/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function deleteService(id, body) {
  try {
    const obj = {...body, requestedAccess: 'Edit'}
    return await axiosInstance.delete(`services/${id}`, obj);
  } catch (error) {
    return error;
  }
}

export async function getAllServices(body) {
  try {
    return await axiosInstance.post(`services/get`, body);
  } catch (error) {
    return error;
  }
}

export async function getUsersByServiceId(id) {
  try {
    return await axiosInstance.get(`services/getUsers/${id}`);
  } catch (error) {
    return error;
  }
}

export async function getMyServices(body) {
  try {
    return await axiosInstance.post(`myservices/getAllServiceRegistrations`, body);
  } catch (error) {
    return error;
  }
}

export async function createRole(body) {
  try {
    return await axiosInstance.post(`roles/`, body);
  } catch (error) {
    return error;
  }
}

export async function updateRole(body, id) {
  try {
    return await axiosInstance.put(`roles/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function deleteRole(id) {
  try {
    const obj = {requestedAccess: 'Edit'}
    return await axiosInstance.delete(`roles/${id}`, obj);
  } catch (error) {
    return error;
  }
}

export async function getAllRoles() {
  try {
    return await axiosInstance.get(`roles/`);
  } catch (error) {
    return error;
  }
}

export async function createOrder(data) {
  try {
    return await axiosInstance.post(`orders/`, data);
  } catch (error) {
    return error;
  }
}

export async function verifyOrder(data) {
  try {
    return await axiosInstance.post(`orders/verify`, data);
  } catch (error) {
    return error;
  }
}

export async function refundOrder(data) {
  try {
    return await axiosInstance.post(`orders/refund`, data);
  } catch (error) {
    return error;
  }
}

export async function getOrders(body) {
  try {
    return await axiosInstance.get("orders/", {
      params: {
        userID: body?.userId,
        serviceName: body?.serviceName,
      },
    });
  } catch (error) {
    return error;
  }
}

export async function createEvent(body) {
  try {
    return await axiosInstance.post(`events/`, body);
  } catch (error) {
    return error;
  }
}

export async function updateEvent(body, id) {
  try {
    return await axiosInstance.put(`events/${id}`, body);
  } catch (error) {
    return error;
  }
}

export async function deleteEvent(id) {
  try {
    const obj = {requestedAccess: 'Edit'}
    return await axiosInstance.delete(`events/${id}`,obj);
  } catch (error) {
    return error;
  }
}

export async function getAllEvents(body) {
  try {
    return await axiosInstance.post(`events/get`, body);
  } catch (error) {
    return error;
  }
}

export async function createServiceTicket(body) {
  try {
    return await axiosInstance.post(`myservices/create-ticket`, body);
  } catch (error) {
    return error;
  }
}

export async function createUserServiceChat(body) {
  try {
    return await axiosInstance.post(`myservices/chat-create`, body);
  } catch (error) {
    return error;
  }
}

export async function getUserServiceTickets(body) {
  try {
    return await axiosInstance.post(`myservices/get-tickets`, body);
  } catch (error) {
    return error;
  }
}

export async function updateServiceTicketStatus(body) {
  try {
    return await axiosInstance.put(`myservices/update-ticket-status`, body);
  } catch (error) {
    return error;
  }
}

export async function singleFileUpload(body) {
  try {
    return await axiosInstance.post(`file/upload-single`, body);
  } catch (error) {
    return error;
  }
}

export async function fileDownload(body) {
  try {
    return await axiosInstance.post(`file/download`, body);
  } catch (error) {
    return error;
  }
}

export async function fileDelete(fileName) {
  try {
    return await axiosInstance.delete(`file/${fileName}`);
  } catch (error) {
    return error;
  }
}

export async function getDashboardData() {
  try {
    return await axiosInstance.get(`dashboard/`);
  } catch (error) {
    return error;
  }
}

export async function getRolePermissionsById() {
  try {
    return await axiosInstance.get(`roles/getPermissions/`);
  } catch (error) {
    return error;
  }
}
