import React, { useEffect, useState, useRef } from "react";
import NewLayout from "../../Components/SidebarLayout";
import ServicesCard from "../../Components/ServicesCard";
import {useApi, EmptyState, ApiService} from "../../Utilities";
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Placeholder } from "react-bootstrap";

function Services() {
  const [allServices, setAllServices] = useState([]);
  const [type, setType] = useState("");
  const [serviceName, setServiceName] = useState("");

  const { action: getAllCategories } = useApi(ApiService.getAllCategories, {
    success: true,
    error: true,
    actionName: "Categories fetching",
  });
  const {
    action: getAllServices,
    data: services,
    snackbarComponent: getAllServicesSnackbarComponent,
    loading: serviceLoading,
    error: servicesError,
  } = useApi(ApiService.getAllServices, {
    success: true,
    error: true,
    actionName: "Services fetching",
  });

  const [activeKey, setActiveKey] = useState(""); // State for active category key
  const categoryRefs = useRef({}); // Ref to store category elements
  
  useEffect(() => {
    getAllCategories();
    getAllServices({ serviceStatus: "active", isDeleted: false });
  }, []);

  useEffect(() => {
    if (!serviceLoading && !servicesError && services?.length > 0) {
      const groupedServices = {};

      services?.forEach((service) => {
        const categoryName = service.categoryID
          ? service?.categoryID?.categoryName?.toString()
          : null;
        if (categoryName) {
          if (!groupedServices[categoryName]) {
            groupedServices[categoryName] = [];
          }
          groupedServices[categoryName].push(service);
        }
      });

      setAllServices([groupedServices]);
    } else {
      setAllServices([]);
    }
  }, [services, serviceLoading, servicesError]);

  // Create refs for each category dynamically
  const createRefsForCategories = () => {
    const groupedServices = allServices[0];
    if (groupedServices) {
      Object.keys(groupedServices).forEach((category) => {
        if (!categoryRefs.current[category]) {
          categoryRefs.current[category] = React.createRef();
        }
      });
    }
  };

  // Call this function to create refs
  useEffect(() => {
    createRefsForCategories();
  }, [allServices]);

  const scrollToCategory = (key) => {
    if (categoryRefs.current[key]) {
      categoryRefs.current[key].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      setActiveKey(key);
    }
  };

  const handleTypeChange = (type) => {
    setType(type);
    let obj = { serviceStatus: "active", isDeleted: false };
    if (type) {
      obj.serviceType = type;
      if (serviceName) {
        obj.serviceName = serviceName;
      }
      getAllServices(obj);
    } else if (serviceName) {
      obj.serviceName = serviceName;
      getAllServices(obj);
    } else {
      getAllServices(obj);
    }
  };

  const handleSearch = (value) => {
    setServiceName(value);

    let obj = { serviceStatus: "active", isDeleted: false };
    if (value?.length > 2) {
      obj.serviceName = value;
      if (type) {
        obj.serviceType = type;
      }
      getAllServices(obj);
    } else if (!value) {
      if (type) {
        obj.serviceType = type;
        getAllServices(obj);
      } else {
        getAllServices(obj);
      }
    }
  };

  const paths = [{ label: `All Services`, navigation: "all-services" }];

  return (
    <>
      <NewLayout paths={paths} pageHeading={"All Services"}>
        {getAllServicesSnackbarComponent}

        <div className="w-100 mt-3 d-flex align-items-center gap-2 flex-wrap">
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "" ? "sm-w-b-b" : ""
              }`}
              onClick={() => handleTypeChange("")}
            >
              <Typography variant="subtitle">All</Typography>
            </Grid>
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "Individual" ? "sm-w-b-b" : ""
              }`}
              onClick={() => {
                handleTypeChange("Individual");
              }}
            >
              <Typography variant="subtitle">Individual</Typography>
            </Grid>
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "Business" ? "sm-w-b-b" : ""
              }`}
              onClick={() => {
                handleTypeChange("Business");
              }}
            >
              <Typography variant="subtitle">Business</Typography>
            </Grid>

          <div className="d-flex ms-auto gap-2">
            <OutlinedInput
              id="search"
              type="text"
              placeholder="search"
              className="sm-search w-10"
              onChange={(e) => handleSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>

        <div className="w-75 bg-primary setup-g1 my-4 rounded" />
        
        {/* Render category keys separately */}
        <div className="sm-category-keys d-flex flex-wrap">
          {allServices.length > 0 && Object.keys(allServices[0]).map((key) => (
            <div key={key} className={`sm-category-key ${activeKey === key ? "active" : ""}`} onClick={() => scrollToCategory(key)}>
              <Typography variant="body1">{key}</Typography>
            </div>
          ))}
        </div>
        {serviceLoading ? (
          [...Array(8)]?.map((a, i) => (
            <Placeholder
              className="servicesWrap placeholder-background rounded"
              animation="wave"
              style={{ height: "220px" }}
            />
          ))
        ) :
        <div>
          {allServices.length > 0 ? (
            allServices.map((service, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.keys(service)?.map((key) => (
                  <div key={key} ref={(el) => (categoryRefs.current[key] = el)} className="my-2 d-flex flex-column gap-2"> {/* Attach ref to each category */}
                    <Typography
                      fontWeight={"600"}
                      fontSize={"1em"}
                      color={"#090E24"}
                    >
                      {key}
                    </Typography>
                    <div className="d-flex flex-wrap">
                      {service[key]?.map((value, i) => (
                        <ServicesCard key={i} data={value} route_path={'all-services'} route_path_name={'All Services'}/> 
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <EmptyState/>
          )}
        </div>
        }

      </NewLayout>
    </>
  );
}

export default Services;
