import React, { useState } from "react";
import NewLayout from "../../Components/SidebarLayout";
import TabsComponent from "../../Components/TabsComponent";
import DashboardAdminSetup from "./DashboardAdminSetup";
import DashboardAdmin from "./DashboardAdmin";
import { useRolePermissions } from "../../Utilities";
import { eNums } from "../../constants";

const AdminDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabTitles = ["Setup", "Dashboard"];
  const tabContents = [<DashboardAdminSetup />, <DashboardAdmin />];

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const paths = [
    { label: `Dashboard ${selectedTab === 0 ? 'Setup' : ''}`, navigation: "dashboard" },
  ];

  const Permissions = useRolePermissions();
  
  return (
    <>
      <NewLayout
        pageHeading={`Dashboard ${selectedTab === 0 && Permissions?.dashboard?.submodules?.dashboardSetup?.enabled && (Permissions?.dashboard?.submodules?.dashboardSetup?.permission?.includes(eNums?.EDIT) || Permissions?.dashboard?.submodules?.dashboardSetup?.permission?.includes(eNums?.VIEW)) ? '- Setup' : ''}`}
        paths={paths}
      >
        {Permissions?.dashboard?.submodules?.dashboardSetup?.enabled ? 
        <TabsComponent
          tabTitles={tabTitles}
          tabContents={tabContents}
          onTabChange={handleTabChange}
          contentBoxStyles={{ pt: 8, overflow: 'auto', height: '85%' }}
          tabsBoxStyles={{position: "fixed", zIndex: 1, width: '100%', background: '#fff'}}
        />
        : <DashboardAdmin/>}
        
      </NewLayout>
    </>
  );
};

export default AdminDashboard;
