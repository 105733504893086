import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dashboard from "../assets/images/sidebar/dashboard.svg";
import {
  useApi,
  ApiService,
  uploadSingleBlobFile,
  getBlobFile,
  CustomizedSnackbar,
} from "../Utilities";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { emailRegExp } from "../Utilities/regex";

function SettingsProfile({ data }) {
  const [orgData, setOrgData] = useState(null);
  const [userData, setUserData] = useState(null);
  const userID = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const languages = [
    "English",
    "Hindi",
    "Telugu",
    "Tamil",
    "Malayalam",
    "Kannada",
  ];

  const [cities, setCities] = useState([]);
  const apiKey = "bUlNN3FSSWNWSExQZHFjYmk3VmlMVHZqVlR2T0xsODJlQ1JPSFhyeg=="; // Replace with your actual API key

  const indianStates = [
    {
      id: 4023,
      name: "Andaman and Nicobar Islands",
      iso2: "AN",
    },
    {
      id: 4017,
      name: "Andhra Pradesh",
      iso2: "AP",
    },
    {
      id: 4024,
      name: "Arunachal Pradesh",
      iso2: "AR",
    },
    {
      id: 4027,
      name: "Assam",
      iso2: "AS",
    },
    {
      id: 4037,
      name: "Bihar",
      iso2: "BR",
    },
    {
      id: 4031,
      name: "Chandigarh",
      iso2: "CH",
    },
    {
      id: 4040,
      name: "Chhattisgarh",
      iso2: "CT",
    },
    {
      id: 4033,
      name: "Dadra and Nagar Haveli and Daman and Diu",
      iso2: "DH",
    },
    {
      id: 4021,
      name: "Delhi",
      iso2: "DL",
    },
    {
      id: 4030,
      name: "Gujarat",
      iso2: "GJ",
    },
    {
      id: 4009,
      name: "Goa",
      iso2: "GA",
    },
    {
      id: 4007,
      name: "Haryana",
      iso2: "HR",
    },
    {
      id: 4020,
      name: "Himachal Pradesh",
      iso2: "HP",
    },
    {
      id: 4025,
      name: "Jharkhand",
      iso2: "JH",
    },
    {
      id: 4029,
      name: "Jammu and Kashmir",
      iso2: "JK",
    },
    {
      id: 4026,
      name: "Karnataka",
      iso2: "KA",
    },
    {
      id: 4028,
      name: "Kerala",
      iso2: "KL",
    },
    {
      id: 4019,
      name: "Lakshadweep",
      iso2: "LD",
    },
    {
      id: 4852,
      name: "Ladakh",
      iso2: "LA",
    },
    {
      id: 4039,
      name: "Madhya Pradesh",
      iso2: "MP",
    },
    {
      id: 4008,
      name: "Maharashtra",
      iso2: "MH",
    },
    {
      id: 4010,
      name: "Manipur",
      iso2: "MN",
    },
    {
      id: 4006,
      name: "Meghalaya",
      iso2: "ML",
    },
    {
      id: 4036,
      name: "Mizoram",
      iso2: "MZ",
    },
    {
      id: 4018,
      name: "Nagaland",
      iso2: "NL",
    },
    {
      id: 4013,
      name: "Odisha",
      iso2: "OR",
    },
    {
      id: 4015,
      name: "Punjab",
      iso2: "PB",
    },
    {
      id: 4011,
      name: "Puducherry",
      iso2: "PY",
    },
    {
      id: 4014,
      name: "Rajasthan",
      iso2: "RJ",
    },
    {
      id: 4034,
      name: "Sikkim",
      iso2: "SK",
    },
    {
      id: 4035,
      name: "Tamil Nadu",
      iso2: "TN",
    },
    {
      id: 4012,
      name: "Telangana",
      iso2: "TG",
    },
    {
      id: 4038,
      name: "Tripura",
      iso2: "TR",
    },
    {
      id: 4016,
      name: "Uttarakhand",
      iso2: "UT",
    },
    {
      id: 4022,
      name: "Uttar Pradesh",
      iso2: "UP",
    },
    {
      id: 4853,
      name: "West Bengal",
      iso2: "WB",
    },
  ];

  const {
    action: updateUser,
    loading: userUpdateLoading,
    snackbarComponent,
  } = useApi(ApiService.updateUser, {
    noStore: true,
    success: true,
    error: true,
    actionName: "Updating User",
  });
  const handleOrgDataChange = (type, value) => {
    let obj = { ...orgData, [type]: value };
    setOrgData(obj);
  };

  const handleUserDataChange = (type, value) => {
    let obj = { ...userData, [type]: value };
    setUserData(obj);
  };

  const disposableEmailProviders = [
    "mailinator.com",
    "tempmail.com",
    "10minutemail.com",
    "guerrillamail.com",
    "throwawaymail.com",
    "maildrop.cc",
    "yopmail.com",
    "getnada.com",
    "dispostable.com",
    "mytemp.email",
    "mohmal.com",
    "trashmail.com",
    "fakeinbox.com",
    "mailnesia.com",
    "rykone.com",
  ];

  const isTemporaryEmail = (email) => {
    if (!email) return false;
    const domain = email.split("@")[1]?.toLowerCase();
    return disposableEmailProviders.includes(domain);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegExp.test(userData?.email || data?.email)) {
      handleSnackbar("Invalid Email..!", "error");
      return;
    }

    if (isTemporaryEmail(userData?.email || data?.email)) {
      handleSnackbar("Temporary emails are not allowed!", "error");
      return;
    }
    let obj = {
      ...data,
      userType,
      fullname: userData?.fullname,
      email: userData?.email,
      mobile: userData?.mobile,
      language: userData?.language,
      organization: {
        name: orgData?.name,
        address: orgData?.address,
        city: orgData?.city,
        pinCode: orgData?.pinCode,
        state: orgData?.state,
        location: orgData?.location,
        mobile: orgData?.mobile,
        logo: orgData?.logo,
      },
    };
    await updateUser(obj, userID);
    localStorage.setItem("user_name", userData?.fullname || data?.fullname);
  };

  const paths = [
    { label: "Home", navigation: "dashboard" },
    { label: "Settings", navigation: "settings" },
    { label: "Profile" },
  ];

  const handleFileUpload = async (e) => {
    setImageLoading(true);
    const { previewUrl, res } = await uploadSingleBlobFile(e);
    if (previewUrl) {
      setPreviewUrl(previewUrl);
      let obj = { ...orgData, logo: res?.fileName };
      setOrgData(obj);
    }
    setImageLoading(false);
  };

  useEffect(() => {
    (async function () {
      setOrgData(data?.organization);
      setUserData(data)
      if (data?.organization?.state) {
        const selectedState = indianStates?.find(
          (state) => state.name === data?.organization?.state
        );
        fetch(
          `https://api.countrystatecity.in/v1/countries/IN/states/${selectedState?.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": apiKey },
          }
        )
          .then((response) => response.json())
          .then((data) => setCities(data))
          .catch((error) => console.error("Error fetching cities:", error));
      }
      if (data?.organization?.logo) {
        setImageLoading(true);
        const imgUrl = await getBlobFile(data?.organization?.logo);
        setPreviewUrl(imgUrl);
        setImageLoading(false);
      } else {
        setPreviewUrl("");
      }
    })();
  }, [data]);

  return (
    <>
      {snackbarComponent}

      <form onSubmit={handleSubmit}>
        <Grid
          item
          container
          className="d-flex sp-wrap1 justify-content-between align-items-center position-sticky top-0 bg-white"
        >
          <Grid className="d-flex flex-column gap-1 pt-2">
            {paths?.length ? (
              <Grid item className="d-flex align-items-center">
                {paths?.map((item, index) => (
                  <div key={index}>
                    <span
                      className="main-path-text"
                      onClick={() => {
                        if (item?.navigation) {
                          navigate(`/${item?.navigation}`);
                        }
                      }}
                    >
                      {item?.label}
                    </span>
                    <span className="mx-1">
                      {index !== paths?.length - 1 && "/"}
                    </span>
                  </div>
                ))}
              </Grid>
            ) : null}
            <Grid item>
              <h5 className="sp-typo d-flex align-items-center gap-2">
                <img src={Dashboard} alt="" width={20} height={20} />
                <span className="settings-subtitle">Profile</span>
              </h5>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            className="sp-btn1 cursor-pointer"
          >
            Save changes
          </Button>
        </Grid>

        <Grid item className="sp-wr11">
          <Grid item container className="sp-wr1">
            <Grid
              display="flex"
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                display="flex"
                flexDirection="column"
                sx={{ width: "100%", height: "100%" }}
              >
                <Grid display="flex" flexDirection="column">
                  <Grid className="mt-3">
                    <Grid item style={{ width: "100%" }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Contact
                      </Typography>
                    </Grid>

                    <div className="w-100 d-flex flex-wrap">
                      <div className="sp-primary-contant-cont">
                        <div>Name*</div>
                        <div className="w-50">
                          <input
                            type="text"
                            placeholder="Enter name"
                            className="sp-inp"
                            defaultValue={userData?.fullname}
                            onChange={(e) =>
                              handleUserDataChange("fullname", e.target.value)
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="sp-primary-contant-cont">
                        <div>Mail*</div>
                        <div className="w-50">
                          <input
                            type="email"
                            placeholder="Email"
                            className="sp-inp"
                            defaultValue={userData?.email}
                            onChange={(e) =>
                              handleUserDataChange("email", e.target.value)
                            }
                          ></input>
                        </div>
                      </div>
                      {/* <Grid item display="flex" flexDirection="row">
                          <Grid
                            item
                            style={{
                              width: "40%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Time Zone
                          </Grid>
                          <Grid item style={{ width: "50%" }}>
                            <input
                              type="text"
                              placeholder="Enter Timezone"
                              className="sp-inp"
                              defaultValue={data?.timezone}
                              onChange={(e) =>
                                handleUserDataChange("timezone", e.target.value)
                              }
                            ></input>
                          </Grid>
                        </Grid> */}

                      <div className="sp-primary-contant-cont">
                        <div>Mobile No*</div>
                        <div className="w-50">
                          <input
                            type="number"
                            placeholder="Mobile Number"
                            className="sp-inp"
                            value={userData?.mobile}
                            onChange={(e) => {
                              if (e.target.value?.length > 10) {
                                handleSnackbar("Invalid Mobile No..!", "error");
                                return;
                              } else {
                                handleUserDataChange("mobile", e.target.value);
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="sp-primary-contant-cont">
                        <div>Language</div>
                        <div className="w-50">
                          <select
                            className="sp-inp"
                            value={userData?.language || ''}
                            onChange={(e) =>
                              handleUserDataChange("language", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Choose Language
                            </option>
                            {languages?.map((lang) => (
                              <option key={lang} value={lang}>
                                {lang}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <Grid item display="flex" flexDirection="row">
                          <Grid
                            item
                            style={{
                              width: "40%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Date Format
                          </Grid>
                          <Grid item style={{ width: "50%" }}>
                            <input
                              type="date"
                              placeholder="DD/MM/YYYY"
                              className="sp-inp"
                              defaultValue={data?.dateFormat}
                              onChange={(e) =>
                                handleUserDataChange(
                                  "dateFormat",
                                  e.target.value
                                )
                              }
                            ></input>
                          </Grid>
                        </Grid> */}
                    </div>
                  </Grid>
                  <div className="mb-3 mt-3 border-bottom-gray" />
                  <Grid display="flex" gap={"1rem"}>
                    <div className="d-flex w-60 flex-column">
                      <Grid item style={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          Organization Details
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Grid
                          item
                          style={{ width: "40%", paddingBlock: "1vh" }}
                        >
                          Organization Name*
                        </Grid>
                        <Grid item style={{ width: "50%" }}>
                          <input
                            type="text"
                            placeholder="Enter Organization Name"
                            className="sp-inp"
                            defaultValue={orgData?.name}
                            onChange={(e) => {
                              if (e.target.value?.length > 60) {
                                handleSnackbar(
                                  "Name is limited to 60 characters..!",
                                  "error"
                                );
                                return;
                              } else {
                                handleOrgDataChange("name", e.target.value);
                              }
                            }}
                          ></input>
                        </Grid>

                        <Grid
                          item
                          style={{ width: "40%", paddingBlock: "1vh" }}
                        >
                          Organization Location*
                        </Grid>
                        <Grid item style={{ width: "50%" }}>
                          <input
                            type="text"
                            placeholder="Enter Location"
                            className="sp-inp"
                            defaultValue={orgData?.location}
                            onChange={(e) =>
                              handleOrgDataChange("location", e.target.value)
                            }
                          ></input>
                        </Grid>
                      </Grid>

                      <Grid item display="flex" flexDirection="row">
                        <Grid item style={{ width: "40%" }} marginTop={".5rem"}>
                          Organization Address*
                        </Grid>
                        <Grid
                          item
                          display="flex"
                          flexDirection="column"
                          style={{ width: "50%" }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            {" "}
                            <input
                              type="text"
                              placeholder="Street Address"
                              className="sp-inp"
                              defaultValue={orgData?.address}
                              onChange={(e) =>
                                handleOrgDataChange("address", e.target.value)
                              }
                            ></input>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            flexDirection="row"
                            gap={"1rem"}
                          >
                            <Grid item style={{ width: "50%" }}>
                              {" "}
                              <input
                                type="number"
                                placeholder="Phone"
                                className="sp-inp"
                                value={
                                  orgData?.mobile
                                }
                                onChange={(e) => {
                                  if (e.target.value?.length > 10) {
                                    handleSnackbar(
                                      "Invalid Mobile No..!",
                                      "error"
                                    );
                                    return;
                                  } else {
                                    handleOrgDataChange(
                                      "mobile",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></input>
                            </Grid>
                            <Grid item style={{ width: "50%" }}>
                              {" "}
                              <select
                                className="sp-inp"
                                value={
                                  orgData?.state || ""
                                }
                                onChange={(e) => {
                                  setCities([]);
                                  const selectedState = indianStates?.find(
                                    (state) => state.name === e.target.value
                                  );

                                  if (selectedState) {
                                    fetch(
                                      `https://api.countrystatecity.in/v1/countries/IN/states/${selectedState.iso2}/cities`,
                                      {
                                        headers: { "X-CSCAPI-KEY": apiKey },
                                      }
                                    )
                                      .then((response) => response.json())
                                      .then((data) => setCities(data))
                                      .catch((error) =>
                                        console.error(
                                          "Error fetching cities:",
                                          error
                                        )
                                      );

                                    handleOrgDataChange(
                                      "state",
                                      selectedState.name
                                    );
                                  }
                                }}
                              >
                                <option value="" disabled>
                                  Select State
                                </option>
                                {indianStates.map((state) => (
                                  <option key={state?.id} value={state?.name}>
                                    {state?.name}
                                  </option>
                                ))}
                              </select>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            flexDirection="row"
                            style={{ width: "100%", gap: "1rem" }}
                          >
                            <Grid item style={{ width: "50%" }}>
                              {" "}
                              <input
                                type="text"
                                className="sp-inp"
                                list="city-options"
                                value={
                                  orgData?.city
                                }
                                onChange={(e) =>
                                  handleOrgDataChange("city", e.target.value)
                                }
                                placeholder="Select or enter a city"
                              />
                              <datalist id="city-options">
                                {cities?.length ? (
                                  cities.map((city) => (
                                    <option key={city.id} value={city.name} />
                                  ))
                                ) : (
                                  <option value="No Cities" disabled />
                                )}
                              </datalist>
                            </Grid>
                            <Grid item style={{ width: "50%" }}>
                              <input
                                type="number"
                                placeholder="Pin code"
                                className="sp-inp"
                                value={
                                  orgData?.pinCode
                                }
                                onChange={(e) => {
                                  if (e.target.value?.length > 6) {
                                    handleSnackbar(
                                      "PinCode is only 6 characters..!",
                                      "error"
                                    );
                                    return;
                                  } else {
                                    handleOrgDataChange(
                                      "pinCode",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></input>{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    <Grid
                      item
                      style={{ width: "40%" }}
                      className="d-flex flex-column mt-4"
                    >
                      <label
                        htmlFor="fileInput"
                        className="cursor-pointer sp-upload"
                      >
                        {imageLoading ? (
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <CircularProgress />
                          </div>
                        ) : previewUrl ? (
                          <>
                            <img
                              src={previewUrl}
                              alt=""
                              className="w-100 h-100 rounded"
                              loading="lazy"
                            />
                          </>
                        ) : (
                          <span className="upload-logo-text d-flex justify-content-center">
                            Upload your Organization Logo
                          </span>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          className="ld-inp-1"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => handleFileUpload(e, "logo")}
                        />
                      </label>
                      <Grid item className="sp-up-text">
                        <Grid item>
                          <Typography sx={{ fontSize: "10px" }}>
                            Preferred image Dimensions : 240 x 240 pixels @ 72
                            DPI
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontSize: "10px" }}>
                            Maximum File Size : 1 MB
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="row-reverse"
                  container
                  style={{ width: "100%", padding: "2vh" }}
                >
                  <Grid item>
                    {/* <button type="button" className="sp-btn2 cursor-pointer">
                        Cancel
                      </button> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
      {userUpdateLoading ? <Loader /> : null}
    </>
  );
}

export default SettingsProfile;
