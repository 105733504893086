import React from 'react'
import ComplianceCalendar from './ComplianceCalendar'
import ComplianceCalendarAdmin from './ComplianceCalenderAdmin'
import { eNums } from '../../constants'
import { useRolePermissions } from "../../Utilities";
import enums from '../../constants/enums';

const EventCalender = () => {
    const Permissions = useRolePermissions();
    const userType = localStorage.getItem('userType');
   
    return userType?.includes(enums.CLIENT) ? <ComplianceCalendar /> : Permissions?.complianceCalendar?.submodules?.calendarManagement?.enabled ? Permissions?.complianceCalendar?.submodules?.calendarManagement?.permission?.includes(eNums?.EDIT)
        ? <ComplianceCalendarAdmin/> : <ComplianceCalendar /> : null
}

export default EventCalender