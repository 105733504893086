import { Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../Components/Layout'
import FileNowLogo from "../../assets/images/File_Now_Logo.svg";

function EnterNewPassword() {
  return (
    <>
    <Layout>
      <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        <Grid container item className='wrapC1'>
        <Grid item container className='wrapC1'>
        <img alt="content" src={FileNowLogo} width="153" height="100" />
</Grid>
<Grid item container className='wrapC1'  spacing={2}>
<Typography variant='body1' fontWeight="700">Enter New Password</Typography>
</Grid>        
        
<Grid item container className='wrapC1' >
<TextField id="outlined-basic" label="Enter New Password" variant="outlined"   />
</Grid>
<Grid item container className='wrapC1'>
<TextField id="outlined-basic" label="Repeat New Password" variant="outlined" />

</Grid>
<Grid item container className='wrapC1'>
<Button variant="contained" style={{backgroundColor:" #EEEEEE",color:"black", paddingLeft:"8vh",paddingRight:"8vh"}}  ><Typography variant='body2' className='action-btn-text' >
              SignIn</Typography></Button>
       
</Grid>
<Grid item container className='wrapC1'>
</Grid>
        </Grid>

      </Grid>
    </Layout>
  </>
  )
}

export default EnterNewPassword