/* eslint-disable import/no-anonymous-default-export */
export default {
    AGENT: 'AGENT',
    CLIENT: 'CLIENT',
    ACTIVE: 'ACTIVE',
    PROMOTED: 'PROMOTED',
    EDIT: 'Edit',
    VIEW: 'View',
    DEV: 'Dev',
    ADMIN: 'Admin',
    ACCOUNT_MANAGER: 'Account Manager',
    EMPLOYEE: 'Employee',
    ARTICLESHIP: 'ArticleShip',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
};

