import React, { useState, useRef } from "react";
import { Tooltip, Overlay } from "react-bootstrap";
import TooltipImg from '../assets/images/tooltip-info.svg'

const ToolTip = ({ info, width, height }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const triggerHandler = () => setShow((prev) => !prev);

  return (
    <>
      <img
        src={TooltipImg}
        width={width || 16}
        height={height || 16}
        ref={target}
        onMouseOver={triggerHandler}
        onMouseLeave={triggerHandler}
        alt=""
      />
      <Overlay target={target.current} show={show} placement="bottom-start">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {info}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ToolTip;
