import { Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../Components/Layout'
function CreateAccount() {
  return (
    <>
      <Layout>
        <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
          <Grid container item className='wrapC1'>
          <Grid item container className='wrapC1'>
</Grid>
<Grid item container className='wrapC1'  spacing={2}>
  <Typography variant='body1' fontWeight="700">Create Account</Typography>
</Grid>
<Grid item container className='wrapC1'>
      <Typography variant='body1' fontWeight="500">
        Sync your work
      </Typography>
      <Typography style={{ margin: '0 2px' }} fontWeight="700">
        calendar
      </Typography>
      <Typography style={{ margin: '0 2px' }}>
        to start using
      </Typography>
      <Typography style={{ margin: '0 2px' }} fontWeight="700">
        FileNow
      </Typography>
    </Grid>

            <Grid item container className='wrapC1'>
              <Button variant="contained" style={{backgroundColor:" #1679fb"}} ><Typography variant='body2' className='action-btn-text' >
                Countinue with Google</Typography></Button>
            </Grid>
            <Grid item container className='wrapC1'>
              <Button variant="contained"  style={{backgroundColor:" #1679fb"}}><Typography variant='body2' className='action-btn-text' >
                Countinue with Microsoft</Typography></Button>
            </Grid>
            <Grid item container className='wrapC1'>
</Grid>
   <Grid item container className='wrapC1'>
    <Typography variant="body2">or</Typography>
</Grid>
<Grid item container className='wrapC1'>
<TextField id="outlined-basic" label="Email/ Phone no" variant="outlined" />
</Grid>
<Grid item container className='wrapC1'>
<TextField id="outlined-basic" label="Password" variant="outlined" />

</Grid>
<Grid item container className='wrapC1'>
<Button variant="contained" style={{backgroundColor:" #EEEEEE",color:"black"}} ><Typography variant='body2' className='action-btn-text' >
                Create Account</Typography></Button>
         
</Grid>
<Grid item container className='wrapC1'>
</Grid>
          </Grid>

        </Grid>
      </Layout>
    </>
  )
}
export default CreateAccount