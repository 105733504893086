import React from 'react'
import Notifications from './Notifications'
import NotificationsAdmin from './NotificationsAdmin'
import { eNums } from '../../constants'

const UserNotifications = () => {
    const userType = localStorage.getItem('userType')
   
    return userType === eNums?.CLIENT ? <Notifications /> : <NotificationsAdmin/>
}

export default UserNotifications