import React from "react";
import { Grid } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import { useNavigate } from "react-router-dom";

const PathComponent = ({ pageHeading, paths }) => {
  let navigate = useNavigate();

  return (
    <Grid item className={`sp-wrap2 mt-2`}>
      <div className="d-flex align-items-center">
        <Grid item className="d-flex align-items-center">
          <div>
            <span
              className="main-path-text"
              onClick={() => navigate("/dashboard")}
            >
              {"Home"}
            </span>
            {paths?.length ? <span className="mx-1">{"/"}</span> : null}
          </div>
        </Grid>
        {paths?.length ? (
          <Grid item className="d-flex align-items-center">
            {paths?.map((item, index) => (
              <div key={index}>
                <span
                  className="main-path-text"
                  onClick={() => {if(item?.navigation){navigate(`/${item?.navigation}`)}}}
                >
                  {item?.label}
                </span>
                <span className="mx-1">{index !== paths?.length - 1 && "/"}</span>
              </div>
            ))}
          </Grid>
        ) : null}
      </div>
      <Grid item>
        <div className="main-path-heading">
          <GridViewIcon className="sp-icon" />
          <span className="main-path-heading-text mb-1">{pageHeading}</span>
        </div>
      </Grid>
      {/* <Grid item>
            <Typography variant="subtitle2" className="sp-text">
              Monitor any changes made to your project, schema, and
              content with audit logs.
              <Link
                to="#"
                className="sp-redirect"
                target="_blank"
              >
                Learn more
              </Link>
            </Typography>
          </Grid> */}
    </Grid>
  );
};

export default PathComponent;
