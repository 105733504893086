import axios from "axios";
import { getEnv } from "./getEnv";
import { eNums } from "../constants";

// const data = JSON.stringify({
//   "audience": "FILENOWCLIENTID"
// });

let env = getEnv();

const instance = axios.create({
  baseURL: env.apiURL, // Set the base URL for the requests
  headers: {
    audience: "FILENOWCLIENTID",
    // 'Content-Type': 'application/json',
  },
});

// var xhr = new XMLHttpRequest();
// xhr.open("POST", "/attachments", true);

instance.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `${localStorage.getItem("token")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (
      error?.response &&
      error?.response?.data?.error === "Unauthorized / forbidden"
    ) {
      setTimeout(() => {
        const userType = localStorage.getItem("userType");
        localStorage.clear();
        window.location.href = `/${
          userType !== eNums?.CLIENT ? 'agent/' : ""
        }signIn`;
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default instance;
