import { usePermissions } from '../PermissionsContext';
import * as ApiService from './apis'

export const dateFormatter = (dateString) => {
    const options =  {
      day: "2-digit",
      month: "short",
      year: "numeric"
  };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options); 
  };
  
  // Function to handle file upload and return preview URL and response
export const uploadSingleBlobFile = (e) => {
  try {
    const file = e?.target?.files?.[0];

    if (!file) return { previewUrl: '', response: null };
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "file");

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const previewUrl = reader.result;

        try {
          const res = await ApiService.singleFileUpload(formData);
          resolve({ previewUrl, res: res });
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  } catch (error) {
    return { previewUrl: '', res: null };
  }
}

export const getBlobFile = async(fileName) => {
  try {
    const response = await ApiService.fileDownload({
      fileName: fileName,
    });

    const base64String = response?.base64Data;
    const mimeType = response?.mimeType || 'image/jpeg';

    if (base64String) {
      return `data:${mimeType};base64,${base64String}`;
    } else {
      return ''
    }
  } catch (error) {
    return '';
  }
}

export const exportBlobFile = (base64Data, fileName) => {
  // Create an anchor element
  const link = document.createElement('a');
  
  // Set the download attribute with the desired file name
  link.download = fileName;
  
  // Set the href of the link to the base64 image data
  link.href = base64Data;
  
  // Append the link to the body
  document.body.appendChild(link);
  
  // Trigger a click event on the link
  link.click();
  
  // Remove the link from the document
  document.body.removeChild(link);
}

export const useRolePermissions = () => { 
  const { permissions } = usePermissions();
  return permissions || null;  // No need for JSON.parse() since permissions are already an object
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}