import React, { useState, useEffect } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBlobFile } from "../Utilities";

function MyServicesCard(props) {
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState(null);

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("user_name");

  useEffect(() => {
    if (props?.data?.thumbnail) {
      (async function () {
        const imgUrl = await getBlobFile(props?.data?.thumbnail);
        setPreviewUrl(imgUrl);
      })();
    }
  }, [props?.data]);

  return (
    <>
      <Grid
        className="servicesWrap cursor-pointer"
        onClick={() =>
          navigate(`/customer-management`, {
            state: {
              user: {
                _id: userId,
                fullname: userName,
                serviceID: props?.serviceID,
              },
              previous_route_name: "My Services",
              previous_route_path: "my-services",
              serviceName: props?.data?.serviceName,
            },
          })
        }
      >
        <Grid className="serviceCard d-flex flex-column justify-content-between">
          <Grid className="d-flex flex-column gap-2">
            <div className="service-img-placeholder mt-1">
              {previewUrl ? (
                <img src={previewUrl} className="w-100 h-100 rounded" alt="" />
              ) : null}
            </div>
            <Grid container>
              <Tooltip title={props?.data?.serviceName} placement="top">
                <Typography
                  style={{
                    fontWeight: "600",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.85em",
                    color: "##000012",
                  }}
                >
                  {props?.data?.serviceName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container className="g-sc2-btn">
            <button className="services-btn-1">{props.status}</button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MyServicesCard;
