import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Modal from "@mui/material/Modal";

function Loader() {
  return (
    <div>
      <Modal
        // sx={{alignItems:'center', justifyContent:'center'}}
        open={true}
        // onClose={}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="loader">
          <Player
            autoplay
            loop
            src="https://lottie.host/069c28cb-036c-4e44-a769-1886d4a470e6/TGV64caC9A.json"
            style={{ height: "250px", width: "400px" }}
          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
        </div>
      </Modal>
    </div>
  );
}

export default Loader;
