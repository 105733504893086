import React, { useEffect, useState } from "react";

import NewLayout from "../../Components/SidebarLayout";
import * as ApiServices from "../../Utilities/apis";
import useApi from "../../Utilities/useApi";
import { Grid, Typography } from "@mui/material";
import MyServicesCard from "../../Components/MyServicesCard";
import { Placeholder } from "react-bootstrap";
import { useRolePermissions } from "../../Utilities";

function MyServices() {
  const [myServices, setMyServices] = useState([]);
  const [type, setType] = useState("");
  const userID = localStorage.getItem("userId");
  const Permissions = useRolePermissions();

  const { action: getAllCategories } = useApi(ApiServices.getAllCategories, {
    success: true,
    error: true,
    actionName: "Categories fetching",
  });
  const {
    action: getMyServices,
    data: services,
    snackbarComponent: getMyServicesSnackbarComponent,
    loading: serviceLoading,
    error: servicesError,
  } = useApi(ApiServices.getMyServices, {
    success: true,
    error: true,
    actionName: "Services fetching",
  });

  useEffect(() => {
    getAllCategories();
    getMyServices({ userID});
  }, []);

  useEffect(() => {
    if (!serviceLoading && !servicesError && services?.length > 0) {
      const groupedServices = {};

      services?.forEach((service) => {
        const categoryName = service?.registeredServices?.[0]?.categoryName
          ? service?.registeredServices?.[0]?.categoryName?.toString()
          : null;
        if (categoryName) {
          if (!groupedServices[categoryName]) {
            groupedServices[categoryName] = [];
          }
          groupedServices[categoryName].push(service);
        }
      });

      setMyServices([groupedServices]);
    } else {
      setMyServices([]);
    }
  }, [services, serviceLoading, servicesError]);

  const handleTypeChange = (type) => {
    setType(type);
    if (type && services?.length > 0) {
      const groupedServices = {};

      services?.forEach((service) => {
        const categoryName =
          service?.registeredServices?.[0]?.serviceType === type
            ? service?.registeredServices?.[0]?.categoryName?.toString()
            : null;
        if (categoryName) {
          if (!groupedServices[categoryName]) {
            groupedServices[categoryName] = [];
          }
          groupedServices[categoryName].push(service);
        }
      });
      const servicesArray =
        Object.keys(groupedServices).length > 0 ? [groupedServices] : [];
      setMyServices(servicesArray);
    } else {
      getMyServices({ userID});
    }
  };

  const paths = [
    { label: `My Services`, navigation: "my-services" },
  ];

  return (
    <>
      <NewLayout
        paths={paths}
        pageHeading={"My Services"}
      >
        {getMyServicesSnackbarComponent}

        <div className="w-100 mt-3 d-flex align-items-center gap-2 flex-wrap">
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "" ? "sm-w-b-b" : ""
              }`}
              onClick={() => handleTypeChange("")}
            >
              <Typography variant="subtitle">All</Typography>
            </Grid>
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "Individual" ? "sm-w-b-b" : ""
              }`}
              onClick={() => {
                handleTypeChange("Individual");
              }}
            >
              <Typography variant="subtitle">Individual</Typography>
            </Grid>
            <Grid
              item
              className={`sm-w-b-t cursor-pointer ${
                type === "Business" ? "sm-w-b-b" : ""
              }`}
              onClick={() => {
                handleTypeChange("Business");
              }}
            >
              <Typography variant="subtitle">Business</Typography>
            </Grid>

        </div>

        {serviceLoading ? (
          [...Array(8)]?.map((a, i) => (
            <Placeholder
              className="servicesWrap placeholder-background rounded"
              animation="wave"
              style={{ height: "220px" }}
            />
          ))
        ) :
        <div className="d-flex flex-wrap my-3">
          {myServices?.length ? (
            myServices?.map((service, index) => (
              <div key={index}>
                {Object.keys(service)?.map((key) => (
                  <div key={key}>
                    <Typography fontWeight={'600'} fontSize={'1em'} color={'#090E24'}>{key}</Typography>
                    <div key={key} className="d-flex flex-wrap">
                      {service?.[key]?.map((value, i) => (
                        <MyServicesCard
                          status={
                            value?.registeredServices?.[0]?.currentStatus ===
                            "inprogress"
                              ? "Ongoing"
                              : "Completed"
                          }
                          data={value?.registeredServices?.[0] || null}
                          serviceID={value?._id}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p className="mt-2">No Services Available..!</p>
          )}
        </div>
        }
        {/* <Grid
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    className="blue-block-container"
                  >
                    <Grid item className="blue-block-ms"></Grid>
                   
                  </Grid>
                  <Grid>
                    <Grid container className="wrapMS"> 
                    <Typography variant="h6" fontWeight="bold">
                      ITR Filing
                    </Typography>
                    </Grid>
                    <Grid container>
                        <Grid display="flex">
                        <ServicesCard/>
                        <ServicesCard/>
                        <ServicesCard/>
                        <ServicesCard/>
                        

                        </Grid>
                    </Grid>
                    <Grid container className="wrapMS"> 
                    <Typography variant="h6" fontWeight="bold">
                      GST Filing
                    </Typography>
                    </Grid>
                    <Grid container>
                        <Grid display="flex">
                        <ServicesCard/>
                        <ServicesCard/>
                        <ServicesCard/>
                        <ServicesCard/>
                        

                        </Grid>
                    </Grid>
                    
                    </Grid> */}

      </NewLayout>
    </>
  );
}

export default MyServices;
