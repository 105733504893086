import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Pagination from "@mui/material/Pagination";
import { Grid, Typography, Drawer, Slide, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NewLayout from "../../Components/SidebarLayout";
import CloseIcon from "@mui/icons-material/Close";
import {
  useApi,
  CurrencySymbol,
  ApiService,
  dateFormatter,
  EmptyState,
  PlaceholderTable
} from "../../Utilities";
import AlertDialog from "../../Components/AlertDialog";
import DownArrowWhite from "../../assets/images/down_arrow_white.svg";
import { eNums } from "../../constants";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    padding: "10px",
    fontWeight: 400,
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
    fontWeight: 400,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OrderAndPaymentAdmin() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [ordersData, setOrdersData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRefundItem, setSelectedRefundItem] = useState(null);
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [skip, setSkip] = useState(0);

  const [orderDetailsView, setOrderDetailsView] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);

  const {
    action: getOrders,
    data: orders,
    snackbarComponent: getAllOrdersSnackbarComponent,
    loading: ordersLoading,
    error: ordersError,
  } = useApi(ApiService.getOrders, {
    success: true,
    error: true,
    actionName: "Orders fetching",
  });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedRefundItem(null);
  };
  const {
    action: refundOrder,
    snackbarComponent: refundOrderSnackbarComponent,
    loading: refundOrderLoading,
    error: refundOrderError,
  } = useApi(ApiService.refundOrder, {
    success: true,
    error: true,
    noStore: true,
    actionName: "Refund",
  });

  useEffect(() => {
    let obj = {};
    getOrders(obj);
  }, [userType]);

  useEffect(() => {
    if (orders?.length) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const slicedData = orders?.slice(indexOfFirstItem, indexOfLastItem);

      setOrdersData(slicedData);
      setSkip(indexOfFirstItem);
    } else {
      setOrdersData([]);
    }
  }, [orders, currentPage]);

  function getStatusColorClass(paymentStatus) {
    switch (paymentStatus) {
      case "created":
        return "button-cell2";
      case "Active":
      case "success":
        return "button-cell3";
      case "Declined":
      case "Cancelled":
        return "button-cell1";
      default:
        return ""; // Default, if needed
    }
  }

  const handleRefund = async () => {
    await refundOrder({
      paymentId: selectedRefundItem?.paymentID,
      amount: selectedRefundItem?.amountReceived
    });
    setOpenDialog(false);
    setSelectedRefundItem(null);
    await getOrders();
  };

  const paths = [
    { label: `Orders & Payments`, navigation: "orders-and-ayments" },
  ];

  const handleCloseOrderDetails = () => {
    setOrderDetailsView(false);
    setOrderDetails(null);
  };

  const orderDetailsData = [
    { label: "Service", value: orderDetails?.serviceID?.serviceName || "-" },
    { label: "Service ID", value: orderDetails?.serviceID?.serviceID || "-" },
    {
      label: "Category",
      value: orderDetails?.serviceID?.categoryID?.categoryName || "-",
    },
    { label: "Invoice No", value: orderDetails?.receipt || "-" },
    {
      label: "Generated On",
      value: orderDetails?.invoiceGeneratedOn
        ? dateFormatter(orderDetails?.invoiceGeneratedOn)
        : "-",
    },
    {
      label: "Price",
      value: <CurrencySymbol amount={orderDetails?.amountReceived || "-"} />,
    },
    {
      label: "Payment Status",
      value:
        orderDetails?.paymentStatus === "success"
          ? "Paid"
          : orderDetails?.paymentStatus === "created"
          ? "Pending"
          : orderDetails?.paymentStatus,
    },
  ];
  
  return (
    <NewLayout paths={paths} pageHeading={"Orders & Payments"}>
      {refundOrderSnackbarComponent}
      <span className="fw-bold">Overview</span>
      <div className="d-flex flex-wrap column-gap-5 my-2">
        <div className="orders-overview-card orders-overview-card-1">
          <CardContent>
            <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
              {ordersLoading ? 
                <CircularProgress size={25} /> : orders?.length || 0}
            </Typography>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              Total Invoices
            </Typography>
          </CardContent>
          <CardContent>
            <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
              {ordersLoading ? 
                <CircularProgress size={25} /> : orders?.filter(order => dayjs(order?.invoiceGeneratedOn).isSame(dayjs(), "month")).length || 0}
            </Typography>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              Invoices This Month
            </Typography>
          </CardContent>
        </div>
        <div className="orders-overview-card orders-overview-card-2">
          <CardContent>
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
                {ordersLoading ? 
                <CircularProgress size={25} /> : `${orders?.length ? orders
                  ?.filter(order => order?.paymentStatus === "success")
                  .reduce((sum, order) => sum + Math.round((order?.amountReceived || 0) * 1000), 0) / 1000 : 0} Rs`}
              </Typography>
              {/* <Typography
                fontSize={12}
                fontWeight={400}
                color={"#696969"}
                className="d-flex"
              >
                <Typography fontSize={12} fontWeight={400} color={"#FD0003"}>
                  {"-17%"}
                </Typography>{" "}
                <span className="ms-1">{"/month"}</span>
              </Typography> */}
            </div>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              Paid
            </Typography>
          </CardContent>
          <CardContent>
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
              {ordersLoading ? 
                <CircularProgress size={25} /> : `${orders?.length ? orders
                  ?.filter(order => order?.paymentStatus === "created")
                  .reduce((sum, order) => sum + Math.round((order?.amountReceived || 0) * 1000), 0) / 1000 : 0} Rs`}
              </Typography>
              {/* <Typography
                fontSize={12}
                fontWeight={400}
                color={"#696969"}
                className="d-flex"
              >
                <Typography fontSize={12} fontWeight={400} color={"#04AE07"}>
                  {"+17%"}
                </Typography>{" "}
                <span className="ms-1">{"/month"}</span>
              </Typography> */}
            </div>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              UnPaid
            </Typography>
          </CardContent>
          <CardContent>
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
              {ordersLoading ? 
                <CircularProgress size={25} /> : 
                `${orders?.length ? orders
                  ?.filter(order => order?.paymentStatus === "cancelled")
                  .reduce((sum, order) => sum + Math.round((order?.amountReceived || 0) * 1000), 0) / 1000 : 0} Rs`}
              </Typography>
              {/* <Typography
                fontSize={12}
                fontWeight={400}
                color={"#696969"}
                className="d-flex"
              >
                <Typography fontSize={12} fontWeight={400} color={"#FD0003"}>
                  {"-17%"}
                </Typography>{" "}
                <span className="ms-1">{"/month"}</span>
              </Typography> */}
            </div>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              Cancelled
            </Typography>
          </CardContent>
          <CardContent>
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <Typography fontSize={24} fontWeight={700} color={"#0F0F0F"}>
                {"0 Rs"}
              </Typography>
              {/* <Typography
                fontSize={12}
                fontWeight={400}
                color={"#696969"}
                className="d-flex"
              >
                <Typography fontSize={12} fontWeight={400} color={"#04AE07"}>
                  {"0%"}
                </Typography>{" "}
                <span className="ms-1">{"/month"}</span>
              </Typography> */}
            </div>
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight={400}
              color={"#595671"}
              letterSpacing={0}
            >
              Overdue
            </Typography>
          </CardContent>
        </div>
      </div>
      {ordersLoading ? <PlaceholderTable /> : ordersData?.length ? (
        <Grid item container>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="customized table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Service</StyledTableCell>
                  <StyledTableCell>User Name</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>Invoice Number</StyledTableCell>
                  <StyledTableCell>Generated On</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Payment Status</StyledTableCell>
                  <StyledTableCell>Download</StyledTableCell>
                  <StyledTableCell>Payment Link</StyledTableCell>
                  {/* <StyledTableCell>Refund</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {ordersData?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1 + skip}</StyledTableCell>
                    <StyledTableCell
                      className="service-order-text cursor-pointer"
                      onClick={() => {
                        setOrderDetails(row);
                        setOrderDetailsView(true);
                      }}
                    >
                      {row?.serviceID?.serviceName || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.userID?.fullname || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.serviceID?.categoryID?.categoryName || "-"}
                    </StyledTableCell>
                    <StyledTableCell>{row?.receipt || "-"}</StyledTableCell>
                    <StyledTableCell>
                      {dateFormatter(row?.invoiceGeneratedOn) || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <CurrencySymbol amount={row?.amountReceived || "-"} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <span
                        className={`d-flex align-items-center justify-content-center w-fit-content py-1 px-3 ${getStatusColorClass(
                          row?.paymentStatus
                        )}`}
                      >
                        {row?.paymentStatus === "success"
                          ? "Paid"
                          : row?.paymentStatus === "created"
                          ? "Pending"
                          : row?.paymentStatus}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span className="text-op d-flex cursor-pointer">
                        {row.paymentStatus === "success" ? "Download" : "N/A"}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.paymentStatus === "success" ? (
                        <span className="text-op d-flex  cursor-pointer">
                          N/A
                        </span>
                      ) : (
                        <a className="text-op d-flex cursor-pointer">
                          Send payment link
                        </a>
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <span
                        className="text-op d-flex justify-content-center cursor-pointer"
                        onClick={() => {
                          setOpenDialog(true);
                          setSelectedRefundItem(row);
                        }}
                      >
                        {row.paymentStatus === "success" ? "Refund" : "-"}
                      </span>
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <EmptyState />
      )}
      {orders?.length > 10 ? (
        <Grid item display="flex" justifyContent="center" margin={10}>
          <Pagination
            count={Math.ceil(orders?.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Grid>
      ) : null}
      <Drawer
        anchor={"right"}
        open={orderDetailsView}
        onClose={handleCloseOrderDetails}
        TransitionComponent={Slide}
        sx={{
          "& .MuiDrawer-paper": {
            width: "360px",
          },
        }}
      >
        <div>
          <CloseIcon
            fontSize="inherit"
            sx={{ color: "#2A2A2A", right: 0, position: "absolute" }}
            className="cursor-pointer my-2 me-2"
            onClick={handleCloseOrderDetails}
          />
          <div className="my-4 mx-5">
            <Typography fontWeight={600} fontSize={16}>
              Order Details
            </Typography>
            {orderDetailsData?.map((item, index) => (
              <div className="d-flex flex-wrap my-3" key={index}>
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  color={"#404040"}
                  letterSpacing={0}
                >
                  {item?.label} :
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  color={"#404040"}
                  className="ms-1"
                  letterSpacing={0}
                >
                  {item?.value}
                </Typography>
              </div>
            ))}
            {orderDetails?.paymentStatus === "created" ? (
              <div className="d-flex flex-wrap my-3">
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  color={"#404040"}
                  letterSpacing={0}
                >
                  Payment Link :
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  color={"#1E68F9"}
                  className="ms-1 cursor-pointer"
                  letterSpacing={0}
                >
                  Send Payment Link
                </Typography>
              </div>
            ) : null}

            <Typography fontWeight={600} fontSize={16}>
              Customer Details
            </Typography>
            <div className="d-flex flex-wrap my-3">
              <Typography
                fontWeight={700}
                fontSize={12}
                color={"#404040"}
                letterSpacing={0}
              >
                Customer Name :
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={12}
                color={"#404040"}
                className="ms-1"
                letterSpacing={0}
              >
                {orderDetails?.userID?.fullname || "-"}
              </Typography>
            </div>
            <div className="d-flex flex-wrap my-3">
              <Typography
                fontWeight={700}
                fontSize={12}
                color={"#404040"}
                letterSpacing={0}
              >
                Customer Type :
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={12}
                color={"#404040"}
                className="ms-1"
                letterSpacing={0}
              >
                {orderDetails?.userID?.type || "-"}
              </Typography>
            </div>

            {orderDetails?.paymentStatus === "success" ? (
              <>
                <button className="em-btn-1 cursor-pointer d-flex justify-content-center gap-2">
                  <span className="em-btn-1-text">Download Invoice</span>
                  <img src={DownArrowWhite} alt="" />
                </button>
              </>
            ) : null}
          </div>

          <div className="mt-5 mb-2 border-bottom-gray" />

          {orderDetails?.paymentStatus === "success" ? (
            <div className="my-4 mx-5">
              <Typography fontWeight={600} fontSize={16}>
                Refund
              </Typography>
              <div className="d-flex flex-wrap my-3">
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  color={"#404040"}
                  letterSpacing={0}
                >
                  Total Amount Paid :
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  color={"#404040"}
                  className="ms-1"
                  letterSpacing={0}
                >
                  <CurrencySymbol amount={orderDetails?.amountReceived || "-"} />
                </Typography>
              </div>
              <div className="my-3">
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  color={"#404040"}
                  letterSpacing={0}
                >
                  Select Amount to Refund :
                </Typography>
                <TextField
                    id="outlined-basic"
                    label="Enter Amount"
                    variant="outlined"
                    className="my-2"
                    size="small"
                    // onChange={(e) => {
                    //   setData((prev) => {
                    //     return { ...prev, emailorphone: e.target.value };
                    //   });
                    // }}
                  />
              </div>
              <button className="em-btn-1 cursor-pointer d-flex justify-content-center gap-2">
                <span className="em-btn-1-text">Refund</span>
              </button>
            </div>
          ) : null}
        </div>
      </Drawer>
      {openDialog ? (
        <AlertDialog
          title={"Do you want to make Refund?"}
          openDialog={openDialog}
          handleClose={handleClose}
          handleAction={handleRefund}
          loading={refundOrderLoading}
          loadingText={"Processing..."}
          actionText={"Refund"}
          children={
            <>
              <span className="d-flex">
                User Name:{" "}
                <Typography color="#000" marginLeft={1}>
                  {selectedRefundItem?.userID?.fullname}
                </Typography>
              </span>
              <span className="d-flex">
                Service Name:{" "}
                <Typography color="#000" marginLeft={1}>
                  {selectedRefundItem?.serviceID?.serviceName}
                </Typography>
              </span>
              <span className="d-flex">
                Refund Amount:{" "}
                <Typography color="#000" marginLeft={1}>
                  <CurrencySymbol amount={selectedRefundItem?.amountReceived} />
                </Typography>
              </span>
            </>
          }
        />
      ) : null}
    </NewLayout>
  );
}

export default OrderAndPaymentAdmin;
