import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import Logo from "../../assets/images/File_Now_Logo.svg";
import Legall from "../../assets/images/Legall.svg";
import Abstract from "../../assets/images/abstract2.png";
import { getEnv } from "../../Utilities/getEnv";

let env = getEnv();

const scriptURL = env.scriptURL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChooseaService = () => {
  const services = [
    "ITR Filing",
    "Annual Filing - Pvt Ltd",
    "ROC Filing",
    "Annual Filing - Others",
  ];
  let navigate = useNavigate();
  const [service, setService] = useState("");
  const [mobile, setMobile] = useState("");
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleService = (event) => {
    setService(event.target.value);
  };

  const handleMobile = (event) => {
    setMobile(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = { service, mobile };
    console.log("data", data);
    try {
      await fetch(`${scriptURL}?action=addUser`, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      handleClick();
    } catch (err) {
      alert("failed to submit, pls try again later");
    }
  };

  const StyledSelect = styled(Select)({
    fontSize: 20,
    width: "100%",
    marginTop: "10px",
  });

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          className="const_width2"
        >
          Successfully Submitted
        </Alert>
      </Snackbar>
      <div className="background">
        <div className="mainContainer">
          <div className="flexrow">
            <div className="chooseAServiceImg">
              <img alt="content" src={Legall} className="legallImg" />
            </div>
            <div className="wrap-1-cs">
              <div className="chooseAService">
                <div className="wrapContainer">
                  <div className="header const_width">Choose a service</div>
                </div>
                <div className="inputContainer">
                  <div className="wrapContainer">
                    <div className="wrap2">
                      <a className="a1">Phone no</a>
                    </div>
                    <div className="const_width">
                      <input className="phoneInput" onChange={handleMobile} />
                    </div>
                  </div>
                </div>
                <div className=""></div>
                <div className="inputContainer">
                  <div className="wrapContainer">
                    <div className="wrap2">
                      <a className="a1">Services</a>
                    </div>

                    <div className="const_width">
                      <StyledSelect
                        value={service}
                        displayEmpty
                        onChange={handleService}
                        className="const_width2"
                      >
                        {services.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </div>
                  </div>

                  <div className="wrapContainer">
                    <div className="btn-section">
                      <Button variant="contained" onClick={handleSubmit}>
                        Register
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {window.screen.width > 700 ? (
          <>
            <p className="watermark">@ Atlas . Privacy & terms</p>
            <img alt="content" src={Abstract} className="abstract" />
          </>
        ) : null}
        <div className="logoContainer">
          <img
            alt="content"
            src={Logo}
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ChooseaService;
