import { Grid, Typography, IconButton, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Lock from "../assets/images/lock.svg";
import Email from "../assets/images/email.svg";
import editIcon from "../assets/images/edit-img.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import passwordCheck from "../assets/images/password-check.svg";
import { ApiService, useApi, CustomizedSnackbar } from "../Utilities";
import Loader from "./Loader";
import OTPInput from "otp-input-react";
import { emailRegExp, phoneRegExp } from "../Utilities/regex";
import { useNavigate } from "react-router-dom";

function SettingsPasswordLogin({ data }) {
  const userID = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [type, setType] = useState("");
  const [email, setEmail] = useState(data?.email || "");
  const [phoneNo, setPhoneNo] = useState(data?.mobile || "");
  const [loading, setLoading] = useState(false);
  const [enableOTP, setEnableOTP] = useState("");
  const [OTP, setOTP] = useState(null);
  const [changeInput, setChangeInput] = useState("");
  const [edit, setEdit] = useState("");

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const {
    action: requestOTP,
    snackbarComponent: requestOTPComp,
    loading: validateLoading,
    error: validateError,
  } = useApi(ApiService.requestOTP, {
    success: true,
    error: true,
    noStore: true,
    initialLoad: true,
  });

  const {
    action: verifyMobileorEmail,
    loading: verifyLoading,
    snackbarComponent: verifyComponent,
    error: verifyError,
  } = useApi(ApiService.verifyUserMobileOrEmail, {
    success: true,
    error: true,
    noStore: true,
    initialLoad: true,
    actionName: "Mobile Number Verified",
  });

  const {
    action: changePassword,
    loading: resetLoading,
    snackbarComponent,
  } = useApi(ApiService.changePassword, {
    noStore: true,
    success: true,
    error: true,
    actionName: "Change Password",
  });

  const handleClickShowPassword = (setter) => {
    setter((show) => !show);
  };

  useEffect(() => {
    if (!validateLoading && !validateError) {
      setEnableOTP(type);
    } else {
      setEnableOTP("");
    }
  }, [validateLoading, validateError]);

  useEffect(() => {
    if (!verifyLoading && !verifyError) {
      setEnableOTP("");
      if (type === "mobile") {
        localStorage.setItem("mobileVerified", true);
        localStorage.removeItem("mobile");
        data.mobileVerified = true;
        data.mobile = phoneNo;
      } else {
        data.emailVerified = true;
        data.email = email;
      }
      setType("");
    }
  }, [verifyLoading, verifyError]);

  const validateForm = () => {
    let tempErrors = {};
    if (!oldPassword) tempErrors.oldPassword = "Old Password is required";
    if (!newPassword) tempErrors.newPassword = "New Password is required";
    if (newPassword !== confirmPassword)
      tempErrors.confirmPassword = "Passwords do not match";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    setOldPassword(value);
    setErrors((prevErrors) => {
      const { oldPassword, ...rest } = prevErrors;
      return value
        ? rest
        : { ...rest, oldPassword: "Old Password is required" };
    });
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    setErrors((prevErrors) => {
      const { newPassword, confirmPassword, ...rest } = prevErrors;
      let newErrors = value
        ? rest
        : { ...rest, newPassword: "New Password is required" };
      if (value !== confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
      } else {
        delete newErrors.confirmPassword;
      }
      return newErrors;
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setErrors((prevErrors) => {
      const { confirmPassword, ...rest } = prevErrors;
      return value === newPassword
        ? rest
        : { ...rest, confirmPassword: "Passwords do not match" };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword?.length < 6) {
      handleSnackbar("New Password should be atleast 6 characters", "error");
      return;
    }
    if (validateForm()) {
      changePassword({ userID, oldPassword, newPassword, userType });
    }
  };

  const handleSendOTP = async (value) => {
    if (!phoneNo || !email) {
      if (value === "email") {
        handleSnackbar("Email is required", "error");
      } else {
        handleSnackbar("Mobile Number is required", "error");
      }
      return;
    }
    if (value === "email") {
      if (!emailRegExp.test(email)) {
        handleSnackbar("Invalid Email", "error");
        return;
      }
    }
    if (value === "mobile") {
      if (!phoneRegExp.test(phoneNo)) {
        handleSnackbar("Invalid Mobile Number", "error");
        return;
      }
    }
    setOTP("");
    setType(value);
    const obj = {
      userID,
      userType,
      [value]: value === "mobile" ? phoneNo : email,
    };
    setLoading(true);
    await requestOTP(obj);
    setLoading(false);
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    if (OTP.length !== 6) {
      alert("Please enter a valid 6-digit OTP");
      return;
    }
    setLoading(true);
    const obj = {
      userID,
      userType,
      otp: OTP,
      [type]: type === "mobile" ? phoneNo : email,
    };
    await verifyMobileorEmail(obj);
    setLoading(false);
  };

  const paths = [
    { label: "Home", navigation: "dashboard" },
    { label: "Settings", navigation: "settings" },
    { label: "Password and Login" },
  ];

  return (
    <>
      {snackbarComponent}
      {requestOTPComp}
      {verifyComponent}
      <Grid
        item
        className="d-flex flex-column gap-1 position-sticky top-0 bg-white"
      >
        {paths?.length ? (
          <Grid item className="d-flex align-items-center pt-2">
            {paths?.map((item, index) => (
              <div>
                <span
                  className="main-path-text"
                  onClick={() => {
                    if (item?.navigation) {
                      navigate(`/${item?.navigation}`);
                    }
                  }}
                >
                  {item?.label}
                </span>
                <span className="mx-1">
                  {index !== paths?.length - 1 && "/"}
                </span>
              </div>
            ))}
          </Grid>
        ) : null}
        <Grid item>
          <h5 className="sp-typo d-flex align-items-center gap-2">
            <img src={Lock} alt="" width={20} height={20} />
            <span className="settings-subtitle">Password and Login</span>
          </h5>
        </Grid>
      </Grid>
      <Grid item container className="mb-5">
        <Grid item container className="spl-wrap">
          <Grid item className="spl-wrap11">
            <Grid item className="spl-wrap1">
              <Grid item className="spl-w1 d-flex align-items-center gap-2">
                <span className="settings-subtitle">Login</span>
                <img
                  src={editIcon}
                  alt=""
                  className="cursor-pointer"
                  width={24}
                  height={24}
                  onClick={() => {
                    if (edit === "login") {
                      setEdit("");
                      setChangeInput("");
                    } else {
                      setEdit("login");
                    }
                  }}
                />
              </Grid>
              <Grid item className="textWrap">
                <div className="d-flex align-items-center gap-2">
                  <img src={Email} alt="" width={18} height={18} />
                  <span className="spn-key-title-text">Email Verification</span>
                </div>
                <Grid item className="textWrap2">
                  <span className="spn-key-subtitle-text w-30">
                    Get emails to find out what’s going on when you’re not
                    online. You can turn these off.
                  </span>

                  <input
                    placeholder="email"
                    type="text"
                    className="spl-input"
                    value={edit === "login" ? email : data?.email}
                    disabled={edit === "login" ? false : true}
                    onChange={(e) => {
                      setChangeInput("email");
                      setEnableOTP("");
                      setEmail(e.target.value);
                    }}
                  />
                  {enableOTP === "email" ? (
                    <form
                      onSubmit={handleVerifyOTP}
                      className="d-flex align-items-center gap-2"
                    >
                      <OTPInput
                        inputStyle={"inputstyle_indv"}
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                      />
                      <Typography
                        variant="body2"
                        onClick={() => handleSendOTP("email")}
                        fontWeight="700"
                        color={"#1E68F9"}
                        fontSize={".75em"}
                        className="cursor-pointer align-self-start ms-1 text-decoration-underline"
                      >
                        Resend OTP ?
                      </Typography>
                      <button type="submit" className="d-none"></button>
                    </form>
                  ) : (
                    <div className="spl-verified">
                      {data?.emailVerified && changeInput !== "email" ? (
                        <Typography
                          className="sp-typo d-flex align-items-center"
                          sx={{ fontSize: "12px" }}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                            className="spl-icon"
                          />
                          <span>Verified</span>
                        </Typography>
                      ) : (
                        <Typography
                          className="sp-typo cursor-pointer"
                          sx={{ fontSize: "12px", color: "#2A2A2A" }}
                          onClick={() => handleSendOTP("email")}
                        >
                          <span>
                            {type === "email" && loading
                              ? "Loading..."
                              : "Verify"}
                          </span>
                        </Typography>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item className="textWrap">
                <div className="d-flex align-items-center">
                  <PhoneAndroidOutlinedIcon
                    fontSize="small"
                    className="sp-icon"
                  />
                  <span className="spn-key-title-text">
                    Mobile Verification
                  </span>
                </div>
                <Grid item className="textWrap2">
                  <span className="spn-key-subtitle-text w-30">
                    Receive text messages to confirm your identity and enhance
                    the security of your account.
                  </span>
                  <input
                    placeholder="phone"
                    type="number"
                    className="spl-input"
                    value={edit === "login" ? phoneNo : data?.mobile}
                    disabled={edit === "login" ? false : true}
                    onChange={(e) => {
                      setChangeInput("mobile");
                      setEnableOTP("");
                      setPhoneNo(e.target.value);
                    }}
                  />
                  {enableOTP === "mobile" ? (
                    <form
                      onSubmit={handleVerifyOTP}
                      className="d-flex align-items-center gap-2"
                    >
                      <OTPInput
                        inputStyle={"inputstyle_indv"}
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                      />
                      <Typography
                        variant="body2"
                        onClick={() => handleSendOTP("mobile")}
                        fontWeight="700"
                        color={"#1E68F9"}
                        fontSize={".75em"}
                        className="cursor-pointer align-self-start ms-1 text-decoration-underline"
                      >
                        Resend OTP ?
                      </Typography>
                      <button type="submit" className="d-none"></button>
                    </form>
                  ) : (
                    <div className="spl-verified">
                      {data?.mobileVerified && changeInput !== "mobile" ? (
                        <Typography
                          className="sp-typo d-flex align-items-center"
                          sx={{ fontSize: "12px" }}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                            className="spl-icon"
                          />
                          <span>Verified</span>
                        </Typography>
                      ) : (
                        <Typography
                          className="sp-typo cursor-pointer"
                          sx={{ fontSize: "12px", color: "#2A2A2A" }}
                          onClick={() => handleSendOTP("mobile")}
                        >
                          <span>
                            {type === "mobile" && loading
                              ? "Loading..."
                              : "Verify"}
                          </span>
                        </Typography>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="spl-wrap11">
            <form onSubmit={handleSubmit} className="w-100">
              <Grid item className="spl-wrap1">
                <Grid item className="spl-w1 d-flex align-items-center gap-1">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    className="d-flex align-items-center"
                  >
                    <img src={passwordCheck} className="sp-icon" alt="" />
                    Password
                  </Typography>
                  {edit === ('password') ? (
                    <Grid
                      container
                      className="align-items-center justify-content-end gap-3 flex-wrap"
                    >
                      <Button
                        variant="contained"
                        className="alert-cancel-btn cursor-pointer ms-2"
                        onClick={() => setEdit("")}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="save-btn cursor-pointer"
                        type="submit"
                      >
                        {!resetLoading ? "Save" : "Saving..."}
                      </Button>
                    </Grid>
                  ) : (
                    <img
                      src={editIcon}
                      alt=""
                      className="cursor-pointer"
                      width={24}
                      height={24}
                      onClick={() => {
                        if (edit === "password") {
                          setEdit("");
                          setChangeInput("");
                          setOldPassword("");
                          setNewPassword("");
                          setConfirmPassword("");
                          let tempErrors = {
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                          };
                          setErrors(tempErrors);
                        } else {
                          setEdit("password");
                        }
                      }}
                    />
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="reset-btn"
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item className="textWrap">
                  <Grid item className="textWrap2">
                    <Typography variant="body2" className="spl-typo w-30">
                      <img
                        src={passwordCheck}
                        className="sp-icon"
                        width={16}
                        height={16}
                        alt=""
                        disabled={edit === "password" ? false : true}
                      />
                      Old Password
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        placeholder="Enter old password"
                        type={showOldPassword ? "text" : "password"}
                        className="spl-input"
                        value={oldPassword}
                        disabled={edit === "password" ? false : true}
                        onChange={handleOldPasswordChange}
                      />
                      <IconButton
                        onClick={() =>
                          handleClickShowPassword(setShowOldPassword)
                        }
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                    {errors.oldPassword && (
                      <Typography variant="caption" color="error">
                        {errors.oldPassword}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid item className="textWrap">
                  <Grid item className="textWrap2">
                    <Typography variant="body2" className="spl-typo w-30">
                      <img
                        src={passwordCheck}
                        className="sp-icon"
                        width={16}
                        height={16}
                        alt=""
                      />
                      New Password
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        placeholder="Enter new password"
                        type={showNewPassword ? "text" : "password"}
                        className="spl-input"
                        value={newPassword}
                        disabled={edit === "password" ? false : true}
                        onChange={handleNewPasswordChange}
                      />

                      <IconButton
                        onClick={() =>
                          handleClickShowPassword(setShowNewPassword)
                        }
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>

                    {errors.newPassword && (
                      <Typography variant="caption" color="error">
                        {errors.newPassword}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid item className="textWrap">
                  <Grid item className="textWrap2">
                    <Typography variant="body2" className="spl-typo w-30">
                      <img
                        src={passwordCheck}
                        className="sp-icon"
                        width={16}
                        height={16}
                        alt=""
                      />
                      Re Enter New Password
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        placeholder="Confirm new password"
                        type={showConfirmPassword ? "text" : "password"}
                        className="spl-input"
                        value={confirmPassword}
                        disabled={edit === "password" ? false : true}
                        onChange={handleConfirmPasswordChange}
                      />
                      <IconButton
                        onClick={() =>
                          handleClickShowPassword(setShowConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                    {errors.confirmPassword && confirmPassword && (
                      <Typography variant="caption" color="error">
                        {errors.confirmPassword}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid item className="textWrap"></Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      {resetLoading ? <Loader /> : null}
      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
    </>
  );
}

export default SettingsPasswordLogin;
