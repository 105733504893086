import { Navigate } from "react-router-dom";

function RedirectIfAuthenticated({ children }) {
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  
  if (token) {
    return <Navigate to={`/dashboard`} replace />;
  }

  return children;
}

export default RedirectIfAuthenticated;
