import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NewLayout from "../../Components/SidebarLayout";
import {
  ApiService,
  CurrencySymbol,
  getBlobFile,
  uploadSingleBlobFile,
  useApi,
  useRolePermissions,
  CustomQuill,
} from "../../Utilities";
import RenderRazorpay from "../../Components/razorpay";
import CircularProgress from "@mui/material/CircularProgress";
import { eNums } from "../../constants";

function ServiceDetails() {
  const Permissions = useRolePermissions();
  const { state } = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [imageLoading, setImageLoading] = useState(false);
  const [previewBannerUrl, setPreviewBannerUrl] = useState(null);
  const [previewThumbnailUrl, setPreviewThumbnailUrl] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [activeSection, setActiveSection] = useState("Overview");

  const {
    action: updateService,
    snackbarComponent: updateServiceSnackbarComponent,
    error: updateServiceError,
    loading: updateServiceLoading,
  } = useApi(ApiService.updateService, {
    success: true,
    error: true,
    actionName: "File updating",
  });

  const {
    action: createOrder,
    data: orderData,
    loading: orderCreateLoading,
    snackbarComponent,
  } = useApi(ApiService.createOrder, {
    success: true,
    error: true,
    actionName: "Order created",
    additionalActionName: "Please wait for a while to make payment..!",
  });
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });

  // Refs for each section
  const overviewRef = useRef(null);
  const benefitsRef = useRef(null);
  const documentsRef = useRef(null);
  const deliverablesRef = useRef(null);

  useEffect(() => {
    if (state?.serviceDetails?._id) {
      setSelectedService(state?.serviceDetails);
      if (state?.serviceDetails?.banner) {
        (async () => {
          setImageLoading("banner");
          const imgUrl = await getBlobFile(state?.serviceDetails?.banner);
          setPreviewBannerUrl(imgUrl);
          setImageLoading("");
        })();
      } else {
        setPreviewBannerUrl("");
      }
      if (state?.serviceDetails?.thumbnail) {
        (async () => {
          setImageLoading("thumbnail");
          const imgUrl = await getBlobFile(state?.serviceDetails?.thumbnail);
          setPreviewThumbnailUrl(imgUrl);
          setImageLoading("");
        })();
      } else {
        setPreviewThumbnailUrl("");
      }
      if (state?.previous_route_path) {
        localStorage.setItem("previous_route_path", state?.previous_route_path);
      }
    } else {
      let previous_route_path = localStorage.getItem("previous_route_path");
      if (previous_route_path) {
        navigate(`/${previous_route_path}`);
        localStorage.removeItem("previous_route_path");
      }
    }
  }, [state]);

  const handleCreateOrder = async (serviceId, amount, currency) => {
    await createOrder({
      amountReceived: amount,
      currency,
      userID: userId,
      invoiceGeneratedOn: new Date(),
      serviceID: serviceId
    });
  };

  useEffect(() => {
    if (orderData && orderData?.razorPay?.id) {
      setOrderDetails({
        orderId: orderData?.razorPay?.id,
        currency: orderData?.razorPay?.currency,
        amount: orderData?.razorPay?.amount,
      });
      setDisplayRazorpay(true);
    }
  }, [orderData]);

  const paths = [
    {
      label: `${state?.previous_route_name || ""}`,
      navigation: `${state?.previous_route_path}` || "",
    },
    { label: `${selectedService?.serviceName}` || "" },
  ];

  // Scroll and highlight logic
  const scrollToSection = (section) => {
    const sectionRefs = {
      Overview: overviewRef,
      Benefits: benefitsRef,
      Documents: documentsRef,
      Deliverables: deliverablesRef,
    };

    // Scroll to the selected section
    sectionRefs[section]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest", // Prevents UI height disturbance
      inline: "nearest", // Adjust horizontal alignment if needed
    });

    // Update active section
    setActiveSection(section);
  };

  const Section = ({ title, content, sectionRef }) =>
    content ? (
      <div className="wrap-sd1" ref={sectionRef}>
        <div onClick={() => scrollToSection(title)}>
          <Typography fontWeight="bold" fontSize={"16px"} color={"#303030"}>
            {title}
          </Typography>
        </div>
        <Typography
          style={{ fontSize: "14px", color: "#808080", fontWeight: 400 }} letterSpacing={0}
          className="mt-2"
        >
            <CustomQuill 
              value={content}
              readOnly={true}
              modules={{ toolbar: false }}
              theme={null}
            />
        </Typography>
      </div>
    ) : null;

  const sections = [
    {
      title: "Overview",
      content: selectedService?.serviceContext?.overview?.[0]?.title,
      ref: overviewRef,
    },
    {
      title: "Benefits",
      content: selectedService?.serviceContext?.benefits?.[0]?.title,
      ref: benefitsRef,
    },
    {
      title: "Documents",
      content: selectedService?.serviceContext?.documents?.[0]?.title,
      ref: documentsRef,
    },
    {
      title: "Deliverables",
      content: selectedService?.serviceContext?.deliverables?.[0]?.title,
      ref: deliverablesRef,
    },
  ];

  const handleFileUpload = async (e) => {
    setImageLoading(true);
    const { previewUrl, res } = await uploadSingleBlobFile(e);
    if (previewUrl && res?.fileName) {
      setPreviewBannerUrl(previewUrl);
      await updateService({ banner: res?.fileName }, selectedService?._id);
    }
    setImageLoading(false);
  };

  const handleThumbnailFileUpload = async (e) => {
    setImageLoading(true);
    const { previewUrl, res } = await uploadSingleBlobFile(e);
    if (previewUrl && res?.fileName) {
      setPreviewThumbnailUrl(previewUrl);
      await updateService({ thumbnail: res?.fileName }, selectedService?._id);
    }
    setImageLoading(false);
  };

  return (
    <NewLayout paths={paths} pageHeading={selectedService?.serviceName}>
      {snackbarComponent}
      {updateServiceSnackbarComponent}
      <Grid className="d-flex blue-block-container gap-3">
        {" "}
        <label className="cursor-pointer w-75">
          {imageLoading === "banner" ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <CircularProgress />
            </div>
          ) : previewBannerUrl ? (
            <img
              src={previewBannerUrl}
              alt=""
              className="w-100 h-100 rounded"
            />
          ) : (
            <Grid item className="blue-block-ms"></Grid>
          )}
          {userType !== eNums?.CLIENT && Permissions?.serviceManagement?.submodules?.serviceDetails?.enabled && Permissions?.serviceManagement?.submodules?.serviceDetails?.permission?.includes(eNums?.VIEW) ? (
            <input
              type="file"
              id="fileInput"
              className="ld-inp-1 d-none"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => handleFileUpload(e)}
            />
          ) : null}
        </label>
        <label className="cursor-pointer w-25">
          {imageLoading === "thumbnail" ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <CircularProgress />
            </div>
          ) : previewThumbnailUrl ? (
            <img
              src={previewThumbnailUrl}
              alt=""
              className="w-100 h-100 rounded"
            />
          ) : (
            <Grid item className="blue-block-ms"></Grid>
          )}
          {userType !== eNums?.CLIENT && Permissions?.serviceManagement?.submodules?.serviceDetails?.enabled && Permissions?.serviceManagement?.submodules?.serviceDetails?.permission?.includes(eNums?.EDIT) ? (
            <input
              type="file"
              id="fileInput"
              className="ld-inp-1 d-none"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => handleThumbnailFileUpload(e)}
            />
          ) : null}
        </label>
      </Grid>

      <Grid className="d-flex align-items-center justify-content-center w-75 flex-wrap my-3 position-sticky top-0 bg-white">
        {selectedService?.serviceContext?.overview?.[0]?.title ? (
          <div
            className={`sd-text-wrap ${
              activeSection === "Overview" ? "sd-text-wrap-active" : ""
            }`}
            onClick={() => scrollToSection("Overview")}
          >
            <Typography>Overview</Typography>
          </div>
        ) : null}
        {selectedService?.serviceContext?.benefits?.[0]?.title ? (
          <div
            className={`sd-text-wrap ${
              activeSection === "Benefits" ? "sd-text-wrap-active" : ""
            }`}
            onClick={() => scrollToSection("Benefits")}
          >
            <Typography>Benefits</Typography>
          </div>
        ) : null}
        {selectedService?.serviceContext?.documents?.[0]?.title ? (
          <div
            className={`sd-text-wrap ${
              activeSection === "Documents" ? "sd-text-wrap-active" : ""
            }`}
            onClick={() => scrollToSection("Documents")}
          >
            <Typography>Documents</Typography>
          </div>
        ) : null}
        {selectedService?.serviceContext?.deliverables?.[0]?.title ? (
          <div
            className={`sd-text-wrap ${
              activeSection === "Deliverables" ? "sd-text-wrap-active" : ""
            }`}
            onClick={() => scrollToSection("Deliverables")}
          >
            <Typography>Deliverables</Typography>
          </div>
        ) : null}
      </Grid>

      <Grid container>
        <div className="d-flex w-100">
          <Grid className="view-service-details-container w-75 d-flex me-2 flex-column px-5 py-3 gap-3">
            {sections.map((section) => (
              <Section
                key={section.title}
                title={section.title}
                content={section.content}
                sectionRef={section.ref}
              />
            ))}
          </Grid>

          <div className="d-flex flex-column flex-wrap w-25 gap-2">
            <div
              className="d-flex flex-column flex-wrap gap-2 rounded border px-3 py-2"
              style={{ borderColor: "#d7d7d7" }}
            >
              <Typography fontWeight={600} fontSize={"16px"}>
                Category
              </Typography>
              <span className="service-card-serviceType-cont">
                {selectedService?.serviceType
                  ? selectedService?.serviceType
                  : "-"}
              </span>
            </div>
            <div
              className="d-flex flex-wrap gap-2 rounded border px-3 py-2"
              style={{ borderColor: "#d7d7d7" }}
            >
              <Typography fontWeight={600} fontSize={"16px"}>
                Price:
              </Typography>
              <Typography fontWeight={400} fontSize={"16px"}>
                <CurrencySymbol amount={selectedService?.priceTag} />
              </Typography>
            </div>
            {userType === eNums?.CLIENT ? (<>
            <button className="request-call-back-btn mt-3 py-3">Request Callback</button>

            <button
                className="btn-service-details py-3"
                onClick={() =>
                  handleCreateOrder(
                    selectedService?._id,
                    selectedService?.priceTag,
                    "INR"
                  )
                }
                disabled={orderCreateLoading}
              >
                {orderCreateLoading ? "Loading..." : "Buy Now"}
              </button>
              </>) : null}
          </div>
        </div>
      </Grid>

      {userType === eNums?.CLIENT && displayRazorpay ? (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          returnFunction={() => setDisplayRazorpay(false)}
        />
      ) : null}
    </NewLayout>
  );
}

export default ServiceDetails;
