import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import icon1 from "../../assets/images/tick_blue.svg";
import NewLayout from "../../Components/SidebarLayout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { ApiService, dateFormatter, ToolTip, useApi, useRolePermissions } from "../../Utilities";
import EmptyStateImg from '../../assets/images/empty_state.svg'
import RightGreyArrow from "../../assets/images/right_arrow_grey.svg";
import setUpImg from "../../assets/images/phone-setup-page.svg";
import headPhone from "../../assets/images/headphone.png";
import moment from "moment";
import dayjs from "dayjs";
import { Placeholder } from "react-bootstrap";

function DashBoard() {
  let navigate = useNavigate();
  const Permissions = useRolePermissions();
  const userID = localStorage.getItem("userId");
  const userName = localStorage.getItem("user_name");

  const [date, setDate] = useState(dayjs());
  const [events, setEvents] = useState(null);

  const {
    action: getMyServices,
    data: services,
  } = useApi(ApiService.getMyServices);

  const {
      action: getAllEvents,
      data: allEvents,
      loading: eventsLoading
    } = useApi(ApiService.getAllEvents);

  useEffect(() => {
    getMyServices({ userID, recent: true, skipValue: 0, limitValue: 2 });
    getAllEvents({ fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(new Date()).format('YYYY-MM-DD'), skipValue: 0, limitValue: 2})
  }, [userID])

  useEffect(() => {
    if(allEvents?.length){
      setEvents(allEvents)
    }
    else{
      setEvents([])
    }
  }, [allEvents])

  const PieChartData = [
    { name: "Active Services", value: 400 },
    { name: "Processing Services", value: 300 },
    { name: "Completed Services", value: 300 },
    { name: "Closed Services", value: 200 },
  ];
  const PieChartCOLORS = ["#1E68F9", "#429FFF", "#81BFFF", "#C2E0FF"];

  const options = [
    { label: "Today", value: "today" },
    { label: "Last Week", value: "7days" },
    { label: "Last Month", value: "30days" },
    { label: "This Year", value: "365days" },
  ];

  const paths = [{ label: "Dashboard", navigation: "dashboard" }];

  const handleClickEmail = () => {
    window.location.href = "mailto:support@filenow.com";
  };
  
  const handleCalenderDateChange = (value) => {
    setDate(value);
    getAllEvents({ fromDate: (value).format('YYYY-MM-DD'), toDate: (value).format('YYYY-MM-DD'), skipValue: 0, limitValue: 2})
  };

  return (
    <>
      <Grid container className="d-flex">
        <NewLayout pageHeading={"Dashboard"} paths={paths}>
          <Grid item container>
            <Grid item>
              <div className="setup-banners">
                <div className="setup-g1 setup-banners-1 px-5">
                  <Typography variant="h5" fontWeight={700} fontSize="20px">
                    {"10,00,000 + "}
                  </Typography>
                  <Typography variant="h5" fontWeight={700} fontSize="20px">
                    Members
                  </Typography>
                  <Typography
                    variant="subtitle"
                    fontWeight={500}
                    fontSize="16px"
                  >
                    Connect with Charted Accountants &<br />
                    Articleships from all over India
                  </Typography>
                </div>

                <Grid className="setup-g1 setup-banners-2 ms-3">
                  <img src={setUpImg} className="setup-img1" alt="" />
                </Grid>
              </div>
              <div className="w-100 d-flex gap-3 ">
                <div className="py-4 setup-banners-1 d-flex flex-wrap justify-content-between">
                  <div className="dashboard-client-card overflow-y-auto overflow-x-hidden db-tb1">
                    <Grid container className="db-tb-cell">
                      <Grid item className="db-c-a">
                        <span>Service Status</span>
                        <span className="ms-2 mb-1">
                          <ToolTip info={"Service Status"} />
                        </span>
                      </Grid>
                    </Grid>
                    <ResponsiveContainer width="100%" height={250}>
                      <div className="d-flex align-items-center justify-content-evenly flex-wrap my-auto">
                        <PieChart width={200} height={250} className="ms-2">
                          <Pie
                            data={PieChartData}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                          >
                            {PieChartData?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  PieChartCOLORS[index % PieChartCOLORS?.length]
                                }
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>

                        <div className="d-flex flex-column flex-wrap mx-auto justify-content-center">
                          {PieChartData?.map((entry, index) => (
                            <div className="d-flex align-items-center gap-3">
                              <span
                                className={`fill-service-status`}
                                style={{
                                  backgroundColor:
                                    PieChartCOLORS[
                                      index % PieChartCOLORS.length
                                    ],
                                }}
                              ></span>
                              <div className="d-flex">
                                <span className="service-status-key">
                                  {entry?.name} -
                                </span>
                                <span className="service-status-value ms-1">
                                  {entry?.value}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </ResponsiveContainer>
                  </div>

                  <div className="recent-services-card db-tb1 d-flex flex-column gap-3">
                    <Grid container className="db-tb-cell w-auto">
                      <Grid item className="db-c-a">
                        <span className="d-flex align-items-center">
                          <img src={icon1} alt="" width={22} height={22} />{" "}
                          <span className="ms-2">Recent Services</span>
                        </span>
                        <span className="ms-2 mb-1">
                          <ToolTip info={"Recent Services"} />
                        </span>
                      </Grid>
                      <span
                        className="db-c-b"
                        onClick={() => navigate("/my-services")}
                      >
                        View All
                      </span>
                    </Grid>

                    <div className="px-5 d-flex flex-column gap-3 my-auto">
                    {services?.length ? <>
                      <span className="d-flex w-100 align-items-center gap-2 recent-services-title">
                        <Typography fontSize={"32px"} fontWeight={700}>
                          {services?.length || 0}
                        </Typography>{" "}
                        Services
                      </span>
                      
                      {services?.map((item) => (
                            <div className="recent-services-data-card d-flex py-2 px-4 justify-content-between align-items-center">
                              <Grid className="d-flex flex-column gap-1">
                                <span className="recent-services-data-card-title">
                                  {item?.registeredServices?.[0]?.serviceName ||
                                    "-"}
                                </span>
                                <span className="recent-services-data-card-sub-title">
                                  {`Category - ${
                                    item?.registeredServices?.[0]
                                      ?.categoryName || "-"
                                  }`}
                                </span>
                              </Grid>

                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  navigate(`/customer-management`, {
                                    state: {
                                      user: {
                                        _id: userID,
                                        fullname: userName,
                                        serviceID: item?._id,
                                      },
                                      previous_route_name: "Dashboard",
                                      previous_route_path: "dashboard",
                                      serviceName:
                                        item?.registeredServices?.[0]
                                          ?.serviceName,
                                    },
                                  })
                                }
                              >
                                <img src={RightGreyArrow} alt="" />
                              </div>
                            </div>
                          ))
                        }
                        </> : <img src={EmptyStateImg} className='img img-fluid justify-content-center d-flex mx-auto' alt='' width={200} height={10}/>}
                    </div>
                  </div>

                  <div className="dashboard-client-card mt-3 db-tb1 d-flex flex-column gap-4">
                    <Grid container className="db-tb-cell">
                      <Grid item className="db-c-a">
                        <span className="d-flex align-items-center">
                          <span>Need an Expert ?</span>
                        </span>
                      </Grid>
                    </Grid>

                    <div className="px-4 d-flex flex-column gap-3 my-auto">
                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"Talk to a lawyer"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>

                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"Talk to a CA"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>

                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"Talk to a CS"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="recent-services-card mt-3 db-tb1 d-flex flex-column gap-3">
                    <Grid container className="db-tb-cell">
                      <Grid item className="db-c-a">
                        <span className="d-flex align-items-center">
                          <span>Renewals</span>
                        </span>
                      </Grid>
                    </Grid>

                    <div className="px-4 d-flex flex-column gap-3 my-auto">
                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"DSC for T R Charan Kumar"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>

                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"DSC for Karthik"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>

                      <div className="need-help-card d-flex py-2 px-4 justify-content-between align-items-center">
                        <span className="need-help-card-title">
                          {"DSC for Srrenivas"}
                        </span>
                        <img
                          src={RightGreyArrow}
                          alt=""
                          className="cursor-pointer"
                          // onClick={() => navigate(`/${item?.navigation}`)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-25 pt-3 d-flex flex-column gap-3">
                  <div className="h-100 db-tb1 w-auto">
                    <Grid container className="db-tb-cell w-auto mt-1">
                      <Grid item className="db-c-a">
                        <span className="d-flex align-items-center">
                          <img src={icon1} alt="" width={22} height={22} />{" "}
                          <span className="ms-2">Compliance Calendar</span>
                        </span>
                        <span className="ms-2 mb-1">
                          <ToolTip info={"Compliance Calendar"} />
                        </span>
                      </Grid>
                      <span
                        className="db-c-b"
                        onClick={() => navigate("/compliance-calendar")}
                      >
                        View All
                      </span>
                    </Grid>
                    <Grid container className="ad-cent my-auto">
                      <Grid item className="cont-db-1 mt-5">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={date}
                            onChange={(newValue) =>
                              handleCalenderDateChange(newValue)
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                      <div className="cont-db-2">
                        {eventsLoading ? (
                          [...Array(1)]?.map((a, i) => (
                            <Placeholder
                              className="w-100 placeholder-background rounded"
                              animation="wave"
                              style={{ height: "25px" }}
                            />
                          ))
                        ) : events?.length ? (
                          events?.map((event) => (
                            <>
                              <Typography
                                fontSize="14px"
                                fontWeight={"700"}
                                color={"#545969"}
                              >
                                {dateFormatter(event?.dueDate) || "Due"} -
                                <span className="db-cal-text">
                                  {" "}
                                  {event?.eventName || "Event"}
                                </span>
                              </Typography>
                            </>
                          ))
                        ) : (
                          <>No Events</>
                        )}
                      </div>
                    </Grid>
                  </div>

                  <Grid item className="setup-g2-b1 p-2">
                    <Grid container className="align-items-center">
                      <Grid item className="setup-g2-b11">
                        <img src={headPhone} alt="" width={20} height={20} />
                      </Grid>
                      <Grid item className="setup-g2-b11">
                        <Typography
                          fontWeight={600}
                          fontSize={"14px"}
                          color={"#00001B"}
                          className="mt-1"
                        >
                          Need Assistance ?
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className="p-1">
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        fontSize={"12px"}
                        color={"#636A76"}
                        className="d-flex flex-wrap align-items-center"
                      >
                        Write to us at&nbsp;
                        <Typography
                          color={"#121212"}
                          fontSize={"12px"}
                          fontWeight={600}
                        >
                          {" "}
                          support@filenow.com.
                        </Typography>
                        We are available 24*5 (Monday - Friday).
                      </Typography>
                    </Grid>
                    <Grid container className="setup-button gap-2">
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          className="emailus-btn"
                          onClick={handleClickEmail}
                        >
                          Email Us
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          className="emailus-btn"
                        >
                          Request Callback
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </NewLayout>
      </Grid>
    </>
  );
}

export default DashBoard;
