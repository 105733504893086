import React from 'react'
import { eNums } from '../../constants'
import ClientDetails from './ClientDetails'
import ClientOrderManagement from './orderManagement'

const OrderandPayments = () => {
    const role_level = Number(localStorage.getItem('role_level'))
   
    return role_level > 3 ? <ClientOrderManagement/> : <ClientDetails />
}

export default OrderandPayments