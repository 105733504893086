import React, { useEffect, useState } from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import RenderRazorpay from "./razorpay";
import { useNavigate } from "react-router-dom";
import { getBlobFile, ApiService, useApi, CurrencySymbol } from "../Utilities";
import { eNums } from "../constants";

function ServicesCard({ data, route_path, route_path_name }) {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [previewUrl, setPreviewUrl] = useState(null);

  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const {
    action: createOrder,
    data: orderData,
    loading: orderCreateLoading,
    snackbarComponent,
  } = useApi(ApiService.createOrder, {
    success: true,
    error: true,
    actionName: "Order created",
    additionalActionName: "Please wait for a while to make payment..!",
  });

  const handleCreateOrder = async (serviceId, amount, currency) => {
    await createOrder({
      amountReceived: parseFloat(amount),
      currency,
      userID: userId,
      invoiceGeneratedOn: new Date(),
      serviceID: serviceId,
    });
  };

  useEffect(() => {
    if (orderData && orderData?.razorPay?.id) {
      setOrderDetails({
        orderId: orderData?.razorPay?.id,
        currency: orderData?.razorPay?.currency,
        amount: parseFloat(orderData?.razorPay?.amount),
      });
      setDisplayRazorpay(true);
    }
  }, [orderData]);

  const handleRazorpayClose = () => {
    setDisplayRazorpay(false);
  };

  useEffect(() => {
    if (data?.thumbnail) {
      (async function () {
        const imgUrl = await getBlobFile(data?.thumbnail);
        setPreviewUrl(imgUrl);
      })();
    }
  }, [data]);
  
  return (
    <>
      {snackbarComponent}
      <Grid className="servicesWrap">
        <div className="serviceCard cursor-pointer d-flex flex-column justify-content-between">
          <div
            className="d-flex flex-column justify-content-between h-100 cursor-pointer"
            onClick={() =>
              navigate(`/service-details`, {
                state: {
                  serviceDetails: data,
                  previous_route_name: route_path_name,
                  previous_route_path: route_path,
                },
              })
            }
          >
            <div className="cursor-pointer d-flex flex-column mx-auto my-1 gap-2">
              <div className="service-img-placeholder">
                {previewUrl ? (
                  <img src={previewUrl} className="w-100 h-100 rounded" alt="" />
                ) : null}
              </div>
              <Grid container>
                <Tooltip title={data?.serviceName} placement="top">
                  <Typography
                    style={{
                      fontWeight: "600",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "0.85em",
                      color: "##000012",
                    }}
                  >
                    {data?.serviceName}
                  </Typography>
                </Tooltip>
              </Grid>
            </div>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <div className="service-card-starts-cont">
                <span className="service-card-starts-txt">Starts @</span>

                <span className="ms-1 fw-bold">
                  <CurrencySymbol amount={data?.priceTag || data?.discount}/>
                </span>
              </div>

              <span className="service-card-serviceType-cont">
                {data?.serviceType ? data?.serviceType?.slice(0, 3) : "Bus"}
              </span>
            </div>
          </div>

          {userType === eNums?.CLIENT ? (
            <div className="d-flex align-items-center gap-1 mt-2 me-2 justify-content-between flex-wrap">
              <button
                type="button"
                disabled={orderCreateLoading}
                className="services-btn cursor-pointer"
                onClick={() =>
                  handleCreateOrder(data?._id, data?.priceTag, "INR")
                }
              >
                {orderCreateLoading ? "Loading..." : "Buy Now"}
              </button>
              <Typography
                fontWeight={500}
                fontSize={14}
                color={"#2770FF"}
                onClick={() =>
                  navigate(`/service-details`, {
                    state: {
                      serviceDetails: data,
                      previous_route_name: route_path_name,
                      previous_route_path: route_path,
                    },
                  })
                }
              >
                {"Details >"}
              </Typography>
            </div>
          ) : null}
        </div>
        {userType === eNums?.CLIENT && displayRazorpay && (
          <RenderRazorpay
            amount={orderDetails.amount}
            currency={orderDetails.currency}
            orderId={orderDetails.orderId}
            returnFunction={handleRazorpayClose}
          />
        )}
      </Grid>
    </>
  );
}

export default ServicesCard;
