import { Navigate } from "react-router-dom";
import { eNums } from "../../constants";

function ProtectedRoute ({ children, allowedUserTypes  })  {
    const token = localStorage.getItem("token");
    const userType = localStorage.getItem('userType')
    if (!token) {
      return <Navigate to={`/${userType?.includes(eNums?.AGENT)} ? 'agent/' : signIn`} replace />;
    }
    if(allowedUserTypes && !(allowedUserTypes?.includes(userType))){
      return <Navigate to="/dashboard" replace />;
    }
  
    return children;
  }

export default ProtectedRoute;
