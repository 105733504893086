import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@mui/material";
import People from "../assets/images/people.svg";
import RoleIcon from "../assets/images/role_icon.svg";
import RoleIconhighlight from "../assets/images/role_icon_highlight.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  useApi,
  EmptyState,
  ApiService,
  CustomizedSnackbar,
  useRolePermissions,
} from "../Utilities";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import { rolePermissionsData } from "../constants";

function SettingsRoleCreation() {
  const Permissions = useRolePermissions();
  const [rolesPayload, setRolesPayload] = useState([]);
  const [roleEditId, setRoleEditId] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [isChangesMade, setIsChanged] = useState(false);
  const [rolePermissions, setRolePermissions] = useState(
    rolePermissionsData || []
  );
  const [submodules, setSubmodules] = useState(
    rolePermissionsData?.[0]?.submodules || []
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate();
  const {
    action: createRole,
    snackbarComponent: createSnackbarComponent,
    error: createRoleError,
    loading: createRoleLoading,
  } = useApi(ApiService.createRole, {
    success: true,
    error: true,
    actionName: "Role creating",
  });
  const {
    action: updateRole,
    snackbarComponent: updateSnackbarComponent,
    error: updateRoleError,
    loading: updateRoleLoading,
  } = useApi(ApiService.updateRole, {
    success: true,
    error: true,
    actionName: "Role updating",
  });
  const {
    action: deleteRole,
    snackbarComponent: deleteSnackbarComponent,
    loading: deleteRoleLoading,
  } = useApi(ApiService.deleteRole, {
    success: true,
    error: true,
    actionName: "Role deleting",
  });
  const {
    action: getAllRoles,
    data: roles,
    loading: roleLoading,
    snackbarComponent: getAllRolesSnackbarComponent,
  } = useApi(ApiService.getAllRoles, {
    success: true,
    error: true,
    actionName: "Roles fetching",
  });

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (roles?.length && !(rolesPayload?.filter((item => item?.clicked))?.length)) {
      handleClickRole(roles, 0);
    } else {
      if(!roles?.length){
        setRolesPayload([]);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (
      loading &&
      !createRoleLoading &&
      !createRoleError &&
      !updateRoleLoading &&
      !updateRoleError
    ) {
      getAllRoles();
      setRoleEditId("");
      setIsLoading(false);
      setIsChanged(false)
    }
  }, [loading, roleEditId, createRoleError, updateRoleError]);

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddRole = () => {
    let updatedData = rolesPayload.map((item) => {
      return { ...item, clicked: false };
    });

    // Check if the last item in updatedData has a name
    if (
      updatedData.length === 0 ||
      updatedData[updatedData.length - 1]?.saved
    ) {
      let obj = {
        name: "",
        clicked: true,
        edit: false,
        saved: false,
        createdOn: new Date(),
        updatedOn: new Date(),
        permissions: rolePermissionsData,
      };
      updatedData.push(obj);
    } else {
      updatedData[updatedData.length - 1] = {
        ...updatedData[updatedData.length - 1],
        clicked: true,
        createdOn: new Date(),
        updatedOn: new Date(),
      };
      handleSnackbar("Please save current role", "error");
    }

    setRolesPayload(updatedData);
  };

  const handleSubmitRole = async () => {
    if (rolesPayload?.length) {
      if (
        rolesPayload &&
        rolesPayload?.filter(
          (item) => !item?.saved || item?.edit || item?.clicked
        )?.length
      ) {
        if (!roleEditId && rolesPayload?.find((item) => !item?.saved)?.length) {
          let obj = rolesPayload?.find((item) => !item?.saved);
          if (!obj?.name) {
            handleSnackbar("Please enter name to save", "error");
            return;
          } else {
            obj.permissions = rolePermissions.reduce((acc, module) => {
              acc[module.key] = {
                enabled: module.enabled,
                submodules: module.submodules.reduce((subAcc, submodule) => {
                  subAcc[submodule.key] = {
                    permission: submodule?.value,
                    enabled: submodule?.enabled,
                  };
                  return subAcc;
                }, {}),
              };
              return acc;
            }, {}); 
            await createRole(obj);
            setIsLoading(true);
          }
        } else {
          let obj = rolesPayload?.find((item) => item?.edit || item?.clicked);
          if (!obj?.name) {
            handleSnackbar("Please enter name to save", "error");
            return;
          } else {
            obj.permissions = rolePermissions.reduce((acc, module) => {
              acc[module.key] = {
                enabled: module.enabled,
                submodules: module.submodules.reduce((subAcc, submodule) => {
                  subAcc[submodule.key] = {
                    permission: submodule?.value,
                    enabled: submodule?.enabled,
                  };
                  return subAcc;
                }, {}),
              };
              return acc;
            }, {}); 
            await updateRole(obj, roleEditId || obj?._id);
            setIsLoading(true);
          }
        }
      } else {
        handleSnackbar("No role selected to save", "error");
      }
    }
  };

  const handleDeleteRole = async (id, index) => {
    if (id) {
      await deleteRole(id);
      await getAllRoles();
    } else {
      const updatedData = rolesPayload?.filter((_, i) => i !== index);

      setRolesPayload(updatedData);
    }
    setRoleEditId("");
  };

  const handleRoleName = (value, index) => {
    rolesPayload[index].name = value;
  };

  const handleEditRole = (id, index) => {
    setRoleEditId(id);
    let updatedData =
      (rolesPayload &&
        rolesPayload?.length &&
        rolesPayload.map((item, i) => ({
          ...item,
          edit: i === index ? true : false,
        }))) ||
      [];

    setRolesPayload(updatedData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSubmitRole();
  };
  
  const handleClickRole = (rolesData, index) => {
    // Step 1: Update the clicked state in rolesPayload
    const updatedRolesPayload = rolesData?.map((item, i) => ({
      ...item,
      clicked: i === index ? true : false,
      edit: false,
      saved: true,
    }));

    setRolesPayload(updatedRolesPayload);

    // Step 2: Get the permissions of the clicked role
    const permissions = updatedRolesPayload[index]?.permissions;
    if (!permissions) {
      console.error("Permissions not found for the clicked role.");
      return;
    }

    // Parse the permissions object (assuming it's already a valid object or JSON string)
    let parsedPermissions;
    try {
      parsedPermissions =
        typeof permissions === "string" ? JSON.parse(permissions) : permissions;
    } catch (error) {
      console.error("Failed to parse permissions:", permissions, error);
      return; // Exit the function if parsing fails
    }
    
    // Step 3: Update rolePermissions with the parsed permissions
    const updatedRolePermissions = rolePermissions.map((module) => {
      // Find the corresponding permission module from parsedPermissions
      const permissionModule = parsedPermissions?.[module.key];
    
      return {
        ...module,
        enabled: permissionModule?.enabled ?? module.enabled, // Preserve original if not found
        submodules: module.submodules.map((sub) => {
          // Find the corresponding submodule permission
          const permissionSubmodule = permissionModule?.submodules?.[sub.key];
    
          return {
            ...sub,
            enabled: permissionSubmodule?.enabled ?? sub.enabled, // Preserve submodule enabled
            value: permissionSubmodule?.permission ?? sub.value, // Preserve original value if not found
          };
        }),
      };
    });
    
    setRolePermissions(updatedRolePermissions);
  };
  
  const handleClickModule = (key) => {
    let updatedData = rolePermissions?.map((item) => {
      return { ...item, clicked: item?.key === key ? true : false };
    });
    setRolePermissions(updatedData);
    let submodulesArray =
      rolePermissions?.find((item) => item?.key === key)?.submodules || [];
    setSubmodules(submodulesArray);
  };

  const paths = [
    { label: "Home", navigation: "dashboard" },
    { label: "Settings", navigation: "settings" },
    { label: "Role Creation and Access" },
  ];

  const handleChangePermission = (type, keyVal, roleID, val) => {
    setIsChanged(true)
    // Find the module where `clicked` is true
    const moduleKey = rolePermissions?.find((item) => item?.clicked)?.key;

    // Create a shallow copy of rolePermissions
    const updatedRolePermissions = [...rolePermissions];

    // Find the module that matches the `moduleKey`
    const moduleIndex = updatedRolePermissions.findIndex(
      (module) => module.key === moduleKey
    );

    if (moduleIndex !== -1) {
      // Find the submodule within the module where the `key` matches `keyVal`
      const submoduleIndex = updatedRolePermissions[
        moduleIndex
      ].submodules.findIndex((submodule) => submodule.key === keyVal);

      if (submoduleIndex !== -1) {
        // Update the value of the matched submodule
        updatedRolePermissions[moduleIndex].submodules[submoduleIndex][type] =
          val;
      }
    }

    // Update rolePermissions state
    setRolePermissions(updatedRolePermissions);

    // Create a copy of rolesPayload
    const updatedRolesData = [...rolesPayload];

    // Find the role where _id matches roleID
    const roleIndex = updatedRolesData.findIndex((role) => role._id === roleID);

    if (roleIndex !== -1) {
      // Update the permissions for the matched role
      updatedRolesData[roleIndex].permissions = updatedRolePermissions?.reduce(
        (acc, module) => {
          acc[module.key] = {
            enabled: module.enabled,
            submodules: module.submodules.reduce((subAcc, submodule) => {
              subAcc[submodule.key] = {
                permission: submodule?.value,
                enabled: submodule?.enabled,
              };
              return subAcc;
            }, {}),
          };
          return acc;
        },
        {}
      );
    }

    // Update rolesPayload state
    setRolesPayload(updatedRolesData);
  };

  const handleModuleSwitch = (moduleKey, roleID, checked) => {

    setIsChanged(true)
    const updatedRolePermissions = [...rolePermissions];

    const moduleIndex = updatedRolePermissions.findIndex(
      (module) => module.key === moduleKey
    );

    if (moduleIndex !== -1) {
        updatedRolePermissions[moduleIndex].enabled = checked;
    }

    setRolePermissions(updatedRolePermissions);

    const updatedRolesData = [...rolesPayload];

    const roleIndex = updatedRolesData.findIndex((role) => role._id === roleID);

    if (roleIndex !== -1) {
      
      updatedRolesData[roleIndex].permissions = updatedRolePermissions?.reduce(
        (acc, module) => {
          acc[module.key] = {
            enabled: module.enabled,
            submodules: module.submodules.reduce((subAcc, submodule) => {
              subAcc[submodule.key] = {
                permission: submodule?.value,
                enabled: submodule?.enabled,
              };
              return subAcc;
            }, {}),
          };
          return acc;
        },
        {}
      );
    }

    setRolesPayload(updatedRolesData);
  }

  return (
    <>
      {createSnackbarComponent}
      {deleteSnackbarComponent}
      {updateSnackbarComponent}

      <Grid item className="d-flex flex-column gap-1">
        {paths?.length ? (
          <Grid item className="d-flex align-items-center pt-2">
            {paths?.map((item, index) => (
              <div>
                <span
                  className="main-path-text"
                  onClick={() => {
                    if (item?.navigation) {
                      navigate(`/${item?.navigation}`);
                    }
                  }}
                >
                  {item?.label}
                </span>
                <span className="mx-1">
                  {index !== paths?.length - 1 && "/"}
                </span>
              </div>
            ))}
          </Grid>
        ) : null}
        <Grid item>
          <h5 className="sp-typo d-flex align-items-center gap-2">
            <img src={People} alt="" width={20} height={20} />
            <span className="settings-subtitle">Role Creation and Access</span>
          </h5>
        </Grid>
      </Grid>
      <Grid className="sr-g-1">
        <Grid
          className="rg-col"
          sx={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}
        >
          <Grid className="sr-l-1 ms-4">
            <Typography letterSpacing={0} fontWeight={700} fontSize={16}>
              User Role
            </Typography>
            {/* <Button
              variant="contained"
              className="add-item-btn cursor-pointer d-flex align-items-center justify-content-center me-1"
              onClick={handleAddRole}
            >
              +
            </Button> */}
            {/* <button className="sr-b1 d-flex align-items-center justify-content-between cursor-pointer" onClick={handleAddRole}>{!createRoleLoading ? "+ Add" : '...'}</button> */}
          </Grid>
          <div className="border-bottom-gray" />
          <Grid className="sr-l-2">
            <Grid flexDirection="column" className="w-100 my-2">
              {rolesPayload && rolesPayload?.length ? (
                rolesPayload?.filter((role) => !role?.name?.includes('Client'))?.map((item, Cindex) => (
                  <form
                    className="d-flex flex-wrap py-1"
                    onSubmit={handleSubmit}
                  >
                    <Grid
                      className={`sr-l-2-t align-items-center ${
                        item?.clicked ? "bg-f0f0f0" : ""
                      } px-3 py-2`}
                    >
                      {!item?.saved || item?.edit ? (
                        <TextField
                          variant="standard"
                          className="w-70"
                          placeholder="Enter name"
                          defaultValue={item?.name}
                          style={{ borderBottom: "none" }}
                          onChange={(e) =>
                            handleRoleName(e.target.value, Cindex)
                          }
                        />
                      ) : (
                        <div
                          className="d-flex gap-2 flex-wrap align-items-center"
                          onClick={() => {
                            if(isChangesMade){
                              handleSubmitRole();
                            }
                            handleClickRole(rolesPayload, Cindex);
                          }}
                        >
                          <img
                            src={item?.clicked ? RoleIconhighlight : RoleIcon}
                            alt=""
                          />
                          <Typography
                            fontSize={"14px"}
                            fontWeight={500}
                            color={"#404040"}
                            letterSpacing={0}
                          >
                            {item?.name || ""}
                          </Typography>
                          {item?.isDefault ? (
                            <Typography
                              fontSize={"10px"}
                              fontWeight={400}
                              color={"#616161"}
                              letterSpacing={0}
                              backgroundColor={"#d9d9d9"}
                              className="px-2 my-1 rounded"
                            >
                              {"Default"}
                            </Typography>
                          ) : null}
                        </div>
                      )}
                      {item?.isDefault ? null : (
                        <div className={`sr-al`}>
                          <span
                            className="sr-del cursor-pointer"
                            onClick={() =>
                              handleDeleteRole(item?._id || "", Cindex)
                            }
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </span>
                          {item?.saved ? (
                            <span
                              className="sr-edit cursor-pointer"
                              onClick={() => handleEditRole(item?._id, Cindex)}
                            >
                              <BorderColorOutlinedIcon fontSize="small" />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Grid>
                  </form>
                ))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        {rolesPayload?.filter((item) => item?.clicked)?.length ? (
          <>
            <Grid className="rg-col">
              <Grid className="sr-l-1 ms-4">
                <Typography letterSpacing={0} fontWeight={700} fontSize={16}>
                  Feature Access
                </Typography>
              </Grid>
              <div className="border-bottom-gray" />

              <Grid className="border-bottom-gray">
                <Typography
                  letterSpacing={0}
                  fontWeight={600}
                  fontSize={14}
                  className="mx-4 py-1"
                >
                  Module
                </Typography>
              </Grid>

              {rolePermissions?.map((item, i) => (
                <Grid
                  className={`border-bottom-gray justify-content-between align-items-center d-flex ${
                    item?.clicked ? "bg-E4EBFF" : ""
                  }`}
                  key={item?.key}
                >
                  <div
                    className={`cursor-pointer d-flex flex-wrap align-items-center py-3 mx-4 gap-2`}
                    onClick={() => handleClickModule(item?.key)}
                  >
                    <img src={item?.imgSrc} alt="" className="ms-0" />
                    <Typography
                      fontSize={"14px"}
                      fontWeight={600}
                      color={"#404040"}
                      letterSpacing={0}
                    >
                      {item?.name || ""}
                    </Typography>
                  </div>
                  <Switch
                    checked={item?.enabled || false}
                    onChange={(e) => handleModuleSwitch(
                      item?.key,
                      rolesPayload?.find((role) => role?.clicked)?._id,
                      e.target.checked
                    )}
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              className="rg-col overflow-x-hidden"
              sx={{
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              {rolePermissions?.find((item) => item?.clicked)?.enabled ? <>
                <Grid className="sr-l-1 mx-4 w-100" />
                <div className="border-bottom-gray" />

                <Grid className="border-bottom-gray">
                  <Typography
                    letterSpacing={0}
                    fontWeight={600}
                    fontSize={14}
                    color={"#090E24"}
                    className="mx-4 py-1"
                  >
                    Sub Module
                  </Typography>
                </Grid>

                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color={"#090E24"}
                  letterSpacing={0}
                  className="mx-4 py-3"
                >
                  {rolePermissions?.find((item) => item?.clicked)?.name || ""}
                </Typography>
                <div className="d-flex align-items-center flex-wrap border-bottom-gray">
                  {submodules?.map((item, i) => (
                    <Grid className={`mx-4`} key={i}>
                      <div className="d-flex align-items-center gap-2">
                        <Typography
                          fontSize={"14px"}
                          fontWeight={500}
                          color={"#404040"}
                          letterSpacing={0}
                        >
                          {item?.name || ""}
                        </Typography>
                        <Switch
                          checked={rolesPayload?.find((role) => role?.clicked)?.permissions?.[
                            rolePermissions?.find((item) => item?.clicked)?.key
                          ]?.submodules?.[item?.key]?.enabled || false}
                          onChange={(e) => handleChangePermission(
                            "enabled",
                            item?.key,
                            rolesPayload?.find((role) => role?.clicked)?._id,
                            e.target.checked
                          )}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </div>
                      {item?.enabled ?
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="my-2"
                        value={
                          rolesPayload?.find((role) => role?.clicked)
                            ?.permissions?.[
                            rolePermissions?.find((item) => item?.clicked)?.key
                          ]?.submodules?.[item?.key]?.permission || ""
                        }
                        onChange={(e) =>
                          handleChangePermission(
                            'value',
                            item?.key,
                            rolesPayload?.find((role) => role?.clicked)?._id,
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="Edit"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 16,
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color={"#404040"}
                            >
                              Edit
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="View"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 16,
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color={"#404040"}
                            >
                              View
                            </Typography>
                          }
                        />
                      </RadioGroup> : null}
                    </Grid>
                  ))}
                </div>

                <Grid
                  container
                  className="align-items-center justify-content-end px-3 my-3 me-5"
                >
                  <Button
                    variant="contained"
                    className="save-btn cursor-pointer"
                    onClick={handleSubmitRole}
                  >
                    {!createRoleLoading || !updateRoleLoading
                      ? "Save"
                      : "Saving..."}
                  </Button>
                </Grid>
                <div className="border-bottom-gray" />
              </> : null}
            </Grid>
          </>
        ) : (
          <EmptyState />
        )}

        {snackbar?.open ? (
          <CustomizedSnackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          />
        ) : null}

        {createRoleLoading || updateRoleLoading || deleteRoleLoading ? (
          <Loader />
        ) : null}
      </Grid>
    </>
  );
}

export default SettingsRoleCreation;
