import FileNowLogo from "../../assets/images/File_Now_Logo.svg";
import HeaderImage from "../../assets/images/HeaderImage.svg";
import BrandLogo1 from "../../assets/images/Brand Logo 1.svg";
import BrandLogo2 from "../../assets/images/Dcoder Logo.svg";
import BrandLogo3 from "../../assets/images/School Easee Logo.svg";
import BrandLogo6 from "../../assets/images/Seqto Logo.svg";

import DataStorage from "../../assets/images/Data Storage.svg";
import Encription from "../../assets/images/Data-Security.svg";
import Privacy from "../../assets/images/Privacy.svg";

import whyFileNow1 from "../../assets/images/whyFileNow1.svg";
import whyFileNow2 from "../../assets/images/whyFileNow2.svg";
import whyFileNow3 from "../../assets/images/whyFileNow3.svg";
import whyFileNow4 from "../../assets/images/whyFileNow4.svg";
import whyFileNow5 from "../../assets/images/whyFileNow5.svg";
import whyFileNow6 from "../../assets/images/whyFileNow6.svg";
import whyFileNow7 from "../../assets/images/whyFileNow7.svg";
import whyFileNow8 from "../../assets/images/whyFileNow8.svg";
import whyFileNow9 from "../../assets/images/whyFileNow9.svg";

import facebook from "../../assets/images/Facebook.svg";

import quora from "../../assets/images/Quora.svg";
import twitter from "../../assets/images/Twitter.svg";
import LinkedIn from "../../assets/images/Linked In.svg";
import Instagram from "../../assets/images/Instagram.svg";

import ItsEasy from "../../assets/images/its-that-easy-Mobile.svg";
import WorkProcessFlow from "../../assets/images/Work-Process-Flow.svg";
import PlaystoreLogo from "../../assets/images/Playstore Logo.svg";
import AppStoreLogo from "../../assets/images/App Store Logo.svg";
import FooterMobile from "../../assets/images/Footer Mobile.svg";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  let navigate = useNavigate();

  const handleClick = (route) => {
    navigate(`/${route}`);
    // navigate("/choose-a-service");
  };
  return (
    <>
      <div className="main-heading">
        <div className="sub-heading">
          <div className="header position-sticky top-0 bg-white">
            <div className="logo-container">
              <img alt="content" src={FileNowLogo} width="120" height="70" />
            </div>
            <div className="header-contents">
              <ul className="navbar-list" style={{ display: "none" }}>
                <li>
                  <a href="#">Business Setup</a>
                </li>
                <li>
                  <a href="#">Compliances</a>
                </li>
                <li>
                  <a href="#">Manage Business</a>
                </li>
              </ul>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <button
                  className="register-button"
                  onClick={() => {
                    handleClick("signIn");
                  }}
                >
                  Login
                </button>
                <button
                  className="register-button social-media-btns w-auto"
                  onClick={() => {
                    handleClick("agent/signIn");
                  }}
                >
                  Agent Login
                </button>
                {/* <button
                  className="register-button"
                  onClick={() => {
                    handleClick("register");
                  }}
                >
                  Register
                </button> */}
              </div>
            </div>
          </div>

          <div className="intro-section">
            <div className="intro-section-details">
              <h1 style={{ color: "#1679fb", fontSize: "28px" }}>
                We Do All Legal Services At Affordable Pricing
              </h1>
              <p
                style={{
                  margin: "30px auto",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                We deliver all the services at the Competitive price We are
                India's largest tax and financial services software platform for
                individuals, tax experts, SMEs and enterprises with ITR, GST,
                e-invoicing solutions and more.
              </p>
              <div>
                <button
                  className="get-started-button"
                  onClick={() => {
                    handleClick("register");
                  }}
                >
                  Get Started
                </button>
                <button
                  className="talk-experts-button"
                  onClick={() => {
                    handleClick("");
                  }}
                >
                  Talk to Our Experts
                </button>
              </div>
            </div>
            <div className="intro-section-images">
              <img alt="content" className="intro-img" src={HeaderImage} />
            </div>
          </div>

          <div className="companies-container">
            <h1 style={{ color: "#606060" }}>
              Powering 150+ future ready companies
            </h1>
            <div className="companies-list">
              <img alt="content" src={BrandLogo1} width="90" height="100" />
              <img alt="content" src={BrandLogo2} width="120" height="100" />
              <img alt="content" src={BrandLogo3} width="180" height="120" />
              <img alt="content" src={BrandLogo6} width="120" height="100" />
            </div>
          </div>

          <div className="our-services" style={{ display: "none" }}>
            <h1 style={{ margin: "5%" }}>Our Services</h1>
            <div className="services-list">
              <button>Start a company</button>
              <button>Individual</button>
              <button>Enterprise</button>
              <button>SMEs</button>
              <button>Compliances</button>
            </div>
          </div>

          <div className="itr-filing" style={{ display: "none" }}>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
            <div className="itr-filing-boxes">
              <h1>ITR Filing</h1>
              <p>Starts @ just 99Rs.</p>
            </div>
          </div>

          <div className="why-legally">
            <h1 style={{ margin: "5%" }}>Why FileNow?</h1>
            <div className="sub-why-legally">
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow1}
                  width="100px"
                  height="100px"
                />
                <p>Lowest Pricing in the market</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow2}
                  width="100px"
                  height="100px"
                />
                <p>Handled by Experts</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow3}
                  width="100px"
                  height="100px"
                />
                <p>100% Online Services</p>
              </div>
            </div>
            <div className="sub-why-legally">
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow4}
                  width="100px"
                  height="100px"
                />
                <p>Free Consultancy</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow5}
                  width="100px"
                  height="100px"
                />
                <p>Data Security and Trust</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow6}
                  width="100px"
                  height="100px"
                />
                <p>Quick Response Team</p>
              </div>
            </div>
            <div className="sub-why-legally">
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow7}
                  width="100px"
                  height="100px"
                />
                <p>ISO Certified</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow8}
                  width="100px"
                  height="100px"
                />
                <p>Hasstle Free Service</p>
              </div>
              <div className="sub-why-legally-cards">
                <img
                  alt="content"
                  src={whyFileNow9}
                  width="100px"
                  height="100px"
                />
                <p>100% Work Done</p>
              </div>
            </div>
          </div>

          <div className="lowest-price-challenge">
            <h1>Lowest Price Challenge</h1>
            <p>
              We provide all the services for Companies, Individuals and
              Everyone at Lowest Fares in the Market
            </p>
          </div>

          <div className="encry-priv-data">
            <div className="encryption">
              <div className="encryption-logo">
                <img
                  alt="content"
                  src={DataStorage}
                  width="100px"
                  height="100px"
                />
                <p>Data Storage</p>
              </div>
              <p
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  fontWeight: 550,
                }}
              >
                All the data provided by you is stored securely.
              </p>
            </div>
            <div className="encryption">
              <div className="encryption-logo">
                <img
                  alt="content"
                  src={Encription}
                  width="100px"
                  height="100px"
                />
                <p>Encryption</p>
              </div>
              <p
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  fontWeight: 550,
                }}
              >
                All the trasactions are encrypted.
              </p>
            </div>
            <div className="encryption">
              <div className="encryption-logo">
                <img alt="content" src={Privacy} width="100px" height="100px" />
                <p>Privacy</p>
              </div>
              <p
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  fontWeight: 550,
                }}
              >
                we provide top of the line privacy protection strategies.
              </p>
            </div>
          </div>

          <h1 style={{ margin: "5%", display: "none" }}>
            FileNow App is Coming Soon!
          </h1>

          <div className="six-point" style={{ display: "none" }}>
            <div className="first-three">
              <div className="six-point-wise">
                <div style={{ width: "400px", marginRight: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">1</span>
              </div>
              <div className="six-point-wise">
                <div style={{ width: "400px", marginRight: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">2</span>
              </div>
              <div className="six-point-wise">
                <div style={{ width: "400px", marginRight: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">3</span>
              </div>
            </div>
            <div>
              <img alt="content" src={ItsEasy} width="350px" height="500px" />
            </div>
            <div className="second-three">
              <div className="six-point-wise-reverse">
                <div style={{ width: "400px", marginLeft: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">4</span>
              </div>
              <div className="six-point-wise-reverse">
                <div style={{ width: "400px", marginLeft: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">5</span>
              </div>
              <div className="six-point-wise-reverse">
                <div style={{ width: "400px", marginLeft: "15px" }}>
                  <h1 style={{ marginTop: "0px" }}>Lorem ipsum</h1>
                  <p>
                    how thr yjrkjdfksnvi kdvnnksv kadjvkdv sskadjvk advk kadjv
                    adkvj kadjv adkvjad kadjv kadjv
                  </p>
                </div>
                <span className="dot">6</span>
              </div>
            </div>
          </div>

          <div className="how-legally-works" style={{ display: "none" }}>
            <h1 style={{ margin: "5%" }}>How FileNow Works?</h1>
            <img alt="content" src={WorkProcessFlow} />
          </div>

          <div className="online-support">
            <h1>100% Online Support</h1>
            <p
              style={{
                fontSize: "18px",
                fontWeight: 550,
                width: "75%",
                textAlign: "center",
              }}
            >
              we ensure business continuity with unparalleled reliability. We
              provide webportal backup 99.99% uptime using our multiple
              redundancy tech architecture.
            </p>
          </div>

          <div className="app-store">
            <div style={{ marginTop: "40px", marginRight: "50px" }}>
              <h1>Simplify All Your Filings in One App - Coming Soon</h1>
              <div style={{ margin: "5% auto" }}>
                <img
                  alt="content"
                  src={PlaystoreLogo}
                  width="150px"
                  height="50px"
                  style={{ marginRight: "20px" }}
                />
                <img
                  alt="content"
                  src={AppStoreLogo}
                  width="150px"
                  height="50px"
                />
              </div>
            </div>
            <img
              alt="content"
              src={FooterMobile}
              width="200px"
              style={{ marginTop: "20px" }}
            />
          </div>
          <div className="main-footer">
            <div className="spacer" />
            {/* <button
              className="register-button social-media-btns w-auto"
              onClick={() => {
                handleClick("agent/signIn");
              }}
            >
              Agent Login
            </button> */}
            <div className="sub-footer">
              <div className="social-media-btns">
                <div className="footer-title cursor-pointer">
                  <span
                    className="cursor-pointer"
                    onClick={() => handleClick("agent/signIn")}
                  >
                    FILENOW
                  </span>
                  <span
                    className="links-text cursor-pointer"
                    onClick={() => handleClick("terms_and_conditions")}
                  >
                    Terms and Conditions
                  </span>
                  <span
                    className="links-text cursor-pointer"
                    onClick={() => handleClick("privacy_policy")}
                  >
                    Privacy Policy
                  </span>
                  <span
                    className="links-text cursor-pointer"
                    onClick={() => handleClick("refund_policy")}
                  >
                    Refund Policy
                  </span>
                  <span
                    className="links-text cursor-pointer"
                    onClick={() => handleClick("cookies_policy")}
                  >
                    Cookies Policy
                  </span>
                </div>
                <div className="social-links">
                  <a href="#">
                    <img
                      alt="content"
                      src={facebook}
                      width="30px"
                      height="30px"
                    />
                  </a>
                  <a href="https://www.instagram.com/filenow01/">
                    <img
                      alt="content"
                      src={Instagram}
                      width="30px"
                      height="30px"
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/file-now">
                    <img
                      alt="content"
                      src={LinkedIn}
                      width="30px"
                      height="30px"
                    />
                  </a>
                  <a href="https://filenow.quora.com">
                    <img alt="content" src={quora} width="30px" height="30px" />
                  </a>
                  <a href="https://twitter.com/File_Now">
                    <img
                      alt="content"
                      src={twitter}
                      width="30px"
                      height="30px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
