import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Root from "./pages/root/Root";
import "./assets/styles/main.scss";
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { PermissionsProvider } from "./PermissionsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PermissionsProvider>
  <GoogleOAuthProvider clientId="774193195729-84b8t66l8rgmgr6r9r7nvpsoaa6lnbmh.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
  </PermissionsProvider>
);
