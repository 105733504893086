import React from 'react'
import OrderAndPayments from './OrderAndPayments'
import OrderAndPaymentAdmin from './OrdersAndPaymentAdmin'
import { eNums } from '../../constants'

const OrderandPayments = () => {
    const userType = localStorage.getItem('userType')
   
    return userType === eNums?.CLIENT ? <OrderAndPayments /> : <OrderAndPaymentAdmin/>
}

export default OrderandPayments