import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SettingsSidebar from "../../Components/SettingsSidebar";
import SettingsProfile from "../../Components/SettingsProfile";
import SettingsNotification from "../../Components/SettingsNotification";
import SettingsPasswordLogin from "../../Components/SettingsPasswordLogin";
import NewLayout from "../../Components/SidebarLayout";
import Dashboard from "../../assets/images/sidebar/dashboard.svg";
import NotificationSettings from "../../assets/images/notification-settings.svg";
import Lock from "../../assets/images/lock.svg";
import People from "../../assets/images/people.svg";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsRoleCreation from "../../Components/SettingsRoleCreation";
import { useApi, ApiService } from '../../Utilities';
import { useLocation } from "react-router-dom";
import { eNums } from "../../constants";

const data = [
  {
    name: "Settings",
    logo: <SettingsOutlinedIcon sx={{width: '24px', height: '24px'}}/>,
  },
  { 
    key: 1,
    name: "Profile",
    logo: Dashboard,
  },
  { 
    key: 2,
    name: "Notification Settings",
    logo: NotificationSettings,
  },
  {
    key: 3,
    name: "Password and Login",
    logo: Lock,
  },
  
];
function Settings() {
  const { state } = useLocation();
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');
  const role_level = Number(localStorage.getItem('role_level'));
  const [selectedSetting, setSelectedSetting] = useState(1);
  const [services, setServices] = useState(data || [])

  useEffect(() => {
    if (state && state?.type) {
      setSelectedSetting(state?.type);
    }
  }, [state]);

  useEffect(() => {
    if (userType === eNums?.AGENT && role_level === 1) {
      let updatedData = [...data]
      let obj = {
        key: 4,
        name: "Role Creation and Access",
        logo: People,
      }
      updatedData.push(obj)
      setServices(updatedData)
    }
  }, [userType]);

  const { action: getUserById, data: user } = useApi(
    ApiService.getUserById
  );

  useEffect(() => {
  if(userId){
    getUserById(userId, {userType})
  }

  },[userId, selectedSetting])

  return (
    <NewLayout
      path={"Home / Settings / Profile"}
      pageHeading={"Profile"}
      noShowHeading={true}
      noScroll={true}
    >
      <div className="d-flex">
        <div className="wrap-settings-splitA1">
          <SettingsSidebar
            setSelectedSetting={setSelectedSetting}
            selectedSetting={selectedSetting}
            services={services}
          />
        </div>
        <div className="border-right-gray"/>
        <Grid item className="wrap-settings-splitB1 w-100 overflow-auto h-100vh">
          {selectedSetting === 1 && <SettingsProfile data={user?.[0]}/>}
          {selectedSetting === 2 && <SettingsNotification data={user?.[0]}/>}
          {selectedSetting === 3 && (
            <SettingsPasswordLogin data={user?.[0]}/>
          )}
          {selectedSetting === 4 && (
            <SettingsRoleCreation data={user?.[0]}/>
          )}
        </Grid>
      </div>
    </NewLayout>
  );
}

export default Settings;
