import React from "react";

function NewServiceCard({ title, serviceinfo, price, onClickBuyNow }) {
  return (
    <div className="newSer_Card_base">
      <div className="newser_card_title">{title}</div>
      <div className="newser_card_type">{serviceinfo}</div>
      <div className="newset_card_buttontextcontai">
        <div className="newser_card_pricetext">Rs - {price}/-</div>
        <div
          className="newSer_card_button"
          onClick={() => {
            onClickBuyNow();
          }}
        >
          Buy Now
        </div>
      </div>
    </div>
  );
}

export default NewServiceCard;
