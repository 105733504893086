import React from "react";
import {useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import img1 from "../../assets/images/phone-setup-page.svg";
import i1 from "../../assets/images/user-add.png";
import i2 from "../../assets/images/edit.svg";
import i3 from "../../assets/images/headphone.png";
import RightBlueArrow from "../../assets/images/right_blue_arrow.svg";
import { useRolePermissions } from "../../Utilities";
import { eNums } from "../../constants";
import { Troubleshoot } from "@mui/icons-material";

function DashboardAdminSetup() {
  const Permissions = useRolePermissions();
  const navigate = useNavigate();
  const roleLevel = Number(localStorage.getItem('role_level'));

  const configureData = [
    {
      title: "Add Your Team",
      subTitle:
        "Add your team to join File Now and configure their roles and permissions.",
      image: i1,
      navigation: "employee-management",
      permission: Permissions?.employeeManagement?.submodules?.employeeManagement?.enabled && Permissions?.employeeManagement?.submodules?.employeeManagement?.permission?.includes(eNums?.EDIT) ? true : false
    },
    {
      title: "Add Categories and Services",
      subTitle:
        "Add all the list of services and categories of services you are offering.",
      image: i2,
      navigation: "service-create-category",
      permission: Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled && Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(eNums?.EDIT) ? true : false
    },
    {
      title: "Add Your Client",
      subTitle:
        "Add your existing clients and new clients into filenow with few clicks.",
      image: i1,
      navigation: "client-details",
      permission: Permissions?.clientManagement?.submodules?.clientDashboard?.enabled && Permissions?.clientManagement?.submodules?.clientDashboard?.permission?.includes(eNums?.EDIT) ? true : false
    },
    {
      title: "Add Your Profile",
      subTitle:
        "Add your team to join File Now and configure their roles and permissions.",
      image: i1,
      navigation: "settings",
      permission: roleLevel === 1 ? Troubleshoot : false
    },
  ];

  const handleClickEmail = () => {
    window.location.href = "mailto:support@filenow.com";
  };
 
  return (
    <>
        <Grid className="setup-w1">
          <div className="setup-banners">
              <div className="setup-g1 admin-setup-banners-1 px-5">
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    fontSize="20px"
                  >
                    50,000 Charted Accountants
                  </Typography>
                  <Typography
                    variant="subtitle"
                    fontWeight={500}
                    fontSize="16px"
                  >
                    Trusted by top Charted Accountants all over India
                  </Typography>
                {/* <Grid item>
                  <Typography component="ul" className="st-data-list">
                    <li>This is point one </li>
                    <li>This is point two</li>
                  </Typography>
                </Grid> */}
              </div>

              <Grid className="setup-g1 admin-setup-banners-2 ms-3">
                <img src={img1} className="setup-img1" alt="" />
              </Grid>
            {/* <div item className="setup-g1-b">
              {" "}
            </div> */}
          </div>
          <div className="setup-g2">
            <div className="ms-4">
              <Typography fontWeight="bold">Hello !</Typography>

              <span className="st-configure-data-subtitle">
                Explore our new File Now made to manage your clients in a more
                effective way !
              </span>
            </div>
            <div className="st-configure-data mt-5">
              <Grid className="setup-g2-a">
                {configureData?.filter((item) => item?.permission)?.map((item) => (
                  <Grid
                    container
                    className="setup-g2-a-block"
                    sx={{ margin: "1vh" }}
                  >
                    <Grid className="setup-b-1">
                      <img src={item?.image} alt="" />
                    </Grid>

                    <Grid className="setup-b-2">
                      <Grid container>
                        <Grid container item>
                          <Typography fontWeight="bold" variant="subtitle2">
                            {item?.title}
                          </Typography>
                        </Grid>
                        <Grid container item>
                          <span className="st-configure-data-subtitle">
                            {item?.subTitle}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      className="setup-b-3 cursor-pointer"
                      onClick={() => navigate(`/${item?.navigation}`)}
                    >
                      <span className="setup-b-3-text">Configure </span>
                      <img src={RightBlueArrow} alt="" className="ms-4" />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid className="setup-g2-b ms-2">
                <Grid item className="setup-g2-b1">
                  <Grid container>
                    <Grid item className="setup-g2-b11">
                      <img src={i3} alt="" />
                    </Grid>
                    <Grid item className="setup-g2-b11">
                      <Typography
                        fontWeight={600}
                        fontSize={"14px"}
                        color={"#00001B"}
                      >
                        Need Assistance ?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className="setup-body">
                    <Grid container>
                      <Grid item>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={"12px"}
                          color={"#636A76"}
                        >
                          Write to us at support@filenow.com.
                        </Typography>{" "}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={"14px"}
                          color={"#636A76"}
                        >
                          We are available 24*5 (Monday - Friday).
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="setup-button">
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        className="emailus-btn"
                        onClick={handleClickEmail}
                      >
                        Email Us
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
    </>
  );
}

export default DashboardAdminSetup;
