import { Grid, Typography, Button } from "@mui/material";
import React, { useState, PureComponent, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
} from "recharts";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import setUpImg from "../../assets/images/phone-setup-page.svg";
import ClientServiceStatus from "../../assets/images/dashboard/client-service-status.svg";
import TasksProgress from "../../assets/images/dashboard/task-progress.svg";
import CallbackRequests from "../../assets/images/dashboard/call-back-requests.svg";
import SalesStatus from "../../assets/images/dashboard/sales-status.svg";
import ComplianceCalender from "../../assets/images/dashboard/compliance-calender.svg";
import { useNavigate } from "react-router-dom";
import { ApiService, dateFormatter, EmptyState, ToolTip, useApi } from "../../Utilities";
import { Placeholder } from "react-bootstrap";
import moment from "moment";
import dayjs from "dayjs";
import { eNums } from "../../constants";
import EmptyStateImg from '../../assets/images/empty_state.svg'

const DashboardAdmin = () => {
  const [showHighlight, setShowHighlight] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [filterPayload, setFilterPayload] = useState(null);
  const roleName = localStorage.getItem("roleName");

  const navigate = useNavigate();

  const [date, setDate] = useState(dayjs());
  const [events, setEvents] = useState(null);
  const {
    action: getDashboardData,
    data: dashboardData,
    loading: dashboardDataLoading,
  } = useApi(ApiService.getDashboardData);

  const {
    action: getAllEvents,
    data: allEvents,
    loading: eventsLoading,
  } = useApi(ApiService.getAllEvents);

  useEffect(() => {
    getAllEvents({
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      skipValue: 0,
      limitValue: 2,
    });
    getDashboardData();
  }, []);

  useEffect(() => {
    if (allEvents?.length) {
      setEvents(allEvents);
    } else {
      setEvents([]);
    }
  }, [allEvents]);

  const PieChartCOLORS = ["#1E68F9", "#429FFF", "#81BFFF", "#C2E0FF"];

  const d1 = [
    { x: 100, y: 200, id: 1 },
    { x: 120, y: 100, id: 2 },
    { x: 170, y: 300, id: 3 },
    { x: 140, y: 250, id: 4 },
    { x: 150, y: 400, id: 5 },
    { x: 110, y: 280, id: 6 },
  ];

  const options = [
    { label: "Today", value: "today" },
    { label: "Last Week", value: "7days" },
    { label: "Last Month", value: "30days" },
    { label: "This Year", value: "365days" },
  ];

  const handleCalenderDateChange = (value) => {
    setDate(value);
    getAllEvents({
      fromDate: value.format("YYYY-MM-DD"),
      toDate: value.format("YYYY-MM-DD"),
      skipValue: 0,
      limitValue: 2,
    });
  };

  const callBackChartSetting = {
    yAxis: [
      {
        label: "No of Callback Requests",
      },
    ],
    series: [
      {
        dataKey: "count",
        label: "Requests",
        color: "#1e68f9",
      },
    ],
    height: 200,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  const clientsData = [
    {
      label: "Active Clients",
      value: dashboardData?.clientOrCustomer?.activeClients,
    },
    {
      label: "In Active Clients",
      value: dashboardData?.clientOrCustomer?.inActiveClients,
    },
  ];

  const servicesData = [
    {
      label: "Services",
      "Active Services": dashboardData?.services?.activeServices || 0,
      "On Hold Services": dashboardData?.services?.onHoldServices || 0,
      "Deleted Services": dashboardData?.services?.deletedServices || 0,
    },
  ];

  return (
    <>
      <Grid item container>
        <div className="setup-banners">
          <div className="setup-g1 admin-setup-banners-1 px-5">
            <Typography variant="h5" fontWeight={700} fontSize="20px">
              {"10,00,000 + "}
            </Typography>
            <Typography variant="h5" fontWeight={700} fontSize="20px">
              Members
            </Typography>
            <Typography variant="subtitle" fontWeight={500} fontSize="16px">
              Connect with Charted Accountants &<br />
              Articleships from all over India
            </Typography>
          </div>

          <Grid className="setup-g1 admin-setup-banners-2 ms-3">
            <img src={setUpImg} className="setup-img1" alt="" />
          </Grid>
        </div>
        <div className="dashboard-grid py-3">
          <div className="dashboard-admin-card db-tb1">
            <div className="db-tb-cell">
              <Grid item className="db-c-a">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={ClientServiceStatus}
                    alt=""
                    width={20}
                    height={20}
                  />
                  <span className="dashboard-header-title-text">
                    Clients Service Status
                  </span>
                </div>
                <span className="ms-2 mb-1">
                  <ToolTip info={"Service Status"} />
                </span>
              </Grid>
              {/* <span className="db-c-b">View report</span> */}
            </div>
            {dashboardData?.clientStatus?.allServicesCount ? 
            <span className="db-tb-cell-total px-5">
              {dashboardData?.clientStatus?.allServicesCount || 0}
            </span> : null}
            <ResponsiveContainer width="100%" height={200} className="my-auto">
              <div className="d-flex align-items-center justify-content-evenly flex-wrap">
                {dashboardData?.clientStatus?.pieChartData?.length ? (<>
                  <PieChart width={200} height={200} className="ms-2">
                    <Pie
                      data={dashboardData?.clientStatus?.pieChartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {dashboardData?.clientStatus?.pieChartData?.map(
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              PieChartCOLORS[index % PieChartCOLORS?.length]
                            }
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip />
                  </PieChart>

                  <div className="d-flex flex-column flex-wrap mx-auto justify-content-center">
                    {dashboardData?.clientStatus?.pieChartData?.map(
                      (entry, index) => (
                        <div className="d-flex align-items-center gap-3">
                          <span
                            className="fill-service-status"
                            style={{
                              backgroundColor:
                                PieChartCOLORS[index % PieChartCOLORS.length],
                            }}
                          ></span>
                          <div className="d-flex">
                            <span className="service-status-key">
                              {entry?.name} -
                            </span>
                            <span className="service-status-value ms-1">
                              {entry?.value}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
                ) : <img src={EmptyStateImg} className='img img-fluid justify-content-center d-flex mx-auto' alt='' width={200} height={10}/>}

              </div>
            </ResponsiveContainer>
          </div>

          {/* <div className="dashboard-admin-card db-tb1">
            <div className="db-tb-cell border-bottom-gray">
              <Grid item className="db-c-a">
                <span>Client Engagement</span>
                <span className="ms-2 mb-1">
                  <ToolTip info={"Client Engagement"} />
                </span>
              </Grid>
              <Grid item className="db-c-b">
                <select
                  className="width-filter"
                  defaultValue={filterPayload || "today"}
                >
                  {options?.map((item) => (
                    <option key={item} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              </Grid>
            </div>
            <div className="d-flex my-auto">
              <ScatterChart
                width={500}
                height={250}
                series={[{ data: d1, label: "pv", id: "pvId" }]}
                xAxis={[{ min: 0 }]}
              />
            </div>
          </div> */}
          {roleName?.includes(eNums?.DEV) ? <>
            <div className="dashboard-admin-card db-tb1">
              <div className="db-tb-cell">
                <Grid item className="db-c-a">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={ClientServiceStatus}
                      alt=""
                      width={20}
                      height={20}
                    />
                    <span className="dashboard-header-title-text">Services</span>
                  </div>
                  <span className="ms-2 mb-1">
                    <ToolTip info={"Task Progress"} />
                  </span>
                </Grid>
                {/* <span className="db-c-b">View report</span> */}
              </div>
              <div className="d-flex justify-content-between flex-wrap my-auto mx-3">
                <ResponsiveContainer width="25%" height={200}>
                  <BarChart data={servicesData} barSize={80}>
                    <XAxis dataKey="label" tick={false} axisLine={false} />
                    <YAxis hide />
                    <Tooltip />
                    {/* Stacked bars */}
                    <Bar dataKey="Active Services" stackId="a" fill="#1E68F9" />
                    <Bar dataKey="On Hold Services" stackId="a" fill="#429FFF" />
                    <Bar dataKey="Deleted Services" stackId="a" fill="#81BFFF" />
                  </BarChart>
                </ResponsiveContainer>

                <div className="d-flex flex-column flex-wrap mx-auto justify-content-center">
                  <span className="db-tb-cell-total mb-3">
                    {dashboardData?.services?.createdServices || 0}
                  </span>
                  <div className="d-flex align-items-center gap-3">
                    <span
                      className="fill-service-status"
                      style={{
                        backgroundColor: PieChartCOLORS[0],
                      }}
                    ></span>
                    <div className="d-flex">
                      <span className="service-status-key">
                        {"Active Services"} -
                      </span>
                      <span className="service-status-value ms-1">
                        {dashboardData?.services?.activeServices || 0}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <span
                      className="fill-service-status"
                      style={{
                        backgroundColor: PieChartCOLORS[1],
                      }}
                    ></span>
                    <div className="d-flex">
                      <span className="service-status-key">
                        {"On hold Services"} -
                      </span>
                      <span className="service-status-value ms-1">
                        {dashboardData?.services?.onHoldServices || 0}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <span
                      className="fill-service-status"
                      style={{
                        backgroundColor: PieChartCOLORS[2],
                      }}
                    ></span>
                    <div className="d-flex">
                      <span className="service-status-key">
                        {"Deleted Services"} -
                      </span>
                      <span className="service-status-value ms-1">
                        {dashboardData?.services?.deletedServices || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-admin-card db-tb1">
              <div className="db-tb-cell">
                <Grid item className="db-c-a">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={ClientServiceStatus}
                      alt=""
                      width={20}
                      height={20}
                    />
                    <span className="dashboard-header-title-text">
                      Client/Customer Status
                    </span>
                  </div>
                  <span className="ms-2 mb-1">
                    <ToolTip info={"Task Progress"} />
                  </span>
                </Grid>
                {/* <span className="db-c-b">View report</span> */}
              </div>
              <div className="d-flex justify-content-between flex-wrap my-auto mx-3">
                <ResponsiveContainer width="50%" height={200} className="my-auto">
                  <BarChart data={clientsData} barSize={40}>
                    <XAxis dataKey="label" tick={false} axisLine={false} />
                    <YAxis hide />
                    <Tooltip />
                    <Bar dataKey="value">
                      {clientsData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={PieChartCOLORS[index % PieChartCOLORS.length]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="d-flex flex-column flex-wrap mx-auto justify-content-center">
                  <span className="db-tb-cell-total mb-3">
                    {dashboardData?.clientOrCustomer?.activeClients +
                      dashboardData?.clientOrCustomer?.inActiveClients || 0}
                  </span>
                  {clientsData?.map((entry, index) => (
                    <div className="d-flex align-items-center gap-3">
                      <span
                        className="fill-service-status"
                        style={{
                          backgroundColor:
                            PieChartCOLORS[index % PieChartCOLORS.length],
                        }}
                      ></span>
                      <div className="d-flex">
                        <span className="service-status-key">
                          {entry?.label} -
                        </span>
                        <span className="service-status-value ms-1">
                          {entry?.value}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </> : null}

          <div className="dashboard-admin-card db-tb1">
            <div className="db-tb-cell">
              <Grid item className="db-c-a">
                <div className="d-flex align-items-center gap-2">
                  <img src={TasksProgress} alt="" width={20} height={20} />
                  <span className="dashboard-header-title-text">
                    Task Progress
                  </span>
                </div>
                <span className="ms-2 mb-1">
                  <ToolTip info={"Task Progress"} />
                </span>
              </Grid>
              {/* <span className="db-c-b">View report</span> */}
            </div>
            {dashboardData?.tasksProgress?.totalTasks ? 
            <span className="db-tb-cell-total px-5">
              {dashboardData?.tasksProgress?.totalTasks || 0}
            </span> : null}

            {dashboardData?.tasksProgress?.taskData?.length ? (
              <Grid container className="my-auto">
                <LineChart
                  width={500}
                  height={250}
                  xAxis={[
                    {
                      scaleType: "point",
                      data: dashboardData.tasksProgress.taskData.map(
                        (item) => item?.week
                      ),
                      label: "Days",
                      labelStyle: {
                        fontSize: "15px",
                        fontWeight: 600,
                        fill: "#0F82FB",
                      },
                    },
                  ]}
                  series={[
                    {
                      data: dashboardData.tasksProgress.taskData.map(
                        (item) => item?.count
                      ),
                      label: "Tasks",
                      color: "#1e68f9",
                    },
                  ]}
                />
              </Grid>
            ) : <img src={EmptyStateImg} className='img img-fluid justify-content-center d-flex mx-auto' alt='' width={200} height={10}/>}
          </div>
          {!roleName?.includes(eNums?.EMPLOYEE) || !roleName?.includes(eNums?.ARTICLESHIP) ? <>
            <div className="dashboard-admin-card db-tb1">
              <div className="db-tb-cell">
                <Grid item className="db-c-a">
                  <div className="d-flex align-items-center gap-2">
                    <img src={SalesStatus} alt="" width={20} height={20} />
                    <span className="dashboard-header-title-text">
                      Sales Status
                    </span>
                  </div>
                  <span className="ms-2 mb-1">
                    <ToolTip info={"Sales status"} />
                  </span>
                </Grid>
                {/* <span className="db-c-b">View report</span> */}
              </div>
              {dashboardData?.salesStatus?.totalSales ? 
              <span className="db-tb-cell-total px-5">
                {dashboardData?.salesStatus?.totalSales || 0}
              </span> : null}
              {dashboardData?.salesStatus?.salesData?.length ? (
                <Grid container className="my-auto">
                  <LineChart
                    width={500}
                    height={250}
                    xAxis={[
                      {
                        scaleType: "point",
                        data: dashboardData?.salesStatus?.salesData.map(
                          (item) => item?.week
                        ),
                        label: "Days",
                        labelStyle: {
                          fontSize: "15px",
                          fontWeight: 600,
                          fill: "#0F82FB",
                        },
                      },
                    ]}
                    series={[
                      {
                        data: dashboardData?.salesStatus?.salesData.map(
                          (item) => item?.count
                        ),
                        label: "Sales",
                        area: true,
                        color: "#1e68f9",
                      },
                    ]}
                  />
                </Grid>
              ) : <img src={EmptyStateImg} className='img img-fluid justify-content-center d-flex mx-auto' alt='' width={200} height={10}/>}
            </div>

            <div className="dashboard-admin-card db-tb1">
              <Grid container className="db-tb-cell">
                <Grid item className="db-c-a">
                  <div className="d-flex align-items-center gap-2">
                    <img src={CallbackRequests} alt="" width={20} height={20} />
                    <span className="dashboard-header-title-text">
                      Call Back Request
                    </span>
                  </div>
                  <span className="ms-2 mb-1">
                    <ToolTip info={"New Service Request"} />
                  </span>
                </Grid>
                {/* <span className="db-c-b">View report</span> */}
              </Grid>
              {dashboardData?.callBackRequest?.totalSales ? 
              <span className="db-tb-cell-total px-5">
                {dashboardData?.callBackRequest?.totalSales || 0}
              </span> : null}
              {dashboardData?.callBackRequest?.callBackData?.length ? (
                <ResponsiveContainer
                  width={"100%"}
                  height={180}
                  className="my-auto"
                >
                  <BarChart
                    data={dashboardData?.callBackRequest?.callBackData}
                    barSize={40}
                  >
                    <XAxis
                      dataKey="week"
                      label={{
                        value: "Days",
                        position: "insideBottom",
                        offset: -2,
                        dy: 2,
                        style: {
                          fontSize: "14px",
                          fontWeight: 600,
                          fill: "#0F82FB",
                        },
                      }}
                      tick={{
                        style: {
                          fontSize: "12px",
                          fill: "#212529",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <YAxis
                      label={{
                        value: "No of Requests",
                        angle: -90,
                        dx: -10,
                        style: {
                          fontSize: "14px",
                          fontWeight: 600,
                          fill: "#0F82FB",
                          right: 10,
                        },
                      }}
                      tick={{
                        style: {
                          fontSize: "12px",
                          fill: "#212529",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <Tooltip />
                    <Bar dataKey="count" fill="#1e68f9" />
                  </BarChart>
                </ResponsiveContainer>
              ) : <img src={EmptyStateImg} className='img img-fluid justify-content-center d-flex mx-auto' alt='' width={200} height={10}/>}
            </div>
          </> : null}

          <div className="dashboard-admin-card db-tb1">
            <div className="db-tb-cell mt-1">
              <Grid item className="db-c-a">
                <div className="d-flex align-items-center gap-2">
                  <img src={ComplianceCalender} alt="" width={20} height={20} />
                  <span className="dashboard-header-title-text">
                    Compliance Calendar
                  </span>
                </div>
                <span className="ms-2 mb-1">
                  <ToolTip info={"Compliance Calendar"} />
                </span>
              </Grid>
              {/* <span
                className="db-c-b"
                onClick={() => navigate("/compliance-calendar")}
              >
                View report
              </span> */}
            </div>
            <Grid container className="ad-cent my-auto">
              <Grid item className="cont-db-1 mt-5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={date}
                    onChange={(newValue) => handleCalenderDateChange(newValue)}
                  />
                </LocalizationProvider>
              </Grid>
              <div className="cont-db-2">
                {eventsLoading ? (
                  [...Array(1)]?.map((a, i) => (
                    <Placeholder
                      className="w-100 placeholder-background rounded"
                      animation="wave"
                      style={{ height: "25px" }}
                    />
                  ))
                ) : events?.length ? (
                  events?.map((event) => (
                    <Typography
                      fontSize="14px"
                      fontWeight={"700"}
                      color={"#545969"}
                    >
                      {dateFormatter(event?.dueDate) || "Due"} -
                      <span className="db-cal-text">
                        {" "}
                        {event?.eventName || "Event"}
                      </span>
                    </Typography>
                  ))
                ) : (
                  <div className="my-3">No Events</div>
                )}
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DashboardAdmin;
