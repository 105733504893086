import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';

function LegalDocumentCard({detail, OnClickCard}) {
  return (
    <div className='legaldoc_card_base'>
        <div style={{backgroundImage:'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS08KOBuqfP4UnLQ6StvxJnp-qJ5w-Y9zC3Q&usqp=CAU")'}}
            className='legal_doc_cardimg'>
            <div onClick={()=>{OnClickCard()}} className='legaldoc_onhover_downloadbutton'>
            <Player
                autoplay
                loop
                src="https://assets1.lottiefiles.com/packages/lf20_oxneh5jv.json"
                style={{ height: '80px', width: '80px' }}
            >
                {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
            </div>
        </div>
        <div className='legaldoc_detailsec'>
            {detail}
        </div>
    </div>
  )
}

export default LegalDocumentCard