import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import filenowlogo from "../assets/images/sidebar_filenow_logo.svg";
import SettingsIcon from "../assets/images/settings-icon.svg";
import Dashboard from "../assets/images/sidebar/dashboard.svg";
import ServicesManagement from "../assets/images/sidebar/service_management.svg";
import MyServices from "../assets/images/sidebar/my_services.svg";
import AllServices from "../assets/images/sidebar/all_services.svg";
import Compliance_Calender from "../assets/images/sidebar/compliance_calender.svg";
import Clients from "../assets/images/sidebar/client_management.svg";
import Employees from "../assets/images/sidebar/employee_managment.svg";
import ServicesAssignee from "../assets/images/sidebar/service_assignee.svg";
import LegalDocuments from "../assets/images/sidebar/legal_documents.svg";
import Orders_Payments from "../assets/images/sidebar/orders_&_payments.svg";
import AuditTrial from "../assets/images/sidebar/audit_trial.svg";
import Switch from "@mui/material/Switch";
import {eNums} from '../constants'
import { useRolePermissions } from "../Utilities";

function MiniSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const userType = localStorage.getItem("userType");
  const role_level = Number(localStorage.getItem('role_level'));

  const getActiveIcon = () => {
    switch (location?.pathname) {
      case "/dashboard":
        return "dashboard";
      case "/service-management":
        return "ServiceManagement";
      case "/service-create-category":
        return "ServiceManagement";
      case "/client-details":
        return "ClientDetails";
      case "/employee-management":
        return "EmployeeManagement";
      case "/legal-documentation":
        return "legal-documentation";
      case "/orders-and-payments":
        return "orders-and-payments";
      case "/compliance-calendar":
        return "compliance-calendar";
      case "/my-services":
        return "my-services";
      case "/all-services":
        return "all-services";
      case "/AuditTrial":
        return "AuditTrial";
      case "/settings":
        return "settings";
      default:
        return "";
    }
  };

  const Permissions = useRolePermissions();
  const activeIcon = getActiveIcon();

  const iconStyle = (iconName) => ({
    position: "relative",
    cursor: "pointer",
    borderRight: activeIcon === iconName ? "4px solid skyblue" : "none",
  });

  return (
    <div className="mini-sidebar-wrap d-flex flex-column justify-content-between">
      <div className="icons-container">
        <div
          className="mini-logos-wrap"
          onClick={() => navigate(`/dashboard`)}
        >
          <IconButton>
            <img src={filenowlogo} alt="F" width={20} height={20} />
          </IconButton>
        </div>
        <Tooltip
          title="Dashboard"
          arrow
          placement="right"
          sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
        >
          <div
            className="mini-logos-wrap"
            onClick={() => navigate(`/dashboard`)}
            style={iconStyle(`dashboard`)}
          >
            <IconButton>
              <img src={Dashboard} alt="D" width={20} height={20} />
            </IconButton>
          </div>
        </Tooltip>
        {!Permissions?.serviceManagement?.enabled || userType?.includes(eNums?.CLIENT) ? null : (
          <Tooltip
            title="Service Management"
            arrow
            placement="right"
            sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
          >
            <div
              className="mini-logos-wrap"
              onClick={() => navigate("/service-management")}
              style={iconStyle("ServiceManagement")}
            >
              <IconButton>
                <img
                  src={ServicesManagement}
                  alt="S"
                  width={20}
                  height={20}
                />
              </IconButton>
            </div>
          </Tooltip>
        )}
        {userType === eNums?.CLIENT ? (
          <Tooltip
            title="My Services"
            arrow
            placement="right"
            sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
          >
            <div
              className="mini-logos-wrap"
              onClick={() => navigate("/my-services")}
              style={iconStyle("my-services")}
            >
              <IconButton>
                <img
                  src={MyServices}
                  alt="M"
                  width={20}
                  height={20}
                />
              </IconButton>
            </div>
          </Tooltip>
        ) : null}
        {userType === eNums?.AGENT ? null :
        <Tooltip
          title="All Services"
          arrow
          placement="right"
          sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
        >
          <div
            className="mini-logos-wrap"
            onClick={() => navigate("/all-services")}
            style={iconStyle("all-services")}
          >
            <IconButton>
              <img
                src={AllServices}
                alt="A"
                width={20}
                height={20}
              />
            </IconButton>
          </div>
        </Tooltip>
        }
        {userType?.includes(eNums?.CLIENT) || Permissions?.complianceCalendar?.enabled ? 
        <Tooltip
          title="Compliance Calendar"
          arrow
          placement="right"
          sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
        >
          <div
            className="mini-logos-wrap"
            onClick={() => navigate(`/compliance-calendar`)}
            style={iconStyle(`compliance-calendar`)}
          >
            <IconButton>
              <img
                src={Compliance_Calender}
                alt="Compliance Calendar"
                width={20}
                height={20}
              />
            </IconButton>
          </div>
        </Tooltip> : null}
        {!Permissions?.clientManagement?.enabled || userType?.includes(eNums?.CLIENT) ? null : (
          <Tooltip
            title="Client Management"
            arrow
            placement="right"
            sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
          >
            <div
              className="mini-logos-wrap"
              onClick={() => navigate("/client-details")}
              style={iconStyle("ClientDetails")}
            >
              <IconButton>
                <img src={Clients} alt="C" />
              </IconButton>
            </div>
          </Tooltip>
        )}
        {Permissions?.employeeManagement?.enabled && userType?.includes(eNums?.AGENT) ? (
          <Tooltip
            title="Employee Management"
            arrow
            placement="right"
            sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
          >
            <div
              className="mini-logos-wrap"
              onClick={() => navigate("/employee-management")}
              style={iconStyle("EmployeeManagement")}
            >
              <IconButton>
                <img
                  src={Employees}
                  alt="E"
                  width={20}
                  height={20}
                />
              </IconButton>
            </div>
          </Tooltip>
        ) : null}
        {/* <Tooltip
          title="Legal Documents"
          arrow
          placement="right"
          sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
        >
          <div
            className="mini-logos-wrap"
            onClick={() => navigate(`/legal-documentation`)}
            style={iconStyle(legal-documentation`)}
          >
            <img src={LegalDocuments} alt="Legal Documents" />
          </div>
        </Tooltip> */}
        {userType?.includes(eNums?.CLIENT) || Permissions?.ordersAndPayments?.enabled ? 
        <Tooltip
          title="Orders and Payments"
          arrow
          placement="right"
          sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
        >
          <div
            className="mini-logos-wrap"
            onClick={() => navigate(`/orders-and-payments`)}
            style={iconStyle(`orders-and-payments`)}
          >
            <IconButton>
              <img
                src={Orders_Payments}
                alt="Orders and Payments"
                width={20}
                height={20}
              />
            </IconButton>
          </div>
        </Tooltip> : null}
      </div>

      <div className="mini-sidebar-wrap3 mb-25">
        <div className="mini-logos-wrap flex-column gap-2">
          <Tooltip
            title="Settings"
            arrow
            placement="right"
            sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "black" } }}
          >
            <div
              className="mini-logos-wrap"
              onClick={() => navigate(`/settings`)}
              style={iconStyle(`settings`)}
            >
              <IconButton>
                <img src={SettingsIcon} alt="S" width={24} height={24} />
              </IconButton>
            </div>
          </Tooltip>

          {/* <Switch
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              transform: "rotate(270deg)",
            }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default MiniSidebar;
