import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NewLayout from '../../Components/SidebarLayout'

import React,{useState} from 'react'
import { Grid,Typography } from '@mui/material';
import NotifyPopup from '../../Components/NotifyPopup';
import Pagination from '@mui/material/Pagination';
import Notify from '../../Components/Notify';


function NotificationAdmin() {
  const [type, setType] = useState('ALL')
  const data = [
    { name: 'vish', text: 'Anna Srzand has accepted the Aadhar file and left a comment in the Ticket   ',ticket:"#134554", time: '2h', view: false, ad: true },
    { name: 'vish', text: 'Aadhar uploaded recently',ticket:"#134554", time: '2h', view: false, ad: false },
    { name: 'vish', text: 'Aadhar uploaded recently', ticket:"#134554",time: '2h', view: true, ad: false },
    { name: 'vish', text: 'Aadhar uploaded recently',ticket:"#134554", time: '2h', view: false, ad: false },
    { name: 'vish', text: 'Aadhar uploaded recently',ticket:"#134554", time: '2h', view: false, ad: false },
    { name: 'vish', text: 'Aadhar uploaded recently',ticket:"#134554", time: '2h', view: false, ad: false },
    { name: 'vish', text: 'Aadhar uploaded recently',ticket:"#134554", time: '2h', view: false, ad: true },
    { name: 'vish', text: 'Aadhar uploaded recently', ticket:"#134554",time: '2h', view: true, ad: false },
  
  ];
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const paths = [
    { label: `Notifications`, navigation: "notifications" },
  ];

  return (
 <>
 <NewLayout paths={paths} pageHeading={"Notifications"}>
    <Grid container  flexDirection="row-reverse">
      <button className='btn-mark'>Mark all as read</button>  
     </Grid>
    <Grid className='Notifynav'>
    <Grid item className="Notifynav1">
            <Typography className={`typo1 ${type === "ALL" ? 'typoselect' : ''}`} onClick={() => setType('ALL')}>All</Typography>
            <Typography className={`typo1 ${type === "SERVICE" ? 'typoselect' : ''}`} onClick={() => setType('SERVICE')}>Service</Typography>
            <Typography className={`typo1 ${type === "GENERAL" ? 'typoselect' : ''}`} onClick={() => setType('GENERAL')}>General</Typography>
          </Grid>
        </Grid> 
    {currentData.map((item, index) => (
        <Notify
          key={index}
          name={item.name}
          text={item.text}
          time={item.time}
          view={item.view}
          ad={item.ad}
        />
      ))}
        <Grid container className="NotifyPagination">
      <Pagination
        count={Math.ceil(data.length / itemsPerPage)}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
      />
      </Grid>
    {/* <Grid className='notif-popup-wrap'>
<Grid container className='np-w1'>
  <Grid className='np-g1'>
    <p>
      Notifications
    </p>
  </Grid>
  <Grid className='np-g2'>
<button className='btn-mark'>View</button>
  </Grid>
</Grid>
<Grid container className='np-w1'>
      <Grid container  className='np-w1 np-g11'>
        <Typography className='typo1 typoselect'>All</Typography>
        <Typography className='typo1'>Service</Typography>
        <Typography className='typo1'>General</Typography>
       </Grid>
        </Grid> 
        {currentData.slice(0, 3).map((item, index) => (
        <NotifyPopup
          key={index}
          name={item.name}
          text={item.text}
          ticket={item.ticket}
          time={item.time}
          view={item.view}
          ad={item.ad}
        />
      ))}
    </Grid> */}
 </NewLayout>
 </>
  )
}

export default NotificationAdmin