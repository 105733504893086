import React, { useState, useEffect } from "react";
import DashBoard from "./DashBoard";
import DashboardAdmin from "../DashboardAdmin";
import AlertDialog from "../../Components/AlertDialog";
import { Typography, TextField, Button } from "@mui/material";
import { phoneRegExp } from "../../Utilities/regex";
import { useApi, ApiService, CustomizedSnackbar } from "../../Utilities";
import OTPInput from "otp-input-react";
import { eNums } from "../../constants";

const UserDashBoard = () => {
  const userID = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const mobileNo = localStorage.getItem("mobile");
  const mobileVerified = JSON.parse(localStorage.getItem("mobileVerified"));
  const [openDialog, setOpenDialog] = useState(false);
  const [enableOTP, setEnableOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [phoneNo, setPhoneNo] = useState(mobileNo || '');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const { action: requestOTP, snackbarComponent, loading: validateLoading, error: validateError } = useApi(
    ApiService.requestOTP,
    {
      success: true,
      error: true,
      noStore: true,
      initialLoad: true,
    }
  );

  const { action: verifyMobileNumber, loading: verifyLoading, snackbarComponent: verifyComponent, error: verifyError } = useApi(
    ApiService.verifyUserMobileOrEmail,
    {
      success: true,
      error: true,
      noStore: true,
      initialLoad: true,
      actionName: 'Mobile Number Verified'
    }
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (userType?.includes('AGENT') && !mobileVerified) {
      setOpenDialog(true);
    }
  }, [mobileVerified]);

  useEffect(() => {
    if (!validateLoading && !validateError) {
      setEnableOTP(true);
    }
  }, [validateLoading, validateError]);

  useEffect(() => {
    if (!verifyLoading && !verifyError) {
      setEnableOTP(false);
      setOpenDialog(false);
      localStorage.setItem('mobileVerified', true)
      localStorage.removeItem('mobile')
    }
  }, [verifyLoading, verifyError]);
  
  const sendOTP = async (event) => {
    event.preventDefault();

    if (!phoneNo) {
      handleSnackbar("Please Enter Phone No", "error");
      return;
    }
    if (!phoneRegExp.test(phoneNo)) {
      handleSnackbar("Invalid Phone No", "error");
      return;
    }
    let obj = { userID, userType};
    if(mobileNo){
      obj.mobile = phoneNo
    }
    setLoading(true)
    await requestOTP(obj);
    setOTP(null)
    setLoading(false)
  };

  const verifyMobile = async (event) => {
    event.preventDefault();

    if (!(OTP?.length === 6)) {
      handleSnackbar("Please Enter OTP", "error");
      return;
    }
    setLoading(true)
    let obj = { userID, otp: OTP, mobile: phoneNo, userType };
    
    await verifyMobileNumber(obj);
    setLoading(false)
  };

  return (
    <>
      {snackbarComponent}{verifyComponent}
      {userType === eNums?.CLIENT ? <DashBoard /> : <DashboardAdmin />}
      {openDialog ? (
        <AlertDialog
          title={"Mobile Number Verification"}
          openDialog={openDialog}
          handleClose={handleClose}
          handleAction={verifyMobile}
          loading={loading}
          loadingText={"Verifying..."}
          actionText={"Verify"}
          noCancelBtn={true}
          noActionBtn={enableOTP ? false : true}
          backdropClose={true}
          noCloseIcon={true}
          showLogout={true}
          children={
            <>
              <form className="d-flex align-items-center py-2 px-0 gap-2" onSubmit={sendOTP}>
                <TextField
                  onChange={(e) => {
                    setEnableOTP(false)
                    setPhoneNo(e?.target?.value);
                  }}
                  id="outlined-basic"
                  label="Phone No"
                  variant="outlined"
                  className="auth-input"
                  size="small"
                  value={phoneNo}
                />
                {enableOTP ? null : (
                  <Button
                    variant="contained"
                    type='submit'
                    className="verify-otp-btn-dashboard"
                  >
                    <span>{loading ? 'Sending...' : 'Send OTP'}</span>
                  </Button>
                )}
              </form>
              {enableOTP ? (
                <form onSubmit={verifyMobile} className="d-flex flex-column align-items-center gap-3 justify-content-center mt-3 ms-1">
                  <span className="otp-verification-text align-self-start">
                    OTP Verification
                  </span>
                  <div className="w-100">
                    <OTPInput
                      inputStyle={"inputstyle_indv"}
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />
                  </div>
                  <Typography
                    variant="body2"
                    onClick={sendOTP}
                    fontWeight="700"
                    color={"#1E68F9"}
                    fontSize={"10px"}
                    className="cursor-pointer align-self-start ms-1 text-decoration-underline"
                  >
                    Resend OTP ?
                  </Typography>

                  <button type="submit" className="d-none"></button>
                </form>
              ) : null}
            </>
          }
        />
      ) : null}
      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
    </>
  );
};

export default UserDashBoard;
