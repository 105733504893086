import React, { useState } from "react";
import ServicesSideBar from "../../Components/ServicesSideBar";
import logo from "../../assets/images/FileNowLogo.svg";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NewServiceCard from "../../Components/NewServiceCard";
import Header from "../../Components/Header";

const statesInIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

function NewService() {
  const [state, setState] = useState("select State");

  const handleChange = (event) => {
    setState(event.target.value);
    // alert(statesInIndia[Number(event.target.value)])
  };

  return (
    <>
         <div style={{display:"flex"}}>

     
<div style={{width:"20% "}}>
<ServicesSideBar />

</div>
<div style={{width:"80%"}}>

      <div className="flexrowcont">
        <div className="logo-Container">
          <img src={logo} className="logoimg" />
        </div>
        <div className="navigationContainer">
          <div className="navigationText">
            {"Home>My Services>"}
            <p className="navigationText2">Company Name Change</p>
          </div>
        </div>
        </div>

        <div className=" flexrowcont">
        
          <div className="myServicesContainer">
            <div className="HeadingsearchContainer">
              <div className="heading">New Service</div>
              <div className="newService_select">
                <Select
                  sx={{ height: "30px", width: "190px", color: "black" }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={state}
                  placeholder="Select State"
                  // label="Select State"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {statesInIndia.map((item, index) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className="SearchContainer">
                <input className="Searchinput" placeholder="Search a service" />
                <SearchIcon className="searchIcon" />
              </div>
            </div>
            <div className="newService_addContainer ">
              <div style={{ height: "120px" }}></div>
            </div>

            <div className="newser_main_Container">
              <div className="newServ_title">New Service</div>
              <div className="newser_cardContain">
                {[1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <NewServiceCard
                      title={"Digital Signature Certificate"}
                      serviceinfo={"Service Raised - 12-05-2023"}
                      price={"10000"}
                      onClickBuyNow={() => {
                        alert("BuyNow");
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="newser_main_Container">
              <div className="newServ_title">New Service</div>
              <div className="newser_cardContain">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                  return (
                    <NewServiceCard
                      title={"Digital Signature Certificate"}
                      serviceinfo={"Service Raised - 12-05-2023"}
                      price={"10000"}
                      onClickBuyNow={() => {
                        alert("BuyNow");
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="newser_main_Container">
              <div className="newServ_title">New Service</div>
              <div className="newser_cardContain">
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <NewServiceCard
                      title={"Digital Signature Certificate"}
                      serviceinfo={"Service Raised - 12-05-2023"}
                      price={"10000"}
                      onClickBuyNow={() => {
                        alert("BuyNow");
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default NewService;
