import React from 'react'
import LegalDocumentation from './LegalDocumentation'
import LegalDocumentationAdmin from './LegalDocumentationAdmin'
import { eNums } from '../../constants'

const LegalDocuments = () => {
    const userType = localStorage.getItem('userType')
   
    return userType === eNums?.CLIENT ? <LegalDocumentation /> : <LegalDocumentationAdmin/>
}

export default LegalDocuments