import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import NewLayout from "../../Components/SidebarLayout";
import { useApi, ApiService, EmptyState, CustomQuill, CustomizedSnackbar } from "../../Utilities";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

function ComplianceCalendar() {
  const [events, setEvents] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const {
    action: getAllEvents,
    data: allEvents,
    snackbarComponent: allEventsSnackbarComponent,
    error: allEventsError,
    loading: allEventsLoading,
  } = useApi(ApiService.getAllEvents, {
    success: true,
    error: true,
    actionName: "Event fetching",
  });

  const currentMonthNumber = moment().month();
  useEffect(() => {
    handleMonthChange(currentMonthNumber);
  }, []);

  const [months, setMonths] = useState([
    { label: "JAN", value: 0, clicked: false, name: "January" },
    { label: "FEB", value: 1, clicked: false, name: "February" },
    { label: "MAR", value: 2, clicked: false, name: "March" },
    { label: "APR", value: 3, clicked: false, name: "April" },
    { label: "MAY", value: 4, clicked: false, name: "May" },
    { label: "JUN", value: 5, clicked: false, name: "June" },
    { label: "JUL", value: 6, clicked: false, name: "July" },
    { label: "AUG", value: 7, clicked: false, name: "August" },
    { label: "SEP", value: 8, clicked: false, name: "September" },
    { label: "OCT", value: 9, clicked: false, name: "October" },
    { label: "NOV", value: 10, clicked: false, name: "November" },
    { label: "DEC", value: 11, clicked: false, name: "December" },
  ]);

  useEffect(() => {
    if (allEvents?.length) {
      const modifiedEvents = allEvents.map((event) => {
        return {
          ...event,
          saved: true,
          clicked: false,
        };
      });

      setEvents(modifiedEvents);
    } else {
      setEvents([]);
    }
  }, [allEvents]);

  const handleClickEvent = (index) => {
    if (
      events &&
      events?.filter((item) => !item?.saved)?.length &&
      events?.[index]?.saved
    ) {
      handleSnackbar("Please save current event", "error");
    } else {
      let updatedData =
        (events &&
          events?.length &&
          events.map((item, i) => ({
            ...item,
            clicked: i === index ? true : false,
          }))) ||
        [];

      setEvents(updatedData);
    }
  };

  const handleMonthChange = (monthNumber) => {
    const updatedMonths = months?.map((month) => ({
      ...month,
      clicked: month?.value === monthNumber ? true : false,
    }));
    setMonths(updatedMonths);
    setMonthName(months?.find((item) => item?.value === monthNumber)?.name);

    const selectedMonth = moment().month(monthNumber).year(selectedYear);
    const firstDay = selectedMonth.startOf("month");
    const formattedFirstDay = firstDay.format('YYYY-MM-DD');
    const lastDay = selectedMonth.endOf("month");
    const formattedLastDay = lastDay.format('YYYY-MM-DD');

    getAllEvents({ fromDate: formattedFirstDay, toDate: formattedLastDay });
  };

  const handleYearChange = (value) => {
    setSelectedYear(value.$y);
  };

  const paths = [
    { label: `Compliance Calendar`, navigation: "compliance-calendar" },
  ];

  return (
    <>
      <Grid container className="d-flex">
        <NewLayout
          paths={paths}
          pageHeading={"Compliance Calendar"}
        >
          <Grid item className="wrap-settings-splitB">
            <Grid item container>
              <div className="w-100 d-flex justify-content-end my-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year"]}
                    label="Select Year"
                    value={dayjs().set("year", selectedYear)}
                    onChange={handleYearChange}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        width: "10rem",
                        height: "2rem",
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <Grid container className="cc-calendar">
                {months?.map((month) => (
                  <Grid
                    key={month}
                    className="cc-month-wrap cursor-pointer"
                    onClick={() => handleMonthChange(month?.value)}
                  >
                    <Grid
                      item
                      className="cc-month"
                      style={{
                        backgroundColor: month?.clicked ? "#b2ccfb" : "white",
                      }}
                    >
                      <Typography>{month?.label}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid container className="cc-month-wrap">
                <Typography fontWeight="bold">
                  {monthName || ""} Compliances
                </Typography>
              </Grid>

              <Grid
                className="d-flex w-100"
                style={{ height: "70vh", minWidth: '1000px' }}
                sx={{ border: "0.5px solid #9D9D9D" }}
              >
                <Grid
                  className="w-25"
                  style={{ borderRight: "0.5px solid #9D9D9D" }}
                >
                  <Grid style={{ height: "100%", overflowY: "auto" }}>
                    {events?.length
                      ? events?.map((item, index) => (
                          <form
                            key={index}
                            className="d-flex cursor-pointer align-items-center w-100 cursor-pointer"
                            style={{
                              ...(item?.clicked && {
                                backgroundColor: "#F2F2F2",
                              }),
                              height: "15%",
                              borderBottom: "0.5px solid #9D9D9D",
                              paddingInline: "1rem",
                              paddingBlock: ".5rem",
                            }}
                            onClick={() => handleClickEvent(index)}
                          >
                            <div>
                              <Grid
                                item
                                display="flex"
                                style={{ alignItems: "center" }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  Due Date
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontWeight="thin"
                                  style={{ marginLeft: ".5rem" }}
                                >
                                  {item?.dueDate}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                display="flex"
                                style={{
                                  wordBreak: "break-word",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="body2 d-flex flex-wrap">
                                  {item?.eventName}
                                </Typography>
                              </Grid>
                            </div>
                          </form>
                        ))
                      : <EmptyState/>}
                  </Grid>
                </Grid>

                <Grid className="w-75">
                  {events?.filter((item) => item?.clicked)?.length ? (
                    <>
                      <div
                        className="h-100"
                      >
                        <Grid
                          className="d-flex align-items-center justify-content-between"
                          item
                          style={{
                            height: "15%",
                            borderBottom: "0.5px solid #9D9D9D",
                            paddingInline: "1rem",
                            paddingBlock: ".64rem",
                          }}
                        >
                          {events?.filter(
                            (item) => item?.clicked && item?.saved
                          )?.length ? (
                            <div className="flex-column">
                              <Grid
                                item
                                display="flex"
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  {
                                    events?.find((item) => item?.clicked)
                                      ?.eventName
                                  }
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                display="flex"
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  Due Date:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontWeight="thin"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  {
                                    events?.find((item) => item?.clicked)
                                      ?.dueDate
                                  }
                                </Typography>
                              </Grid>
                            </div>
                          ) : null}
                        </Grid>

                        <Grid
                          item
                          className="h-75"
                          style={{
                            paddingInline: "2rem",
                            paddingTop: "1rem",
                            overflow: "auto"
                          }}
                        >
                         <CustomQuill
                              value={
                                events?.find((item) => item?.clicked)?.context ||
                                ""
                              }
                              readOnly={true}
                            />
                        </Grid>

                        {/* {events?.filter((item) => item?.clicked && item?.saved)
                          ?.length ? (
                          <Grid
                            className="d-flex align-items-center justify-content-end w-100"
                            container
                            style={{ height: "10%", borderTop: "0.5px solid #9D9D9D" }}
                          >
                            <Button
                              variant="contained"
                              className="cc-btn1 cursor-pointer py-2 me-2"
                            >
                              File TDS Payment
                            </Button>
                          </Grid>
                        ) : null} */}
                      </div>
                    </>
                  ) : <EmptyState/>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {snackbar?.open ? (
            <CustomizedSnackbar
              open={snackbar?.open}
              message={snackbar?.message}
              severity={snackbar?.severity}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
          ) : null}
        </NewLayout>
      </Grid>
    </>
  );
}

export default ComplianceCalendar;
