import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Utilities/axiosInstance";
import Loader from "../../Components/Loader";
import Layout from "../../Components/Layout";
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import UserNamePlaceHolderIcon from "../../assets/images/username-placeholder-icon.svg";
import PasswordPlaceHolderIcon from "../../assets/images/password-placeholder-icon.svg";
import Filenowlogo from "../../assets/images/sidebar_filenow_logo.svg";
import GoogleIcon from "../../assets/images/google.svg";
import { emailRegExp, phoneRegExp } from "../../Utilities/regex";
import CustomizedSnackbar from "../../Utilities/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { eNums } from "../../constants";
import { usePermissions } from "../../PermissionsContext";

function SignIn() {
  let navigate = useNavigate();
  const { setRolePermissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    emailorphone: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const LogIn = async (isEmail, isMobile) => {
    var body = {
      password: data.password,
      userType: eNums?.AGENT
    };
    if (isEmail) {
      body.email = data?.emailorphone;
    }
    if (isMobile) {
      body.mobile = data?.emailorphone;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post(`auth/login`, body);
      var res = response;
      if (res.accessToken) {
        var token = res.accessToken;
        localStorage.setItem("token", token);
        if (res?.userData?.length) {
          await localStorage.setItem(
            "mobileVerified",
            res?.userData?.[0]?.mobileVerified
          );
          await localStorage.setItem("userId", res?.userData?.[0]?._id);
          await localStorage.setItem("roleID", res?.userData?.[0]?.roleID?._id || null);
          await localStorage.setItem("roleName", res?.userData?.[0]?.roleID?.name || null);
          await localStorage.setItem("role_level", res?.userData?.[0]?.roleID?.level || null);
          await localStorage.setItem("userType", res?.userData?.[0]?.userType);
          await localStorage.setItem(
            "user_name",
            res?.userData?.[0]?.fullname || ""
          );
          if (!res?.userData?.[0]?.mobileVerified) {
            await localStorage.setItem(
              "mobile",
              res?.userData?.[0]?.mobile || ""
            );
          }
          if(res?.userData?.[0]?.roleID?.permissions){
            setRolePermissions(res?.userData?.[0]?.roleID?.permissions);
          }
        }
        navigate(`/dashboard`);
      } else {
        handleSnackbar(res?.msg || "Something went wrong", "error");
        return;
      }
    } catch (error) {
      let msg =
        error?.response?.data?.msg ||
        error?.response?.data?.error ||
        "Something went wrong, Please try again!";
        console.log('login error --->', error)
      handleSnackbar(msg, "error");
    } finally {
      setLoading(false);
    }
  };

  const onSignIn = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const { emailorphone, password } = data;

    if (!emailorphone && !password) {
      handleSnackbar("Please enter Email / Phone no & password", "error");
      return;
    }
    if (!emailorphone) {
      handleSnackbar("Please enter Email / Phone no", "error");
      return;
    }
    if (
      !emailRegExp.test(emailorphone) &&
      !phoneRegExp.test(data?.emailorphone)
    ) {
      handleSnackbar("Invalid Email / Phone no", "error");
      return;
    }
    if (!password) {
      handleSnackbar("Please enter Password", "error");
      return;
    }
    let isEmail = emailRegExp?.test(data?.emailorphone);
    let isMobile = phoneRegExp?.test(data?.emailorphone);

    await LogIn(isEmail, isMobile);
  };

  return (
    <>
      <Layout>
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item className="wrapC1">
            <div className="d-flex flex-column align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center gap-2">
                <img src={Filenowlogo} alt="" width={30} height={30} />
                <span className="filenow-title-text">FILENOW</span>
              </div>
              <div className="wrapC1">
                <Typography variant="body1" fontWeight="700">
                  Sign in to your Account
                </Typography>
              </div>
              {/* <Grid item container className="wrapC1">
                <Button
                  variant="contained"
                  className="d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: "#1E68F9", minWidth: "250px" }}
                  // onClick={login}
                >
                  <div className="google-img d-flex align-items-center justify-content-center">
                    <img src={GoogleIcon} alt="" />
                  </div>
                  <span className="buttonText">Sign in with Google</span>
                </Button>
              </Grid> */}
              
            </div>
            {/* <span className="auth-or-text mt-2 d-flex justify-content-center w-100">
              or
            </span> */}
            <Grid item container className="wrapC1 pt-0">
              <form onSubmit={onSignIn} className="d-flex flex-column gap-2">
                <Grid item container className="wrapC1">
                  <TextField
                    id="outlined-basic"
                    label="Email / Phone no"
                    variant="outlined"
                    className="auth-input"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={UserNamePlaceHolderIcon}
                            alt=""
                            width={18}
                            height={18}
                          />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, emailorphone: e.target.value };
                      });
                    }}
                  />
                </Grid>
                <Grid item container className="wrapC1">
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    className="auth-input"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    value={data.password}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={PasswordPlaceHolderIcon}
                            alt=""
                            width={18}
                            height={18}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item className="py-1 d-flex align-items-center">
                  <Checkbox onChange={(e) => {}} />
                  <Typography variant="body2">Remember Password</Typography>
                </Grid>
                <Grid item container className="wrapC1">
                  <Button
                    variant="contained"
                    type="submit"
                    className="w-100 action-btn"
                  >
                    <span className="action-btn-text">Sign In</span>
                  </Button>
                </Grid>

                <Grid item container className="ms-2 mt-1 gap-1">
                  <Typography
                    variant="body2"
                    fontWeight="700"
                    color={"#403E4C"}
                    fontSize={"15px"}
                  >
                    Forgot Password ?
                  </Typography>
                  <Typography
                    variant="body2"
                    onClick={() => {
                      navigate("/resetPassword", {
                        state: { userType: "AGENT" },
                      });
                    }}
                    fontWeight="700"
                    color={"#1E68F9"}
                    fontSize={"15px"}
                    className="cursor-pointer ms-1"
                  >
                    Reset Here
                  </Typography>
                </Grid>
              </form>
            </Grid>

            {/* <Grid item container className="wrapC1 mt-4 gap-1">
              <Typography
                variant="body2"
                fontWeight="700"
                color={"#403E4C"}
                fontSize={"15px"}
              >
                Don’t Have an Account ?
              </Typography>
              <Typography
                variant="body2"
                onClick={() => {
                  navigate("/register");
                }}
                fontWeight="700"
                color={"#1E68F9"}
                fontSize={"15px"}
                className="cursor-pointer ms-1"
              >
                Create One
              </Typography>
            </Grid> */}
            <Grid container item className="wrapC1"></Grid>
            <Grid container item className="wrapC1"></Grid>
          </Grid>
        </Grid>
      </Layout>

      {loading === true ? <Loader /> : null}

      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
    </>
  );
}

export default SignIn;
