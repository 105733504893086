import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Filenowlogo from "../../assets/images/sidebar_filenow_logo.svg";
import { useLocation } from "react-router-dom";

import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import Layout from "../../Components/Layout";
import CustomizedSnackbar from "../../Utilities/Alert";
import axiosInstance from "../../Utilities/axiosInstance";
import { useApi, ApiService } from "../../Utilities";
import { emailRegExp, phoneRegExp } from "../../Utilities/regex";

const OTPScreen = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [OTP, setOTP] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const { action: requestOTP } = useApi(ApiService.requestOTP, {
    success: true,
    error: true,
    noStore: true,
  });

  const SubmitOtp = async (e) => { 
    e.preventDefault()
    var otp = localStorage.getItem("otp");
    // otp which is set in the local storage is console logged and naviageted to otp page
    setLoading(true);
    if (otp === OTP) {
      try {
        const response = await axiosInstance.post(
          `auth/register`,
          state?.createUserPayload
        );
        if (response) {
          handleSnackbar("User Registered Successfully!", "success");
          setTimeout(() => {
            navigate("/signIn");
          }, 1000);
        } else {
          handleSnackbar("Something went wrong", "error");
        }
      } catch (error) {
        let msg =
          error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Something went wrong!";
        handleSnackbar(msg, "error");
      } finally {
        setLoading(false);
      }
    } else {
      handleSnackbar("Invalid Otp", "error");
    }
    setLoading(false);
  };

  const OnResendOTP = async (event) => {
    event.preventDefault();

    if (!state?.createUserPayload?.emailPhone) {
      handleSnackbar("Please enter Email / Phone", "error");
      return;
    }
    if (
      !emailRegExp.test(state?.createUserPayload) &&
      !phoneRegExp.test(state?.createUserPayload)
    ) {
      handleSnackbar("Invalid Email / Phone", "error");
      return;
    }
    let isEmail = emailRegExp?.test(state?.createUserPayload);
    let isMobile = phoneRegExp?.test(state?.createUserPayload);
    let obj = {};
    if (isEmail) {
      obj.email = state?.createUserPayload;
    }
    if (isMobile) {
      obj.mobile = state?.createUserPayload;
    }
    await requestOTP(obj);
  };

  return (
    <Layout>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <div className="d-flex flex-column align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center gap-2">
                <img src={Filenowlogo} alt="" width={30} height={30} />
                <span className="filenow-title-text">FILENOW</span>
              </div>
              <div className="wrapC1">
                <Typography variant="body1" fontWeight="700" fontSize={"16px"}>
                  Verfity Your Account
                </Typography>
              </div>
            </div>

          <form onSubmit={SubmitOtp} className="mt-5">
            <div className="d-flex flex-column gap-1">
              <span className="otp-verification-text">OTP Verification</span>
              <div className="d-flex flex-column align-items-center gap-3 justify-content-center mt-3">
                <OTPInput
                  containerStyle={"otpinputContainer"}
                  inputStyle={"inputstyle_indv"}
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />

                <Button
                  variant="contained"
                  onClick={OnResendOTP}
                  className="resend-otp-btn mt-3"
                >
                  <span>Resend OTP</span>
                </Button>
              </div>
            </div>

            <Grid item container className="wrapC1 mt-3">
              <Button
                variant="contained"
                type="submit"
                className="w-100 confirm-btn"
                disabled={loading}
              >
                <span className="action-btn-text">
                  {loading ? "Verifying..!" : "Verify Account"}
                </span>
              </Button>
            </Grid>
          </form>
       
      </Grid>
      {snackbar?.open ? (
        <CustomizedSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      ) : null}
      {loading === true ? <Loader /> : null}
    </Layout>
  );
};

export default OTPScreen;
