import {Route, Routes } from "react-router-dom";
import LandingPage from "../landing/LandingPage";
import ChooseaService from "../chooseAService/ChooseaService";
import Register from "../Register/Register";
import OTPScreeen from "../otp/OTPScreen";
import ResetPassword from "../ResetPassword/ResetPassword";
import AllServices from "../Services/Services";
import MyServices from "../myServices/MyServices";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import DashBoard from "../dashBoard";
import NewService from "../newService/NewService";
import LegalDocumentation from "../legalDocumentation";
import OrderAndPayments from "../orderAndPayments";
import SignIn from '../signIn/SignIn';
import ClientSignIn from '../signIn/ClientSignIn';
import EnterNewPassword from '../EnterNewPassword/EnterNewPassword';
import Notifications from '../Notifications';
import CreateAccount from '../createAccount/CreateAccount';
import SettingsLayout from '../Settings';
import ComplianceCalendar from "../ComplianceCalendar";
import ClientDetails from "../clientDetails";
import ServiceManagement from "../serviceManagement/ServiceManagement";
import ServiceManagementCreateCategory from "../ServiceManagementCreateCategory/NewSMFile";
import CustomerManagement from "../CustomerManagement/NewCMFile";
import EmployeeManagement from "../EmployeeManagement/EmployeeManagement";
import RedirectIfAuthenticated from "./RedirectToDashboard";
import ProtectedRoute from "./ProtectedRoute";
import TermsAndConditions from "../Policies/TermsAndConditions";
import PrivacyPolicy from "../Policies/PrivacyPolicy";
import CookiesPolicy from "../Policies/CookiesPolicy";
import RefundPolicy from "../Policies/RefundPolicy";
import { usePermissions } from "../../PermissionsContext";

function Root() {
  
  const { permissions } = usePermissions();

  return (
    <>
      <Routes>
        <Route path="/" element={<RedirectIfAuthenticated><LandingPage /></RedirectIfAuthenticated>} />
        <Route path="/register" element={<RedirectIfAuthenticated><Register /></RedirectIfAuthenticated>} />
        <Route path="/signIn" element={<RedirectIfAuthenticated><SignIn /></RedirectIfAuthenticated>} />
        <Route path="/terms_and_conditions" element={<RedirectIfAuthenticated><TermsAndConditions /></RedirectIfAuthenticated>} />
        <Route path="/privacy_policy" element={<RedirectIfAuthenticated><PrivacyPolicy /></RedirectIfAuthenticated>} />
        <Route path="/cookies_policy" element={<RedirectIfAuthenticated><CookiesPolicy /></RedirectIfAuthenticated>} />
        <Route path="/refund_policy" element={<RedirectIfAuthenticated><RefundPolicy /></RedirectIfAuthenticated>} />
        <Route path="/agent/signIn" element={<RedirectIfAuthenticated><SignIn /></RedirectIfAuthenticated>} />
        <Route path="/resetPassword" element={<RedirectIfAuthenticated><ResetPassword /></RedirectIfAuthenticated>} />
        <Route path="/otp" element={<RedirectIfAuthenticated><OTPScreeen /></RedirectIfAuthenticated>} />
        <Route path="/enternewpassword" element={<RedirectIfAuthenticated><EnterNewPassword /></RedirectIfAuthenticated>} />
       

        <Route
          path="/choose-a-service"
          element={
            <ProtectedRoute>
              <ChooseaService />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-services"
          element={
            <ProtectedRoute allowedUserTypes={['CLIENT']}>
              <AllServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-services"
          element={
            <ProtectedRoute allowedUserTypes={['CLIENT']}>
              <MyServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-details"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.serviceManagement?.enabled && permissions?.serviceManagement?.submodules?.serviceDetails?.enabled ? 'AGENT' : '', 'CLIENT']}>
              <ServiceDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashBoard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/NewService"
          element={
            <ProtectedRoute>
              <NewService />
            </ProtectedRoute>
          }
        />
        <Route
          path="/legal-documentation"
          element={
            <ProtectedRoute>
              <LegalDocumentation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders-and-payments"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.ordersAndPayments?.enabled ? 'AGENT' : '', 'CLIENT']}>
              <OrderAndPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create"
          element={
            <ProtectedRoute>
              <CreateAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.notifications?.enabled ? 'AGENT' : '', 'CLIENT']}>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsLayout />
            </ProtectedRoute>
          }
        >
        </Route>
        
        <Route
          path="/compliance-calendar"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.complianceCalendar?.enabled ? 'AGENT' : '', 'CLIENT']}>
              <ComplianceCalendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/client-details" 
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.clientManagement?.enabled && permissions?.clientManagement?.submodules?.clientDashboard?.enabled ? 'AGENT' : '']}>
              <ClientDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-management"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.serviceManagement?.enabled && permissions?.serviceManagement?.submodules?.serviceDashboard?.enabled ? 'AGENT' : '']}>
              <ServiceManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-management"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.employeeManagement?.enabled ? 'AGENT' : '']}>
              <EmployeeManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/service-create-category"
          element={
            <ProtectedRoute allowedUserTypes={[permissions?.serviceManagement?.enabled && permissions?.serviceManagement?.submodules?.serviceManagement?.enabled ? 'AGENT' : '']}>
              <ServiceManagementCreateCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customer-management"
          element={
            <ProtectedRoute>
              <CustomerManagement />
            </ProtectedRoute>
          }
        />
       
      </Routes>
    </>
  );
}

export default Root;
