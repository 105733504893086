import React, { useState, useEffect, useRef } from "react";
import NewLayout from "../../Components/SidebarLayout";
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import icon1 from "../../assets/images/tick_blue.svg";
import icon2 from "../../assets/images/icon_client_details_2.svg";
import ServicesCard from "../../Components/ServicesCard";
import { useApi, ApiService, EmptyState, useRolePermissions } from "../../Utilities";
import { useNavigate } from "react-router";
import SearchIcon from "@mui/icons-material/Search";
import { Placeholder } from "react-bootstrap";
import { eNums } from "../../constants";

function ServiceManagement() {

  const Permissions = useRolePermissions();
  const navigate = useNavigate();
  const [allServices, setAllServices] = useState([]);
  const [type, setType] = useState("");
  const [serviceName, setServiceName] = useState("");

  const {
    action: getAllServices,
    data: services,
    snackbarComponent: getAllServicesSnackbarComponent,
    loading: serviceLoading,
    error: servicesError,
  } = useApi(ApiService.getAllServices, {
    error: true,
    actionName: "Services fetching",
  });

  const [activeKey, setActiveKey] = useState(""); // State for active category key
  const categoryRefs = useRef({}); // Ref to store category elements

  useEffect(() => {
    getAllServices({ serviceStatus: "active", isDeleted: false });
  }, []);

  useEffect(() => {
    if (!serviceLoading && !servicesError && services?.length) {
      const groupedServices = {};

      services.forEach((service) => {
        const categoryName = service.categoryID
          ? service?.categoryID?.categoryName?.toString()
          : null;
        if (categoryName) {
          if (!groupedServices[categoryName]) {
            groupedServices[categoryName] = [];
          }
          groupedServices[categoryName].push(service);
        }
      });

      setAllServices([groupedServices]);
    } else {
      setAllServices([]);
    }
  }, [services, serviceLoading, servicesError]);

  const handleTypeChange = (type) => {
    setType(type);
    let obj = { serviceStatus: "active", isDeleted: false };
    if (type) {
      obj.serviceType = type;
      if (serviceName) {
        obj.serviceName = serviceName;
      }
      getAllServices(obj);
    } else if (serviceName) {
      obj.serviceName = serviceName;
      getAllServices(obj);
    } else {
      getAllServices(obj);
    }
  };
  const handleSearch = (value) => {
    setServiceName(value);

    let obj = { serviceStatus: "active", isDeleted: false };
    if (value?.length > 2) {
      obj.serviceName = value;
      if (type) {
        obj.serviceType = type;
      }
      getAllServices(obj);
    } else if (!value) {
      if (type) {
        obj.serviceType = type;
        getAllServices(obj);
      } else {
        getAllServices(obj);
      }
    }
  };

  const paths = [
    { label: `Service Management`, navigation: "service-management" },
  ];

  // Create refs for each category dynamically
  const createRefsForCategories = () => {
    const groupedServices = allServices[0];
    if (groupedServices) {
      Object.keys(groupedServices).forEach((category) => {
        if (!categoryRefs.current[category]) {
          categoryRefs.current[category] = React.createRef();
        }
      });
    }
  };

  // Call this function to create refs
  useEffect(() => {
    createRefsForCategories();
  }, [allServices]);

  // const scrollToCategory = (key) => {
  //   if (categoryRefs.current[key]) {
  //     categoryRefs.current[key].scrollIntoView({ behavior: "smooth" });
  //     setActiveKey(key);
  //   }
  // };

  
  const scrollToCategory = (key) => {
    if (categoryRefs.current[key]) {
      categoryRefs.current[key].scrollIntoView({
        behavior: "smooth",
        block: "nearest", // Prevents UI height disturbance
        inline: "nearest", // Adjust horizontal alignment if needed
      });
      setActiveKey(key);
    }
  };

  return (
    <>
      <NewLayout paths={paths} pageHeading={"Service Management"}>
        {getAllServicesSnackbarComponent}
        <Grid container className="cd-wrap-1">
          <Grid item className="sm-wrap-2">
            <div item className="cd-section-1">
              <Grid item className="bar-cd blue-cd">
                <Grid item>
                  <img src={icon1} />
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" variant="subtitle2">
                    Total Categories
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {allServices?.length
                      ? Object.keys(allServices?.[0])?.length || 0
                      : 0}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="cd-section-1">
              <Grid item className="bar-cd orange-cd">
                <Grid item>
                  <img src={icon2} />
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" variant="subtitle2">
                    Total Services
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {services?.length || 0}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid></Grid>
        <div className="w-100 mt-3 d-flex align-items-center gap-2 flex-wrap">
          <Grid
            item
            className={`sm-w-b-t cursor-pointer ${
              type === "" ? "sm-w-b-b" : ""
            }`}
            onClick={() => handleTypeChange("")}
          >
            <Typography variant="subtitle">All</Typography>
          </Grid>
          <Grid
            item
            className={`sm-w-b-t cursor-pointer ${
              type === "Individual" ? "sm-w-b-b" : ""
            }`}
            onClick={() => {
              handleTypeChange("Individual");
            }}
          >
            <Typography variant="subtitle">Individual</Typography>
          </Grid>
          <Grid
            item
            className={`sm-w-b-t cursor-pointer ${
              type === "Business" ? "sm-w-b-b" : ""
            }`}
            onClick={() => {
              handleTypeChange("Business");
            }}
          >
            <Typography variant="subtitle">Business</Typography>
          </Grid>

          <div className="d-flex ms-auto gap-2">
            <OutlinedInput
              id="search"
              type="text"
              placeholder="search"
              className="sm-search w-10"
              onChange={(e) => handleSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled && Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(eNums?.EDIT) ? 
            <button
              className="sm-b sm-b-b cursor-pointer"
              onClick={() => navigate("/service-create-category")}
            >
              + Add Category / Service
            </button> : null}
          </div>
        </div>

        <div className="w-75 bg-primary setup-g1 my-4 rounded" />

        {/* <Grid contianer className='sm-w-3'>
                        <Grid className='sm-d-1'>
<Grid container>
    
</Grid>
                        </Grid>
                        <div className='sm-d-2'>
<Grid container className='sm-w-d-2'>
    <div className='sm-div-1'>
    <Typography variant="body2">Banner1</Typography>

    </div>
    <div className='sm-div-1'>
    <div className='sm-t-div'>Add Banner URL here</div>

    </div>
    <div className='sm-div-1'>
    <div className='sm-t-div-1'>Drop Banner Here</div>

</div>
<div className='sm-div-1'>
<button className='sm-b sm-b-b'>Save</button>

</div>
<div className='sm-div-1'>
<button className='sm-b sm-b-r'>Delete</button>

</div>

</Grid>

            <Grid container className="sm-w-d-2">
              <div className="sm-div-1">
                <Typography variant="body2">Banner2</Typography>
              </div>
              <div className="sm-div-1">
                <div className="sm-t-div">Add Banner URL here</div>
              </div>
              <div className="sm-div-1">
                <div className="sm-t-div-1">Drop Banner Here</div>
              </div>
              <div className="sm-div-1">
                <button className="sm-b sm-b-b">Save</button>
              </div>
              <div className="sm-div-1">
                <button className="sm-b sm-b-r">Delete</button>
              </div>
            </Grid>

            <Grid container className="sm-w-d-2">
              <div className="sm-div-1">
                <Typography variant="body2">Banner3</Typography>
              </div>
              <div className="sm-div-1">
                <div className="sm-t-div">Add Banner URL here</div>
              </div>
              <div className="sm-div-1">
                <div className="sm-t-div-1">Drop Banner Here</div>
              </div>
              <div className="sm-div-1">
                <button className="sm-b sm-b-b">Save</button>
              </div>
              <div className="sm-div-1">
                <button className="sm-b sm-b-r">Delete</button>
              </div>
            </Grid>
          </div>
        </Grid> */}

        {/* Render category keys separately */}
        <div className="sm-category-keys d-flex flex-wrap">
          {allServices.length > 0 && Object.keys(allServices[0]).map((key) => (
            <div key={key} className={`sm-category-key ${activeKey === key ? "active" : ""}`} onClick={() => scrollToCategory(key)}>
              <Typography variant="body1">{key}</Typography>
            </div>
          ))}
        </div>
        {serviceLoading ? (
          [...Array(8)]?.map((a, i) => (
            <Placeholder
              className="servicesWrap placeholder-background rounded"
              animation="wave"
              style={{ height: "220px" }}
            />
          ))
        ) :
        <div>
          {allServices.length > 0 ? (
            allServices.map((service, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.keys(service)?.map((key) => (
                  <div key={key} ref={(el) => (categoryRefs.current[key] = el)} className="my-2 d-flex flex-column gap-2"> {/* Attach ref to each category */}
                    <Typography
                      fontWeight={"600"}
                      fontSize={"1em"}
                      color={"#090E24"}
                    >
                      {key}
                    </Typography>
                    <div className="d-flex flex-wrap">
                      {service[key]?.map((value, i) => (
                        <ServicesCard key={i} data={value} route_path={'all-services'} route_path_name={'All Services'}/> 
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <EmptyState/>
          )}
        </div>
        }

      </NewLayout>
    </>
  );
}

export default ServiceManagement;
