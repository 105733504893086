import React, { useState, useEffect, useRef } from "react";
import NewLayout from "../../Components/SidebarLayout";
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Typography,
    Button,
    Select,
    MenuItem,
    styled,
    IconButton,
    Collapse,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import imgfile from "../../assets/images/Vector.png";
import edit from "../../assets/images/edit-img.svg";
import hold from "../../assets/images/hold.svg";
import deleteImg from "../../assets/images/delete.svg";
import {
    ApiService,
    getBlobFile,
    useApi,
    CustomizedSnackbar,
    uploadSingleBlobFile,
    EmptyState,
    ToolTip,
    useRolePermissions,
    CustomQuill,
    capitalizeFirstLetter,
    exportBlobFile,
} from "../../Utilities";
import Loader from "../../Components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { eNums, renewables } from "../../constants";
import Document from "../../assets/images/document-text.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        padding: "6px",
        fontWeight: 600,
        color: "#000",
        letterSpacing: 0,
        borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "8px",
        fontWeight: 600,
        color: "#000",
        letterSpacing: 0,
        borderBottom: 'none'
    },
}));

function ServiceManagementCreateCategory() {

    const userId = localStorage.getItem("userId");
    const userType = localStorage.getItem("userType");
    const Permissions = useRolePermissions();

    const [categoryPayload, setCategoryPayload] = useState([]);
    const [servicePayload, setServicePayload] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});
    const [categoryEditId, setCategoryEditId] = useState("");
    const [serviceEditId, setServiceEditId] = useState("");
    const [imageLoading, setImageLoading] = useState("");
    const [previewBannerUrl, setPreviewBannerUrl] = useState(null);
    const [previewThumbnailUrl, setPreviewThumbnailUrl] = useState(null);
    const [loading, setIsLoading] = useState("");
    const [currentUsers, setCurrentUsers] = useState(0);
    const [selectedServiceTasks, setSelectedServiceTasks] = useState([]);
    const [isDownloading, setIsDownloading] = useState("");
    const [expandedSubTask, setExpandedSubTask] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [previewDeliverableUrl, setPreviewDeliverableUrl] = useState(null);
    const [uploadedDocs, setUploadedDocs] = useState([]);

    const {
        action: createCategory,
        snackbarComponent: createSnackbarComponent,
        error: createCategoryError,
        loading: createCategoryLoading,
    } = useApi(ApiService.createCategory, {
        success: true,
        error: true,
        actionName: "Category creating",
    });
    const {
        action: updateCategory,
        snackbarComponent: updateSnackbarComponent,
        error: updateCategoryError,
        loading: updateCategoryLoading,
    } = useApi(ApiService.updateCategory, {
        success: true,
        error: true,
        actionName: "Category updating",
    });
    const {
        action: createService,
        snackbarComponent: createServiceSnackbarComponent,
        error: createServiceError,
        loading: createServiceLoading,
    } = useApi(ApiService.createService, {
        success: true,
        error: true,
        actionName: "Service creating",
    });
    const {
        action: updateService,
        snackbarComponent: updateServiceSnackbarComponent,
        error: updateServiceError,
        loading: updateServiceLoading,
    } = useApi(ApiService.updateService, {
        success: true,
        error: true,
        actionName: "Service updating",
    });
    const {
        action: deleteCategory,
        loading: deleteCategoryLoading,
        snackbarComponent: deleteCategorySnackbarComponent,
    } = useApi(ApiService.deleteCategory, {
        success: true,
        error: true,
        actionName: "Category deleting",
    });
    const {
        action: deleteService,
        loading: deleteServiceLoading,
        snackbarComponent: deleteServiceSnackbarComponent,
    } = useApi(ApiService.deleteService, {
        success: true,
        error: true,
        actionName: "Service deleting",
    });
    const { action: fileDelete, loading: deleteFileLoading } = useApi(
        ApiService.fileDelete,
        {
            noStore: true,
        }
    );
    const {
        action: getAllCategories,
        data: categories,
        snackbarComponent: getAllCategoriesSnackbarComponent,
        loading: categoryLoading,
    } = useApi(ApiService.getAllCategories, {
        success: true,
        error: true,
        actionName: "Categories fetching",
    });
    const {
        action: getAllServices,
        data: services,
        snackbarComponent: getAllServicesSnackbarComponent,
        loading: servicesLoading,
    } = useApi(ApiService.getAllServices, {
        success: true,
        error: true,
        actionName: "Services fetching",
    });

    const {
        action: getUsersByServiceId,
        data: currentServiceUsers,
        loading: currentServiceUsersLoading,
        error: currentServiceUsersError,
        clearActions: currentServiceUsersActions,
    } = useApi(ApiService.getUsersByServiceId);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const componentRef = useRef(null);

    const resetClickedAndEdit = () => {
        setSelectedServiceTasks(prevTasks =>
            prevTasks.map(task => ({
                ...task,
                clicked: false,
                edit: false,
                subTasks: task.subTasks?.map(subTask => ({
                    ...subTask,
                    clicked: false,
                    edit: false,
                    saved: true
                })) || []
            }))
        );
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                resetClickedAndEdit(); // Reset all clicked and edit states
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getAllCategories();
    }, []);

    useEffect(() => {
        if (!currentServiceUsersError && currentServiceUsers?.count) {
            setCurrentUsers(currentServiceUsers?.count);
        } else {
            setCurrentUsers(0);
        }
    }, [currentServiceUsers]);

    useEffect(() => {
        if (categories?.length) {
            const modifiedCategories = categories.map((category, index) => {
                return {
                    ...category,
                    clicked: index === 0 ? true : false,
                    edit: false,
                    saved: true,
                };
            });

            setCategoryPayload(modifiedCategories);
            getAllServices({
                categoryID: categories?.[0]?._id,
                isDeleted: false,
            });
            setExpandedCategories(prev => ({ [0]: !prev[0] }));
        } else {
            setCategoryPayload([]);
        }

        setCategoryEditId("");
    }, [categories]);
    useEffect(() => {
        if (
            loading === "service" &&
            (!createServiceLoading || !updateServiceLoading) &&
            (!createServiceError || !updateServiceError)
        ) {
            getAllServices({
                categoryID: categoryPayload?.find((item) => item?.clicked)?._id,
                isDeleted: false,
            });
            setIsLoading("");
            setCurrentUsers(0);
            setServiceEditId("");
        } else if (
            loading === "category" &&
            (!createCategoryLoading || !updateCategoryLoading) &&
            (!createCategoryError || !updateCategoryError)
        ) {
            getAllCategories();
            setIsLoading("");
        }
    }, [
        loading,
        categoryEditId,
        serviceEditId,
        createServiceError,
        updateServiceError,
        createCategoryError,
        updateCategoryError,
    ]);

    useEffect(() => {
        if (services?.length) {
            const modifiedServices = services.map((service, index) => {
                return {
                    ...service,
                    clicked: index === 0 ? true : false,
                    edit: false,
                    saved: true,
                };
            });
            handleClickService(0, services?.[0], services);
            setServicePayload(modifiedServices);
        } else {
            setServicePayload([]);
        }
    }, [services]);

    const handleSnackbar = (message, severity = "success") => {
        setSnackbar({ open: true, message, severity });
    };

    const handleServiceTypeChange = async (index, e) => {
        let updatedData = [...servicePayload];
        updatedData[index].serviceType = e.target.value;
        setServicePayload(updatedData);
        let obj = updatedData?.find((item) => item?.clicked);
        obj.tasks = selectedServiceTasks;
        await updateService(obj, serviceEditId);
    };

    const handleAddCategory = () => {
        let updatedData = categoryPayload.map((item) => {
            return { ...item, clicked: false };
        });

        // Check if the last item in updatedData has a categoryName
        if (
            (updatedData.length === 0 || updatedData[updatedData.length - 1].saved) &&
            !updatedData?.filter((item) => item?.edit)?.length
        ) {
            setCategoryEditId("");
            let obj = {
                categoryName: "",
                clicked: true,
                edit: false,
                saved: false,
                updatedOn: new Date(),
                createdOn: new Date(),
                createdBy: userId,
                updatedBy: userId,
            };
            updatedData.push(obj);
        } else {
            // If the last item doesn't have a categoryName, update it instead of pushing a new object
            updatedData[updatedData.length - 1] = {
                ...updatedData[updatedData.length - 1],
                clicked: true,
                updatedOn: new Date(),
                updatedBy: userId,
            };
            handleSnackbar("Please save current category", "error");
        }
        setExpandedCategories(prev => ({ [updatedData?.length - 1]: !prev[updatedData?.length - 1] }));
        setCategoryPayload(updatedData);
        setServicePayload([]);
    };

    const handleAddService = () => {
        setPreviewBannerUrl("");
        setPreviewThumbnailUrl("");
        currentServiceUsersActions.clearError();
        setSelectedServiceTasks([]);
        let updatedData = servicePayload?.map((item) => {
            return { ...item, clicked: false };
        });

        // Check if the last item in updatedData has a categoryName
        if (
            (updatedData.length === 0 || updatedData[updatedData.length - 1].saved) &&
            !updatedData?.filter((item) => item?.edit)?.length
        ) {
            setServiceEditId("");
            let obj = {
                serviceName: "",
                clicked: true,
                edit: false,
                saved: false,
                createdOn: new Date(),
                updatedOn: new Date(),
                serviceRaisedOn: new Date(),
                lastModifiedOn: new Date(),
                serviceContext: {
                    overview: [],
                    benefits: [],
                    documents: [],
                    deliverables: [],
                },
                createdBy: userId,
                priceTag: "",
                updatedBy: userId,
                country: "India",
                serviceStatus: "active",
                serviceType: "Business",
                categoryID:
                    categoryPayload &&
                    categoryPayload?.find((item) => item?.clicked)?._id,
            };
            updatedData.push(obj);
        } else {
            // If the last item doesn't have a categoryName, update it instead of pushing a new object
            updatedData[updatedData.length - 1] = {
                ...updatedData[updatedData.length - 1],
                clicked: true,
                lastModifiedOn: new Date(),
                modifiedBy: userId,
            };
            handleSnackbar("Please save current service", "error");
        }

        setServicePayload(updatedData);
    };

    const handleClickCategory = (index) => {
        setCategoryEditId("");
        if (
            categoryPayload &&
            categoryPayload?.filter((item) => !item?.saved)?.length &&
            categoryPayload?.[index]?.saved
        ) {
            handleSnackbar("Please save current category", "error");
        } else {
            let updatedData =
                (categoryPayload &&
                    categoryPayload?.length &&
                    categoryPayload.map((item, i) => ({
                        ...item,
                        clicked: i === index ? true : false,
                        edit: false,
                    }))) ||
                [];

            setCategoryPayload(updatedData);
            setExpandedCategories(prev => ({ [index]: !prev[index] }));
            getAllServices({
                categoryID: updatedData?.find((item) => item?.clicked)?._id,
                isDeleted: false,
            });
            setCurrentUsers(0);
        }
    };

    const handleEditCategory = (id, index) => {
        setCategoryEditId(id);
        let updatedData =
            (categoryPayload &&
                categoryPayload?.length &&
                categoryPayload.map((item, i) => ({
                    ...item,
                    edit: i === index ? true : false,
                    clicked: i === index ? true : false,
                }))) ||
            [];

        setCategoryPayload(updatedData);
    };

    const handleEditService = (id, index) => {
        setServiceEditId(id);
        let updatedData =
            (servicePayload &&
                servicePayload?.length &&
                servicePayload.map((item, i) => ({
                    ...item,
                    edit: i === index ? true : false,
                    clicked: i === index ? true : false,
                }))) ||
            [];

        setServicePayload(updatedData);
    };

    const handleDeleteCategory = async (id, index) => {
        if (servicePayload?.length > 0) {
            handleSnackbar("Services are available under this category", "error");
            return;
        }
        if (id) {
            await deleteCategory(id);
            await getAllCategories();
        } else {
            const updatedData = categoryPayload?.filter((_, i) => i !== index);
            setCategoryPayload(updatedData);
        }
    };

    const handleDeleteService = async (id, index) => {
        if (currentServiceUsers?.count > 0) {
            handleSnackbar("Current Users are available", "error");
            return;
        }
        if (id) {
            await deleteService(id);
            await getAllServices({
                categoryID: categoryPayload?.find((item) => item?.clicked)?._id,
                isDeleted: false,
            });
            setCurrentUsers(0);
        } else {
            const updatedData = servicePayload?.filter((_, i) => i !== index);

            setServicePayload(updatedData);
        }
        setServiceEditId("");
    };

    const handleRemoveService = async () => {
        if (currentServiceUsers?.count > 0) {
            handleSnackbar("Current Users are available", "error");
            return;
        }
        await deleteService(servicePayload?.find((item) => item?.clicked)?._id);
        await getAllServices({
            categoryID: categoryPayload?.find((item) => item?.clicked)?._id,
            isDeleted: false,
        });
        setCurrentUsers(0);

        setServiceEditId("");
    };

    const handleCategoryName = (value, index) => {
        categoryPayload[index].categoryName = value;
    };
    const handleServiceName = (value, index) => {
        servicePayload[index].serviceName = value;
    };
    const handleServicePrice = (value) => {
        let updatedData = servicePayload?.map((item, i) => {
            if (item.clicked) {
                return {
                    ...item,
                    priceTag: parseFloat(value),
                };
            } else {
                return item;
            }
        });
        setServicePayload(updatedData);
    };

    const handleChangeServiceValue = (key, value) => {
        let updatedData = servicePayload?.map((item, i) => {
            if (item.clicked) {
                return {
                    ...item,
                    [key]: value,
                };
            } else {
                return item;
            }
        });
        setServicePayload(updatedData);
    };

    const handleServiceAdditionalDetails = (type, value) => {
        setServicePayload((prevServicePayload) =>
            prevServicePayload?.map((item) =>
                item.clicked
                    ? {
                        ...item,
                        serviceContext: {
                            ...item.serviceContext,
                            [type]: [{ title: value }],
                        },
                    }
                    : item
            )
        );
    };

    const handleClickService = async (index, item, servicesData) => {
        setPreviewBannerUrl("");
        setPreviewThumbnailUrl("");
        currentServiceUsersActions.clearError();
        setServiceEditId(item?._id || "");
        if (
            servicesData &&
            servicesData?.filter((item) => !item?.saved)?.length &&
            servicesData?.[index]?.saved
        ) {
            handleSnackbar("Please save current service", "error");
        } else {
            let updatedData = servicesData.map((item, i) => ({
                ...item,
                clicked: i === index ? true : false,
                edit: false,
            }));
            setServicePayload(updatedData);
            let banner = updatedData?.find((item) => item?.clicked)?.banner;
            let thumbnail = updatedData?.find((item) => item?.clicked)?.thumbnail;
            if (banner) {
                setImageLoading("banner");
                const imgUrl = await getBlobFile(banner);
                setPreviewBannerUrl(imgUrl);
                setImageLoading("");
            } else {
                setPreviewBannerUrl("");
            }
            if (thumbnail) {
                setImageLoading("thumbnail");
                const imgUrl = await getBlobFile(thumbnail);
                setPreviewThumbnailUrl(imgUrl);
                setImageLoading("");
            } else {
                setPreviewThumbnailUrl("");
            }
            handleShowTasks(item);
            getUsersByServiceId(item?._id);
        }
    };

    const handleSubmitCategory = async (e) => {
        e.preventDefault();
        if (categoryPayload?.length) {
            if (
                categoryPayload &&
                categoryPayload?.filter((item) => !item?.saved || item?.edit)?.length
            ) {
                if (!categoryEditId) {
                    let obj = categoryPayload?.find((item) => !item?.saved);
                    if (!obj?.categoryName) {
                        handleSnackbar("Please enter name to save", "error");
                        return;
                    } else {
                        await createCategory(obj);
                    }
                } else {
                    let obj = categoryPayload?.find((item) => item?.edit);
                    await updateCategory(obj, categoryEditId);
                }
                setIsLoading("category");
            } else {
                handleSnackbar("No category selected to save", "error");
            }
        }
    };

    const handleSubmitService = async (e) => {
        e.preventDefault();
        if (servicePayload?.length) {
            if (
                servicePayload &&
                servicePayload?.filter(
                    (item) => !item?.saved || item?.edit || item?.clicked
                )?.length
            ) {
                if (!serviceEditId) {
                    let obj = servicePayload?.find((item) => !item?.saved);
                    if (!obj?.serviceName) {
                        handleSnackbar("Please enter name to save", "error");
                        return;
                    }
                    if (!obj?.priceTag || obj?.priceTag === 0) {
                        handleSnackbar("Service Price is required to save", "error");
                        return;
                    } else {
                        obj.tasks = selectedServiceTasks;
                        await createService(obj);
                    }
                } else {
                    let obj = servicePayload?.find((item) => item?.clicked);
                    if (!obj?.serviceName) {
                        handleSnackbar("Please enter name to save", "error");
                        return;
                    }
                    if (!obj?.priceTag || obj?.priceTag === 0) {
                        handleSnackbar("Price is required to save", "error");
                        return;
                    } else {
                        obj.tasks = selectedServiceTasks;
                        await updateService(obj, serviceEditId);
                    }
                }
                setIsLoading("service");
            } else {
                handleSnackbar("No service selected to save", "error");
            }
        }
    };

    const handleUpdateService = async (item) => {
        let obj = item;
        if (obj?.serviceStatus === "active") {
            obj.serviceStatus = "inactive";
        } else {
            obj.serviceStatus = "active";
        }
        await updateService(obj, item?._id);
        setIsLoading("service");
    };

    const paths = [
        { label: "Service Management", navigation: "service-management" },
        { label: "Create Category / Service" },
    ];

    const handleFileUpload = async (e, type) => {
        if (type === 'samplefile') {
            handleDeliverableUpload(e)
        }
        else {
            setImageLoading(type);
            const { previewUrl, res } = await uploadSingleBlobFile(e);
            if (type === "banner") {
                setPreviewBannerUrl(previewUrl);
            } else {
                setPreviewThumbnailUrl(previewUrl);
            }
            setImageLoading("");
            let updatedData = servicePayload?.map((item) => {
                if (item?.clicked) {
                    return {
                        ...item,
                        [type === "banner" ? "banner" : "thumbnail"]: res?.fileName,
                    };
                } else {
                    return item;
                }
            });
            setServicePayload(updatedData);
        }
    };

    const handleFileDelete = async (fileName, type) => {
        await fileDelete(fileName);
        if (type === "banner") {
            setPreviewBannerUrl("");
        } else {
            setPreviewThumbnailUrl("");
        }
        let updatedData = servicePayload?.map((item) => {
            if (item?.clicked) {
                return {
                    ...item,
                    [type === "banner" ? "banner" : "thumbnail"]: "",
                };
            } else {
                return item;
            }
        });
        setServicePayload(updatedData);
    };

    const [showMenu, setShowMenu] = useState(false);

    const triggerFilePicker = (type) => {
        document.getElementById(type).click();
    };

    const handleShowTasks = (item) => {
        setExpandedSubTask(null)
        const updatedTasks =
            item?.tasks?.map((task) => {
                const updatedSubTasks = task?.subTasks.map((subTask) => ({
                    ...subTask,
                    clicked: false,
                    edit: false,
                    saved: true,
                }));

                return {
                    ...task,
                    clicked: false,
                    edit: false,
                    saved: true,
                    subTasks: updatedSubTasks,
                };
            }) || [];

        setSelectedServiceTasks(updatedTasks);
    };

    const handleAddStatus = async () => {
        let updatedData = selectedServiceTasks?.map((item) => {
            return {
                ...item, clicked: false, edit: false, subTasks: item?.subTasks?.map((subtask) => ({
                    ...subtask,
                    clicked: false,
                    edit: false,
                    saved: true
                }))
            };
        });

        let task = {
            title: "",
            clicked: true,
            edit: false,
            saved: false,
            createdOn: new Date(),
            updatedOn: new Date(),
            createdBy: userId,
            updatedBy: userId,
            subTasks: [],
        };
        updatedData.push(task);

        setSelectedServiceTasks(updatedData);
        let obj = servicePayload?.find((item) => item?.clicked);
        if (!obj?.serviceName) {
            handleSnackbar("Please enter name to save", "error");
            return;
        }
        if (!obj?.priceTag || obj?.priceTag === 0) {
            handleSnackbar("Price is required to save", "error");
            return;
        } else {
            obj.tasks = selectedServiceTasks;
            if (serviceEditId) {
                await updateService(obj, serviceEditId);
            }
        }
    };

    const handleAddSubtask = async (index) => {
        setExpandedSubTask(null)
        let updatedData = selectedServiceTasks?.map((task, taskIndex) => {
            if (taskIndex === index) {
                task.subTasks = task?.subTasks?.map((subtask) => ({
                    ...subtask,
                    clicked: false,
                    edit: false,
                    saved: true
                }));
            }
            return {
                ...task,
                subTasks: task?.subTasks?.map((subtask) => ({
                    ...subtask,
                    clicked: false,
                    edit: false,
                    saved: true
                })),
                clicked: false,
                edit: false,
                saved: true
            };
        });

        let newSubtask = {
            name: "",
            priority: "",
            status: "active",
            subTaskType: 'input',
            createdOn: new Date(),
            updatedOn: new Date(),
            updatedBy: userId,
            clicked: true,
            edit: false,
            saved: false,
        };

        updatedData[index].subTasks.push(newSubtask);
        setSelectedServiceTasks(updatedData);
        let obj = servicePayload?.find((item) => item?.clicked);
        if (!obj?.serviceName) {
            handleSnackbar("Please enter name to save", "error");
            return;
        }
        if (!obj?.priceTag || obj?.priceTag === 0) {
            handleSnackbar("Price is required to save", "error");
            return;
        } else {
            obj.tasks = selectedServiceTasks;
            if (serviceEditId) {
                await updateService(obj, serviceEditId);
            }
        }
    };

    const handleTaskNameChange = (value, index) => {
        selectedServiceTasks[index].title = value;
    };

    const handleSubTaskNameChange = (value, taskIndex, subTaskIndex) => {
        selectedServiceTasks[taskIndex].subTasks[subTaskIndex].name = value;
    };

    const handleSubTaskPriorityChange = async (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].priority = value;
        setSelectedServiceTasks(updatedData);
        if (serviceEditId) {
            let obj = servicePayload?.find((item) => item?.clicked);
            obj.tasks = updatedData;
            await updateService(obj, serviceEditId);
        }
    };

    const handleSubTaskStatusChange = async (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].status = value;
        setSelectedServiceTasks(updatedData);
        if (serviceEditId) {
            let obj = servicePayload?.find((item) => item?.clicked);
            obj.tasks = updatedData;
            await updateService(obj, serviceEditId);
        }
    };

    const handleSubTaskTypeChange = async (value, taskIndex, subTaskIndex) => {
        const updatedData = [...selectedServiceTasks];
        updatedData[taskIndex].subTasks[subTaskIndex].subTaskType = value;
        setSelectedServiceTasks(updatedData);
        if (serviceEditId) {
            let obj = servicePayload?.find((item) => item?.clicked);
            obj.tasks = updatedData;
            await updateService(obj, serviceEditId);
        }
    };

    const handleEditSubTask = (taskIndex, subTaskIndex) => {
        setExpandedSubTask({ taskIndex, subTasksIndex: subTaskIndex });
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        edit: false,
                        subTasks: task.subTasks.map((subTask, subTaskInd) => {
                            if (subTaskInd === subTaskIndex) {
                                setUploadedDocs(subTask?.uploadedDocuments || [])
                                return {
                                    ...subTask,
                                    edit: true,
                                };
                            } else {
                                return {
                                    ...subTask,
                                    edit: false,
                                };
                            }
                        }),
                    };
                } else {
                    return {
                        ...task,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            edit: false,
                        })),
                    };
                }
            })
        );
    };

    const handleEditTask = (taskIndex) => {
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: true,
                        edit: true,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                }
            })
        );
    };

    const handleDeleteTask = async (taskIndex) => {
        const updatedTasks = selectedServiceTasks?.filter(
            (_, index) => index !== taskIndex
        );
        setSelectedServiceTasks(updatedTasks);
        let obj = servicePayload?.find((item) => item?.clicked);
        if (!obj?.serviceName) {
            handleSnackbar("Please enter name to save", "error");
            return;
        }
        if (!obj?.priceTag || obj?.priceTag === 0) {
            handleSnackbar("Price is required to save", "error");
            return;
        } else {
            if (serviceEditId) {
                obj.tasks = updatedTasks;
                await updateService(obj, serviceEditId);
            }
        }
    };

    const handleDeleteSubTask = async (taskIndex, subTaskIndex) => {
        const updatedTasks = [...selectedServiceTasks];

        const updatedSubTasks = [...updatedTasks[taskIndex].subTasks];

        updatedSubTasks.splice(subTaskIndex, 1);

        updatedTasks[taskIndex] = {
            ...updatedTasks[taskIndex],
            subTasks: updatedSubTasks,
        };

        setSelectedServiceTasks(updatedTasks);
        let obj = servicePayload?.find((item) => item?.clicked);
        if (!obj?.serviceName) {
            handleSnackbar("Please enter name to save", "error");
            return;
        }
        if (!obj?.priceTag || obj?.priceTag === 0) {
            handleSnackbar("Price is required to save", "error");
            return;
        } else {
            if (serviceEditId) {
                obj.tasks = updatedTasks;
                await updateService(obj, serviceEditId);
            }
        }
    };

    const handleClickTask = (taskIndex) => {
        setExpandedSubTask(null)
        setUploadedDocs([])
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: true,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            clicked: false,
                            edit: false,
                        })),
                    };
                }
            })
        );
    };

    const handleClickSubTask = (taskIndex, subTaskIndex) => {
        setExpandedSubTask({ taskIndex, subTasksIndex: subTaskIndex });
        setSelectedServiceTasks((prevTasks) =>
            prevTasks.map((task, index) => {
                if (index === taskIndex) {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask, subTaskInd) => {
                            if (subTaskInd === subTaskIndex) {
                                setUploadedDocs(subTask?.uploadedDocuments || []);
                                return {
                                    ...subTask,
                                    edit: false,
                                    clicked: true,
                                };
                            } else {
                                return {
                                    ...subTask,
                                    edit: false,
                                    clicked: false,
                                };
                            }
                        }),
                    };
                } else {
                    return {
                        ...task,
                        clicked: false,
                        edit: false,
                        subTasks: task.subTasks.map((subTask) => ({
                            ...subTask,
                            edit: false,
                            clicked: false,
                        })),
                    };
                }
            })
        );
        if (expandedSubTask?.taskIndex === taskIndex && expandedSubTask?.subTasksIndex === subTaskIndex) {
            setExpandedSubTask(null);
        }
    };

    const handleDeliverableUpload = async (e) => {
        e.preventDefault()
        setImageLoading(true);
        const { previewUrl, res } = await uploadSingleBlobFile(e);

        setPreviewDeliverableUrl(previewUrl);
        setImageLoading(false);

        if (res?.fileName) {
            // Remove any existing document uploaded by an "AGENT"
            const filteredDocs = uploadedDocs?.filter(doc => doc?.userType !== eNums.AGENT);

            // Add the new document
            filteredDocs.push({
                url: res?.fileName,
                filename: e?.target.files?.[0]?.name,
                uploadedDate: new Date(),
                uploadedBy: userId,
                userType,
            });

            // Update the state
            setUploadedDocs(filteredDocs);

            // Find the subTask where clicked is true and update its uploadedDocs
            let updatedServiceTasks = selectedServiceTasks?.map((task) => ({
                ...task,
                subTasks: task.subTasks.map((subTask) =>
                    subTask.clicked ? { ...subTask, uploadedDocuments: filteredDocs } : subTask
                ),
            }) || []);
            setSelectedServiceTasks(updatedServiceTasks);
        }
    };
    const handleChangeSampleFileDescription = (description) => {
        const index = uploadedDocs.findIndex(doc => doc.userType === eNums?.AGENT);
        if (index !== -1) {
            uploadedDocs[index] = { ...uploadedDocs[index], fileDescription: description };
        }
        else {
            // Add the new document
            uploadedDocs.push({
                uploadedDate: new Date(),
                uploadedBy: userId,
                userType,
                fileDescription: description
            });
        }
    };

    const complexityPoints = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
        { label: 11, value: 11 },
        { label: 12, value: 12 },
        { label: 13, value: 13 },
        { label: 14, value: 14 },
        { label: 15, value: 15 },
        { label: 16, value: 16 },
        { label: 17, value: 17 },
        { label: 18, value: 18 },
        { label: 19, value: 19 },
        { label: 20, value: 20 },
    ];
    return (
        <>
            <NewLayout paths={paths} pageHeading={"Create Category / Service"}>
                {createSnackbarComponent}
                {deleteCategorySnackbarComponent}
                {updateSnackbarComponent}
                {createServiceSnackbarComponent}
                {updateServiceSnackbarComponent}
                {deleteServiceSnackbarComponent}
                <div className="sm-main-container mb-3">
                    <div className="category-container">
                        <div className="d-flex align-items-center justify-content-between border-bottom-gray flex-wrap p-2">
                            <span className="category-title ms-3">Category</span>
                            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                    eNums?.EDIT
                                ) ? (
                                <Button
                                    variant="contained"
                                    className="add-item-btn cursor-pointer d-flex align-items-center justify-content-center"
                                    onClick={handleAddCategory}
                                >
                                    +
                                </Button>
                            ) : null}
                        </div>

                        <div className="h-100 overflow-auto pb-5">
                            {categoryPayload && categoryPayload?.length ? (
                                categoryPayload?.map((item, Cindex) => (
                                    <form
                                        id="category"
                                        onSubmit={handleSubmitCategory}
                                        className={`cursor-pointer`}
                                    >
                                        <div
                                            className={`d-flex align-items-center justify-content-between cursor-pointer px-3`}
                                        >
                                            {item?.edit || !item?.saved ? (
                                                <TextField
                                                    variant="standard"
                                                    className="w-100"
                                                    placeholder="Enter name"
                                                    defaultValue={item?.categoryName}
                                                    style={{ borderBottom: "none" }}
                                                    onChange={(e) =>
                                                        handleCategoryName(e.target.value, Cindex)
                                                    }
                                                />
                                            ) : (

                                                <div
                                                    onClick={() => handleClickCategory(Cindex)}>
                                                    <Typography className={`w-100`} fontWeight={700} fontSize={14} color={'#404040'}>
                                                        {item?.categoryName}
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleClickCategory(Cindex)
                                                            }}
                                                        >
                                                            {expandedCategories[Cindex] ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                        </IconButton>
                                                    </Typography>

                                                </div>
                                            )}
                                            {item?.clicked && Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                    eNums?.EDIT
                                                ) ? (
                                                <div className={`d-flex align-items-center gap-2 ms-1`}>
                                                    {item?.saved ?
                                                        <Button
                                                            variant="contained"
                                                            className="add-service-btn cursor-pointer d-flex align-items-center justify-content-center"
                                                            onClick={handleAddService}
                                                        >
                                                            +
                                                        </Button> : null}
                                                    {item?.saved ? (
                                                        <img
                                                            src={edit}
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleEditCategory(item?._id, Cindex)
                                                            }
                                                        />
                                                    ) : (
                                                        null
                                                    )}
                                                    <img
                                                        src={deleteImg}
                                                        alt=""
                                                        width={16}
                                                        height={16}
                                                        className="delete cursor-pointer"
                                                        onClick={() =>
                                                            handleDeleteCategory(item?._id || "", Cindex)
                                                        }
                                                    />
                                                </div>
                                            ) : null}

                                        </div>
                                        <Collapse
                                            in={expandedCategories[Cindex] || false}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <div className="px-4 w-100">
                                                {servicesLoading ? <div className="d-flex justify-content-center align-items-center my-5">
                                                                  <CircularProgress />
                                                                </div> : servicePayload?.length ? (
                                                    servicePayload.map((item, index) => (
                                                        <div
                                                            id="service"
                                                            className={`cursor-pointer p-10 ${item?.clicked ? "highlight-content-container" : ""
                                                                }`}
                                                            onSubmit={handleSubmitService}
                                                        >
                                                            {!item?.clicked ? (
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        handleClickService(index, item, servicePayload)
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <div
                                                                        item
                                                                        flexDirection="column"
                                                                        className="service-select cursor-pointer"
                                                                    >
                                                                        <Typography>{index + 1}. {item?.serviceName}</Typography>
                                                                    </div>
                                                                    {item?.clicked ?
                                                                        <div className="d-flex flex-wrap justify-content-between gap-1 px-1">
                                                                            {item?.serviceType ? (
                                                                                <div className="d-flex align-items-center justify-content-center h-25 typo-blue w-45">
                                                                                    {item?.serviceType}
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            <div className="d-flex align-items-center justify-content-center h-25 typo-blue w-45">
                                                                                {item?.serviceStatus === "active"
                                                                                    ? "Active"
                                                                                    : item?.serviceStatus === "pending"
                                                                                        ? "Pending"
                                                                                        : "In-Active"}
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        className="d-flex align-items-center justify-content-between p-10"
                                                                    >
                                                                        {item?.edit || !item?.saved ? (
                                                                            <TextField
                                                                                variant="standard"
                                                                                className="w-80"
                                                                                placeholder="Enter name"
                                                                                defaultValue={item?.serviceName}
                                                                                style={{ borderBottom: "none" }}
                                                                                onChange={(e) =>
                                                                                    handleServiceName(e.target.value, index)
                                                                                }
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === "Enter") {
                                                                                        e.preventDefault(); // Prevent category form submission
                                                                                        handleSubmitService(e); // Manually trigger service submission
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <Typography className="w-80">
                                                                                    {index + 1}. {item?.serviceName}
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                        {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled && Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                                            eNums?.EDIT
                                                                        ) ? (
                                                                            <div className={`d-flex ms-1 gap-2`}>
                                                                                {item?.saved ? (
                                                                                    <img
                                                                                        src={edit}
                                                                                        alt=""
                                                                                        width={16}
                                                                                        height={16}
                                                                                        className="cursor-pointer"
                                                                                        onClick={() =>
                                                                                            handleEditService(item?._id, index)
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <img
                                                                                    src={deleteImg}
                                                                                    alt=""
                                                                                    width={16}
                                                                                    height={16}
                                                                                    className="delete cursor-pointer"
                                                                                    onClick={() =>
                                                                                        handleDeleteService(
                                                                                            item?._id || "",
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : null}
                                                                    </Grid>

                                                                    <div className="d-flex flex-wrap justify-content-between gap-1 px-1 mb-1 service-select">
                                                                        {item?.edit || !item?.saved ? (
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                className="typo-blue"
                                                                                id="demo-simple-select"
                                                                                value={item?.serviceType}
                                                                                sx={{
                                                                                    borderRadius: "2vh",
                                                                                    textAlign: "center",
                                                                                    height: "25px",
                                                                                    width: "45%",
                                                                                    color: "#ffffff",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                    handleServiceTypeChange(index, e)
                                                                                }
                                                                            >
                                                                                <MenuItem value={"Individual"}>
                                                                                    Individual
                                                                                </MenuItem>
                                                                                <MenuItem value={"Business"}>
                                                                                    Business
                                                                                </MenuItem>
                                                                            </Select>
                                                                        ) : item?.serviceType ? (
                                                                            <div className="d-flex align-items-center justify-content-center h-25 typo-blue w-45">
                                                                                {item?.serviceType}
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        <div className="d-flex align-items-center justify-content-center h-25 typo-blue w-45">
                                                                            {item?.serviceStatus === "active"
                                                                                ? "Active"
                                                                                : item?.serviceStatus === "pending"
                                                                                    ? "Pending"
                                                                                    : "In-Active"}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <EmptyState height={'40vh'} />
                                                )}
                                            </div>
                                        </Collapse>
                                        <button className="d-none" type="submit" id="category">
                                            Save
                                        </button>
                                    </form>
                                ))
                            ) : (
                                <EmptyState />
                            )}
                        </div>
                    </div>

                    <form className="service-details-container" onSubmit={handleSubmitService}>

                        {servicePayload &&
                            servicePayload?.filter((item) => item?.clicked)?.length ? (
                            <>
                                <div className="d-flex flex-wrap justify-content-end border-bottom-gray p-2 gap-2">
                                    {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                            eNums?.EDIT
                                        ) ? (
                                        <Button
                                            variant="contained"
                                            className="tg-span-c d-flex align-items-center justify-content-between gap-2 cursor-pointer"
                                            onClick={() =>
                                                handleUpdateService(
                                                    servicePayload?.find((item) => item?.clicked)
                                                )
                                            }
                                        >
                                            {servicePayload?.find((item) => item?.clicked)
                                                ?.serviceStatus === "inactive"
                                                ? "Resume"
                                                : "Hold"}{" "}
                                            Sevice
                                            <img src={hold} alt="" width={20} height={20} />
                                        </Button>
                                    ) : null}
                                    <Button
                                        variant="contained"
                                        className="tg-span-c d-flex align-items-center justify-content-between gap-2 cursor-pointer"
                                    >
                                        Current Users
                                        <span className="fw-bold">
                                            {currentServiceUsersLoading ? "..." : currentUsers || 0}
                                        </span>
                                    </Button>
                                    {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                            eNums?.EDIT
                                        ) ? (
                                        <Button
                                            variant="contained"
                                            className="tg-span-b d-flex align-items-center justify-content-center cursor-pointer"
                                            onClick={handleRemoveService}
                                        >
                                            Delete{" "}
                                        </Button>
                                    ) : null}
                                </div>

                                <Grid item className="p-3 h-100">
                                    <Grid container>
                                        <Grid container>
                                            <Grid className="g d-flex gap-4 flex-wrap w-100">
                                                <Grid className="w-25">
                                                    <Grid item className="d-flex flex-column">
                                                        <span className="sm-font">Service Price</span>
                                                        <input
                                                            className="sm-grid-1"
                                                            type="number"
                                                            min={0}
                                                            placeholder="Enter Amount"
                                                            value={
                                                                servicePayload?.find((item) => item?.clicked)
                                                                    ?.priceTag
                                                            }
                                                            onChange={(e) => handleServicePrice(e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid className="g d-flex gap-4 flex-wrap w-100">
                                                <Grid className="w-25">
                                                    <Grid item className="d-flex flex-column">
                                                        <span className="sm-font">
                                                            Service Complexity Points
                                                        </span>
                                                        <select
                                                            className="sm-grid-1"
                                                            value={
                                                                servicePayload?.find((item) => item?.clicked)
                                                                    ?.complexity || ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeServiceValue(
                                                                    "complexity",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                Select Points
                                                            </option>
                                                            {complexityPoints?.map((item) => (
                                                                <option key={item?.value} value={item?.value}>
                                                                    {item?.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                </Grid>
                                                <Grid className="w-25">
                                                    <Grid item className="d-flex flex-column">
                                                        <span className="sm-font">Renewables</span>
                                                        <select
                                                            className="sm-grid-1"
                                                            value={
                                                                servicePayload?.find((item) => item?.clicked)
                                                                    ?.renewability || ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeServiceValue(
                                                                    "renewability",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                Select Period
                                                            </option>
                                                            {renewables?.map((item) => (
                                                                <option key={item?.value} value={item?.value}>
                                                                    {item?.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container className="tg-g1 mt-2">
                                            <Grid className="d-flex flex-column flex-wrap w-70 pe-2">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="sm-font">Add Banner Image</span>
                                                    {previewBannerUrl &&
                                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                            eNums?.EDIT
                                                        ) ? (
                                                        <img
                                                            src={deleteImg}
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                            className="delete cursor-pointer"
                                                            onClick={() =>
                                                                handleFileDelete(
                                                                    servicePayload?.find((item) => item?.clicked)
                                                                        ?.banner,
                                                                    "banner"
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </div>

                                                <label className="cursor-pointer d-flex flex-column justify-content-center align-items-center grid-tg-banner">
                                                    {imageLoading === "banner" ? (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <CircularProgress />
                                                        </div>
                                                    ) : previewBannerUrl ? (
                                                        <>
                                                            <div
                                                                className="image-preview"
                                                                onMouseEnter={() => setShowMenu("banner")}
                                                                onMouseLeave={() => setShowMenu("banner")}
                                                            >
                                                                <img
                                                                    src={previewBannerUrl}
                                                                    alt=""
                                                                    className="w-100 h-100 rounded"
                                                                    loading="lazy"
                                                                />
                                                                {showMenu === "banner" && (
                                                                    <div
                                                                        className="change-icon-menu"
                                                                        onClick={() => triggerFilePicker("banner")}
                                                                    />
                                                                )}
                                                            </div>
                                                            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                                    eNums?.EDIT
                                                                ) ? (
                                                                <input
                                                                    type="file"
                                                                    id="banner"
                                                                    className="d-none"
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    onChange={(e) => handleFileUpload(e, "banner")}
                                                                />
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography>
                                                                <img src={imgfile} alt=""></img>
                                                                <span className="ms-2">Add Banner</span>
                                                            </Typography>

                                                            <span className="ld-text-1">
                                                                Drag and drop or browse your files
                                                            </span>
                                                            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                                    eNums?.EDIT
                                                                ) ? (
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="ld-inp-1 d-none"
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    onChange={(e) => handleFileUpload(e, "banner")}
                                                                />
                                                            ) : null}
                                                        </>
                                                    )}
                                                </label>
                                            </Grid>
                                            <Grid className="w-30">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="sm-font">Add Thumbnail</span>
                                                    {previewThumbnailUrl &&
                                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                        Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                            eNums?.EDIT
                                                        ) ? (
                                                        <img
                                                            src={deleteImg}
                                                            alt=""
                                                            width={16}
                                                            height={16}
                                                            className="delete cursor-pointer"
                                                            onClick={() =>
                                                                handleFileDelete(
                                                                    servicePayload?.find((item) => item?.clicked)
                                                                        ?.thumbnail,
                                                                    "thumbnail"
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                                <label className="cursor-pointer d-flex flex-column justify-content-center align-items-center grid-tg-banner">
                                                    {imageLoading === "thumbnail" ? (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <CircularProgress />
                                                        </div>
                                                    ) : previewThumbnailUrl ? (
                                                        <>
                                                            <div
                                                                className="image-preview"
                                                                onMouseEnter={() => setShowMenu("thumbnail")}
                                                                onMouseLeave={() => setShowMenu("thumbnail")}
                                                            >
                                                                <img
                                                                    src={previewThumbnailUrl}
                                                                    alt=""
                                                                    className="w-100 h-100 rounded"
                                                                    loading="lazy"
                                                                />
                                                                {showMenu === "thumbnail" && (
                                                                    <div
                                                                        className="change-icon-menu"
                                                                        onClick={() => triggerFilePicker("thumbnail")}
                                                                    />
                                                                )}
                                                            </div>
                                                            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                                    eNums?.EDIT
                                                                ) ? (
                                                                <input
                                                                    type="file"
                                                                    id="thumbnail"
                                                                    className="d-none"
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    onChange={(e) =>
                                                                        handleFileUpload(e, "thumbnail")
                                                                    }
                                                                />
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography>
                                                                <img src={imgfile} alt=""></img>
                                                                <span className="ms-2">Add Thumbnail</span>
                                                            </Typography>

                                                            <span className="ld-text-1">
                                                                Drag and drop or browse your files
                                                            </span>
                                                            {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                                                Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                                    eNums?.EDIT
                                                                ) ? (
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="ld-inp-1 d-none"
                                                                    accept="image/png, image/jpeg, image/jpg"
                                                                    onChange={(e) =>
                                                                        handleFileUpload(e, "thumbnail")
                                                                    }
                                                                />
                                                            ) : null}
                                                        </>
                                                    )}
                                                </label>
                                            </Grid>
                                        </Grid>
                                        <Grid className="d-flex flex-wrap w-100 mt-2">
                                            <Grid item className="w-50 pe-2 d-flex flex-column gap-2">
                                                <span className="sm-font">OverView</span>
                                                <CustomQuill
                                                    value={
                                                        servicePayload?.find((item) => item?.clicked)
                                                            ?.serviceContext?.overview?.[0]?.title || ""
                                                    }
                                                    onChange={(value) =>
                                                        handleServiceAdditionalDetails("overview", value)
                                                    }
                                                />
                                            </Grid>

                                            <Grid item className="w-50 d-flex flex-column gap-2">
                                                <span className="sm-font">Benefits</span>
                                                <CustomQuill
                                                    value={
                                                        servicePayload?.find((item) => item?.clicked)
                                                            ?.serviceContext?.benefits?.[0]?.title || ""
                                                    }
                                                    onChange={(value) =>
                                                        handleServiceAdditionalDetails("benefits", value)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid className="d-flex flex-wrap w-100 mt-2">
                                            <Grid item className="w-50 pe-2 d-flex flex-column gap-2">
                                                <span className="sm-font">Documents</span>
                                                <CustomQuill
                                                    value={
                                                        servicePayload?.find((item) => item?.clicked)
                                                            ?.serviceContext?.documents?.[0]?.title || ""
                                                    }
                                                    onChange={(value) =>
                                                        handleServiceAdditionalDetails("documents", value)
                                                    }
                                                />
                                            </Grid>

                                            <Grid item className="w-50 d-flex flex-column gap-2">
                                                <span className="sm-font">Deliverables</span>
                                                <CustomQuill
                                                    value={
                                                        servicePayload?.find((item) => item?.clicked)
                                                            ?.serviceContext?.deliverables?.[0]?.title || ""
                                                    }
                                                    onChange={(value) =>
                                                        handleServiceAdditionalDetails("deliverables", value)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <div className="w-100 d-flex flex-column gap-2 mt-2">
                                            <div className="d-flex gap-1 align-items-center">
                                                <Typography
                                                    fontWeight={"500"}
                                                    fontSize={"1em"}
                                                    color={"#000"}
                                                >
                                                    Service Status
                                                </Typography>
                                                <ToolTip info={"Service Status"} />
                                            </div>
                                            <form
                                                className="overflow-auto rounded py-2 h-100"
                                                style={{ border: "1px solid #D9D9D9" }}
                                                onSubmit={handleSubmitService}
                                            >
                                                <Grid className="overflow-auto rounded py-2">
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>
                                                                    <span className="ms-4">Task</span>
                                                                </StyledTableCell>
                                                                <StyledTableCell>Task Priority</StyledTableCell>
                                                                <StyledTableCell>
                                                                    Task Status
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    Document
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    Payment Status
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    Invoice
                                                                </StyledTableCell>
                                                                {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                    eNums?.EDIT
                                                                ) ? (
                                                                    <StyledTableCell>Actions</StyledTableCell>
                                                                ) : null}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody ref={componentRef}>
                                                            {selectedServiceTasks?.length
                                                                ? selectedServiceTasks.map((task, taskIndex) => (
                                                                    <React.Fragment key={taskIndex}>
                                                                        <TableRow className={`${task?.clicked ? "zoom-in-box" : ""}`}>
                                                                            <StyledTableCell className="d-flex gap-1 align-items-center">
                                                                                <Typography color={'#000'} fontWeight={600} className="ms-2">{taskIndex + 1}.</Typography>
                                                                                {task?.edit || !task?.saved ? (
                                                                                    <TextField
                                                                                        variant="standard"
                                                                                        placeholder="Enter Task"
                                                                                        defaultValue={task?.title}
                                                                                        fullWidth
                                                                                        onChange={(e) =>
                                                                                            handleTaskNameChange(
                                                                                                e.target.value,
                                                                                                taskIndex
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === "Enter") {
                                                                                                handleUpdateService();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        fontSize={14}
                                                                                        onClick={() =>
                                                                                            handleClickTask(taskIndex)
                                                                                        }
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        className="word-break"
                                                                                    >
                                                                                        {task?.title || "N/A"}
                                                                                    </Typography>
                                                                                )}
                                                                            </StyledTableCell>
                                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                                eNums?.EDIT
                                                                            ) ? (
                                                                                <>
                                                                                    <StyledTableCell>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            className="cm-create-status cursor-pointer"
                                                                                            onClick={() =>
                                                                                                handleAddSubtask(taskIndex)
                                                                                            }
                                                                                        >
                                                                                            + Add Sub Task
                                                                                        </Button>
                                                                                    </StyledTableCell>

                                                                                    <StyledTableCell>
                                                                                        {/* <Button
                                            variant="contained"
                                            className="cm-create-status cursor-pointer"
                                            onClick={() =>
                                                handleAddSubtask(taskIndex)
                                            }
                                        >
                                            + Add Invoice
                                        </Button> */}
                                                                                    </StyledTableCell>
                                                                                </>) : <><StyledTableCell></StyledTableCell><StyledTableCell></StyledTableCell></>}
                                                                            <StyledTableCell></StyledTableCell>
                                                                            <StyledTableCell>-</StyledTableCell>
                                                                            <StyledTableCell>-</StyledTableCell>
                                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                                eNums?.EDIT
                                                                            ) ? (
                                                                                <>
                                                                                    <StyledTableCell>
                                                                                        {
                                                                                            task?.saved &&
                                                                                            !task?.edit && (
                                                                                                <img
                                                                                                    src={edit}
                                                                                                    alt="edit"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        marginRight: 8,
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleEditTask(taskIndex)
                                                                                                    }
                                                                                                />
                                                                                            )}

                                                                                        <img
                                                                                            src={deleteImg}
                                                                                            alt="delete"
                                                                                            width={16}
                                                                                            height={16}
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() =>
                                                                                                handleDeleteTask(taskIndex)
                                                                                            }
                                                                                        />
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell></StyledTableCell>
                                                                                </>
                                                                            ) : null}
                                                                        </TableRow>
                                                                        {task?.subTasks?.length > 0 &&
                                                                            task?.subTasks.map(
                                                                                (subTask, subTasksIndex) => (
                                                                                    <React.Fragment key={subTasksIndex} >
                                                                                        <TableRow className={`${subTask?.clicked ? "zoom-in-box" : ""}`}>
                                                                                            <StyledTableCell className="d-flex gap-2 align-items-center">
                                                                                                <Typography className="ms-4" color={'#64748B'}>
                                                                                                    {subTasksIndex + 1}.
                                                                                                </Typography>
                                                                                                {subTask?.edit ||
                                                                                                    !subTask?.saved ? (
                                                                                                    <TextField
                                                                                                        variant="standard"
                                                                                                        placeholder="Enter Sub Task"
                                                                                                        defaultValue={subTask?.name}
                                                                                                        fullWidth
                                                                                                        onChange={(e) =>
                                                                                                            handleSubTaskNameChange(
                                                                                                                e.target.value,
                                                                                                                taskIndex,
                                                                                                                subTasksIndex
                                                                                                            )
                                                                                                        }
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === "Enter") {
                                                                                                                handleUpdateService();
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        sx={{
                                                                                                            cursor: "pointer",
                                                                                                            color: "#64748B",
                                                                                                            textUnderlineOffset: '4px',
                                                                                                            textDecoration: "underline",
                                                                                                            "&:hover": {
                                                                                                                color: "#aaa7a7",
                                                                                                            },
                                                                                                        }}
                                                                                                        className="word-break"
                                                                                                        onClick={() => handleClickSubTask(taskIndex, subTasksIndex)}
                                                                                                    >
                                                                                                        {subTask?.name || "N/A"}
                                                                                                    </Typography>
                                                                                                )}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell>
                                                                                                {subTask?.edit ||
                                                                                                    !subTask?.saved ? (
                                                                                                    <Select
                                                                                                        value={
                                                                                                            subTask?.priority || ""
                                                                                                        }
                                                                                                        onChange={(e) =>
                                                                                                            handleSubTaskPriorityChange(
                                                                                                                e.target.value,
                                                                                                                taskIndex,
                                                                                                                subTasksIndex
                                                                                                            )
                                                                                                        }
                                                                                                        displayEmpty
                                                                                                        fullWidth
                                                                                                        style={{ height: "2rem" }}
                                                                                                    >
                                                                                                        <MenuItem value="">
                                                                                                            Choose Priority
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Low">
                                                                                                            Low
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Medium">
                                                                                                            Medium
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="High">
                                                                                                            High
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Very High">
                                                                                                            Very High
                                                                                                        </MenuItem>
                                                                                                    </Select>
                                                                                                ) : (
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            color:
                                                                                                                subTask?.priority ===
                                                                                                                    "Low"
                                                                                                                    ? "#36AA00"
                                                                                                                    : subTask?.priority ===
                                                                                                                        "Medium"
                                                                                                                        ? "#36AA00"
                                                                                                                        : "#F7425F",
                                                                                                        }}
                                                                                                    >
                                                                                                        {subTask?.priority}
                                                                                                    </Typography>
                                                                                                )}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell>
                                                                                                {subTask?.edit ||
                                                                                                    !subTask?.saved ? (
                                                                                                    <Select
                                                                                                        value={
                                                                                                            subTask?.status || "active"
                                                                                                        }
                                                                                                        onChange={(e) =>
                                                                                                            handleSubTaskStatusChange(
                                                                                                                e.target.value,
                                                                                                                taskIndex,
                                                                                                                subTasksIndex
                                                                                                            )
                                                                                                        }
                                                                                                        displayEmpty
                                                                                                        fullWidth
                                                                                                        style={{ height: "2rem" }}
                                                                                                    >
                                                                                                        <MenuItem value="active">
                                                                                                            Active
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="pending">
                                                                                                            Pending
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="completed">
                                                                                                            Completed
                                                                                                        </MenuItem>
                                                                                                    </Select>
                                                                                                ) : (
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            color: '#000'
                                                                                                        }}
                                                                                                    >
                                                                                                        {subTask?.status ? capitalizeFirstLetter(subTask?.status) : 'N/A'}
                                                                                                    </Typography>
                                                                                                )}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell>
                                                                                                {subTask?.edit ||
                                                                                                    !subTask?.saved ? (
                                                                                                    <Select
                                                                                                        value={
                                                                                                            subTask?.subTaskType || ""
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            handleSubTaskTypeChange(
                                                                                                                e.target.value,
                                                                                                                taskIndex,
                                                                                                                subTasksIndex
                                                                                                            )
                                                                                                            setExpandedSubTask({ taskIndex, subTasksIndex })
                                                                                                        }}
                                                                                                        displayEmpty
                                                                                                        fullWidth
                                                                                                        style={{ height: "2rem" }}
                                                                                                    >
                                                                                                        <MenuItem value="input">
                                                                                                            Not Required
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="upload">
                                                                                                            Required
                                                                                                        </MenuItem>
                                                                                                    </Select>
                                                                                                ) : (
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            color: '#000'
                                                                                                        }}
                                                                                                    >
                                                                                                        {subTask?.subTaskType === 'input' ? 'Not' : ''} Required
                                                                                                    </Typography>
                                                                                                )}
                                                                                            </StyledTableCell>

                                                                                            <StyledTableCell>
                                                                                                <Typography
                                                                                                    variant="body2"
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        color: "#000",
                                                                                                    }}
                                                                                                    className="word-break"
                                                                                                >
                                                                                                    {subTask?.type || "N/A"}
                                                                                                </Typography>
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell>
                                                                                                <Typography
                                                                                                    variant="body2"
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        color: "#000",
                                                                                                    }}
                                                                                                    className="word-break"
                                                                                                >
                                                                                                    {subTask?.invoice || "N/A"}
                                                                                                </Typography>
                                                                                            </StyledTableCell>

                                                                                            {Permissions?.clientManagement?.submodules?.clientManagement?.enabled && Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                                                                eNums?.EDIT
                                                                                            ) ? (
                                                                                                <StyledTableCell>
                                                                                                    {
                                                                                                        subTask?.saved &&
                                                                                                        !subTask?.edit && (
                                                                                                            <img
                                                                                                                src={edit}
                                                                                                                alt="edit"
                                                                                                                width={16}
                                                                                                                height={16}
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                    marginRight: 8,
                                                                                                                }}
                                                                                                                onClick={() =>
                                                                                                                    handleEditSubTask(
                                                                                                                        taskIndex,
                                                                                                                        subTasksIndex
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                        )}

                                                                                                    <img
                                                                                                        src={deleteImg}
                                                                                                        alt="delete"
                                                                                                        width={16}
                                                                                                        height={16}
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleDeleteSubTask(
                                                                                                                taskIndex,
                                                                                                                subTasksIndex
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                            ) : null}
                                                                                        </TableRow>


                                                                                        {selectedServiceTasks?.some((item) =>
                                                                                            item?.subTasks?.some((subTask) => subTask.clicked && subTask?.subTaskType?.includes('upload'))
                                                                                        ) && expandedSubTask?.taskIndex === taskIndex &&
                                                                                            expandedSubTask?.subTasksIndex === subTasksIndex && (
                                                                                                <TableRow className="zoom-in-box">
                                                                                                    <StyledTableCell colSpan={7} className="px-4 pt-1 pb-3">
                                                                                                        {/* Tabs Component */}
                                                                                                        {userType?.includes(eNums?.AGENT) ?
                                                                                                            <Tabs
                                                                                                                value={activeTab}
                                                                                                                onChange={(event, newValue) => setActiveTab(newValue)}
                                                                                                                indicatorColor="primary"
                                                                                                                textColor="#000"
                                                                                                            >
                                                                                                                <Tab label="Sample Document" sx={{ textTransform: "none" }} />
                                                                                                            </Tabs> : null}

                                                                                                        {/* Tab Content */}
                                                                                                        <Box mt={2} className='d-flex justify-content-between flex-wrap'>
                                                                                                            {activeTab === 0 && (
                                                                                                                <div className="d-flex justify-content-between flex-wrap w-100 mx-2">
                                                                                                                    <div className="w-30 d-flex flex-column">
                                                                                                                        <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">{uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.url ? 'Change' : 'Upload'} File</Typography>
                                                                                                                        {imageLoading ? (
                                                                                                                            <div className="d-flex justify-content-center align-items-center h-100">
                                                                                                                                <CircularProgress />
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <label
                                                                                                                                for="samplefile"
                                                                                                                                class="cm-sample-file-upload cursor-pointer"
                                                                                                                            >
                                                                                                                                {previewDeliverableUrl ? (
                                                                                                                                    <>
                                                                                                                                        <div className="image-preview">
                                                                                                                                            <img
                                                                                                                                                src={previewDeliverableUrl}
                                                                                                                                                alt=""
                                                                                                                                                className="w-100 h-100 rounded"
                                                                                                                                                loading="lazy"
                                                                                                                                                height={'150px'}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <>
                                                                                                                                        <Typography color={'#312D2D'} fontSize={16} fontWeight={400}>
                                                                                                                                            <img
                                                                                                                                                src={imgfile}
                                                                                                                                                className="img-ld"
                                                                                                                                                alt=""
                                                                                                                                            ></img>
                                                                                                                                            Drag Here
                                                                                                                                        </Typography>

                                                                                                                                        <p className="ld-text-1 mt-2 d-flex">
                                                                                                                                            Drag and drop or <span>&nbsp;</span>
                                                                                                                                            <Typography color={'#1E68F9'} fontSize={12} fontWeight={400}>
                                                                                                                                                browse
                                                                                                                                            </Typography> <span>&nbsp;</span>your files
                                                                                                                                        </p>

                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            </label>
                                                                                                                        )}
                                                                                                                        <input
                                                                                                                            type="file"
                                                                                                                            id="samplefile"
                                                                                                                            className="ld-inp-1"
                                                                                                                            accept="image/png, image/jpeg, image/jpg"
                                                                                                                            onChange={(e) =>
                                                                                                                                handleFileUpload(e, "samplefile")
                                                                                                                            }
                                                                                                                        />
                                                                                                                        {uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.url ?
                                                                                                                            <Typography color={'#1E68F9'} fontSize={12} fontWeight={500} className="my-2 cursor-pointer justify-content-end d-flex w-100" onClick={async () => {
                                                                                                                                if (uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.url) {
                                                                                                                                    setIsDownloading(uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.url);

                                                                                                                                    const imgUrl = await getBlobFile(uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.url);

                                                                                                                                    if (imgUrl) {
                                                                                                                                        const fileName = uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.filename || "image.png";
                                                                                                                                        exportBlobFile(imgUrl, fileName);
                                                                                                                                    }

                                                                                                                                    setIsDownloading("");
                                                                                                                                }
                                                                                                                            }}>
                                                                                                                                {isDownloading ? '...' : 'View Sample'}
                                                                                                                            </Typography> : null}
                                                                                                                    </div>
                                                                                                                    <div className="w-45">

                                                                                                                        <Typography fontSize={14} fontWeight={500} color={'#5C5C5C'} className="pb-1">File Description</Typography>
                                                                                                                        <CustomQuill
                                                                                                                            value={
                                                                                                                                uploadedDocs?.find((item) => item?.userType === eNums?.AGENT)?.fileDescription ||
                                                                                                                                ""
                                                                                                                            }
                                                                                                                            readOnly={false}
                                                                                                                            onChange={(value) =>
                                                                                                                                handleChangeSampleFileDescription(value)
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    {serviceEditId ?
                                                                                                                        <div className="d-flex flex-column gap-2 justify-content-end">
                                                                                                                            <Button
                                                                                                                                variant="contained"
                                                                                                                                className="alert-cancel-btn cursor-pointer"
                                                                                                                                onClick={() => handleClickSubTask(expandedSubTask?.taskIndex, expandedSubTask?.subTasksIndex)}
                                                                                                                            >
                                                                                                                                Cancel
                                                                                                                            </Button>
                                                                                                                            <Button
                                                                                                                                variant="contained"
                                                                                                                                className="save-btn cursor-pointer"
                                                                                                                                type="button"
                                                                                                                                onClick={() => {
                                                                                                                                    let updatedServiceTasks = selectedServiceTasks?.map((task) => ({
                                                                                                                                        ...task,
                                                                                                                                        subTasks: task.subTasks.map((subTask) => ({
                                                                                                                                            ...subTask,
                                                                                                                                            uploadedDocuments: subTask?.clicked ? uploadedDocs : subTask?.uploadedDocuments,
                                                                                                                                        })),
                                                                                                                                    }) || []);

                                                                                                                                    setSelectedServiceTasks(updatedServiceTasks);
                                                                                                                                    let obj = servicePayload?.find((item) => item?.clicked)
                                                                                                                                    obj.tasks = updatedServiceTasks;
                                                                                                                                    updateService(obj, serviceEditId);
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {!updateServiceLoading ? "Save Changes" : "Saving..."}
                                                                                                                            </Button>
                                                                                                                        </div> : null}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Box>

                                                                                                        {/* Close Button */}
                                                                                                        {/* <Box mt={2}>
                                                            <Button onClick={() => setExpandedSubTask(null)} variant="contained" color="secondary">
                                                                Close
                                                            </Button>
                                                        </Box> */}
                                                                                                    </StyledTableCell>
                                                                                                </TableRow>
                                                                                            )}

                                                                                    </React.Fragment>
                                                                                )
                                                                            )}
                                                                    </React.Fragment>
                                                                ))
                                                                : null}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>

                                                {userType?.includes(eNums?.AGENT) &&
                                                    Permissions?.clientManagement?.submodules?.clientManagement
                                                        ?.enabled &&
                                                    Permissions?.clientManagement?.submodules?.clientManagement?.permission?.includes(
                                                        eNums?.EDIT
                                                    ) ? (
                                                    <Grid
                                                        item
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "3rem",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            className="cm-create-status cursor-pointer"
                                                            onClick={handleAddStatus}
                                                        >
                                                            + Add Task
                                                        </Button>
                                                    </Grid>
                                                ) : null}

                                            </form>
                                        </div>
                                        {Permissions?.serviceManagement?.submodules?.serviceManagement?.enabled &&
                                            Permissions?.serviceManagement?.submodules?.serviceManagement?.permission?.includes(
                                                eNums?.EDIT
                                            ) ? (
                                            <div className="d-flex align-items-center justify-content-end gap-2 pe-2 mb-1 w-100 position-sticky bottom-0">
                                                <Button
                                                    variant="contained"
                                                    className="tg-button2 cursor-pointer"
                                                    onClick={() => {
                                                        let updatedData = services?.map((item) => ({
                                                            ...item,
                                                            clicked: false,
                                                            edit: false,
                                                            saved: true,
                                                        }));
                                                        setServicePayload(updatedData);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    className="tg-button1 my-2 cursor-pointer"
                                                    type="submit"
                                                >
                                                    Save Changes
                                                </Button>
                                            </div>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </>
                        ) : servicePayload?.length ? (
                            <div className="w-100 d-flex justify-content-center h-100 align-items-center">
                                Select any service to check its details..!
                            </div>
                        ) : (
                            <EmptyState />
                        )}
                    </form>
                </div>

                {snackbar?.open ? (
                    <CustomizedSnackbar
                        open={snackbar.open}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                    />
                ) : null}
            </NewLayout>
        </>
    );
}

export default ServiceManagementCreateCategory;