import { createContext, useContext, useState } from "react";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setRolePermissions] = useState({});

  return (
    <PermissionsContext.Provider value={{ permissions, setRolePermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
