import React from 'react'
import EmptyStateImg from '../assets/images/empty_state.svg'

const emptyState = ({ message, height, type }) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center w-100' style={{ height: height ?? "60vh" }}>
      <img src={EmptyStateImg} className='img img-fluid' alt='' width={300}/>
      {message ? <h5 className='fs-small theme-dark-color mt-16'>{message}</h5> :
        // <h5 className='fs-header theme-dark-color mt-16'>{"No Data Found"}</h5>
        null
        } 
    </div>
  )
}
export default emptyState;
