import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import NotificationSettings from "../assets/images/notification-settings.svg";
import Email from "../assets/images/email.svg";
import PushNoti from "../assets/images/direct-send.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ApiService, useApi } from "../Utilities";
import { useNavigate } from "react-router-dom";

function SettingsNotification({ data }) {
  const [emailNoti, setEmailNoti] = useState(
    data?.preferences?.notifications?.email
  );
  const [pushNoti, setPushNoti] = useState(
    data?.preferences?.notifications?.pushNotifications
  );
  const navigate = useNavigate();

  const { action: updateUser } = useApi(ApiService.updateUser, {
    noStore: true,
    success: true,
    error: true,
  });

  const handleCheckboxChange = async (type, e) => {
    const updatedData = {
      ...data,
      preferences: {
        ...data.preferences,
        notifications: {
          ...data.preferences.notifications,
          [type]: e.target.checked,
        },
      },
    };
    await updateUser(updatedData, data?._id);
  };

  const paths = [
    { label: "Home", navigation: "dashboard" },
    { label: "Settings", navigation: "settings" },
    { label: "Notification Settings" },
  ];

  return (
    <>
      <Grid item className="d-flex flex-column gap-1 position-sticky top-0 bg-white">
        {paths?.length ? (
          <Grid item className="d-flex align-items-center pt-2">
            {paths?.map((item, index) => (
              <div>
                <span
                  className="main-path-text"
                  onClick={() => {
                    if (item?.navigation) {
                      navigate(`/${item?.navigation}`);
                    }
                  }}
                >
                  {item?.label}
                </span>
                <span className="mx-1">
                  {index !== paths?.length - 1 && "/"}
                </span>
              </div>
            ))}
          </Grid>
        ) : null}
        <Grid item>
          <h5 className="sp-typo d-flex align-items-center gap-2">
            <img src={NotificationSettings} alt="" width={20} height={20} />
            <span className="settings-subtitle">Notification Settings</span>
          </h5>
        </Grid>
      </Grid>

      <Grid item className="spn-wrap11">
        <Grid item className="spn-wrap1 d-flex align-items-center">
          <Grid item className="textWrap w-30">
            <div className="d-flex align-items-center gap-2">
              <img src={Email} alt="" width={18} height={18} />
              <span className="spn-key-title-text">Email Notifications</span>
            </div>
            <Grid item className="textWrap2">
              <span className="spn-key-subtitle-text">
                Get emails to find out what’s going on when you’re not online.
                You can turn these off.
              </span>
            </Grid>
          </Grid>
          <Grid item className="spn-wrap4 w-70 d-flex justify-content-center">
            <label className="ios-switch spl-switch mt-1">
              <input
                type="checkbox"
                checked={emailNoti}
                onChange={(e) => {
                  setEmailNoti(e.target.checked);
                  handleCheckboxChange("email", e);
                }}
              />
              <div className="slider"></div>
            </label>
            <Grid item className="spn-notifWrap">
              <Typography
                sx={{ fontSize: ".8em", fontWeight: "700", color: "#2F3146" }}
              >
                News and updates
              </Typography>
              <Typography
                sx={{ fontSize: ".8em", fontWeight: "500", color: "#52565F" }}
              >
                News about product and feature updates.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="spn-wrap11">
        <Grid item className="spn-wrap1 d-flex align-items-center">
          <Grid item className="textWrap w-30">
            <div className="d-flex align-items-center gap-2">
              <img src={PushNoti} alt="" width={18} height={18} />
              <span className="spn-key-title-text">Push Notifications</span>
            </div>
            <Grid item className="textWrap2">
              <span className="spn-key-subtitle-text">
                Get push notifications to find out what’s going on when you’re
                not online. You can turn these off.
              </span>
            </Grid>
          </Grid>
          <Grid item className="spn-wrap4 w-70 d-flex justify-content-center">
            <label className="ios-switch spl-switch mt-1">
              <input
                type="checkbox"
                checked={pushNoti}
                onChange={(e) => {
                  setPushNoti(e.target.checked);
                  handleCheckboxChange("pushNotifications", e);
                }}
              />
              <div className="slider"></div>
            </label>
            <Grid item className="spn-notifWrap">
              <Typography
                sx={{ fontSize: ".8em", fontWeight: "700", color: "#2F3146" }}
              >
                News and updates
              </Typography>
              <Typography
                sx={{ fontSize: ".8em", fontWeight: "500", color: "#52565F" }}
              >
                News about product and feature updates.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SettingsNotification;
