import React, { useState } from 'react'
// import { useEffect } from 'react';
import ServicesSideBar from '../../Components/ServicesSideBar'
import logo from '../../assets/images/FileNowLogo.svg'
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LegalDocumentCard from '../../Components/LegalDocumentCard';
import Header from '../../Components/Header';
import NewLayout from "../../Components/SidebarLayout";
import axiosInstance from '../../Utilities/axiosInstance'
import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ImageExpand from '../../Components/ImageExpand';
const filters = [
    "filter1",
    "filter2",
    "filter3",
    "filter4"
]

function LegalDocumentation() {

    const [filter, setFilter] = useState("")

    var res = [
    
    ]
   
  
    const [data, setData] = useState(res)
  
    const getdata = async() => {
      // axiosInstance.get('/myservices/647926a96d35fa252c7de13b')
        try {
            const response = await axiosInstance.get('/legaldocs');
            const res =await response.map((item) => item);
            // for(var i=0; i<res.length; i++){
            //   var date = new Date(res[0].createdOn);
            //   var formattedDate = moment(date).format('DD-MM-YYYY');
            //   res[0]["createdOn"] = formattedDate
            // }
            // alert(res)
            setData(res)
        } catch (error) {
          console.log(error);
        }
    }
    
    // useEffect(()=> {
    //   res = getdata()
      
    // }, [])

    const handleChange = (event) => {
        setFilter(event.target.value);
        
    };
getdata();

  const paths = [
    { label: `Legal Documents`, navigation: "legal-documentation" },
  ];

  return (
    <>
        <NewLayout
          paths={paths}
          pageHeading={"Legal Documents"}
      >
         
          <div className='myServicesContainer'>
          
            <Grid container className='pt-2'>

<Grid container direction="row">
  <Grid item sx={{margin:"1vh"}}>
    Housing
  </Grid>
  <Grid item sx={{margin:"1vh"}}>
    Business
  </Grid>

</Grid>
<ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
<Grid container direction="column">
<Grid item sx={{margin:"2vh"}}>
<h3>Rental Documents</h3>
</Grid>
<Grid container direction="row">
<Grid item sx={{margin:"2vh"}} direction="column"> 
<Grid item> <ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
</Grid>
<Grid item>
  <p>Rental Document- 1 year Agreement</p>
</Grid>
</Grid>
<Grid item sx={{margin:"2vh"}} direction="column"> 
<Grid item> <ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
</Grid>
<Grid item>
  <p>Rental Document- 1 year Agreement</p>
</Grid>
</Grid>
<Grid item sx={{margin:"2vh"}} direction="column"> 
<Grid item> <ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
</Grid>
<Grid item>
  <p>Rental Document- 1 year Agreement</p>
</Grid>
</Grid>
</Grid>

<Grid item sx={{margin:"2vh"}}>
<h3>Non Disclosure Agreement</h3>
</Grid>
<Grid container direction="row">
<Grid item sx={{margin:"2vh"}} direction="column"> 
<Grid item> <ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
</Grid>
<Grid item>
  <p>Rental Document- 1 year Agreement</p>
</Grid>
</Grid>
<Grid item sx={{margin:"2vh"}} direction="column"> 
<Grid item> <ImageExpand  imageUrl='https://digital.library.unt.edu/ark:/67531/metadc851827/m1/1/med_res/'/>
</Grid>
<Grid item>
  <p>Rental Document- 1 year Agreement</p>
</Grid>
</Grid>
</Grid>
<Grid item display="flex" justifyContent="center" margin={10} >
<Pagination count={10}  variant="outlined" shape="rounded" showFirstButton showLastButton />
</Grid>
</Grid>


        </Grid>
            <div className='legaldoc_cardContainer'>
                {
                    data.map((item, index)=>{
                        return(
                            <LegalDocumentCard
                              detail={item.title}
                              OnClickCard={()=>{alert("Downloading from url "+item.URL)}}
                            />
                        )
                    })
                }
            </div>
          </div>
       
        </NewLayout>
    </>
  )
}

export default LegalDocumentation