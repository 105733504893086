import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import NewLayout from "../../Components/SidebarLayout";
import {
  useApi,
  ApiService,
  EmptyState,
  CustomQuill,
  CustomizedSnackbar,
} from "../../Utilities";
import Loader from "../../Components/Loader";
import edit from "../../assets/images/edit-img.svg";
import deleteImg from "../../assets/images/delete.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

function ComplianceCalendarAdmin() {
  const userId = localStorage.getItem("userId");
  const [editId, setEditId] = useState("");
  const [monthName, setMonthName] = useState("");
  const [events, setEvents] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const handleSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const {
    action: createEvent,
    snackbarComponent: createSnackbarComponent,
    error: createEventError,
    loading: createEventLoading,
  } = useApi(ApiService.createEvent, {
    success: true,
    error: true,
    noStore: true,
    actionName: "Event creating",
  });

  const {
    action: updateEvent,
    snackbarComponent: updateSnackbarComponent,
    error: updateEventError,
    loading: updateEventLoading,
  } = useApi(ApiService.updateEvent, {
    success: true,
    error: true,
    noStore: true,
    actionName: "Event updating",
    initialLoad: true,
  });

  const {
    action: deleteEvent,
    snackbarComponent: deleteSnackbarComponent,
    error: deleteEventError,
    loading: deleteEventLoading,
  } = useApi(ApiService.deleteEvent, {
    success: true,
    error: true,
    noStore: true,
    actionName: "Event deleting",
  });

  const {
    action: getAllEvents,
    data: allEvents,
    snackbarComponent: allEventsSnackbarComponent,
    error: allEventsError,
    loading: allEventsLoading,
  } = useApi(ApiService.getAllEvents, {
    success: true,
    error: true,
    actionName: "Event fetching",
  });

  const currentMonthNumber = moment().month();
  useEffect(() => {
    handleMonthChange(currentMonthNumber, selectedYear);
  }, []);

  const [months, setMonths] = useState([
    { label: "JAN", value: 0, clicked: false, name: "January" },
    { label: "FEB", value: 1, clicked: false, name: "February" },
    { label: "MAR", value: 2, clicked: false, name: "March" },
    { label: "APR", value: 3, clicked: false, name: "April" },
    { label: "MAY", value: 4, clicked: false, name: "May" },
    { label: "JUN", value: 5, clicked: false, name: "June" },
    { label: "JUL", value: 6, clicked: false, name: "July" },
    { label: "AUG", value: 7, clicked: false, name: "August" },
    { label: "SEP", value: 8, clicked: false, name: "September" },
    { label: "OCT", value: 9, clicked: false, name: "October" },
    { label: "NOV", value: 10, clicked: false, name: "November" },
    { label: "DEC", value: 11, clicked: false, name: "December" },
  ]);

  useEffect(() => {
    if (allEvents?.length) {
      const modifiedEvents = allEvents.map((event, i) => {
        return {
          ...event,
          clicked: i === 0 ? true : false,
          edit: false,
          saved: true,
        };
      });

      setEvents(modifiedEvents);
    } else {
      setEvents([]);
    }
    setEditId("");
  }, [allEvents]);

  const handleAddEvent = () => {
    setEditId("");
    let updatedData = events?.map((item) => {
      return { ...item, clicked: false, edit: false };
    });

    if (
      updatedData.length === 0 ||
      updatedData[updatedData.length - 1]?.saved
    ) {
      let obj = {
        eventName: "",
        clicked: true,
        edit: false,
        saved: false,
        dueDate: "",
        createdOn: new Date(),
        updatedOn: new Date(),
        createdBy: userId,
        updatedBy: userId,
        context: "",
        new: true
      };
      updatedData.push(obj);
    } else {
      updatedData[updatedData.length - 1] = {
        ...updatedData[updatedData.length - 1],
        clicked: true,
        lastModifiedOn: new Date(),
        modifiedBy: userId,
      };
      handleSnackbar("Please save current event", "error");
    }

    setEvents(updatedData);
  };

  const handleEventDueDateChange = (value, index) => {
    const updatedEvents = [...events];
    updatedEvents[index].dueDate = value;
    setEvents(updatedEvents);
  };

  const handleEventNameChange = (value, index) => {
    const updatedEvents = [...events];
    updatedEvents[index].eventName = value;
    setEvents(updatedEvents);
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    if (events?.length) {
      const hasUnsavedOrClickedEvents = events?.some(
        (item) => !item?.saved || item?.edit || item?.clicked
      );
      if (hasUnsavedOrClickedEvents) {
        if (!editId && !(events?.some((item) => item?.saved)?.length)) {
          let obj = events?.find((item) => !item?.saved);
          if (!obj?.eventName) {
            handleSnackbar("Please enter name to save", "error");
            return;
          }
          if (!obj?.dueDate) {
            handleSnackbar("Due date is required to save", "error");
            return;
          }
          await createEvent(obj);
        } else {
          if (editId) {
            let obj = events?.find((item) => item?.clicked);

            if (!obj?.eventName) {
              handleSnackbar("Please enter name to save", "error");
              return;
            }
            if (!obj?.dueDate) {
              handleSnackbar("Due date is required to save", "error");
              return;
            }

            obj.updatedOn = new Date();
            setLoading(true);
            await updateEvent(obj, (editId || obj?._id));
            setLoading(false);
          }
          else {
            handleSnackbar("No event selected to save", "error");
          }
        }

        handleMonthChange(
          months?.find((item) => item?.clicked)?.value,
          selectedYear
        );
      } else {
        handleSnackbar("No event selected to save", "error");
      }
    }
  };

  const handleEditEvent = (id, index) => {
    setEditId(id);
    let updatedData =
      (events &&
        events?.length &&
        events.map((item, i) => ({
          ...item,
          edit: i === index ? true : false,
          clicked: i === index ? true : false,
        }))) ||
      [];

    setEvents(updatedData);
  };

  const handleClickEvent = (index) => {
    if (!editId) {
      if (
        events &&
        events?.filter((item) => !item?.saved)?.length &&
        events?.[index]?.saved
      ) {
        handleSnackbar("Please save current event", "error");
      } else {
        let updatedData =
          (events &&
            events?.length &&
            events.map((item, i) => ({
              ...item,
              clicked: i === index ? true : false,
              edit: item?._id === editId ? true : false,
            }))) ||
          [];

        setEvents(updatedData);
      }
    }
  };

  const handleDeleteEvent = async (id, index) => {
    if (id) {
      await deleteEvent(id);
      handleMonthChange(
        months?.find((item) => item?.clicked)?.value,
        selectedYear
      );
    } else {
      const updatedData = events?.filter((_, i) => i !== index);
      setEvents(updatedData);
    }
  };

  const handleMonthChange = (monthNumber, year) => {
    const updatedMonths = months?.map((month) => ({
      ...month,
      clicked: month?.value === monthNumber ? true : false,
    }));
    setMonths(updatedMonths);
    setMonthName(months?.find((item) => item?.value === monthNumber)?.name);

    const selectedMonth = moment().month(monthNumber).year(year);
    const firstDay = selectedMonth.startOf("month");
    const formattedFirstDay = firstDay.format("YYYY-MM-DD");
    const lastDay = selectedMonth.endOf("month");
    const formattedLastDay = lastDay.format("YYYY-MM-DD");

    getAllEvents({ fromDate: formattedFirstDay, toDate: formattedLastDay });
  };


  const handleYearChange = (value) => {
    setSelectedYear(value?.$y);
    handleMonthChange(months?.find((item) => item?.clicked)?.value, value?.$y);
  };

  const paths = [
    { label: `Compliance Calendar`, navigation: "compliance-calendar" },
  ];

  const handleEditContext = (value) => {
    setEvents((prevEvent) =>
      prevEvent?.map((item) =>
        item.clicked
          ? {
            ...item,
            context: value,
          }
          : item
      )
    );
  };

  return (
    <>
      {createSnackbarComponent}{updateSnackbarComponent}{deleteSnackbarComponent}
      <Grid container className="d-flex">
        <NewLayout paths={paths} pageHeading={"Compliance Calendar"}>
          <Grid item className="wrap-settings-splitB">
            <Grid item container>
              <div className="w-100 d-flex my-2 justify-content-end">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year"]}
                    label="Select Year"
                    value={dayjs().set("year", selectedYear)}
                    onChange={handleYearChange}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        width: "10rem",
                        height: "2rem",
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <Grid container className="cc-calendar">
                {months?.map((month) => (
                  <Grid
                    key={month}
                    className="cc-month-wrap cursor-pointer"
                    onClick={() =>
                      handleMonthChange(month?.value, selectedYear)
                    }
                  >
                    <Grid
                      item
                      className="cc-month"
                      style={{
                        backgroundColor: month?.clicked ? "#b2ccfb" : "white",
                      }}
                    >
                      <Typography>{month?.label}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid container className="cc-month-wrap">
                <Typography fontWeight="bold">
                  {monthName || ""} Compliances
                </Typography>
              </Grid>

              <Grid
                className="d-flex w-100"
                style={{ height: "70vh", minWidth: "1000px" }}
                sx={{ border: "0.5px solid #9D9D9D" }}
              >
                <Grid
                  className="w-25"
                  style={{ borderRight: "0.5px solid #9D9D9D" }}
                >
                  <Grid
                    style={{
                      height: "90%",
                      borderBottom: "0.5px solid #9D9D9D",
                      overflowY: "auto",
                    }}
                  >
                    {events?.length ? (
                      events?.map((item, index) => (
                        <form
                          key={index}
                          className="d-flex cursor-pointer justify-content-between w-100 align-items-center"
                          style={{
                            ...(item?.clicked && {
                              backgroundColor: "#F2F2F2",
                            }),
                            borderBottom: "0.5px solid #9D9D9D",
                            paddingInline: "1rem",
                            paddingBlock: ".5rem",
                          }}
                          onSubmit={handleSubmitEvent}
                        >
                          <div
                            className="d-flex flex-column gap-2 w-75"
                            onClick={() => handleClickEvent(index)}
                          >
                            <Grid
                              item
                              display="flex"
                              style={{ alignItems: "center" }}
                            >
                              <Typography variant="body2" fontWeight="bold">
                                Due Date
                              </Typography>
                              {item?.edit || !item?.saved ? (
                                <input
                                  placeholder="eg. JAN 01 2024"
                                  value={item?.dueDate}
                                  type="date"
                                  className="d-flex align-items-center ms-1"
                                  style={{ border: "none" }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;

                                    // Check if the year part of the date exceeds 4 digits
                                    const yearPart = inputValue.split("-")[0]; // Extract year
                                    if (yearPart.length > 4) {
                                      return;
                                    }

                                    handleEventDueDateChange(inputValue, index);
                                  }}
                                />

                              ) : (
                                <Typography
                                  variant="body2"
                                  fontWeight="thin"
                                  style={{ marginLeft: ".5rem" }}
                                >
                                  {item?.dueDate}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              display="flex"
                              style={{
                                wordBreak: "break-word",
                                alignItems: "center",
                              }}
                            >
                              {item?.edit || !item?.saved ? (
                                <input
                                  placeholder="Enter Event Name"
                                  defaultValue={item?.eventName}
                                  className="w-100"
                                  style={{ border: "none" }}
                                  onChange={(e) =>
                                    handleEventNameChange(e.target.value, index)
                                  }
                                />
                              ) : (
                                <Typography variant="body2d-flex flex-wrap">
                                  {item?.eventName}
                                </Typography>
                              )}
                            </Grid>
                          </div>
                          <div className={`d-flex ms-4 gap-2`}>
                            {item?.saved ? (
                              <img
                                src={edit}
                                alt=""
                                width={16}
                                height={16}
                                className="cursor-pointer"
                                onClick={() =>
                                  handleEditEvent(item?._id, index)
                                }
                              />
                            ) : (
                              <></>
                            )}
                            <img
                              src={deleteImg}
                              alt=""
                              width={16}
                              height={16}
                              className="delete cursor-pointer"
                              onClick={() =>
                                handleDeleteEvent(item?._id || "", index)
                              }
                            />
                          </div>
                        </form>
                      ))
                    ) : (
                      <EmptyState />
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10%",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="cc-btn1 cursor-pointer py-2"
                      onClick={handleAddEvent}
                    >
                      Create New Compliance +
                    </Button>
                  </Grid>
                </Grid>

                <Grid className="w-75 h-100">
                  {events?.filter((item) => item?.clicked)?.length ? (
                    <>
                      <form
                        style={{
                          height: '90%',
                          borderBottom: "0.5px solid #9D9D9D",
                        }}
                        onSubmit={handleSubmitEvent}
                      >
                        <Grid
                          className="d-flex align-items-center justify-content-between"
                          item
                          style={{
                            borderBottom: "0.5px solid #9D9D9D",
                            paddingInline: "1rem",
                            paddingBlock: ".62rem",
                            minHeight: "10%",
                          }}
                        >
                          {events?.filter(
                            (item) => item?.clicked && item?.saved
                          )?.length ? (
                            <div className="d-flex flex-column gap-2">
                              <Grid
                                item
                                display="flex"
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  {
                                    events?.find((item) => item?.clicked)
                                      ?.eventName
                                  }
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                display="flex"
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  Due Date:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontWeight="thin"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  {
                                    events?.find((item) => item?.clicked)
                                      ?.dueDate
                                  }
                                </Typography>
                              </Grid>
                            </div>
                          ) : null}
                          <p></p>
                          <Button
                            type="submit"
                            variant="contained"
                            className="cc-btn1 cursor-pointer"
                            style={{ justifyContent: "end" }}
                          >
                            Save
                          </Button>
                        </Grid>

                        <Grid
                          item
                          style={{
                            paddingInline: "2rem",
                            paddingTop: "1rem",
                            height: '80%',
                            overflow: 'auto'
                          }}
                        >
                          <CustomQuill
                            value={
                              events?.find((item) => item?.clicked)?.context ||
                              ""
                            }
                            onChange={(value) => handleEditContext(value)}
                          />
                        </Grid>
                      </form>

                      <Grid
                        item
                        className="d-flex align-items-center justify-content-end"
                        container
                        style={{
                          height: "10%",
                        }}
                      >

                        {/* {events?.filter((item) => item?.clicked && item?.saved)
                        ?.length ? (
                          <Button
                            variant="contained"
                            className="cc-btn1 cursor-pointer py-3 me-2"
                          >
                            File TDS Payment
                          </Button>
                      ) : null} */}
                      </Grid>
                    </>
                  ) : (
                    <EmptyState />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {snackbar?.open ? (
            <CustomizedSnackbar
              open={snackbar?.open}
              message={snackbar?.message}
              severity={snackbar?.severity}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
          ) : null}
        </NewLayout>
      </Grid>
    </>
  );
}

export default ComplianceCalendarAdmin;
