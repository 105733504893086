import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomQuill = ({ value, readOnly = false, modules, theme, ...props }) => {
  const defaultModules = {
    toolbar: readOnly ? false : [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <div className="custom-quill-container">
      <ReactQuill
        value={value}
        readOnly={readOnly}
        modules={modules || defaultModules}
        theme={theme || "snow"}
        style={{
          backgroundColor: readOnly ? "transparent" : "white",
          height: "auto",
        }}
        {...props}
      />
    </div>
  );
};

export default CustomQuill;
