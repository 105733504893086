import Dashboard from "../assets/images/sidebar/dashboard.svg";
import ServicesManagement from "../assets/images/sidebar/service_management.svg";
import Compliance_Calender from "../assets/images/sidebar/compliance_calender.svg";
import Clients from "../assets/images/sidebar/client_management.svg";
import Employees from "../assets/images/sidebar/employee_managment.svg";
import LegalDocuments from "../assets/images/sidebar/legal_documents.svg";
import Orders_Payments from "../assets/images/sidebar/orders_&_payments.svg";
import AuditTrial from "../assets/images/sidebar/audit_trial.svg";
import Notifications from "../assets/images/notifications-icon.svg";
import eNums from "./enums";

const useRolePermissions = [
  {
    name: "Dashboard",
    key: "dashboard",
    imgSrc: Dashboard,
    submodules: [
      { name: "Dashboard Setup", key: "dashboardSetup", value: eNums?.VIEW, enabled: true },
      { name: "Dashboard Main", key: "dashboardMain", value: eNums?.VIEW, enabled: true },
    ],
    clicked: true,
    enabled: true,
  },
  {
    name: "Service Management",
    key: "serviceManagement",
    imgSrc: ServicesManagement,
    submodules: [
      { name: "Service Dashboard", key: "serviceDashboard", value: eNums?.VIEW, enabled: true  },
      { name: "Service Management", key: "serviceManagement", value: eNums?.VIEW, enabled: true  },
      { name: "Service Details", key: "serviceDetails", value: eNums?.VIEW, enabled: true  },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Client Management",
    key: "clientManagement",
    imgSrc: Clients,
    submodules: [
      { name: "Client Dashboard", key: "clientDashboard", value: eNums?.VIEW, enabled: true },
      { name: "Client Management", key: "clientManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Employee Management",
    key: "employeeManagement",
    imgSrc: Employees,
    submodules: [
      { name: "Employee Management", key: "employeeManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Legal Documents",
    key: "legalDocuments",
    imgSrc: LegalDocuments,
    submodules: [
      { name: "Legal Documents", key: "legalDocManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Orders & Payments",
    key: "ordersAndPayments",
    imgSrc: Orders_Payments,
    submodules: [
      { name: "Orders and Payments", key: "ordAndPayManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Audit Trail",
    key: "auditTrail",
    imgSrc: AuditTrial,
    submodules: [
      { name: "Audit Trial", key: "auditTrailManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Notifications",
    key: "notifications",
    imgSrc: Notifications,
    submodules: [
      { name: "Notifications", key: "notificationManagements", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Compliance Calendar",
    key: "complianceCalendar",
    imgSrc: Compliance_Calender,
    submodules: [
      { name: "Compliance Calender", key: "calendarManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
  {
    name: "Settings",
    key: "settings",
    imgSrc: Compliance_Calender,
    submodules: [
      { name: "Role Management", key: "roleManagement", value: eNums?.VIEW, enabled: true },
    ],
    clicked: false,
    enabled: true
  },
];

export default useRolePermissions;
