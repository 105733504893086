import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

const AlertDialog = ({
  title,
  openDialog,
  handleClose,
  handleAction,
  loading,
  actionText,
  loadingText,
  children,
  backdropClose,
  ...props
}) => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const userName = localStorage.getItem("user_name");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleDialogClose = (event, reason) => {
    // Prevent closing if the reason is "backdropClick" or "escapeKeyDown"
    if (
      backdropClose &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    handleClose();
  };

  const handleClickProfile = (e) => {
    setAnchorEl(e?.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/`);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      PaperProps={{
        style: {
          width: "fit-content",
          maxWidth: "90vw",
          margin: "auto",
        },
      }}
    >
      {title ? (
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex align-items-center justify-content-between w-100 gap-5"
        >
          {title || ""}
          {props?.noCloseIcon ? null : (
            <CloseIcon
              fontSize="inherit"
              sx={{ color: "grey" }}
              className="cursor-pointer"
              onClick={handleDialogClose}
            />
          )}
        </DialogTitle>
      ) : null}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="d-flex flex-column"
          style={{ whiteSpace: "nowrap" }}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-3">
        {props?.noCancelBtn ? null : (
          <Button
            variant="contained"
            className="alert-cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        )}
        {props?.noActionBtn ? null : (
          <Button
            variant="contained"
            className="alert-action-btn"
            onClick={handleAction}
            autoFocus
            disabled={loading}
          >
            {loading ? loadingText || "Loading..." : actionText || "Save"}
          </Button>
        )}
      </DialogActions>
      {backdropClose && props?.showLogout && (
        <>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickProfile}
            sx={{
              position: "fixed",
              top: 6,
              right: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
              <div className="user-profile-icon d-flex align-items-center justify-content-center cursor-pointer">
                {userName?.[0] || "P"}
              </div>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </>
      )}
    </Dialog>
  );
};

export default AlertDialog;
