import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Notify from "../../Components/Notify";
import Pagination from "@mui/material/Pagination";
import NewLayout from "../../Components/SidebarLayout";

function Notifications() {

  const [type, setType] = useState('ALL')
  const data = [
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: true,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: false,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: true,
      ad: false,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: false,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: false,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: false,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: false,
      ad: true,
    },
    {
      name: "vish",
      text: "Aadhar uploaded recently",
      time: "2h",
      view: true,
      ad: false,
    },
  ];
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const paths = [
    { label: `Notifications`, navigation: "notifications" },
  ];

  return (
    <>
      <NewLayout
        paths={paths}
        pageHeading={"Notifications"}
      >
        
        <Grid className="Notifynav">
          <Grid item className="Notifynav1">
            <Typography className={`typo1 ${type === "ALL" ? 'typoselect' : ''}`} onClick={() => setType('ALL')}>All</Typography>
            <Typography className={`typo1 ${type === "SERVICE" ? 'typoselect' : ''}`} onClick={() => setType('SERVICE')}>Service</Typography>
            <Typography className={`typo1 ${type === "GENERAL" ? 'typoselect' : ''}`} onClick={() => setType('GENERAL')}>General</Typography>
          </Grid>
        </Grid>
        {currentData.map((item, index) => (
          <Notify
            key={index}
            name={item.name}
            text={item.text}
            time={item.time}
            view={item.view}
            ad={item.ad}
          />
        ))}
        <Grid container className="NotifyPagination">
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Grid>
      </NewLayout>
    </>
  );
}

export default Notifications;
